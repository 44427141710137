.image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 270px;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  .overlay {
    position:relative;
    width: 100%;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);

}
