.name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 14px;
    /* identical to box height, or 70% */
    
    letter-spacing: 0.25px;
    
    /* black 2 */
    
    color: #25282B;

}

.imageSize {
    width:50px;
    height:50px;
    border-radius:50%;
    padding-right: 2px;
   }

   .extra {
    display: flex;

    margin: 0 30px;

    div {
        margin: 0 10px;
    }
}