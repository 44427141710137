.wrapper {
    position: relative;
    height: 450px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1;
}

.container {
    z-index: 2;
    // margin: 0 150px;
}

.box
{

    border:2px solid rgba(255,255,255, 0.9);;
    z-index:1; 
    position:relative;
    padding: 20px;
    margin: 10px;
}

.box:before {
    margin-top: 17px;
    position:absolute;
    top:-20px; 
    left:20%;
    content:"";
    display:block;
    height:5px;
    width:60%;
    background: rgba(0, 0, 0, 0.9);
}

.box:after {
    position:absolute;
    bottom:-10px; 
    left:10%;
    content:"";
    display:block;
    height:10px;
    width:80%;
    // background:#111;
}
