.wrapper {
    background-color: white;
    width: 100%;
    height: 750px;
    border-radius: 20px;
    margin-top: 20px;

    .search {
        padding: 20px;
        width: 100%;
        height: 75px;
        display: flex;


        .inputIcons {

            height: 100%;
            border-radius: 20px;
            display: flex;

            .inputField {

                display: flex;
                height: 100%;
                padding: 10px;
                border-radius: 20px;
            }
        }
    }
}