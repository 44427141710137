
$primary: #258E01;

@import '../../../node_modules/bootstrap/scss/bootstrap';

html, body {
    width: 100%;
    padding:0;
    margin:0;
}
