.o8EKTa_wrapper {
  height: 100vh;
  width: 100vw;
}

.o8EKTa_login {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.o8EKTa_login h1 {
  letter-spacing: 1.02833px;
  color: #fff;
  padding-top: 3rem;
  padding-bottom: 2rem;
  font-family: Electrolux Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

@media (max-width: 768px) {
  .o8EKTa_login h1 {
    font-size: 27px;
  }
}

.o8EKTa_login h2 {
  text-align: center;
  color: #3f434a;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
}

@media (max-width: 768px) {
  .o8EKTa_login h2 {
    font-size: 23px;
  }
}

.o8EKTa_login .o8EKTa_card {
  width: 540px;
  border-radius: 20px;
}

.o8EKTa_login .o8EKTa_card > div {
  margin: 0;
  padding: 0 3rem;
}

@media (max-width: 768px) {
  .o8EKTa_login .o8EKTa_card > div {
    padding: 0 2rem;
  }

  .o8EKTa_login .o8EKTa_card {
    width: 100%;
  }
}

.lQ2BJq_wrapper {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

.lQ2BJq_register {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.lQ2BJq_register h1 {
  letter-spacing: 1.02833px;
  color: #fff;
  padding-top: 3rem;
  padding-bottom: 2rem;
  font-family: Electrolux Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

@media (max-width: 768px) {
  .lQ2BJq_register h1 {
    font-size: 27px;
  }
}

.lQ2BJq_register h2 {
  text-align: center;
  color: #3f434a;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
}

@media (max-width: 768px) {
  .lQ2BJq_register h2 {
    font-size: 23px;
  }
}

.lQ2BJq_register .lQ2BJq_card {
  width: 540px;
  border-radius: 20px;
  margin-bottom: 2rem;
}

.lQ2BJq_register .lQ2BJq_card > div {
  margin: 0;
  padding: 0 3rem;
}

@media (max-width: 768px) {
  .lQ2BJq_register .lQ2BJq_card > div {
    padding: 0 2rem;
  }

  .lQ2BJq_register .lQ2BJq_card {
    width: 100%;
  }
}

.M3CCRW_footer {
  color: #fff;
  padding: 80px 80px 30px;
  position: relative;
}

.M3CCRW_footer .M3CCRW_tint {
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #000000e6;
  position: absolute;
  top: 0;
  left: 0;
}

.M3CCRW_footer div, .M3CCRW_footer span {
  z-index: 1;
}

.M3CCRW_footer h5 {
  margin-bottom: 25px;
}

.M3CCRW_footer a {
  all: unset;
  margin: 10px 0;
  display: block;
}

.M3CCRW_footer input {
  all: unset;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 18px;
}

.M3CCRW_footer .M3CCRW_logo {
  height: 45px;
}

.M3CCRW_footer .M3CCRW_info {
  margin: 30px 0;
}

.M3CCRW_footer .M3CCRW_address .M3CCRW_line {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.M3CCRW_footer .M3CCRW_address .M3CCRW_line span {
  margin-left: 10px;
}

.S0jenW_mainButton {
  color: #fff;
  min-width: 120px;
  padding-left: 30px;
  padding-right: 30px;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #258e01;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 37, 142, 1;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #258e01;
  --bs-link-hover-color: #1e7201;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .sWSNaq_h6, h5, .sWSNaq_h5, h4, .sWSNaq_h4, h3, .sWSNaq_h3, h2, .sWSNaq_h2, h1, .sWSNaq_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .sWSNaq_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .sWSNaq_h1 {
    font-size: 2.5rem;
  }
}

h2, .sWSNaq_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .sWSNaq_h2 {
    font-size: 2rem;
  }
}

h3, .sWSNaq_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .sWSNaq_h3 {
    font-size: 1.75rem;
  }
}

h4, .sWSNaq_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .sWSNaq_h4 {
    font-size: 1.5rem;
  }
}

h5, .sWSNaq_h5 {
  font-size: 1.25rem;
}

h6, .sWSNaq_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .sWSNaq_small {
  font-size: .875em;
}

mark, .sWSNaq_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.sWSNaq_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.sWSNaq_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .sWSNaq_display-1 {
    font-size: 5rem;
  }
}

.sWSNaq_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .sWSNaq_display-2 {
    font-size: 4.5rem;
  }
}

.sWSNaq_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .sWSNaq_display-3 {
    font-size: 4rem;
  }
}

.sWSNaq_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .sWSNaq_display-4 {
    font-size: 3.5rem;
  }
}

.sWSNaq_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .sWSNaq_display-5 {
    font-size: 3rem;
  }
}

.sWSNaq_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .sWSNaq_display-6 {
    font-size: 2.5rem;
  }
}

.sWSNaq_list-unstyled, .sWSNaq_list-inline {
  padding-left: 0;
  list-style: none;
}

.sWSNaq_list-inline-item {
  display: inline-block;
}

.sWSNaq_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.sWSNaq_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.sWSNaq_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.sWSNaq_blockquote > :last-child {
  margin-bottom: 0;
}

.sWSNaq_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.sWSNaq_blockquote-footer:before {
  content: "— ";
}

.sWSNaq_img-fluid {
  max-width: 100%;
  height: auto;
}

.sWSNaq_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.sWSNaq_figure {
  display: inline-block;
}

.sWSNaq_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.sWSNaq_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.sWSNaq_container, .sWSNaq_container-fluid, .sWSNaq_container-xxl, .sWSNaq_container-xl, .sWSNaq_container-lg, .sWSNaq_container-md, .sWSNaq_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .sWSNaq_container-sm, .sWSNaq_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .sWSNaq_container-md, .sWSNaq_container-sm, .sWSNaq_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .sWSNaq_container-lg, .sWSNaq_container-md, .sWSNaq_container-sm, .sWSNaq_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_container-xl, .sWSNaq_container-lg, .sWSNaq_container-md, .sWSNaq_container-sm, .sWSNaq_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_container-xxl, .sWSNaq_container-xl, .sWSNaq_container-lg, .sWSNaq_container-md, .sWSNaq_container-sm, .sWSNaq_container {
    max-width: 1320px;
  }
}

.sWSNaq_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.sWSNaq_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.sWSNaq_col {
  flex: 1 0;
}

.sWSNaq_row-cols-auto > * {
  width: auto;
  flex: none;
}

.sWSNaq_row-cols-1 > * {
  width: 100%;
  flex: none;
}

.sWSNaq_row-cols-2 > * {
  width: 50%;
  flex: none;
}

.sWSNaq_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.sWSNaq_row-cols-4 > * {
  width: 25%;
  flex: none;
}

.sWSNaq_row-cols-5 > * {
  width: 20%;
  flex: none;
}

.sWSNaq_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.sWSNaq_col-auto {
  width: auto;
  flex: none;
}

.sWSNaq_col-1 {
  width: 8.33333%;
  flex: none;
}

.sWSNaq_col-2 {
  width: 16.6667%;
  flex: none;
}

.sWSNaq_col-3 {
  width: 25%;
  flex: none;
}

.sWSNaq_col-4 {
  width: 33.3333%;
  flex: none;
}

.sWSNaq_col-5 {
  width: 41.6667%;
  flex: none;
}

.sWSNaq_col-6 {
  width: 50%;
  flex: none;
}

.sWSNaq_col-7 {
  width: 58.3333%;
  flex: none;
}

.sWSNaq_col-8 {
  width: 66.6667%;
  flex: none;
}

.sWSNaq_col-9 {
  width: 75%;
  flex: none;
}

.sWSNaq_col-10 {
  width: 83.3333%;
  flex: none;
}

.sWSNaq_col-11 {
  width: 91.6667%;
  flex: none;
}

.sWSNaq_col-12 {
  width: 100%;
  flex: none;
}

.sWSNaq_offset-1 {
  margin-left: 8.33333%;
}

.sWSNaq_offset-2 {
  margin-left: 16.6667%;
}

.sWSNaq_offset-3 {
  margin-left: 25%;
}

.sWSNaq_offset-4 {
  margin-left: 33.3333%;
}

.sWSNaq_offset-5 {
  margin-left: 41.6667%;
}

.sWSNaq_offset-6 {
  margin-left: 50%;
}

.sWSNaq_offset-7 {
  margin-left: 58.3333%;
}

.sWSNaq_offset-8 {
  margin-left: 66.6667%;
}

.sWSNaq_offset-9 {
  margin-left: 75%;
}

.sWSNaq_offset-10 {
  margin-left: 83.3333%;
}

.sWSNaq_offset-11 {
  margin-left: 91.6667%;
}

.sWSNaq_g-0, .sWSNaq_gx-0 {
  --bs-gutter-x: 0;
}

.sWSNaq_g-0, .sWSNaq_gy-0 {
  --bs-gutter-y: 0;
}

.sWSNaq_g-1, .sWSNaq_gx-1 {
  --bs-gutter-x: .25rem;
}

.sWSNaq_g-1, .sWSNaq_gy-1 {
  --bs-gutter-y: .25rem;
}

.sWSNaq_g-2, .sWSNaq_gx-2 {
  --bs-gutter-x: .5rem;
}

.sWSNaq_g-2, .sWSNaq_gy-2 {
  --bs-gutter-y: .5rem;
}

.sWSNaq_g-3, .sWSNaq_gx-3 {
  --bs-gutter-x: 1rem;
}

.sWSNaq_g-3, .sWSNaq_gy-3 {
  --bs-gutter-y: 1rem;
}

.sWSNaq_g-4, .sWSNaq_gx-4 {
  --bs-gutter-x: 1.5rem;
}

.sWSNaq_g-4, .sWSNaq_gy-4 {
  --bs-gutter-y: 1.5rem;
}

.sWSNaq_g-5, .sWSNaq_gx-5 {
  --bs-gutter-x: 3rem;
}

.sWSNaq_g-5, .sWSNaq_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .sWSNaq_col-sm {
    flex: 1 0;
  }

  .sWSNaq_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .sWSNaq_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .sWSNaq_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .sWSNaq_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .sWSNaq_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .sWSNaq_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-sm-auto {
    width: auto;
    flex: none;
  }

  .sWSNaq_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .sWSNaq_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-sm-3 {
    width: 25%;
    flex: none;
  }

  .sWSNaq_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .sWSNaq_col-sm-6 {
    width: 50%;
    flex: none;
  }

  .sWSNaq_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .sWSNaq_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .sWSNaq_col-sm-9 {
    width: 75%;
    flex: none;
  }

  .sWSNaq_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .sWSNaq_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .sWSNaq_col-sm-12 {
    width: 100%;
    flex: none;
  }

  .sWSNaq_offset-sm-0 {
    margin-left: 0;
  }

  .sWSNaq_offset-sm-1 {
    margin-left: 8.33333%;
  }

  .sWSNaq_offset-sm-2 {
    margin-left: 16.6667%;
  }

  .sWSNaq_offset-sm-3 {
    margin-left: 25%;
  }

  .sWSNaq_offset-sm-4 {
    margin-left: 33.3333%;
  }

  .sWSNaq_offset-sm-5 {
    margin-left: 41.6667%;
  }

  .sWSNaq_offset-sm-6 {
    margin-left: 50%;
  }

  .sWSNaq_offset-sm-7 {
    margin-left: 58.3333%;
  }

  .sWSNaq_offset-sm-8 {
    margin-left: 66.6667%;
  }

  .sWSNaq_offset-sm-9 {
    margin-left: 75%;
  }

  .sWSNaq_offset-sm-10 {
    margin-left: 83.3333%;
  }

  .sWSNaq_offset-sm-11 {
    margin-left: 91.6667%;
  }

  .sWSNaq_g-sm-0, .sWSNaq_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .sWSNaq_g-sm-0, .sWSNaq_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .sWSNaq_g-sm-1, .sWSNaq_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .sWSNaq_g-sm-1, .sWSNaq_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .sWSNaq_g-sm-2, .sWSNaq_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .sWSNaq_g-sm-2, .sWSNaq_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .sWSNaq_g-sm-3, .sWSNaq_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .sWSNaq_g-sm-3, .sWSNaq_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .sWSNaq_g-sm-4, .sWSNaq_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .sWSNaq_g-sm-4, .sWSNaq_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .sWSNaq_g-sm-5, .sWSNaq_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .sWSNaq_g-sm-5, .sWSNaq_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .sWSNaq_col-md {
    flex: 1 0;
  }

  .sWSNaq_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .sWSNaq_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .sWSNaq_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .sWSNaq_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .sWSNaq_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .sWSNaq_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-md-auto {
    width: auto;
    flex: none;
  }

  .sWSNaq_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .sWSNaq_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-md-3 {
    width: 25%;
    flex: none;
  }

  .sWSNaq_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .sWSNaq_col-md-6 {
    width: 50%;
    flex: none;
  }

  .sWSNaq_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .sWSNaq_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .sWSNaq_col-md-9 {
    width: 75%;
    flex: none;
  }

  .sWSNaq_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .sWSNaq_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .sWSNaq_col-md-12 {
    width: 100%;
    flex: none;
  }

  .sWSNaq_offset-md-0 {
    margin-left: 0;
  }

  .sWSNaq_offset-md-1 {
    margin-left: 8.33333%;
  }

  .sWSNaq_offset-md-2 {
    margin-left: 16.6667%;
  }

  .sWSNaq_offset-md-3 {
    margin-left: 25%;
  }

  .sWSNaq_offset-md-4 {
    margin-left: 33.3333%;
  }

  .sWSNaq_offset-md-5 {
    margin-left: 41.6667%;
  }

  .sWSNaq_offset-md-6 {
    margin-left: 50%;
  }

  .sWSNaq_offset-md-7 {
    margin-left: 58.3333%;
  }

  .sWSNaq_offset-md-8 {
    margin-left: 66.6667%;
  }

  .sWSNaq_offset-md-9 {
    margin-left: 75%;
  }

  .sWSNaq_offset-md-10 {
    margin-left: 83.3333%;
  }

  .sWSNaq_offset-md-11 {
    margin-left: 91.6667%;
  }

  .sWSNaq_g-md-0, .sWSNaq_gx-md-0 {
    --bs-gutter-x: 0;
  }

  .sWSNaq_g-md-0, .sWSNaq_gy-md-0 {
    --bs-gutter-y: 0;
  }

  .sWSNaq_g-md-1, .sWSNaq_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .sWSNaq_g-md-1, .sWSNaq_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .sWSNaq_g-md-2, .sWSNaq_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .sWSNaq_g-md-2, .sWSNaq_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .sWSNaq_g-md-3, .sWSNaq_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .sWSNaq_g-md-3, .sWSNaq_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .sWSNaq_g-md-4, .sWSNaq_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .sWSNaq_g-md-4, .sWSNaq_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .sWSNaq_g-md-5, .sWSNaq_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .sWSNaq_g-md-5, .sWSNaq_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .sWSNaq_col-lg {
    flex: 1 0;
  }

  .sWSNaq_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .sWSNaq_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .sWSNaq_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .sWSNaq_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .sWSNaq_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .sWSNaq_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-lg-auto {
    width: auto;
    flex: none;
  }

  .sWSNaq_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .sWSNaq_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-lg-3 {
    width: 25%;
    flex: none;
  }

  .sWSNaq_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .sWSNaq_col-lg-6 {
    width: 50%;
    flex: none;
  }

  .sWSNaq_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .sWSNaq_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .sWSNaq_col-lg-9 {
    width: 75%;
    flex: none;
  }

  .sWSNaq_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .sWSNaq_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .sWSNaq_col-lg-12 {
    width: 100%;
    flex: none;
  }

  .sWSNaq_offset-lg-0 {
    margin-left: 0;
  }

  .sWSNaq_offset-lg-1 {
    margin-left: 8.33333%;
  }

  .sWSNaq_offset-lg-2 {
    margin-left: 16.6667%;
  }

  .sWSNaq_offset-lg-3 {
    margin-left: 25%;
  }

  .sWSNaq_offset-lg-4 {
    margin-left: 33.3333%;
  }

  .sWSNaq_offset-lg-5 {
    margin-left: 41.6667%;
  }

  .sWSNaq_offset-lg-6 {
    margin-left: 50%;
  }

  .sWSNaq_offset-lg-7 {
    margin-left: 58.3333%;
  }

  .sWSNaq_offset-lg-8 {
    margin-left: 66.6667%;
  }

  .sWSNaq_offset-lg-9 {
    margin-left: 75%;
  }

  .sWSNaq_offset-lg-10 {
    margin-left: 83.3333%;
  }

  .sWSNaq_offset-lg-11 {
    margin-left: 91.6667%;
  }

  .sWSNaq_g-lg-0, .sWSNaq_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .sWSNaq_g-lg-0, .sWSNaq_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .sWSNaq_g-lg-1, .sWSNaq_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .sWSNaq_g-lg-1, .sWSNaq_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .sWSNaq_g-lg-2, .sWSNaq_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .sWSNaq_g-lg-2, .sWSNaq_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .sWSNaq_g-lg-3, .sWSNaq_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .sWSNaq_g-lg-3, .sWSNaq_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .sWSNaq_g-lg-4, .sWSNaq_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .sWSNaq_g-lg-4, .sWSNaq_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .sWSNaq_g-lg-5, .sWSNaq_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .sWSNaq_g-lg-5, .sWSNaq_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_col-xl {
    flex: 1 0;
  }

  .sWSNaq_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .sWSNaq_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .sWSNaq_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .sWSNaq_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .sWSNaq_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .sWSNaq_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-xl-auto {
    width: auto;
    flex: none;
  }

  .sWSNaq_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .sWSNaq_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-xl-3 {
    width: 25%;
    flex: none;
  }

  .sWSNaq_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .sWSNaq_col-xl-6 {
    width: 50%;
    flex: none;
  }

  .sWSNaq_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .sWSNaq_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .sWSNaq_col-xl-9 {
    width: 75%;
    flex: none;
  }

  .sWSNaq_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .sWSNaq_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .sWSNaq_col-xl-12 {
    width: 100%;
    flex: none;
  }

  .sWSNaq_offset-xl-0 {
    margin-left: 0;
  }

  .sWSNaq_offset-xl-1 {
    margin-left: 8.33333%;
  }

  .sWSNaq_offset-xl-2 {
    margin-left: 16.6667%;
  }

  .sWSNaq_offset-xl-3 {
    margin-left: 25%;
  }

  .sWSNaq_offset-xl-4 {
    margin-left: 33.3333%;
  }

  .sWSNaq_offset-xl-5 {
    margin-left: 41.6667%;
  }

  .sWSNaq_offset-xl-6 {
    margin-left: 50%;
  }

  .sWSNaq_offset-xl-7 {
    margin-left: 58.3333%;
  }

  .sWSNaq_offset-xl-8 {
    margin-left: 66.6667%;
  }

  .sWSNaq_offset-xl-9 {
    margin-left: 75%;
  }

  .sWSNaq_offset-xl-10 {
    margin-left: 83.3333%;
  }

  .sWSNaq_offset-xl-11 {
    margin-left: 91.6667%;
  }

  .sWSNaq_g-xl-0, .sWSNaq_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .sWSNaq_g-xl-0, .sWSNaq_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .sWSNaq_g-xl-1, .sWSNaq_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .sWSNaq_g-xl-1, .sWSNaq_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .sWSNaq_g-xl-2, .sWSNaq_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .sWSNaq_g-xl-2, .sWSNaq_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .sWSNaq_g-xl-3, .sWSNaq_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .sWSNaq_g-xl-3, .sWSNaq_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .sWSNaq_g-xl-4, .sWSNaq_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .sWSNaq_g-xl-4, .sWSNaq_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .sWSNaq_g-xl-5, .sWSNaq_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .sWSNaq_g-xl-5, .sWSNaq_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_col-xxl {
    flex: 1 0;
  }

  .sWSNaq_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .sWSNaq_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .sWSNaq_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .sWSNaq_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .sWSNaq_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .sWSNaq_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-xxl-auto {
    width: auto;
    flex: none;
  }

  .sWSNaq_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .sWSNaq_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .sWSNaq_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .sWSNaq_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .sWSNaq_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .sWSNaq_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .sWSNaq_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .sWSNaq_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .sWSNaq_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .sWSNaq_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .sWSNaq_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .sWSNaq_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .sWSNaq_offset-xxl-0 {
    margin-left: 0;
  }

  .sWSNaq_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .sWSNaq_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .sWSNaq_offset-xxl-3 {
    margin-left: 25%;
  }

  .sWSNaq_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .sWSNaq_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .sWSNaq_offset-xxl-6 {
    margin-left: 50%;
  }

  .sWSNaq_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .sWSNaq_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .sWSNaq_offset-xxl-9 {
    margin-left: 75%;
  }

  .sWSNaq_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .sWSNaq_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .sWSNaq_g-xxl-0, .sWSNaq_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .sWSNaq_g-xxl-0, .sWSNaq_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .sWSNaq_g-xxl-1, .sWSNaq_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .sWSNaq_g-xxl-1, .sWSNaq_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .sWSNaq_g-xxl-2, .sWSNaq_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .sWSNaq_g-xxl-2, .sWSNaq_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .sWSNaq_g-xxl-3, .sWSNaq_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .sWSNaq_g-xxl-3, .sWSNaq_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .sWSNaq_g-xxl-4, .sWSNaq_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .sWSNaq_g-xxl-4, .sWSNaq_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .sWSNaq_g-xxl-5, .sWSNaq_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .sWSNaq_g-xxl-5, .sWSNaq_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.sWSNaq_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.sWSNaq_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.sWSNaq_table > tbody {
  vertical-align: inherit;
}

.sWSNaq_table > thead {
  vertical-align: bottom;
}

.sWSNaq_table-group-divider {
  border-top: 2px solid;
}

.sWSNaq_caption-top {
  caption-side: top;
}

.sWSNaq_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.sWSNaq_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.sWSNaq_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.sWSNaq_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.sWSNaq_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.sWSNaq_table-striped > tbody > tr:nth-of-type(2n+1) > *, .sWSNaq_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.sWSNaq_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.sWSNaq_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.sWSNaq_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d3e8cc;
  --bs-table-border-color: #bed1b8;
  --bs-table-striped-bg: #c8dcc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bed1b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3d7bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.sWSNaq_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .sWSNaq_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .sWSNaq_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .sWSNaq_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .sWSNaq_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .sWSNaq_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.sWSNaq_form-label {
  margin-bottom: .5rem;
}

.sWSNaq_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.sWSNaq_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.sWSNaq_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.sWSNaq_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.sWSNaq_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-control {
    transition: none;
  }
}

.sWSNaq_form-control[type="file"] {
  overflow: hidden;
}

.sWSNaq_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.sWSNaq_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.sWSNaq_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.sWSNaq_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.sWSNaq_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.sWSNaq_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-control::file-selector-button {
    transition: none;
  }
}

.sWSNaq_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.sWSNaq_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.sWSNaq_form-control-plaintext:focus {
  outline: 0;
}

.sWSNaq_form-control-plaintext.sWSNaq_form-control-sm, .sWSNaq_form-control-plaintext.sWSNaq_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.sWSNaq_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.sWSNaq_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.sWSNaq_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.sWSNaq_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.sWSNaq_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.sWSNaq_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.sWSNaq_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.sWSNaq_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.sWSNaq_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.sWSNaq_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.sWSNaq_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.sWSNaq_form-control-color.sWSNaq_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.sWSNaq_form-control-color.sWSNaq_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.sWSNaq_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-select {
    transition: none;
  }
}

.sWSNaq_form-select:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.sWSNaq_form-select[multiple], .sWSNaq_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.sWSNaq_form-select:disabled {
  background-color: #e9ecef;
}

.sWSNaq_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.sWSNaq_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.sWSNaq_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.sWSNaq_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.sWSNaq_form-check .sWSNaq_form-check-input {
  float: left;
  margin-left: -1.5em;
}

.sWSNaq_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.sWSNaq_form-check-reverse .sWSNaq_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.sWSNaq_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.sWSNaq_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.sWSNaq_form-check-input[type="radio"] {
  border-radius: 50%;
}

.sWSNaq_form-check-input:active {
  filter: brightness(90%);
}

.sWSNaq_form-check-input:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.sWSNaq_form-check-input:checked {
  background-color: #258e01;
  border-color: #258e01;
}

.sWSNaq_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.sWSNaq_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.sWSNaq_form-check-input[type="checkbox"]:indeterminate {
  background-color: #258e01;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #258e01;
}

.sWSNaq_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.sWSNaq_form-check-input[disabled] ~ .sWSNaq_form-check-label, .sWSNaq_form-check-input:disabled ~ .sWSNaq_form-check-label {
  cursor: default;
  opacity: .5;
}

.sWSNaq_form-switch {
  padding-left: 2.5em;
}

.sWSNaq_form-switch .sWSNaq_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-switch .sWSNaq_form-check-input {
    transition: none;
  }
}

.sWSNaq_form-switch .sWSNaq_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2392c780'/%3e%3c/svg%3e");
}

.sWSNaq_form-switch .sWSNaq_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.sWSNaq_form-switch.sWSNaq_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.sWSNaq_form-switch.sWSNaq_form-check-reverse .sWSNaq_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.sWSNaq_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.sWSNaq_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.sWSNaq_btn-check[disabled] + .sWSNaq_btn, .sWSNaq_btn-check:disabled + .sWSNaq_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.sWSNaq_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.sWSNaq_form-range:focus {
  outline: 0;
}

.sWSNaq_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

.sWSNaq_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

.sWSNaq_form-range::-moz-focus-outer {
  border: 0;
}

.sWSNaq_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.sWSNaq_form-range::-webkit-slider-thumb:active {
  background-color: #beddb3;
}

.sWSNaq_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.sWSNaq_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-range::-moz-range-thumb {
    transition: none;
  }
}

.sWSNaq_form-range::-moz-range-thumb:active {
  background-color: #beddb3;
}

.sWSNaq_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.sWSNaq_form-range:disabled {
  pointer-events: none;
}

.sWSNaq_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.sWSNaq_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.sWSNaq_form-floating {
  position: relative;
}

.sWSNaq_form-floating > .sWSNaq_form-control, .sWSNaq_form-floating > .sWSNaq_form-control-plaintext, .sWSNaq_form-floating > .sWSNaq_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.sWSNaq_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_form-floating > label {
    transition: none;
  }
}

.sWSNaq_form-floating > .sWSNaq_form-control, .sWSNaq_form-floating > .sWSNaq_form-control-plaintext {
  padding: 1rem .75rem;
}

.sWSNaq_form-floating > .sWSNaq_form-control::placeholder, .sWSNaq_form-floating > .sWSNaq_form-control-plaintext::placeholder {
  color: #0000;
}

.sWSNaq_form-floating > .sWSNaq_form-control:focus, .sWSNaq_form-floating > .sWSNaq_form-control:not(:placeholder-shown), .sWSNaq_form-floating > .sWSNaq_form-control-plaintext:focus, .sWSNaq_form-floating > .sWSNaq_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.sWSNaq_form-floating > .sWSNaq_form-control:-webkit-autofill, .sWSNaq_form-floating > .sWSNaq_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.sWSNaq_form-floating > .sWSNaq_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.sWSNaq_form-floating > .sWSNaq_form-control:focus ~ label, .sWSNaq_form-floating > .sWSNaq_form-control:not(:placeholder-shown) ~ label, .sWSNaq_form-floating > .sWSNaq_form-control-plaintext ~ label, .sWSNaq_form-floating > .sWSNaq_form-select ~ label, .sWSNaq_form-floating > .sWSNaq_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.sWSNaq_form-floating > .sWSNaq_form-control-plaintext ~ label {
  border-width: 1px 0;
}

.sWSNaq_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.sWSNaq_input-group > .sWSNaq_form-control, .sWSNaq_input-group > .sWSNaq_form-select, .sWSNaq_input-group > .sWSNaq_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.sWSNaq_input-group > .sWSNaq_form-control:focus, .sWSNaq_input-group > .sWSNaq_form-select:focus, .sWSNaq_input-group > .sWSNaq_form-floating:focus-within {
  z-index: 5;
}

.sWSNaq_input-group .sWSNaq_btn {
  z-index: 2;
  position: relative;
}

.sWSNaq_input-group .sWSNaq_btn:focus {
  z-index: 5;
}

.sWSNaq_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.sWSNaq_input-group-lg > .sWSNaq_form-control, .sWSNaq_input-group-lg > .sWSNaq_form-select, .sWSNaq_input-group-lg > .sWSNaq_input-group-text, .sWSNaq_input-group-lg > .sWSNaq_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.sWSNaq_input-group-sm > .sWSNaq_form-control, .sWSNaq_input-group-sm > .sWSNaq_form-select, .sWSNaq_input-group-sm > .sWSNaq_input-group-text, .sWSNaq_input-group-sm > .sWSNaq_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.sWSNaq_input-group-lg > .sWSNaq_form-select, .sWSNaq_input-group-sm > .sWSNaq_form-select {
  padding-right: 3rem;
}

.sWSNaq_input-group:not(.sWSNaq_has-validation) > :not(:last-child):not(.sWSNaq_dropdown-toggle):not(.sWSNaq_dropdown-menu):not(.sWSNaq_form-floating), .sWSNaq_input-group:not(.sWSNaq_has-validation) > .sWSNaq_dropdown-toggle:nth-last-child(n+3), .sWSNaq_input-group:not(.sWSNaq_has-validation) > .sWSNaq_form-floating:not(:last-child) > .sWSNaq_form-control, .sWSNaq_input-group:not(.sWSNaq_has-validation) > .sWSNaq_form-floating:not(:last-child) > .sWSNaq_form-select, .sWSNaq_input-group.sWSNaq_has-validation > :nth-last-child(n+3):not(.sWSNaq_dropdown-toggle):not(.sWSNaq_dropdown-menu):not(.sWSNaq_form-floating), .sWSNaq_input-group.sWSNaq_has-validation > .sWSNaq_dropdown-toggle:nth-last-child(n+4), .sWSNaq_input-group.sWSNaq_has-validation > .sWSNaq_form-floating:nth-last-child(n+3) > .sWSNaq_form-control, .sWSNaq_input-group.sWSNaq_has-validation > .sWSNaq_form-floating:nth-last-child(n+3) > .sWSNaq_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sWSNaq_input-group > :not(:first-child):not(.sWSNaq_dropdown-menu):not(.sWSNaq_valid-tooltip):not(.sWSNaq_valid-feedback):not(.sWSNaq_invalid-tooltip):not(.sWSNaq_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.sWSNaq_input-group > .sWSNaq_form-floating:not(:first-child) > .sWSNaq_form-control, .sWSNaq_input-group > .sWSNaq_form-floating:not(:first-child) > .sWSNaq_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sWSNaq_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.sWSNaq_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.sWSNaq_was-validated :valid ~ .sWSNaq_valid-feedback, .sWSNaq_was-validated :valid ~ .sWSNaq_valid-tooltip, .sWSNaq_is-valid ~ .sWSNaq_valid-feedback, .sWSNaq_is-valid ~ .sWSNaq_valid-tooltip {
  display: block;
}

.sWSNaq_was-validated .sWSNaq_form-control:valid, .sWSNaq_form-control.sWSNaq_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.sWSNaq_was-validated .sWSNaq_form-control:valid:focus, .sWSNaq_form-control.sWSNaq_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.sWSNaq_was-validated textarea.sWSNaq_form-control:valid, textarea.sWSNaq_form-control.sWSNaq_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.sWSNaq_was-validated .sWSNaq_form-select:valid, .sWSNaq_form-select.sWSNaq_is-valid {
  border-color: #198754;
}

.sWSNaq_was-validated .sWSNaq_form-select:valid:not([multiple]):not([size]), .sWSNaq_was-validated .sWSNaq_form-select:valid:not([multiple])[size="1"], .sWSNaq_form-select.sWSNaq_is-valid:not([multiple]):not([size]), .sWSNaq_form-select.sWSNaq_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.sWSNaq_was-validated .sWSNaq_form-select:valid:focus, .sWSNaq_form-select.sWSNaq_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.sWSNaq_was-validated .sWSNaq_form-control-color:valid, .sWSNaq_form-control-color.sWSNaq_is-valid {
  width: calc(1.5em + 3.75rem);
}

.sWSNaq_was-validated .sWSNaq_form-check-input:valid, .sWSNaq_form-check-input.sWSNaq_is-valid {
  border-color: #198754;
}

.sWSNaq_was-validated .sWSNaq_form-check-input:valid:checked, .sWSNaq_form-check-input.sWSNaq_is-valid:checked {
  background-color: #198754;
}

.sWSNaq_was-validated .sWSNaq_form-check-input:valid:focus, .sWSNaq_form-check-input.sWSNaq_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.sWSNaq_was-validated .sWSNaq_form-check-input:valid ~ .sWSNaq_form-check-label, .sWSNaq_form-check-input.sWSNaq_is-valid ~ .sWSNaq_form-check-label {
  color: #198754;
}

.sWSNaq_form-check-inline .sWSNaq_form-check-input ~ .sWSNaq_valid-feedback {
  margin-left: .5em;
}

.sWSNaq_was-validated .sWSNaq_input-group > .sWSNaq_form-control:not(:focus):valid, .sWSNaq_input-group > .sWSNaq_form-control:not(:focus).sWSNaq_is-valid, .sWSNaq_was-validated .sWSNaq_input-group > .sWSNaq_form-select:not(:focus):valid, .sWSNaq_input-group > .sWSNaq_form-select:not(:focus).sWSNaq_is-valid, .sWSNaq_was-validated .sWSNaq_input-group > .sWSNaq_form-floating:not(:focus-within):valid, .sWSNaq_input-group > .sWSNaq_form-floating:not(:focus-within).sWSNaq_is-valid {
  z-index: 3;
}

.sWSNaq_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.sWSNaq_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.sWSNaq_was-validated :invalid ~ .sWSNaq_invalid-feedback, .sWSNaq_was-validated :invalid ~ .sWSNaq_invalid-tooltip, .sWSNaq_is-invalid ~ .sWSNaq_invalid-feedback, .sWSNaq_is-invalid ~ .sWSNaq_invalid-tooltip {
  display: block;
}

.sWSNaq_was-validated .sWSNaq_form-control:invalid, .sWSNaq_form-control.sWSNaq_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.sWSNaq_was-validated .sWSNaq_form-control:invalid:focus, .sWSNaq_form-control.sWSNaq_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.sWSNaq_was-validated textarea.sWSNaq_form-control:invalid, textarea.sWSNaq_form-control.sWSNaq_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.sWSNaq_was-validated .sWSNaq_form-select:invalid, .sWSNaq_form-select.sWSNaq_is-invalid {
  border-color: #dc3545;
}

.sWSNaq_was-validated .sWSNaq_form-select:invalid:not([multiple]):not([size]), .sWSNaq_was-validated .sWSNaq_form-select:invalid:not([multiple])[size="1"], .sWSNaq_form-select.sWSNaq_is-invalid:not([multiple]):not([size]), .sWSNaq_form-select.sWSNaq_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.sWSNaq_was-validated .sWSNaq_form-select:invalid:focus, .sWSNaq_form-select.sWSNaq_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.sWSNaq_was-validated .sWSNaq_form-control-color:invalid, .sWSNaq_form-control-color.sWSNaq_is-invalid {
  width: calc(1.5em + 3.75rem);
}

.sWSNaq_was-validated .sWSNaq_form-check-input:invalid, .sWSNaq_form-check-input.sWSNaq_is-invalid {
  border-color: #dc3545;
}

.sWSNaq_was-validated .sWSNaq_form-check-input:invalid:checked, .sWSNaq_form-check-input.sWSNaq_is-invalid:checked {
  background-color: #dc3545;
}

.sWSNaq_was-validated .sWSNaq_form-check-input:invalid:focus, .sWSNaq_form-check-input.sWSNaq_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.sWSNaq_was-validated .sWSNaq_form-check-input:invalid ~ .sWSNaq_form-check-label, .sWSNaq_form-check-input.sWSNaq_is-invalid ~ .sWSNaq_form-check-label {
  color: #dc3545;
}

.sWSNaq_form-check-inline .sWSNaq_form-check-input ~ .sWSNaq_invalid-feedback {
  margin-left: .5em;
}

.sWSNaq_was-validated .sWSNaq_input-group > .sWSNaq_form-control:not(:focus):invalid, .sWSNaq_input-group > .sWSNaq_form-control:not(:focus).sWSNaq_is-invalid, .sWSNaq_was-validated .sWSNaq_input-group > .sWSNaq_form-select:not(:focus):invalid, .sWSNaq_input-group > .sWSNaq_form-select:not(:focus).sWSNaq_is-invalid, .sWSNaq_was-validated .sWSNaq_input-group > .sWSNaq_form-floating:not(:focus-within):invalid, .sWSNaq_input-group > .sWSNaq_form-floating:not(:focus-within).sWSNaq_is-invalid {
  z-index: 4;
}

.sWSNaq_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_btn {
    transition: none;
  }
}

.sWSNaq_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.sWSNaq_btn-check + .sWSNaq_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.sWSNaq_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.sWSNaq_btn-check:focus-visible + .sWSNaq_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.sWSNaq_btn-check:checked + .sWSNaq_btn, :not(.sWSNaq_btn-check) + .sWSNaq_btn:active, .sWSNaq_btn:first-child:active, .sWSNaq_btn.sWSNaq_active, .sWSNaq_btn.sWSNaq_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.sWSNaq_btn-check:checked + .sWSNaq_btn:focus-visible, :not(.sWSNaq_btn-check) + .sWSNaq_btn:active:focus-visible, .sWSNaq_btn:first-child:active:focus-visible, .sWSNaq_btn.sWSNaq_active:focus-visible, .sWSNaq_btn.sWSNaq_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.sWSNaq_btn:disabled, .sWSNaq_btn.sWSNaq_disabled, fieldset:disabled .sWSNaq_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.sWSNaq_btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #469f27;
  --bs-btn-hover-border-color: #3b991a;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #51a534;
  --bs-btn-active-border-color: #3b991a;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #258e01;
  --bs-btn-disabled-border-color: #258e01;
}

.sWSNaq_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.sWSNaq_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.sWSNaq_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.sWSNaq_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.sWSNaq_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.sWSNaq_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.sWSNaq_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.sWSNaq_btn-outline-primary {
  --bs-btn-color: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #258e01;
  --bs-btn-hover-border-color: #258e01;
  --bs-btn-focus-shadow-rgb: 37, 142, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #258e01;
  --bs-btn-active-border-color: #258e01;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #258e01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #258e01;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.sWSNaq_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.sWSNaq_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  text-decoration: underline;
}

.sWSNaq_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.sWSNaq_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.sWSNaq_btn-lg, .sWSNaq_btn-group-lg > .sWSNaq_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.sWSNaq_btn-sm, .sWSNaq_btn-group-sm > .sWSNaq_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.sWSNaq_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_fade {
    transition: none;
  }
}

.sWSNaq_fade:not(.sWSNaq_show) {
  opacity: 0;
}

.sWSNaq_collapse:not(.sWSNaq_show) {
  display: none;
}

.sWSNaq_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_collapsing {
    transition: none;
  }
}

.sWSNaq_collapsing.sWSNaq_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_collapsing.sWSNaq_collapse-horizontal {
    transition: none;
  }
}

.sWSNaq_dropup, .sWSNaq_dropend, .sWSNaq_dropdown, .sWSNaq_dropstart, .sWSNaq_dropup-center, .sWSNaq_dropdown-center {
  position: relative;
}

.sWSNaq_dropdown-toggle {
  white-space: nowrap;
}

.sWSNaq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.sWSNaq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.sWSNaq_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.sWSNaq_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.sWSNaq_dropdown-menu-start {
  --bs-position: start;
}

.sWSNaq_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.sWSNaq_dropdown-menu-end {
  --bs-position: end;
}

.sWSNaq_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .sWSNaq_dropdown-menu-sm-start {
    --bs-position: start;
  }

  .sWSNaq_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .sWSNaq_dropdown-menu-sm-end {
    --bs-position: end;
  }

  .sWSNaq_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .sWSNaq_dropdown-menu-md-start {
    --bs-position: start;
  }

  .sWSNaq_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .sWSNaq_dropdown-menu-md-end {
    --bs-position: end;
  }

  .sWSNaq_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .sWSNaq_dropdown-menu-lg-start {
    --bs-position: start;
  }

  .sWSNaq_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .sWSNaq_dropdown-menu-lg-end {
    --bs-position: end;
  }

  .sWSNaq_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_dropdown-menu-xl-start {
    --bs-position: start;
  }

  .sWSNaq_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .sWSNaq_dropdown-menu-xl-end {
    --bs-position: end;
  }

  .sWSNaq_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .sWSNaq_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .sWSNaq_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .sWSNaq_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.sWSNaq_dropup .sWSNaq_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.sWSNaq_dropup .sWSNaq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.sWSNaq_dropup .sWSNaq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.sWSNaq_dropend .sWSNaq_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.sWSNaq_dropend .sWSNaq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.sWSNaq_dropend .sWSNaq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.sWSNaq_dropend .sWSNaq_dropdown-toggle:after {
  vertical-align: 0;
}

.sWSNaq_dropstart .sWSNaq_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.sWSNaq_dropstart .sWSNaq_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.sWSNaq_dropstart .sWSNaq_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.sWSNaq_dropstart .sWSNaq_dropdown-toggle:empty:after {
  margin-left: 0;
}

.sWSNaq_dropstart .sWSNaq_dropdown-toggle:before {
  vertical-align: 0;
}

.sWSNaq_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.sWSNaq_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.sWSNaq_dropdown-item:hover, .sWSNaq_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.sWSNaq_dropdown-item.sWSNaq_active, .sWSNaq_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.sWSNaq_dropdown-item.sWSNaq_disabled, .sWSNaq_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.sWSNaq_dropdown-menu.sWSNaq_show {
  display: block;
}

.sWSNaq_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.sWSNaq_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.sWSNaq_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.sWSNaq_btn-group, .sWSNaq_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.sWSNaq_btn-group > .sWSNaq_btn, .sWSNaq_btn-group-vertical > .sWSNaq_btn {
  flex: auto;
  position: relative;
}

.sWSNaq_btn-group > .sWSNaq_btn-check:checked + .sWSNaq_btn, .sWSNaq_btn-group > .sWSNaq_btn-check:focus + .sWSNaq_btn, .sWSNaq_btn-group > .sWSNaq_btn:hover, .sWSNaq_btn-group > .sWSNaq_btn:focus, .sWSNaq_btn-group > .sWSNaq_btn:active, .sWSNaq_btn-group > .sWSNaq_btn.sWSNaq_active, .sWSNaq_btn-group-vertical > .sWSNaq_btn-check:checked + .sWSNaq_btn, .sWSNaq_btn-group-vertical > .sWSNaq_btn-check:focus + .sWSNaq_btn, .sWSNaq_btn-group-vertical > .sWSNaq_btn:hover, .sWSNaq_btn-group-vertical > .sWSNaq_btn:focus, .sWSNaq_btn-group-vertical > .sWSNaq_btn:active, .sWSNaq_btn-group-vertical > .sWSNaq_btn.sWSNaq_active {
  z-index: 1;
}

.sWSNaq_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.sWSNaq_btn-toolbar .sWSNaq_input-group {
  width: auto;
}

.sWSNaq_btn-group {
  border-radius: .375rem;
}

.sWSNaq_btn-group > :not(.sWSNaq_btn-check:first-child) + .sWSNaq_btn, .sWSNaq_btn-group > .sWSNaq_btn-group:not(:first-child) {
  margin-left: -1px;
}

.sWSNaq_btn-group > .sWSNaq_btn:not(:last-child):not(.sWSNaq_dropdown-toggle), .sWSNaq_btn-group > .sWSNaq_btn.sWSNaq_dropdown-toggle-split:first-child, .sWSNaq_btn-group > .sWSNaq_btn-group:not(:last-child) > .sWSNaq_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sWSNaq_btn-group > .sWSNaq_btn:nth-child(n+3), .sWSNaq_btn-group > :not(.sWSNaq_btn-check) + .sWSNaq_btn, .sWSNaq_btn-group > .sWSNaq_btn-group:not(:first-child) > .sWSNaq_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sWSNaq_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.sWSNaq_dropdown-toggle-split:after, .sWSNaq_dropup .sWSNaq_dropdown-toggle-split:after, .sWSNaq_dropend .sWSNaq_dropdown-toggle-split:after {
  margin-left: 0;
}

.sWSNaq_dropstart .sWSNaq_dropdown-toggle-split:before {
  margin-right: 0;
}

.sWSNaq_btn-sm + .sWSNaq_dropdown-toggle-split, .sWSNaq_btn-group-sm > .sWSNaq_btn + .sWSNaq_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.sWSNaq_btn-lg + .sWSNaq_dropdown-toggle-split, .sWSNaq_btn-group-lg > .sWSNaq_btn + .sWSNaq_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.sWSNaq_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sWSNaq_btn-group-vertical > .sWSNaq_btn, .sWSNaq_btn-group-vertical > .sWSNaq_btn-group {
  width: 100%;
}

.sWSNaq_btn-group-vertical > .sWSNaq_btn:not(:first-child), .sWSNaq_btn-group-vertical > .sWSNaq_btn-group:not(:first-child) {
  margin-top: -1px;
}

.sWSNaq_btn-group-vertical > .sWSNaq_btn:not(:last-child):not(.sWSNaq_dropdown-toggle), .sWSNaq_btn-group-vertical > .sWSNaq_btn-group:not(:last-child) > .sWSNaq_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.sWSNaq_btn-group-vertical > .sWSNaq_btn ~ .sWSNaq_btn, .sWSNaq_btn-group-vertical > .sWSNaq_btn-group:not(:first-child) > .sWSNaq_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sWSNaq_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.sWSNaq_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_nav-link {
    transition: none;
  }
}

.sWSNaq_nav-link:hover, .sWSNaq_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.sWSNaq_nav-link.sWSNaq_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.sWSNaq_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.sWSNaq_nav-tabs .sWSNaq_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.sWSNaq_nav-tabs .sWSNaq_nav-link:hover, .sWSNaq_nav-tabs .sWSNaq_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.sWSNaq_nav-tabs .sWSNaq_nav-link.sWSNaq_disabled, .sWSNaq_nav-tabs .sWSNaq_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.sWSNaq_nav-tabs .sWSNaq_nav-link.sWSNaq_active, .sWSNaq_nav-tabs .sWSNaq_nav-item.sWSNaq_show .sWSNaq_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.sWSNaq_nav-tabs .sWSNaq_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sWSNaq_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #258e01;
}

.sWSNaq_nav-pills .sWSNaq_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.sWSNaq_nav-pills .sWSNaq_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.sWSNaq_nav-pills .sWSNaq_nav-link.sWSNaq_active, .sWSNaq_nav-pills .sWSNaq_show > .sWSNaq_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.sWSNaq_nav-fill > .sWSNaq_nav-link, .sWSNaq_nav-fill .sWSNaq_nav-item {
  text-align: center;
  flex: auto;
}

.sWSNaq_nav-justified > .sWSNaq_nav-link, .sWSNaq_nav-justified .sWSNaq_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.sWSNaq_nav-fill .sWSNaq_nav-item .sWSNaq_nav-link, .sWSNaq_nav-justified .sWSNaq_nav-item .sWSNaq_nav-link {
  width: 100%;
}

.sWSNaq_tab-content > .sWSNaq_tab-pane {
  display: none;
}

.sWSNaq_tab-content > .sWSNaq_active {
  display: block;
}

.sWSNaq_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.sWSNaq_navbar > .sWSNaq_container, .sWSNaq_navbar > .sWSNaq_container-fluid, .sWSNaq_navbar > .sWSNaq_container-sm, .sWSNaq_navbar > .sWSNaq_container-md, .sWSNaq_navbar > .sWSNaq_container-lg, .sWSNaq_navbar > .sWSNaq_container-xl, .sWSNaq_navbar > .sWSNaq_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sWSNaq_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.sWSNaq_navbar-brand:hover, .sWSNaq_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.sWSNaq_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.sWSNaq_navbar-nav .sWSNaq_show > .sWSNaq_nav-link, .sWSNaq_navbar-nav .sWSNaq_nav-link.sWSNaq_active {
  color: var(--bs-navbar-active-color);
}

.sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
  position: static;
}

.sWSNaq_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.sWSNaq_navbar-text a, .sWSNaq_navbar-text a:hover, .sWSNaq_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.sWSNaq_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.sWSNaq_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_navbar-toggler {
    transition: none;
  }
}

.sWSNaq_navbar-toggler:hover {
  text-decoration: none;
}

.sWSNaq_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.sWSNaq_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.sWSNaq_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .sWSNaq_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_navbar-nav {
    flex-direction: row;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
    position: absolute;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_navbar-nav .sWSNaq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_navbar-nav-scroll {
    overflow: visible;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_navbar-toggler {
    display: none;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_offcanvas .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_navbar-expand-sm .sWSNaq_offcanvas .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .sWSNaq_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_navbar-nav {
    flex-direction: row;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
    position: absolute;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_navbar-nav .sWSNaq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .sWSNaq_navbar-expand-md .sWSNaq_navbar-nav-scroll {
    overflow: visible;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_navbar-toggler {
    display: none;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_offcanvas .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_navbar-expand-md .sWSNaq_offcanvas .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .sWSNaq_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_navbar-nav {
    flex-direction: row;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
    position: absolute;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_navbar-nav .sWSNaq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_navbar-nav-scroll {
    overflow: visible;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_navbar-toggler {
    display: none;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_offcanvas .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_navbar-expand-lg .sWSNaq_offcanvas .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_navbar-nav {
    flex-direction: row;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
    position: absolute;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_navbar-nav .sWSNaq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_navbar-nav-scroll {
    overflow: visible;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_navbar-toggler {
    display: none;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_offcanvas .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_navbar-expand-xl .sWSNaq_offcanvas .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_navbar-nav {
    flex-direction: row;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
    position: absolute;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_navbar-nav .sWSNaq_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_navbar-nav-scroll {
    overflow: visible;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_navbar-toggler {
    display: none;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_offcanvas .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_navbar-expand-xxl .sWSNaq_offcanvas .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.sWSNaq_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.sWSNaq_navbar-expand .sWSNaq_navbar-nav {
  flex-direction: row;
}

.sWSNaq_navbar-expand .sWSNaq_navbar-nav .sWSNaq_dropdown-menu {
  position: absolute;
}

.sWSNaq_navbar-expand .sWSNaq_navbar-nav .sWSNaq_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.sWSNaq_navbar-expand .sWSNaq_navbar-nav-scroll {
  overflow: visible;
}

.sWSNaq_navbar-expand .sWSNaq_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.sWSNaq_navbar-expand .sWSNaq_navbar-toggler {
  display: none;
}

.sWSNaq_navbar-expand .sWSNaq_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.sWSNaq_navbar-expand .sWSNaq_offcanvas .sWSNaq_offcanvas-header {
  display: none;
}

.sWSNaq_navbar-expand .sWSNaq_offcanvas .sWSNaq_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.sWSNaq_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.sWSNaq_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.sWSNaq_card > hr {
  margin-left: 0;
  margin-right: 0;
}

.sWSNaq_card > .sWSNaq_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.sWSNaq_card > .sWSNaq_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.sWSNaq_card > .sWSNaq_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.sWSNaq_card > .sWSNaq_card-header + .sWSNaq_list-group, .sWSNaq_card > .sWSNaq_list-group + .sWSNaq_card-footer {
  border-top: 0;
}

.sWSNaq_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.sWSNaq_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.sWSNaq_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.sWSNaq_card-text:last-child {
  margin-bottom: 0;
}

.sWSNaq_card-link + .sWSNaq_card-link {
  margin-left: var(--bs-card-spacer-x);
}

.sWSNaq_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.sWSNaq_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.sWSNaq_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.sWSNaq_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.sWSNaq_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.sWSNaq_card-header-tabs .sWSNaq_nav-link.sWSNaq_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.sWSNaq_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.sWSNaq_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.sWSNaq_card-img, .sWSNaq_card-img-top, .sWSNaq_card-img-bottom {
  width: 100%;
}

.sWSNaq_card-img, .sWSNaq_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.sWSNaq_card-img, .sWSNaq_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.sWSNaq_card-group > .sWSNaq_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .sWSNaq_card-group {
    flex-flow: wrap;
    display: flex;
  }

  .sWSNaq_card-group > .sWSNaq_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card + .sWSNaq_card {
    border-left: 0;
    margin-left: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card:not(:last-child) .sWSNaq_card-img-top, .sWSNaq_card-group > .sWSNaq_card:not(:last-child) .sWSNaq_card-header {
    border-top-right-radius: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card:not(:last-child) .sWSNaq_card-img-bottom, .sWSNaq_card-group > .sWSNaq_card:not(:last-child) .sWSNaq_card-footer {
    border-bottom-right-radius: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card:not(:first-child) .sWSNaq_card-img-top, .sWSNaq_card-group > .sWSNaq_card:not(:first-child) .sWSNaq_card-header {
    border-top-left-radius: 0;
  }

  .sWSNaq_card-group > .sWSNaq_card:not(:first-child) .sWSNaq_card-img-bottom, .sWSNaq_card-group > .sWSNaq_card:not(:first-child) .sWSNaq_card-footer {
    border-bottom-left-radius: 0;
  }
}

.sWSNaq_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23218001'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #92c780;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #218001;
  --bs-accordion-active-bg: #e9f4e6;
}

.sWSNaq_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_accordion-button {
    transition: none;
  }
}

.sWSNaq_accordion-button:not(.sWSNaq_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.sWSNaq_accordion-button:not(.sWSNaq_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.sWSNaq_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_accordion-button:after {
    transition: none;
  }
}

.sWSNaq_accordion-button:hover {
  z-index: 2;
}

.sWSNaq_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.sWSNaq_accordion-header {
  margin-bottom: 0;
}

.sWSNaq_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.sWSNaq_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.sWSNaq_accordion-item:first-of-type .sWSNaq_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.sWSNaq_accordion-item:not(:first-of-type) {
  border-top: 0;
}

.sWSNaq_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.sWSNaq_accordion-item:last-of-type .sWSNaq_accordion-button.sWSNaq_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.sWSNaq_accordion-item:last-of-type .sWSNaq_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.sWSNaq_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.sWSNaq_accordion-flush .sWSNaq_accordion-collapse {
  border-width: 0;
}

.sWSNaq_accordion-flush .sWSNaq_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.sWSNaq_accordion-flush .sWSNaq_accordion-item:first-child {
  border-top: 0;
}

.sWSNaq_accordion-flush .sWSNaq_accordion-item:last-child {
  border-bottom: 0;
}

.sWSNaq_accordion-flush .sWSNaq_accordion-item .sWSNaq_accordion-button, .sWSNaq_accordion-flush .sWSNaq_accordion-item .sWSNaq_accordion-button.sWSNaq_collapsed {
  border-radius: 0;
}

.sWSNaq_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.sWSNaq_breadcrumb-item + .sWSNaq_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.sWSNaq_breadcrumb-item + .sWSNaq_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.sWSNaq_breadcrumb-item.sWSNaq_active {
  color: var(--bs-breadcrumb-item-active-color);
}

.sWSNaq_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #258e01;
  --bs-pagination-active-border-color: #258e01;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.sWSNaq_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_page-link {
    transition: none;
  }
}

.sWSNaq_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.sWSNaq_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.sWSNaq_page-link.sWSNaq_active, .sWSNaq_active > .sWSNaq_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.sWSNaq_page-link.sWSNaq_disabled, .sWSNaq_disabled > .sWSNaq_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.sWSNaq_page-item:not(:first-child) .sWSNaq_page-link {
  margin-left: -1px;
}

.sWSNaq_page-item:first-child .sWSNaq_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.sWSNaq_page-item:last-child .sWSNaq_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.sWSNaq_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.sWSNaq_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.sWSNaq_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.sWSNaq_badge:empty {
  display: none;
}

.sWSNaq_btn .sWSNaq_badge {
  position: relative;
  top: -1px;
}

.sWSNaq_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.sWSNaq_alert-heading {
  color: inherit;
}

.sWSNaq_alert-link {
  font-weight: 700;
}

.sWSNaq_alert-dismissible {
  padding-right: 3rem;
}

.sWSNaq_alert-dismissible .sWSNaq_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.sWSNaq_alert-primary {
  --bs-alert-color: #165501;
  --bs-alert-bg: #d3e8cc;
  --bs-alert-border-color: #beddb3;
}

.sWSNaq_alert-primary .sWSNaq_alert-link {
  color: #124401;
}

.sWSNaq_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.sWSNaq_alert-secondary .sWSNaq_alert-link {
  color: #34383c;
}

.sWSNaq_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.sWSNaq_alert-success .sWSNaq_alert-link {
  color: #0c4128;
}

.sWSNaq_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.sWSNaq_alert-info .sWSNaq_alert-link {
  color: #04414d;
}

.sWSNaq_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.sWSNaq_alert-warning .sWSNaq_alert-link {
  color: #523e02;
}

.sWSNaq_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.sWSNaq_alert-danger .sWSNaq_alert-link {
  color: #6a1a21;
}

.sWSNaq_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.sWSNaq_alert-light .sWSNaq_alert-link {
  color: #4f5050;
}

.sWSNaq_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.sWSNaq_alert-dark .sWSNaq_alert-link {
  color: #101214;
}

@keyframes sWSNaq_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.sWSNaq_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #258e01;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.sWSNaq_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_progress-bar {
    transition: none;
  }
}

.sWSNaq_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.sWSNaq_progress-bar-animated {
  animation: 1s linear infinite sWSNaq_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_progress-bar-animated {
    animation: none;
  }
}

.sWSNaq_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #258e01;
  --bs-list-group-active-border-color: #258e01;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.sWSNaq_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.sWSNaq_list-group-numbered > .sWSNaq_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.sWSNaq_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.sWSNaq_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.sWSNaq_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.sWSNaq_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.sWSNaq_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.sWSNaq_list-group-item.sWSNaq_disabled, .sWSNaq_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.sWSNaq_list-group-item.sWSNaq_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.sWSNaq_list-group-item + .sWSNaq_list-group-item {
  border-top-width: 0;
}

.sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.sWSNaq_list-group-horizontal {
  flex-direction: row;
}

.sWSNaq_list-group-horizontal > .sWSNaq_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.sWSNaq_list-group-horizontal > .sWSNaq_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.sWSNaq_list-group-horizontal > .sWSNaq_list-group-item.sWSNaq_active {
  margin-top: 0;
}

.sWSNaq_list-group-horizontal > .sWSNaq_list-group-item + .sWSNaq_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.sWSNaq_list-group-horizontal > .sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .sWSNaq_list-group-horizontal-sm {
    flex-direction: row;
  }

  .sWSNaq_list-group-horizontal-sm > .sWSNaq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .sWSNaq_list-group-horizontal-sm > .sWSNaq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .sWSNaq_list-group-horizontal-sm > .sWSNaq_list-group-item.sWSNaq_active {
    margin-top: 0;
  }

  .sWSNaq_list-group-horizontal-sm > .sWSNaq_list-group-item + .sWSNaq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .sWSNaq_list-group-horizontal-sm > .sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .sWSNaq_list-group-horizontal-md {
    flex-direction: row;
  }

  .sWSNaq_list-group-horizontal-md > .sWSNaq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .sWSNaq_list-group-horizontal-md > .sWSNaq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .sWSNaq_list-group-horizontal-md > .sWSNaq_list-group-item.sWSNaq_active {
    margin-top: 0;
  }

  .sWSNaq_list-group-horizontal-md > .sWSNaq_list-group-item + .sWSNaq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .sWSNaq_list-group-horizontal-md > .sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .sWSNaq_list-group-horizontal-lg {
    flex-direction: row;
  }

  .sWSNaq_list-group-horizontal-lg > .sWSNaq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .sWSNaq_list-group-horizontal-lg > .sWSNaq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .sWSNaq_list-group-horizontal-lg > .sWSNaq_list-group-item.sWSNaq_active {
    margin-top: 0;
  }

  .sWSNaq_list-group-horizontal-lg > .sWSNaq_list-group-item + .sWSNaq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .sWSNaq_list-group-horizontal-lg > .sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .sWSNaq_list-group-horizontal-xl {
    flex-direction: row;
  }

  .sWSNaq_list-group-horizontal-xl > .sWSNaq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .sWSNaq_list-group-horizontal-xl > .sWSNaq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .sWSNaq_list-group-horizontal-xl > .sWSNaq_list-group-item.sWSNaq_active {
    margin-top: 0;
  }

  .sWSNaq_list-group-horizontal-xl > .sWSNaq_list-group-item + .sWSNaq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .sWSNaq_list-group-horizontal-xl > .sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .sWSNaq_list-group-horizontal-xxl {
    flex-direction: row;
  }

  .sWSNaq_list-group-horizontal-xxl > .sWSNaq_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .sWSNaq_list-group-horizontal-xxl > .sWSNaq_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .sWSNaq_list-group-horizontal-xxl > .sWSNaq_list-group-item.sWSNaq_active {
    margin-top: 0;
  }

  .sWSNaq_list-group-horizontal-xxl > .sWSNaq_list-group-item + .sWSNaq_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .sWSNaq_list-group-horizontal-xxl > .sWSNaq_list-group-item + .sWSNaq_list-group-item.sWSNaq_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.sWSNaq_list-group-flush {
  border-radius: 0;
}

.sWSNaq_list-group-flush > .sWSNaq_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.sWSNaq_list-group-flush > .sWSNaq_list-group-item:last-child {
  border-bottom-width: 0;
}

.sWSNaq_list-group-item-primary {
  color: #165501;
  background-color: #d3e8cc;
}

.sWSNaq_list-group-item-primary.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-primary.sWSNaq_list-group-item-action:focus {
  color: #165501;
  background-color: #bed1b8;
}

.sWSNaq_list-group-item-primary.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #165501;
  border-color: #165501;
}

.sWSNaq_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.sWSNaq_list-group-item-secondary.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-secondary.sWSNaq_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.sWSNaq_list-group-item-secondary.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.sWSNaq_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.sWSNaq_list-group-item-success.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-success.sWSNaq_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.sWSNaq_list-group-item-success.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.sWSNaq_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.sWSNaq_list-group-item-info.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-info.sWSNaq_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.sWSNaq_list-group-item-info.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.sWSNaq_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.sWSNaq_list-group-item-warning.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-warning.sWSNaq_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.sWSNaq_list-group-item-warning.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.sWSNaq_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.sWSNaq_list-group-item-danger.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-danger.sWSNaq_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.sWSNaq_list-group-item-danger.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.sWSNaq_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.sWSNaq_list-group-item-light.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-light.sWSNaq_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.sWSNaq_list-group-item-light.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.sWSNaq_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.sWSNaq_list-group-item-dark.sWSNaq_list-group-item-action:hover, .sWSNaq_list-group-item-dark.sWSNaq_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.sWSNaq_list-group-item-dark.sWSNaq_list-group-item-action.sWSNaq_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.sWSNaq_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.sWSNaq_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.sWSNaq_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.sWSNaq_btn-close:disabled, .sWSNaq_btn-close.sWSNaq_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.sWSNaq_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.sWSNaq_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.sWSNaq_toast.sWSNaq_showing {
  opacity: 0;
}

.sWSNaq_toast:not(.sWSNaq_show) {
  display: none;
}

.sWSNaq_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.sWSNaq_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.sWSNaq_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.sWSNaq_toast-header .sWSNaq_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.sWSNaq_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.sWSNaq_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sWSNaq_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.sWSNaq_modal.sWSNaq_fade .sWSNaq_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_modal.sWSNaq_fade .sWSNaq_modal-dialog {
    transition: none;
  }
}

.sWSNaq_modal.sWSNaq_show .sWSNaq_modal-dialog {
  transform: none;
}

.sWSNaq_modal.sWSNaq_modal-static .sWSNaq_modal-dialog {
  transform: scale(1.02);
}

.sWSNaq_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.sWSNaq_modal-dialog-scrollable .sWSNaq_modal-content {
  max-height: 100%;
  overflow: hidden;
}

.sWSNaq_modal-dialog-scrollable .sWSNaq_modal-body {
  overflow-y: auto;
}

.sWSNaq_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.sWSNaq_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.sWSNaq_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.sWSNaq_modal-backdrop.sWSNaq_fade {
  opacity: 0;
}

.sWSNaq_modal-backdrop.sWSNaq_show {
  opacity: var(--bs-backdrop-opacity);
}

.sWSNaq_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sWSNaq_modal-header .sWSNaq_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.sWSNaq_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.sWSNaq_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.sWSNaq_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.sWSNaq_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .sWSNaq_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .sWSNaq_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .sWSNaq_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .sWSNaq_modal-lg, .sWSNaq_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_modal-xl {
    --bs-modal-width: 1140px;
  }
}

.sWSNaq_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.sWSNaq_modal-fullscreen .sWSNaq_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.sWSNaq_modal-fullscreen .sWSNaq_modal-header, .sWSNaq_modal-fullscreen .sWSNaq_modal-footer {
  border-radius: 0;
}

.sWSNaq_modal-fullscreen .sWSNaq_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .sWSNaq_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .sWSNaq_modal-fullscreen-sm-down .sWSNaq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-sm-down .sWSNaq_modal-header, .sWSNaq_modal-fullscreen-sm-down .sWSNaq_modal-footer {
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-sm-down .sWSNaq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .sWSNaq_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .sWSNaq_modal-fullscreen-md-down .sWSNaq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-md-down .sWSNaq_modal-header, .sWSNaq_modal-fullscreen-md-down .sWSNaq_modal-footer {
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-md-down .sWSNaq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .sWSNaq_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .sWSNaq_modal-fullscreen-lg-down .sWSNaq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-lg-down .sWSNaq_modal-header, .sWSNaq_modal-fullscreen-lg-down .sWSNaq_modal-footer {
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-lg-down .sWSNaq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .sWSNaq_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .sWSNaq_modal-fullscreen-xl-down .sWSNaq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-xl-down .sWSNaq_modal-header, .sWSNaq_modal-fullscreen-xl-down .sWSNaq_modal-footer {
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-xl-down .sWSNaq_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .sWSNaq_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .sWSNaq_modal-fullscreen-xxl-down .sWSNaq_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-xxl-down .sWSNaq_modal-header, .sWSNaq_modal-fullscreen-xxl-down .sWSNaq_modal-footer {
    border-radius: 0;
  }

  .sWSNaq_modal-fullscreen-xxl-down .sWSNaq_modal-body {
    overflow-y: auto;
  }
}

.sWSNaq_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.sWSNaq_tooltip.sWSNaq_show {
  opacity: var(--bs-tooltip-opacity);
}

.sWSNaq_tooltip .sWSNaq_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.sWSNaq_tooltip .sWSNaq_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.sWSNaq_bs-tooltip-top .sWSNaq_tooltip-arrow, .sWSNaq_bs-tooltip-auto[data-popper-placement^="top"] .sWSNaq_tooltip-arrow {
  bottom: 0;
}

.sWSNaq_bs-tooltip-top .sWSNaq_tooltip-arrow:before, .sWSNaq_bs-tooltip-auto[data-popper-placement^="top"] .sWSNaq_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.sWSNaq_bs-tooltip-end .sWSNaq_tooltip-arrow, .sWSNaq_bs-tooltip-auto[data-popper-placement^="right"] .sWSNaq_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.sWSNaq_bs-tooltip-end .sWSNaq_tooltip-arrow:before, .sWSNaq_bs-tooltip-auto[data-popper-placement^="right"] .sWSNaq_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.sWSNaq_bs-tooltip-bottom .sWSNaq_tooltip-arrow, .sWSNaq_bs-tooltip-auto[data-popper-placement^="bottom"] .sWSNaq_tooltip-arrow {
  top: 0;
}

.sWSNaq_bs-tooltip-bottom .sWSNaq_tooltip-arrow:before, .sWSNaq_bs-tooltip-auto[data-popper-placement^="bottom"] .sWSNaq_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.sWSNaq_bs-tooltip-start .sWSNaq_tooltip-arrow, .sWSNaq_bs-tooltip-auto[data-popper-placement^="left"] .sWSNaq_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.sWSNaq_bs-tooltip-start .sWSNaq_tooltip-arrow:before, .sWSNaq_bs-tooltip-auto[data-popper-placement^="left"] .sWSNaq_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.sWSNaq_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.sWSNaq_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.sWSNaq_popover .sWSNaq_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.sWSNaq_popover .sWSNaq_popover-arrow:before, .sWSNaq_popover .sWSNaq_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.sWSNaq_bs-popover-top > .sWSNaq_popover-arrow, .sWSNaq_bs-popover-auto[data-popper-placement^="top"] > .sWSNaq_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.sWSNaq_bs-popover-top > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="top"] > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-top > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="top"] > .sWSNaq_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.sWSNaq_bs-popover-top > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="top"] > .sWSNaq_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.sWSNaq_bs-popover-top > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="top"] > .sWSNaq_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.sWSNaq_bs-popover-end > .sWSNaq_popover-arrow, .sWSNaq_bs-popover-auto[data-popper-placement^="right"] > .sWSNaq_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.sWSNaq_bs-popover-end > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="right"] > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-end > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="right"] > .sWSNaq_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.sWSNaq_bs-popover-end > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="right"] > .sWSNaq_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.sWSNaq_bs-popover-end > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="right"] > .sWSNaq_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.sWSNaq_bs-popover-bottom > .sWSNaq_popover-arrow, .sWSNaq_bs-popover-auto[data-popper-placement^="bottom"] > .sWSNaq_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.sWSNaq_bs-popover-bottom > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="bottom"] > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-bottom > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="bottom"] > .sWSNaq_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.sWSNaq_bs-popover-bottom > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="bottom"] > .sWSNaq_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.sWSNaq_bs-popover-bottom > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="bottom"] > .sWSNaq_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.sWSNaq_bs-popover-bottom .sWSNaq_popover-header:before, .sWSNaq_bs-popover-auto[data-popper-placement^="bottom"] .sWSNaq_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.sWSNaq_bs-popover-start > .sWSNaq_popover-arrow, .sWSNaq_bs-popover-auto[data-popper-placement^="left"] > .sWSNaq_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.sWSNaq_bs-popover-start > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="left"] > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-start > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="left"] > .sWSNaq_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.sWSNaq_bs-popover-start > .sWSNaq_popover-arrow:before, .sWSNaq_bs-popover-auto[data-popper-placement^="left"] > .sWSNaq_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.sWSNaq_bs-popover-start > .sWSNaq_popover-arrow:after, .sWSNaq_bs-popover-auto[data-popper-placement^="left"] > .sWSNaq_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.sWSNaq_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.sWSNaq_popover-header:empty {
  display: none;
}

.sWSNaq_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.sWSNaq_carousel {
  position: relative;
}

.sWSNaq_carousel.sWSNaq_pointer-event {
  touch-action: pan-y;
}

.sWSNaq_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sWSNaq_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.sWSNaq_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_carousel-item {
    transition: none;
  }
}

.sWSNaq_carousel-item.sWSNaq_active, .sWSNaq_carousel-item-next, .sWSNaq_carousel-item-prev {
  display: block;
}

.sWSNaq_carousel-item-next:not(.sWSNaq_carousel-item-start), .sWSNaq_active.sWSNaq_carousel-item-end {
  transform: translateX(100%);
}

.sWSNaq_carousel-item-prev:not(.sWSNaq_carousel-item-end), .sWSNaq_active.sWSNaq_carousel-item-start {
  transform: translateX(-100%);
}

.sWSNaq_carousel-fade .sWSNaq_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.sWSNaq_carousel-fade .sWSNaq_carousel-item.sWSNaq_active, .sWSNaq_carousel-fade .sWSNaq_carousel-item-next.sWSNaq_carousel-item-start, .sWSNaq_carousel-fade .sWSNaq_carousel-item-prev.sWSNaq_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.sWSNaq_carousel-fade .sWSNaq_active.sWSNaq_carousel-item-start, .sWSNaq_carousel-fade .sWSNaq_active.sWSNaq_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_carousel-fade .sWSNaq_active.sWSNaq_carousel-item-start, .sWSNaq_carousel-fade .sWSNaq_active.sWSNaq_carousel-item-end {
    transition: none;
  }
}

.sWSNaq_carousel-control-prev, .sWSNaq_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_carousel-control-prev, .sWSNaq_carousel-control-next {
    transition: none;
  }
}

.sWSNaq_carousel-control-prev:hover, .sWSNaq_carousel-control-prev:focus, .sWSNaq_carousel-control-next:hover, .sWSNaq_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.sWSNaq_carousel-control-prev {
  left: 0;
}

.sWSNaq_carousel-control-next {
  right: 0;
}

.sWSNaq_carousel-control-prev-icon, .sWSNaq_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.sWSNaq_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.sWSNaq_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.sWSNaq_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sWSNaq_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.sWSNaq_carousel-indicators .sWSNaq_active {
  opacity: 1;
}

.sWSNaq_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.sWSNaq_carousel-dark .sWSNaq_carousel-control-prev-icon, .sWSNaq_carousel-dark .sWSNaq_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.sWSNaq_carousel-dark .sWSNaq_carousel-indicators [data-bs-target] {
  background-color: #000;
}

.sWSNaq_carousel-dark .sWSNaq_carousel-caption {
  color: #000;
}

.sWSNaq_spinner-grow, .sWSNaq_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes sWSNaq_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.sWSNaq_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.sWSNaq_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes sWSNaq_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.sWSNaq_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.sWSNaq_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_spinner-border, .sWSNaq_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.sWSNaq_offcanvas, .sWSNaq_offcanvas-xxl, .sWSNaq_offcanvas-xl, .sWSNaq_offcanvas-lg, .sWSNaq_offcanvas-md, .sWSNaq_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .sWSNaq_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .sWSNaq_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .sWSNaq_offcanvas-sm.sWSNaq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .sWSNaq_offcanvas-sm.sWSNaq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .sWSNaq_offcanvas-sm.sWSNaq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .sWSNaq_offcanvas-sm.sWSNaq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .sWSNaq_offcanvas-sm.sWSNaq_showing, .sWSNaq_offcanvas-sm.sWSNaq_show:not(.sWSNaq_hiding) {
    transform: none;
  }

  .sWSNaq_offcanvas-sm.sWSNaq_showing, .sWSNaq_offcanvas-sm.sWSNaq_hiding, .sWSNaq_offcanvas-sm.sWSNaq_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .sWSNaq_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .sWSNaq_offcanvas-sm .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_offcanvas-sm .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .sWSNaq_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .sWSNaq_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .sWSNaq_offcanvas-md.sWSNaq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .sWSNaq_offcanvas-md.sWSNaq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .sWSNaq_offcanvas-md.sWSNaq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .sWSNaq_offcanvas-md.sWSNaq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .sWSNaq_offcanvas-md.sWSNaq_showing, .sWSNaq_offcanvas-md.sWSNaq_show:not(.sWSNaq_hiding) {
    transform: none;
  }

  .sWSNaq_offcanvas-md.sWSNaq_showing, .sWSNaq_offcanvas-md.sWSNaq_hiding, .sWSNaq_offcanvas-md.sWSNaq_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .sWSNaq_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .sWSNaq_offcanvas-md .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_offcanvas-md .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .sWSNaq_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .sWSNaq_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .sWSNaq_offcanvas-lg.sWSNaq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .sWSNaq_offcanvas-lg.sWSNaq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .sWSNaq_offcanvas-lg.sWSNaq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .sWSNaq_offcanvas-lg.sWSNaq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .sWSNaq_offcanvas-lg.sWSNaq_showing, .sWSNaq_offcanvas-lg.sWSNaq_show:not(.sWSNaq_hiding) {
    transform: none;
  }

  .sWSNaq_offcanvas-lg.sWSNaq_showing, .sWSNaq_offcanvas-lg.sWSNaq_hiding, .sWSNaq_offcanvas-lg.sWSNaq_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .sWSNaq_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .sWSNaq_offcanvas-lg .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_offcanvas-lg .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .sWSNaq_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sWSNaq_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sWSNaq_offcanvas-xl.sWSNaq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .sWSNaq_offcanvas-xl.sWSNaq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .sWSNaq_offcanvas-xl.sWSNaq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .sWSNaq_offcanvas-xl.sWSNaq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .sWSNaq_offcanvas-xl.sWSNaq_showing, .sWSNaq_offcanvas-xl.sWSNaq_show:not(.sWSNaq_hiding) {
    transform: none;
  }

  .sWSNaq_offcanvas-xl.sWSNaq_showing, .sWSNaq_offcanvas-xl.sWSNaq_hiding, .sWSNaq_offcanvas-xl.sWSNaq_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .sWSNaq_offcanvas-xl .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_offcanvas-xl .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .sWSNaq_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .sWSNaq_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .sWSNaq_offcanvas-xxl.sWSNaq_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .sWSNaq_offcanvas-xxl.sWSNaq_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .sWSNaq_offcanvas-xxl.sWSNaq_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .sWSNaq_offcanvas-xxl.sWSNaq_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .sWSNaq_offcanvas-xxl.sWSNaq_showing, .sWSNaq_offcanvas-xxl.sWSNaq_show:not(.sWSNaq_hiding) {
    transform: none;
  }

  .sWSNaq_offcanvas-xxl.sWSNaq_showing, .sWSNaq_offcanvas-xxl.sWSNaq_hiding, .sWSNaq_offcanvas-xxl.sWSNaq_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .sWSNaq_offcanvas-xxl .sWSNaq_offcanvas-header {
    display: none;
  }

  .sWSNaq_offcanvas-xxl .sWSNaq_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.sWSNaq_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .sWSNaq_offcanvas {
    transition: none;
  }
}

.sWSNaq_offcanvas.sWSNaq_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.sWSNaq_offcanvas.sWSNaq_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.sWSNaq_offcanvas.sWSNaq_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.sWSNaq_offcanvas.sWSNaq_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.sWSNaq_offcanvas.sWSNaq_showing, .sWSNaq_offcanvas.sWSNaq_show:not(.sWSNaq_hiding) {
  transform: none;
}

.sWSNaq_offcanvas.sWSNaq_showing, .sWSNaq_offcanvas.sWSNaq_hiding, .sWSNaq_offcanvas.sWSNaq_show {
  visibility: visible;
}

.sWSNaq_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.sWSNaq_offcanvas-backdrop.sWSNaq_fade {
  opacity: 0;
}

.sWSNaq_offcanvas-backdrop.sWSNaq_show {
  opacity: .5;
}

.sWSNaq_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sWSNaq_offcanvas-header .sWSNaq_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.sWSNaq_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.sWSNaq_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.sWSNaq_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.sWSNaq_placeholder.sWSNaq_btn:before {
  content: "";
  display: inline-block;
}

.sWSNaq_placeholder-xs {
  min-height: .6em;
}

.sWSNaq_placeholder-sm {
  min-height: .8em;
}

.sWSNaq_placeholder-lg {
  min-height: 1.2em;
}

.sWSNaq_placeholder-glow .sWSNaq_placeholder {
  animation: 2s ease-in-out infinite sWSNaq_placeholder-glow;
}

@keyframes sWSNaq_placeholder-glow {
  50% {
    opacity: .2;
  }
}

.sWSNaq_placeholder-wave {
  animation: 2s linear infinite sWSNaq_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes sWSNaq_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.sWSNaq_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.sWSNaq_text-bg-primary {
  color: #000 !important;
  background-color: RGBA(37, 142, 1, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.sWSNaq_link-primary {
  color: #258e01 !important;
}

.sWSNaq_link-primary:hover, .sWSNaq_link-primary:focus {
  color: #51a534 !important;
}

.sWSNaq_link-secondary {
  color: #6c757d !important;
}

.sWSNaq_link-secondary:hover, .sWSNaq_link-secondary:focus {
  color: #565e64 !important;
}

.sWSNaq_link-success {
  color: #198754 !important;
}

.sWSNaq_link-success:hover, .sWSNaq_link-success:focus {
  color: #146c43 !important;
}

.sWSNaq_link-info {
  color: #0dcaf0 !important;
}

.sWSNaq_link-info:hover, .sWSNaq_link-info:focus {
  color: #3dd5f3 !important;
}

.sWSNaq_link-warning {
  color: #ffc107 !important;
}

.sWSNaq_link-warning:hover, .sWSNaq_link-warning:focus {
  color: #ffcd39 !important;
}

.sWSNaq_link-danger {
  color: #dc3545 !important;
}

.sWSNaq_link-danger:hover, .sWSNaq_link-danger:focus {
  color: #b02a37 !important;
}

.sWSNaq_link-light {
  color: #f8f9fa !important;
}

.sWSNaq_link-light:hover, .sWSNaq_link-light:focus {
  color: #f9fafb !important;
}

.sWSNaq_link-dark {
  color: #212529 !important;
}

.sWSNaq_link-dark:hover, .sWSNaq_link-dark:focus {
  color: #1a1e21 !important;
}

.sWSNaq_ratio {
  width: 100%;
  position: relative;
}

.sWSNaq_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.sWSNaq_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sWSNaq_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.sWSNaq_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.sWSNaq_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.sWSNaq_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.sWSNaq_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.sWSNaq_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sWSNaq_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sWSNaq_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sWSNaq_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sWSNaq_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sWSNaq_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sWSNaq_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sWSNaq_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sWSNaq_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sWSNaq_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sWSNaq_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.sWSNaq_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.sWSNaq_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.sWSNaq_visually-hidden, .sWSNaq_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.sWSNaq_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.sWSNaq_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sWSNaq_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.sWSNaq_align-baseline {
  vertical-align: baseline !important;
}

.sWSNaq_align-top {
  vertical-align: top !important;
}

.sWSNaq_align-middle {
  vertical-align: middle !important;
}

.sWSNaq_align-bottom {
  vertical-align: bottom !important;
}

.sWSNaq_align-text-bottom {
  vertical-align: text-bottom !important;
}

.sWSNaq_align-text-top {
  vertical-align: text-top !important;
}

.sWSNaq_float-start {
  float: left !important;
}

.sWSNaq_float-end {
  float: right !important;
}

.sWSNaq_float-none {
  float: none !important;
}

.sWSNaq_opacity-0 {
  opacity: 0 !important;
}

.sWSNaq_opacity-25 {
  opacity: .25 !important;
}

.sWSNaq_opacity-50 {
  opacity: .5 !important;
}

.sWSNaq_opacity-75 {
  opacity: .75 !important;
}

.sWSNaq_opacity-100 {
  opacity: 1 !important;
}

.sWSNaq_overflow-auto {
  overflow: auto !important;
}

.sWSNaq_overflow-hidden {
  overflow: hidden !important;
}

.sWSNaq_overflow-visible {
  overflow: visible !important;
}

.sWSNaq_overflow-scroll {
  overflow: scroll !important;
}

.sWSNaq_d-inline {
  display: inline !important;
}

.sWSNaq_d-inline-block {
  display: inline-block !important;
}

.sWSNaq_d-block {
  display: block !important;
}

.sWSNaq_d-grid {
  display: grid !important;
}

.sWSNaq_d-table {
  display: table !important;
}

.sWSNaq_d-table-row {
  display: table-row !important;
}

.sWSNaq_d-table-cell {
  display: table-cell !important;
}

.sWSNaq_d-flex {
  display: flex !important;
}

.sWSNaq_d-inline-flex {
  display: inline-flex !important;
}

.sWSNaq_d-none {
  display: none !important;
}

.sWSNaq_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.sWSNaq_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.sWSNaq_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.sWSNaq_shadow-none {
  box-shadow: none !important;
}

.sWSNaq_position-static {
  position: static !important;
}

.sWSNaq_position-relative {
  position: relative !important;
}

.sWSNaq_position-absolute {
  position: absolute !important;
}

.sWSNaq_position-fixed {
  position: fixed !important;
}

.sWSNaq_position-sticky {
  position: sticky !important;
}

.sWSNaq_top-0 {
  top: 0 !important;
}

.sWSNaq_top-50 {
  top: 50% !important;
}

.sWSNaq_top-100 {
  top: 100% !important;
}

.sWSNaq_bottom-0 {
  bottom: 0 !important;
}

.sWSNaq_bottom-50 {
  bottom: 50% !important;
}

.sWSNaq_bottom-100 {
  bottom: 100% !important;
}

.sWSNaq_start-0 {
  left: 0 !important;
}

.sWSNaq_start-50 {
  left: 50% !important;
}

.sWSNaq_start-100 {
  left: 100% !important;
}

.sWSNaq_end-0 {
  right: 0 !important;
}

.sWSNaq_end-50 {
  right: 50% !important;
}

.sWSNaq_end-100 {
  right: 100% !important;
}

.sWSNaq_translate-middle {
  transform: translate(-50%, -50%) !important;
}

.sWSNaq_translate-middle-x {
  transform: translateX(-50%) !important;
}

.sWSNaq_translate-middle-y {
  transform: translateY(-50%) !important;
}

.sWSNaq_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.sWSNaq_border-0 {
  border: 0 !important;
}

.sWSNaq_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.sWSNaq_border-top-0 {
  border-top: 0 !important;
}

.sWSNaq_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.sWSNaq_border-end-0 {
  border-right: 0 !important;
}

.sWSNaq_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.sWSNaq_border-bottom-0 {
  border-bottom: 0 !important;
}

.sWSNaq_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.sWSNaq_border-start-0 {
  border-left: 0 !important;
}

.sWSNaq_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.sWSNaq_border-1 {
  --bs-border-width: 1px;
}

.sWSNaq_border-2 {
  --bs-border-width: 2px;
}

.sWSNaq_border-3 {
  --bs-border-width: 3px;
}

.sWSNaq_border-4 {
  --bs-border-width: 4px;
}

.sWSNaq_border-5 {
  --bs-border-width: 5px;
}

.sWSNaq_border-opacity-10 {
  --bs-border-opacity: .1;
}

.sWSNaq_border-opacity-25 {
  --bs-border-opacity: .25;
}

.sWSNaq_border-opacity-50 {
  --bs-border-opacity: .5;
}

.sWSNaq_border-opacity-75 {
  --bs-border-opacity: .75;
}

.sWSNaq_border-opacity-100 {
  --bs-border-opacity: 1;
}

.sWSNaq_w-25 {
  width: 25% !important;
}

.sWSNaq_w-50 {
  width: 50% !important;
}

.sWSNaq_w-75 {
  width: 75% !important;
}

.sWSNaq_w-100 {
  width: 100% !important;
}

.sWSNaq_w-auto {
  width: auto !important;
}

.sWSNaq_mw-100 {
  max-width: 100% !important;
}

.sWSNaq_vw-100 {
  width: 100vw !important;
}

.sWSNaq_min-vw-100 {
  min-width: 100vw !important;
}

.sWSNaq_h-25 {
  height: 25% !important;
}

.sWSNaq_h-50 {
  height: 50% !important;
}

.sWSNaq_h-75 {
  height: 75% !important;
}

.sWSNaq_h-100 {
  height: 100% !important;
}

.sWSNaq_h-auto {
  height: auto !important;
}

.sWSNaq_mh-100 {
  max-height: 100% !important;
}

.sWSNaq_vh-100 {
  height: 100vh !important;
}

.sWSNaq_min-vh-100 {
  min-height: 100vh !important;
}

.sWSNaq_flex-fill {
  flex: auto !important;
}

.sWSNaq_flex-row {
  flex-direction: row !important;
}

.sWSNaq_flex-column {
  flex-direction: column !important;
}

.sWSNaq_flex-row-reverse {
  flex-direction: row-reverse !important;
}

.sWSNaq_flex-column-reverse {
  flex-direction: column-reverse !important;
}

.sWSNaq_flex-grow-0 {
  flex-grow: 0 !important;
}

.sWSNaq_flex-grow-1 {
  flex-grow: 1 !important;
}

.sWSNaq_flex-shrink-0 {
  flex-shrink: 0 !important;
}

.sWSNaq_flex-shrink-1 {
  flex-shrink: 1 !important;
}

.sWSNaq_flex-wrap {
  flex-wrap: wrap !important;
}

.sWSNaq_flex-nowrap {
  flex-wrap: nowrap !important;
}

.sWSNaq_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.sWSNaq_justify-content-start {
  justify-content: flex-start !important;
}

.sWSNaq_justify-content-end {
  justify-content: flex-end !important;
}

.sWSNaq_justify-content-center {
  justify-content: center !important;
}

.sWSNaq_justify-content-between {
  justify-content: space-between !important;
}

.sWSNaq_justify-content-around {
  justify-content: space-around !important;
}

.sWSNaq_justify-content-evenly {
  justify-content: space-evenly !important;
}

.sWSNaq_align-items-start {
  align-items: flex-start !important;
}

.sWSNaq_align-items-end {
  align-items: flex-end !important;
}

.sWSNaq_align-items-center {
  align-items: center !important;
}

.sWSNaq_align-items-baseline {
  align-items: baseline !important;
}

.sWSNaq_align-items-stretch {
  align-items: stretch !important;
}

.sWSNaq_align-content-start {
  align-content: flex-start !important;
}

.sWSNaq_align-content-end {
  align-content: flex-end !important;
}

.sWSNaq_align-content-center {
  align-content: center !important;
}

.sWSNaq_align-content-between {
  align-content: space-between !important;
}

.sWSNaq_align-content-around {
  align-content: space-around !important;
}

.sWSNaq_align-content-stretch {
  align-content: stretch !important;
}

.sWSNaq_align-self-auto {
  align-self: auto !important;
}

.sWSNaq_align-self-start {
  align-self: flex-start !important;
}

.sWSNaq_align-self-end {
  align-self: flex-end !important;
}

.sWSNaq_align-self-center {
  align-self: center !important;
}

.sWSNaq_align-self-baseline {
  align-self: baseline !important;
}

.sWSNaq_align-self-stretch {
  align-self: stretch !important;
}

.sWSNaq_order-first {
  order: -1 !important;
}

.sWSNaq_order-0 {
  order: 0 !important;
}

.sWSNaq_order-1 {
  order: 1 !important;
}

.sWSNaq_order-2 {
  order: 2 !important;
}

.sWSNaq_order-3 {
  order: 3 !important;
}

.sWSNaq_order-4 {
  order: 4 !important;
}

.sWSNaq_order-5 {
  order: 5 !important;
}

.sWSNaq_order-last {
  order: 6 !important;
}

.sWSNaq_m-0 {
  margin: 0 !important;
}

.sWSNaq_m-1 {
  margin: .25rem !important;
}

.sWSNaq_m-2 {
  margin: .5rem !important;
}

.sWSNaq_m-3 {
  margin: 1rem !important;
}

.sWSNaq_m-4 {
  margin: 1.5rem !important;
}

.sWSNaq_m-5 {
  margin: 3rem !important;
}

.sWSNaq_m-auto {
  margin: auto !important;
}

.sWSNaq_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sWSNaq_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.sWSNaq_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.sWSNaq_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.sWSNaq_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.sWSNaq_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.sWSNaq_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.sWSNaq_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sWSNaq_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.sWSNaq_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.sWSNaq_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.sWSNaq_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.sWSNaq_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.sWSNaq_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.sWSNaq_mt-0 {
  margin-top: 0 !important;
}

.sWSNaq_mt-1 {
  margin-top: .25rem !important;
}

.sWSNaq_mt-2 {
  margin-top: .5rem !important;
}

.sWSNaq_mt-3 {
  margin-top: 1rem !important;
}

.sWSNaq_mt-4 {
  margin-top: 1.5rem !important;
}

.sWSNaq_mt-5 {
  margin-top: 3rem !important;
}

.sWSNaq_mt-auto {
  margin-top: auto !important;
}

.sWSNaq_me-0 {
  margin-right: 0 !important;
}

.sWSNaq_me-1 {
  margin-right: .25rem !important;
}

.sWSNaq_me-2 {
  margin-right: .5rem !important;
}

.sWSNaq_me-3 {
  margin-right: 1rem !important;
}

.sWSNaq_me-4 {
  margin-right: 1.5rem !important;
}

.sWSNaq_me-5 {
  margin-right: 3rem !important;
}

.sWSNaq_me-auto {
  margin-right: auto !important;
}

.sWSNaq_mb-0 {
  margin-bottom: 0 !important;
}

.sWSNaq_mb-1 {
  margin-bottom: .25rem !important;
}

.sWSNaq_mb-2 {
  margin-bottom: .5rem !important;
}

.sWSNaq_mb-3 {
  margin-bottom: 1rem !important;
}

.sWSNaq_mb-4 {
  margin-bottom: 1.5rem !important;
}

.sWSNaq_mb-5 {
  margin-bottom: 3rem !important;
}

.sWSNaq_mb-auto {
  margin-bottom: auto !important;
}

.sWSNaq_ms-0 {
  margin-left: 0 !important;
}

.sWSNaq_ms-1 {
  margin-left: .25rem !important;
}

.sWSNaq_ms-2 {
  margin-left: .5rem !important;
}

.sWSNaq_ms-3 {
  margin-left: 1rem !important;
}

.sWSNaq_ms-4 {
  margin-left: 1.5rem !important;
}

.sWSNaq_ms-5 {
  margin-left: 3rem !important;
}

.sWSNaq_ms-auto {
  margin-left: auto !important;
}

.sWSNaq_p-0 {
  padding: 0 !important;
}

.sWSNaq_p-1 {
  padding: .25rem !important;
}

.sWSNaq_p-2 {
  padding: .5rem !important;
}

.sWSNaq_p-3 {
  padding: 1rem !important;
}

.sWSNaq_p-4 {
  padding: 1.5rem !important;
}

.sWSNaq_p-5 {
  padding: 3rem !important;
}

.sWSNaq_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sWSNaq_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.sWSNaq_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.sWSNaq_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.sWSNaq_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.sWSNaq_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.sWSNaq_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.sWSNaq_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.sWSNaq_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.sWSNaq_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.sWSNaq_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.sWSNaq_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.sWSNaq_pt-0 {
  padding-top: 0 !important;
}

.sWSNaq_pt-1 {
  padding-top: .25rem !important;
}

.sWSNaq_pt-2 {
  padding-top: .5rem !important;
}

.sWSNaq_pt-3 {
  padding-top: 1rem !important;
}

.sWSNaq_pt-4 {
  padding-top: 1.5rem !important;
}

.sWSNaq_pt-5 {
  padding-top: 3rem !important;
}

.sWSNaq_pe-0 {
  padding-right: 0 !important;
}

.sWSNaq_pe-1 {
  padding-right: .25rem !important;
}

.sWSNaq_pe-2 {
  padding-right: .5rem !important;
}

.sWSNaq_pe-3 {
  padding-right: 1rem !important;
}

.sWSNaq_pe-4 {
  padding-right: 1.5rem !important;
}

.sWSNaq_pe-5 {
  padding-right: 3rem !important;
}

.sWSNaq_pb-0 {
  padding-bottom: 0 !important;
}

.sWSNaq_pb-1 {
  padding-bottom: .25rem !important;
}

.sWSNaq_pb-2 {
  padding-bottom: .5rem !important;
}

.sWSNaq_pb-3 {
  padding-bottom: 1rem !important;
}

.sWSNaq_pb-4 {
  padding-bottom: 1.5rem !important;
}

.sWSNaq_pb-5 {
  padding-bottom: 3rem !important;
}

.sWSNaq_ps-0 {
  padding-left: 0 !important;
}

.sWSNaq_ps-1 {
  padding-left: .25rem !important;
}

.sWSNaq_ps-2 {
  padding-left: .5rem !important;
}

.sWSNaq_ps-3 {
  padding-left: 1rem !important;
}

.sWSNaq_ps-4 {
  padding-left: 1.5rem !important;
}

.sWSNaq_ps-5 {
  padding-left: 3rem !important;
}

.sWSNaq_gap-0 {
  gap: 0 !important;
}

.sWSNaq_gap-1 {
  gap: .25rem !important;
}

.sWSNaq_gap-2 {
  gap: .5rem !important;
}

.sWSNaq_gap-3 {
  gap: 1rem !important;
}

.sWSNaq_gap-4 {
  gap: 1.5rem !important;
}

.sWSNaq_gap-5 {
  gap: 3rem !important;
}

.sWSNaq_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.sWSNaq_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.sWSNaq_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.sWSNaq_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.sWSNaq_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.sWSNaq_fs-5 {
  font-size: 1.25rem !important;
}

.sWSNaq_fs-6 {
  font-size: 1rem !important;
}

.sWSNaq_fst-italic {
  font-style: italic !important;
}

.sWSNaq_fst-normal {
  font-style: normal !important;
}

.sWSNaq_fw-light {
  font-weight: 300 !important;
}

.sWSNaq_fw-lighter {
  font-weight: lighter !important;
}

.sWSNaq_fw-normal {
  font-weight: 400 !important;
}

.sWSNaq_fw-bold {
  font-weight: 700 !important;
}

.sWSNaq_fw-semibold {
  font-weight: 600 !important;
}

.sWSNaq_fw-bolder {
  font-weight: bolder !important;
}

.sWSNaq_lh-1 {
  line-height: 1 !important;
}

.sWSNaq_lh-sm {
  line-height: 1.25 !important;
}

.sWSNaq_lh-base {
  line-height: 1.5 !important;
}

.sWSNaq_lh-lg {
  line-height: 2 !important;
}

.sWSNaq_text-start {
  text-align: left !important;
}

.sWSNaq_text-end {
  text-align: right !important;
}

.sWSNaq_text-center {
  text-align: center !important;
}

.sWSNaq_text-decoration-none {
  text-decoration: none !important;
}

.sWSNaq_text-decoration-underline {
  text-decoration: underline !important;
}

.sWSNaq_text-decoration-line-through {
  text-decoration: line-through !important;
}

.sWSNaq_text-lowercase {
  text-transform: lowercase !important;
}

.sWSNaq_text-uppercase {
  text-transform: uppercase !important;
}

.sWSNaq_text-capitalize {
  text-transform: capitalize !important;
}

.sWSNaq_text-wrap {
  white-space: normal !important;
}

.sWSNaq_text-nowrap {
  white-space: nowrap !important;
}

.sWSNaq_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.sWSNaq_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.sWSNaq_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.sWSNaq_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.sWSNaq_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.sWSNaq_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.sWSNaq_text-opacity-25 {
  --bs-text-opacity: .25;
}

.sWSNaq_text-opacity-50 {
  --bs-text-opacity: .5;
}

.sWSNaq_text-opacity-75 {
  --bs-text-opacity: .75;
}

.sWSNaq_text-opacity-100 {
  --bs-text-opacity: 1;
}

.sWSNaq_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.sWSNaq_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.sWSNaq_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.sWSNaq_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.sWSNaq_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.sWSNaq_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.sWSNaq_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.sWSNaq_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.sWSNaq_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.sWSNaq_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.sWSNaq_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.sWSNaq_pe-none {
  pointer-events: none !important;
}

.sWSNaq_pe-auto {
  pointer-events: auto !important;
}

.sWSNaq_rounded {
  border-radius: var(--bs-border-radius) !important;
}

.sWSNaq_rounded-0 {
  border-radius: 0 !important;
}

.sWSNaq_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.sWSNaq_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.sWSNaq_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.sWSNaq_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.sWSNaq_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.sWSNaq_rounded-circle {
  border-radius: 50% !important;
}

.sWSNaq_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.sWSNaq_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.sWSNaq_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.sWSNaq_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.sWSNaq_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.sWSNaq_visible {
  visibility: visible !important;
}

.sWSNaq_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .sWSNaq_float-sm-start {
    float: left !important;
  }

  .sWSNaq_float-sm-end {
    float: right !important;
  }

  .sWSNaq_float-sm-none {
    float: none !important;
  }

  .sWSNaq_d-sm-inline {
    display: inline !important;
  }

  .sWSNaq_d-sm-inline-block {
    display: inline-block !important;
  }

  .sWSNaq_d-sm-block {
    display: block !important;
  }

  .sWSNaq_d-sm-grid {
    display: grid !important;
  }

  .sWSNaq_d-sm-table {
    display: table !important;
  }

  .sWSNaq_d-sm-table-row {
    display: table-row !important;
  }

  .sWSNaq_d-sm-table-cell {
    display: table-cell !important;
  }

  .sWSNaq_d-sm-flex {
    display: flex !important;
  }

  .sWSNaq_d-sm-inline-flex {
    display: inline-flex !important;
  }

  .sWSNaq_d-sm-none {
    display: none !important;
  }

  .sWSNaq_flex-sm-fill {
    flex: auto !important;
  }

  .sWSNaq_flex-sm-row {
    flex-direction: row !important;
  }

  .sWSNaq_flex-sm-column {
    flex-direction: column !important;
  }

  .sWSNaq_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sWSNaq_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sWSNaq_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .sWSNaq_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .sWSNaq_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sWSNaq_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .sWSNaq_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .sWSNaq_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .sWSNaq_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sWSNaq_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .sWSNaq_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .sWSNaq_justify-content-sm-center {
    justify-content: center !important;
  }

  .sWSNaq_justify-content-sm-between {
    justify-content: space-between !important;
  }

  .sWSNaq_justify-content-sm-around {
    justify-content: space-around !important;
  }

  .sWSNaq_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .sWSNaq_align-items-sm-start {
    align-items: flex-start !important;
  }

  .sWSNaq_align-items-sm-end {
    align-items: flex-end !important;
  }

  .sWSNaq_align-items-sm-center {
    align-items: center !important;
  }

  .sWSNaq_align-items-sm-baseline {
    align-items: baseline !important;
  }

  .sWSNaq_align-items-sm-stretch {
    align-items: stretch !important;
  }

  .sWSNaq_align-content-sm-start {
    align-content: flex-start !important;
  }

  .sWSNaq_align-content-sm-end {
    align-content: flex-end !important;
  }

  .sWSNaq_align-content-sm-center {
    align-content: center !important;
  }

  .sWSNaq_align-content-sm-between {
    align-content: space-between !important;
  }

  .sWSNaq_align-content-sm-around {
    align-content: space-around !important;
  }

  .sWSNaq_align-content-sm-stretch {
    align-content: stretch !important;
  }

  .sWSNaq_align-self-sm-auto {
    align-self: auto !important;
  }

  .sWSNaq_align-self-sm-start {
    align-self: flex-start !important;
  }

  .sWSNaq_align-self-sm-end {
    align-self: flex-end !important;
  }

  .sWSNaq_align-self-sm-center {
    align-self: center !important;
  }

  .sWSNaq_align-self-sm-baseline {
    align-self: baseline !important;
  }

  .sWSNaq_align-self-sm-stretch {
    align-self: stretch !important;
  }

  .sWSNaq_order-sm-first {
    order: -1 !important;
  }

  .sWSNaq_order-sm-0 {
    order: 0 !important;
  }

  .sWSNaq_order-sm-1 {
    order: 1 !important;
  }

  .sWSNaq_order-sm-2 {
    order: 2 !important;
  }

  .sWSNaq_order-sm-3 {
    order: 3 !important;
  }

  .sWSNaq_order-sm-4 {
    order: 4 !important;
  }

  .sWSNaq_order-sm-5 {
    order: 5 !important;
  }

  .sWSNaq_order-sm-last {
    order: 6 !important;
  }

  .sWSNaq_m-sm-0 {
    margin: 0 !important;
  }

  .sWSNaq_m-sm-1 {
    margin: .25rem !important;
  }

  .sWSNaq_m-sm-2 {
    margin: .5rem !important;
  }

  .sWSNaq_m-sm-3 {
    margin: 1rem !important;
  }

  .sWSNaq_m-sm-4 {
    margin: 1.5rem !important;
  }

  .sWSNaq_m-sm-5 {
    margin: 3rem !important;
  }

  .sWSNaq_m-sm-auto {
    margin: auto !important;
  }

  .sWSNaq_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sWSNaq_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sWSNaq_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sWSNaq_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sWSNaq_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sWSNaq_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sWSNaq_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sWSNaq_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sWSNaq_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sWSNaq_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sWSNaq_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sWSNaq_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sWSNaq_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sWSNaq_mt-sm-0 {
    margin-top: 0 !important;
  }

  .sWSNaq_mt-sm-1 {
    margin-top: .25rem !important;
  }

  .sWSNaq_mt-sm-2 {
    margin-top: .5rem !important;
  }

  .sWSNaq_mt-sm-3 {
    margin-top: 1rem !important;
  }

  .sWSNaq_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .sWSNaq_mt-sm-5 {
    margin-top: 3rem !important;
  }

  .sWSNaq_mt-sm-auto {
    margin-top: auto !important;
  }

  .sWSNaq_me-sm-0 {
    margin-right: 0 !important;
  }

  .sWSNaq_me-sm-1 {
    margin-right: .25rem !important;
  }

  .sWSNaq_me-sm-2 {
    margin-right: .5rem !important;
  }

  .sWSNaq_me-sm-3 {
    margin-right: 1rem !important;
  }

  .sWSNaq_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .sWSNaq_me-sm-5 {
    margin-right: 3rem !important;
  }

  .sWSNaq_me-sm-auto {
    margin-right: auto !important;
  }

  .sWSNaq_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .sWSNaq_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .sWSNaq_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .sWSNaq_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .sWSNaq_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .sWSNaq_mb-sm-auto {
    margin-bottom: auto !important;
  }

  .sWSNaq_ms-sm-0 {
    margin-left: 0 !important;
  }

  .sWSNaq_ms-sm-1 {
    margin-left: .25rem !important;
  }

  .sWSNaq_ms-sm-2 {
    margin-left: .5rem !important;
  }

  .sWSNaq_ms-sm-3 {
    margin-left: 1rem !important;
  }

  .sWSNaq_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .sWSNaq_ms-sm-5 {
    margin-left: 3rem !important;
  }

  .sWSNaq_ms-sm-auto {
    margin-left: auto !important;
  }

  .sWSNaq_p-sm-0 {
    padding: 0 !important;
  }

  .sWSNaq_p-sm-1 {
    padding: .25rem !important;
  }

  .sWSNaq_p-sm-2 {
    padding: .5rem !important;
  }

  .sWSNaq_p-sm-3 {
    padding: 1rem !important;
  }

  .sWSNaq_p-sm-4 {
    padding: 1.5rem !important;
  }

  .sWSNaq_p-sm-5 {
    padding: 3rem !important;
  }

  .sWSNaq_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sWSNaq_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sWSNaq_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sWSNaq_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sWSNaq_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sWSNaq_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sWSNaq_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sWSNaq_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sWSNaq_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sWSNaq_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sWSNaq_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sWSNaq_pt-sm-0 {
    padding-top: 0 !important;
  }

  .sWSNaq_pt-sm-1 {
    padding-top: .25rem !important;
  }

  .sWSNaq_pt-sm-2 {
    padding-top: .5rem !important;
  }

  .sWSNaq_pt-sm-3 {
    padding-top: 1rem !important;
  }

  .sWSNaq_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .sWSNaq_pt-sm-5 {
    padding-top: 3rem !important;
  }

  .sWSNaq_pe-sm-0 {
    padding-right: 0 !important;
  }

  .sWSNaq_pe-sm-1 {
    padding-right: .25rem !important;
  }

  .sWSNaq_pe-sm-2 {
    padding-right: .5rem !important;
  }

  .sWSNaq_pe-sm-3 {
    padding-right: 1rem !important;
  }

  .sWSNaq_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .sWSNaq_pe-sm-5 {
    padding-right: 3rem !important;
  }

  .sWSNaq_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .sWSNaq_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .sWSNaq_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .sWSNaq_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .sWSNaq_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .sWSNaq_ps-sm-0 {
    padding-left: 0 !important;
  }

  .sWSNaq_ps-sm-1 {
    padding-left: .25rem !important;
  }

  .sWSNaq_ps-sm-2 {
    padding-left: .5rem !important;
  }

  .sWSNaq_ps-sm-3 {
    padding-left: 1rem !important;
  }

  .sWSNaq_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .sWSNaq_ps-sm-5 {
    padding-left: 3rem !important;
  }

  .sWSNaq_gap-sm-0 {
    gap: 0 !important;
  }

  .sWSNaq_gap-sm-1 {
    gap: .25rem !important;
  }

  .sWSNaq_gap-sm-2 {
    gap: .5rem !important;
  }

  .sWSNaq_gap-sm-3 {
    gap: 1rem !important;
  }

  .sWSNaq_gap-sm-4 {
    gap: 1.5rem !important;
  }

  .sWSNaq_gap-sm-5 {
    gap: 3rem !important;
  }

  .sWSNaq_text-sm-start {
    text-align: left !important;
  }

  .sWSNaq_text-sm-end {
    text-align: right !important;
  }

  .sWSNaq_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .sWSNaq_float-md-start {
    float: left !important;
  }

  .sWSNaq_float-md-end {
    float: right !important;
  }

  .sWSNaq_float-md-none {
    float: none !important;
  }

  .sWSNaq_d-md-inline {
    display: inline !important;
  }

  .sWSNaq_d-md-inline-block {
    display: inline-block !important;
  }

  .sWSNaq_d-md-block {
    display: block !important;
  }

  .sWSNaq_d-md-grid {
    display: grid !important;
  }

  .sWSNaq_d-md-table {
    display: table !important;
  }

  .sWSNaq_d-md-table-row {
    display: table-row !important;
  }

  .sWSNaq_d-md-table-cell {
    display: table-cell !important;
  }

  .sWSNaq_d-md-flex {
    display: flex !important;
  }

  .sWSNaq_d-md-inline-flex {
    display: inline-flex !important;
  }

  .sWSNaq_d-md-none {
    display: none !important;
  }

  .sWSNaq_flex-md-fill {
    flex: auto !important;
  }

  .sWSNaq_flex-md-row {
    flex-direction: row !important;
  }

  .sWSNaq_flex-md-column {
    flex-direction: column !important;
  }

  .sWSNaq_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sWSNaq_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sWSNaq_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .sWSNaq_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .sWSNaq_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sWSNaq_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .sWSNaq_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .sWSNaq_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .sWSNaq_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sWSNaq_justify-content-md-start {
    justify-content: flex-start !important;
  }

  .sWSNaq_justify-content-md-end {
    justify-content: flex-end !important;
  }

  .sWSNaq_justify-content-md-center {
    justify-content: center !important;
  }

  .sWSNaq_justify-content-md-between {
    justify-content: space-between !important;
  }

  .sWSNaq_justify-content-md-around {
    justify-content: space-around !important;
  }

  .sWSNaq_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .sWSNaq_align-items-md-start {
    align-items: flex-start !important;
  }

  .sWSNaq_align-items-md-end {
    align-items: flex-end !important;
  }

  .sWSNaq_align-items-md-center {
    align-items: center !important;
  }

  .sWSNaq_align-items-md-baseline {
    align-items: baseline !important;
  }

  .sWSNaq_align-items-md-stretch {
    align-items: stretch !important;
  }

  .sWSNaq_align-content-md-start {
    align-content: flex-start !important;
  }

  .sWSNaq_align-content-md-end {
    align-content: flex-end !important;
  }

  .sWSNaq_align-content-md-center {
    align-content: center !important;
  }

  .sWSNaq_align-content-md-between {
    align-content: space-between !important;
  }

  .sWSNaq_align-content-md-around {
    align-content: space-around !important;
  }

  .sWSNaq_align-content-md-stretch {
    align-content: stretch !important;
  }

  .sWSNaq_align-self-md-auto {
    align-self: auto !important;
  }

  .sWSNaq_align-self-md-start {
    align-self: flex-start !important;
  }

  .sWSNaq_align-self-md-end {
    align-self: flex-end !important;
  }

  .sWSNaq_align-self-md-center {
    align-self: center !important;
  }

  .sWSNaq_align-self-md-baseline {
    align-self: baseline !important;
  }

  .sWSNaq_align-self-md-stretch {
    align-self: stretch !important;
  }

  .sWSNaq_order-md-first {
    order: -1 !important;
  }

  .sWSNaq_order-md-0 {
    order: 0 !important;
  }

  .sWSNaq_order-md-1 {
    order: 1 !important;
  }

  .sWSNaq_order-md-2 {
    order: 2 !important;
  }

  .sWSNaq_order-md-3 {
    order: 3 !important;
  }

  .sWSNaq_order-md-4 {
    order: 4 !important;
  }

  .sWSNaq_order-md-5 {
    order: 5 !important;
  }

  .sWSNaq_order-md-last {
    order: 6 !important;
  }

  .sWSNaq_m-md-0 {
    margin: 0 !important;
  }

  .sWSNaq_m-md-1 {
    margin: .25rem !important;
  }

  .sWSNaq_m-md-2 {
    margin: .5rem !important;
  }

  .sWSNaq_m-md-3 {
    margin: 1rem !important;
  }

  .sWSNaq_m-md-4 {
    margin: 1.5rem !important;
  }

  .sWSNaq_m-md-5 {
    margin: 3rem !important;
  }

  .sWSNaq_m-md-auto {
    margin: auto !important;
  }

  .sWSNaq_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sWSNaq_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sWSNaq_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sWSNaq_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sWSNaq_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sWSNaq_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sWSNaq_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sWSNaq_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sWSNaq_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sWSNaq_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sWSNaq_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sWSNaq_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sWSNaq_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sWSNaq_mt-md-0 {
    margin-top: 0 !important;
  }

  .sWSNaq_mt-md-1 {
    margin-top: .25rem !important;
  }

  .sWSNaq_mt-md-2 {
    margin-top: .5rem !important;
  }

  .sWSNaq_mt-md-3 {
    margin-top: 1rem !important;
  }

  .sWSNaq_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .sWSNaq_mt-md-5 {
    margin-top: 3rem !important;
  }

  .sWSNaq_mt-md-auto {
    margin-top: auto !important;
  }

  .sWSNaq_me-md-0 {
    margin-right: 0 !important;
  }

  .sWSNaq_me-md-1 {
    margin-right: .25rem !important;
  }

  .sWSNaq_me-md-2 {
    margin-right: .5rem !important;
  }

  .sWSNaq_me-md-3 {
    margin-right: 1rem !important;
  }

  .sWSNaq_me-md-4 {
    margin-right: 1.5rem !important;
  }

  .sWSNaq_me-md-5 {
    margin-right: 3rem !important;
  }

  .sWSNaq_me-md-auto {
    margin-right: auto !important;
  }

  .sWSNaq_mb-md-0 {
    margin-bottom: 0 !important;
  }

  .sWSNaq_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .sWSNaq_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .sWSNaq_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .sWSNaq_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .sWSNaq_mb-md-auto {
    margin-bottom: auto !important;
  }

  .sWSNaq_ms-md-0 {
    margin-left: 0 !important;
  }

  .sWSNaq_ms-md-1 {
    margin-left: .25rem !important;
  }

  .sWSNaq_ms-md-2 {
    margin-left: .5rem !important;
  }

  .sWSNaq_ms-md-3 {
    margin-left: 1rem !important;
  }

  .sWSNaq_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .sWSNaq_ms-md-5 {
    margin-left: 3rem !important;
  }

  .sWSNaq_ms-md-auto {
    margin-left: auto !important;
  }

  .sWSNaq_p-md-0 {
    padding: 0 !important;
  }

  .sWSNaq_p-md-1 {
    padding: .25rem !important;
  }

  .sWSNaq_p-md-2 {
    padding: .5rem !important;
  }

  .sWSNaq_p-md-3 {
    padding: 1rem !important;
  }

  .sWSNaq_p-md-4 {
    padding: 1.5rem !important;
  }

  .sWSNaq_p-md-5 {
    padding: 3rem !important;
  }

  .sWSNaq_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sWSNaq_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sWSNaq_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sWSNaq_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sWSNaq_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sWSNaq_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sWSNaq_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sWSNaq_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sWSNaq_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sWSNaq_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sWSNaq_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sWSNaq_pt-md-0 {
    padding-top: 0 !important;
  }

  .sWSNaq_pt-md-1 {
    padding-top: .25rem !important;
  }

  .sWSNaq_pt-md-2 {
    padding-top: .5rem !important;
  }

  .sWSNaq_pt-md-3 {
    padding-top: 1rem !important;
  }

  .sWSNaq_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .sWSNaq_pt-md-5 {
    padding-top: 3rem !important;
  }

  .sWSNaq_pe-md-0 {
    padding-right: 0 !important;
  }

  .sWSNaq_pe-md-1 {
    padding-right: .25rem !important;
  }

  .sWSNaq_pe-md-2 {
    padding-right: .5rem !important;
  }

  .sWSNaq_pe-md-3 {
    padding-right: 1rem !important;
  }

  .sWSNaq_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .sWSNaq_pe-md-5 {
    padding-right: 3rem !important;
  }

  .sWSNaq_pb-md-0 {
    padding-bottom: 0 !important;
  }

  .sWSNaq_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .sWSNaq_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .sWSNaq_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .sWSNaq_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .sWSNaq_ps-md-0 {
    padding-left: 0 !important;
  }

  .sWSNaq_ps-md-1 {
    padding-left: .25rem !important;
  }

  .sWSNaq_ps-md-2 {
    padding-left: .5rem !important;
  }

  .sWSNaq_ps-md-3 {
    padding-left: 1rem !important;
  }

  .sWSNaq_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .sWSNaq_ps-md-5 {
    padding-left: 3rem !important;
  }

  .sWSNaq_gap-md-0 {
    gap: 0 !important;
  }

  .sWSNaq_gap-md-1 {
    gap: .25rem !important;
  }

  .sWSNaq_gap-md-2 {
    gap: .5rem !important;
  }

  .sWSNaq_gap-md-3 {
    gap: 1rem !important;
  }

  .sWSNaq_gap-md-4 {
    gap: 1.5rem !important;
  }

  .sWSNaq_gap-md-5 {
    gap: 3rem !important;
  }

  .sWSNaq_text-md-start {
    text-align: left !important;
  }

  .sWSNaq_text-md-end {
    text-align: right !important;
  }

  .sWSNaq_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .sWSNaq_float-lg-start {
    float: left !important;
  }

  .sWSNaq_float-lg-end {
    float: right !important;
  }

  .sWSNaq_float-lg-none {
    float: none !important;
  }

  .sWSNaq_d-lg-inline {
    display: inline !important;
  }

  .sWSNaq_d-lg-inline-block {
    display: inline-block !important;
  }

  .sWSNaq_d-lg-block {
    display: block !important;
  }

  .sWSNaq_d-lg-grid {
    display: grid !important;
  }

  .sWSNaq_d-lg-table {
    display: table !important;
  }

  .sWSNaq_d-lg-table-row {
    display: table-row !important;
  }

  .sWSNaq_d-lg-table-cell {
    display: table-cell !important;
  }

  .sWSNaq_d-lg-flex {
    display: flex !important;
  }

  .sWSNaq_d-lg-inline-flex {
    display: inline-flex !important;
  }

  .sWSNaq_d-lg-none {
    display: none !important;
  }

  .sWSNaq_flex-lg-fill {
    flex: auto !important;
  }

  .sWSNaq_flex-lg-row {
    flex-direction: row !important;
  }

  .sWSNaq_flex-lg-column {
    flex-direction: column !important;
  }

  .sWSNaq_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sWSNaq_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sWSNaq_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .sWSNaq_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .sWSNaq_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sWSNaq_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .sWSNaq_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .sWSNaq_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .sWSNaq_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sWSNaq_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .sWSNaq_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .sWSNaq_justify-content-lg-center {
    justify-content: center !important;
  }

  .sWSNaq_justify-content-lg-between {
    justify-content: space-between !important;
  }

  .sWSNaq_justify-content-lg-around {
    justify-content: space-around !important;
  }

  .sWSNaq_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .sWSNaq_align-items-lg-start {
    align-items: flex-start !important;
  }

  .sWSNaq_align-items-lg-end {
    align-items: flex-end !important;
  }

  .sWSNaq_align-items-lg-center {
    align-items: center !important;
  }

  .sWSNaq_align-items-lg-baseline {
    align-items: baseline !important;
  }

  .sWSNaq_align-items-lg-stretch {
    align-items: stretch !important;
  }

  .sWSNaq_align-content-lg-start {
    align-content: flex-start !important;
  }

  .sWSNaq_align-content-lg-end {
    align-content: flex-end !important;
  }

  .sWSNaq_align-content-lg-center {
    align-content: center !important;
  }

  .sWSNaq_align-content-lg-between {
    align-content: space-between !important;
  }

  .sWSNaq_align-content-lg-around {
    align-content: space-around !important;
  }

  .sWSNaq_align-content-lg-stretch {
    align-content: stretch !important;
  }

  .sWSNaq_align-self-lg-auto {
    align-self: auto !important;
  }

  .sWSNaq_align-self-lg-start {
    align-self: flex-start !important;
  }

  .sWSNaq_align-self-lg-end {
    align-self: flex-end !important;
  }

  .sWSNaq_align-self-lg-center {
    align-self: center !important;
  }

  .sWSNaq_align-self-lg-baseline {
    align-self: baseline !important;
  }

  .sWSNaq_align-self-lg-stretch {
    align-self: stretch !important;
  }

  .sWSNaq_order-lg-first {
    order: -1 !important;
  }

  .sWSNaq_order-lg-0 {
    order: 0 !important;
  }

  .sWSNaq_order-lg-1 {
    order: 1 !important;
  }

  .sWSNaq_order-lg-2 {
    order: 2 !important;
  }

  .sWSNaq_order-lg-3 {
    order: 3 !important;
  }

  .sWSNaq_order-lg-4 {
    order: 4 !important;
  }

  .sWSNaq_order-lg-5 {
    order: 5 !important;
  }

  .sWSNaq_order-lg-last {
    order: 6 !important;
  }

  .sWSNaq_m-lg-0 {
    margin: 0 !important;
  }

  .sWSNaq_m-lg-1 {
    margin: .25rem !important;
  }

  .sWSNaq_m-lg-2 {
    margin: .5rem !important;
  }

  .sWSNaq_m-lg-3 {
    margin: 1rem !important;
  }

  .sWSNaq_m-lg-4 {
    margin: 1.5rem !important;
  }

  .sWSNaq_m-lg-5 {
    margin: 3rem !important;
  }

  .sWSNaq_m-lg-auto {
    margin: auto !important;
  }

  .sWSNaq_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sWSNaq_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sWSNaq_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sWSNaq_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sWSNaq_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sWSNaq_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sWSNaq_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sWSNaq_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sWSNaq_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sWSNaq_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sWSNaq_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sWSNaq_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sWSNaq_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sWSNaq_mt-lg-0 {
    margin-top: 0 !important;
  }

  .sWSNaq_mt-lg-1 {
    margin-top: .25rem !important;
  }

  .sWSNaq_mt-lg-2 {
    margin-top: .5rem !important;
  }

  .sWSNaq_mt-lg-3 {
    margin-top: 1rem !important;
  }

  .sWSNaq_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .sWSNaq_mt-lg-5 {
    margin-top: 3rem !important;
  }

  .sWSNaq_mt-lg-auto {
    margin-top: auto !important;
  }

  .sWSNaq_me-lg-0 {
    margin-right: 0 !important;
  }

  .sWSNaq_me-lg-1 {
    margin-right: .25rem !important;
  }

  .sWSNaq_me-lg-2 {
    margin-right: .5rem !important;
  }

  .sWSNaq_me-lg-3 {
    margin-right: 1rem !important;
  }

  .sWSNaq_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .sWSNaq_me-lg-5 {
    margin-right: 3rem !important;
  }

  .sWSNaq_me-lg-auto {
    margin-right: auto !important;
  }

  .sWSNaq_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .sWSNaq_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .sWSNaq_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .sWSNaq_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .sWSNaq_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .sWSNaq_mb-lg-auto {
    margin-bottom: auto !important;
  }

  .sWSNaq_ms-lg-0 {
    margin-left: 0 !important;
  }

  .sWSNaq_ms-lg-1 {
    margin-left: .25rem !important;
  }

  .sWSNaq_ms-lg-2 {
    margin-left: .5rem !important;
  }

  .sWSNaq_ms-lg-3 {
    margin-left: 1rem !important;
  }

  .sWSNaq_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .sWSNaq_ms-lg-5 {
    margin-left: 3rem !important;
  }

  .sWSNaq_ms-lg-auto {
    margin-left: auto !important;
  }

  .sWSNaq_p-lg-0 {
    padding: 0 !important;
  }

  .sWSNaq_p-lg-1 {
    padding: .25rem !important;
  }

  .sWSNaq_p-lg-2 {
    padding: .5rem !important;
  }

  .sWSNaq_p-lg-3 {
    padding: 1rem !important;
  }

  .sWSNaq_p-lg-4 {
    padding: 1.5rem !important;
  }

  .sWSNaq_p-lg-5 {
    padding: 3rem !important;
  }

  .sWSNaq_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sWSNaq_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sWSNaq_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sWSNaq_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sWSNaq_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sWSNaq_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sWSNaq_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sWSNaq_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sWSNaq_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sWSNaq_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sWSNaq_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sWSNaq_pt-lg-0 {
    padding-top: 0 !important;
  }

  .sWSNaq_pt-lg-1 {
    padding-top: .25rem !important;
  }

  .sWSNaq_pt-lg-2 {
    padding-top: .5rem !important;
  }

  .sWSNaq_pt-lg-3 {
    padding-top: 1rem !important;
  }

  .sWSNaq_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .sWSNaq_pt-lg-5 {
    padding-top: 3rem !important;
  }

  .sWSNaq_pe-lg-0 {
    padding-right: 0 !important;
  }

  .sWSNaq_pe-lg-1 {
    padding-right: .25rem !important;
  }

  .sWSNaq_pe-lg-2 {
    padding-right: .5rem !important;
  }

  .sWSNaq_pe-lg-3 {
    padding-right: 1rem !important;
  }

  .sWSNaq_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .sWSNaq_pe-lg-5 {
    padding-right: 3rem !important;
  }

  .sWSNaq_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .sWSNaq_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .sWSNaq_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .sWSNaq_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .sWSNaq_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .sWSNaq_ps-lg-0 {
    padding-left: 0 !important;
  }

  .sWSNaq_ps-lg-1 {
    padding-left: .25rem !important;
  }

  .sWSNaq_ps-lg-2 {
    padding-left: .5rem !important;
  }

  .sWSNaq_ps-lg-3 {
    padding-left: 1rem !important;
  }

  .sWSNaq_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .sWSNaq_ps-lg-5 {
    padding-left: 3rem !important;
  }

  .sWSNaq_gap-lg-0 {
    gap: 0 !important;
  }

  .sWSNaq_gap-lg-1 {
    gap: .25rem !important;
  }

  .sWSNaq_gap-lg-2 {
    gap: .5rem !important;
  }

  .sWSNaq_gap-lg-3 {
    gap: 1rem !important;
  }

  .sWSNaq_gap-lg-4 {
    gap: 1.5rem !important;
  }

  .sWSNaq_gap-lg-5 {
    gap: 3rem !important;
  }

  .sWSNaq_text-lg-start {
    text-align: left !important;
  }

  .sWSNaq_text-lg-end {
    text-align: right !important;
  }

  .sWSNaq_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_float-xl-start {
    float: left !important;
  }

  .sWSNaq_float-xl-end {
    float: right !important;
  }

  .sWSNaq_float-xl-none {
    float: none !important;
  }

  .sWSNaq_d-xl-inline {
    display: inline !important;
  }

  .sWSNaq_d-xl-inline-block {
    display: inline-block !important;
  }

  .sWSNaq_d-xl-block {
    display: block !important;
  }

  .sWSNaq_d-xl-grid {
    display: grid !important;
  }

  .sWSNaq_d-xl-table {
    display: table !important;
  }

  .sWSNaq_d-xl-table-row {
    display: table-row !important;
  }

  .sWSNaq_d-xl-table-cell {
    display: table-cell !important;
  }

  .sWSNaq_d-xl-flex {
    display: flex !important;
  }

  .sWSNaq_d-xl-inline-flex {
    display: inline-flex !important;
  }

  .sWSNaq_d-xl-none {
    display: none !important;
  }

  .sWSNaq_flex-xl-fill {
    flex: auto !important;
  }

  .sWSNaq_flex-xl-row {
    flex-direction: row !important;
  }

  .sWSNaq_flex-xl-column {
    flex-direction: column !important;
  }

  .sWSNaq_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sWSNaq_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sWSNaq_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .sWSNaq_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .sWSNaq_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sWSNaq_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .sWSNaq_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .sWSNaq_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .sWSNaq_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sWSNaq_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .sWSNaq_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .sWSNaq_justify-content-xl-center {
    justify-content: center !important;
  }

  .sWSNaq_justify-content-xl-between {
    justify-content: space-between !important;
  }

  .sWSNaq_justify-content-xl-around {
    justify-content: space-around !important;
  }

  .sWSNaq_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .sWSNaq_align-items-xl-start {
    align-items: flex-start !important;
  }

  .sWSNaq_align-items-xl-end {
    align-items: flex-end !important;
  }

  .sWSNaq_align-items-xl-center {
    align-items: center !important;
  }

  .sWSNaq_align-items-xl-baseline {
    align-items: baseline !important;
  }

  .sWSNaq_align-items-xl-stretch {
    align-items: stretch !important;
  }

  .sWSNaq_align-content-xl-start {
    align-content: flex-start !important;
  }

  .sWSNaq_align-content-xl-end {
    align-content: flex-end !important;
  }

  .sWSNaq_align-content-xl-center {
    align-content: center !important;
  }

  .sWSNaq_align-content-xl-between {
    align-content: space-between !important;
  }

  .sWSNaq_align-content-xl-around {
    align-content: space-around !important;
  }

  .sWSNaq_align-content-xl-stretch {
    align-content: stretch !important;
  }

  .sWSNaq_align-self-xl-auto {
    align-self: auto !important;
  }

  .sWSNaq_align-self-xl-start {
    align-self: flex-start !important;
  }

  .sWSNaq_align-self-xl-end {
    align-self: flex-end !important;
  }

  .sWSNaq_align-self-xl-center {
    align-self: center !important;
  }

  .sWSNaq_align-self-xl-baseline {
    align-self: baseline !important;
  }

  .sWSNaq_align-self-xl-stretch {
    align-self: stretch !important;
  }

  .sWSNaq_order-xl-first {
    order: -1 !important;
  }

  .sWSNaq_order-xl-0 {
    order: 0 !important;
  }

  .sWSNaq_order-xl-1 {
    order: 1 !important;
  }

  .sWSNaq_order-xl-2 {
    order: 2 !important;
  }

  .sWSNaq_order-xl-3 {
    order: 3 !important;
  }

  .sWSNaq_order-xl-4 {
    order: 4 !important;
  }

  .sWSNaq_order-xl-5 {
    order: 5 !important;
  }

  .sWSNaq_order-xl-last {
    order: 6 !important;
  }

  .sWSNaq_m-xl-0 {
    margin: 0 !important;
  }

  .sWSNaq_m-xl-1 {
    margin: .25rem !important;
  }

  .sWSNaq_m-xl-2 {
    margin: .5rem !important;
  }

  .sWSNaq_m-xl-3 {
    margin: 1rem !important;
  }

  .sWSNaq_m-xl-4 {
    margin: 1.5rem !important;
  }

  .sWSNaq_m-xl-5 {
    margin: 3rem !important;
  }

  .sWSNaq_m-xl-auto {
    margin: auto !important;
  }

  .sWSNaq_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sWSNaq_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sWSNaq_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sWSNaq_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sWSNaq_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sWSNaq_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sWSNaq_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sWSNaq_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sWSNaq_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sWSNaq_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sWSNaq_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sWSNaq_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sWSNaq_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sWSNaq_mt-xl-0 {
    margin-top: 0 !important;
  }

  .sWSNaq_mt-xl-1 {
    margin-top: .25rem !important;
  }

  .sWSNaq_mt-xl-2 {
    margin-top: .5rem !important;
  }

  .sWSNaq_mt-xl-3 {
    margin-top: 1rem !important;
  }

  .sWSNaq_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .sWSNaq_mt-xl-5 {
    margin-top: 3rem !important;
  }

  .sWSNaq_mt-xl-auto {
    margin-top: auto !important;
  }

  .sWSNaq_me-xl-0 {
    margin-right: 0 !important;
  }

  .sWSNaq_me-xl-1 {
    margin-right: .25rem !important;
  }

  .sWSNaq_me-xl-2 {
    margin-right: .5rem !important;
  }

  .sWSNaq_me-xl-3 {
    margin-right: 1rem !important;
  }

  .sWSNaq_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .sWSNaq_me-xl-5 {
    margin-right: 3rem !important;
  }

  .sWSNaq_me-xl-auto {
    margin-right: auto !important;
  }

  .sWSNaq_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .sWSNaq_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .sWSNaq_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .sWSNaq_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .sWSNaq_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .sWSNaq_mb-xl-auto {
    margin-bottom: auto !important;
  }

  .sWSNaq_ms-xl-0 {
    margin-left: 0 !important;
  }

  .sWSNaq_ms-xl-1 {
    margin-left: .25rem !important;
  }

  .sWSNaq_ms-xl-2 {
    margin-left: .5rem !important;
  }

  .sWSNaq_ms-xl-3 {
    margin-left: 1rem !important;
  }

  .sWSNaq_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .sWSNaq_ms-xl-5 {
    margin-left: 3rem !important;
  }

  .sWSNaq_ms-xl-auto {
    margin-left: auto !important;
  }

  .sWSNaq_p-xl-0 {
    padding: 0 !important;
  }

  .sWSNaq_p-xl-1 {
    padding: .25rem !important;
  }

  .sWSNaq_p-xl-2 {
    padding: .5rem !important;
  }

  .sWSNaq_p-xl-3 {
    padding: 1rem !important;
  }

  .sWSNaq_p-xl-4 {
    padding: 1.5rem !important;
  }

  .sWSNaq_p-xl-5 {
    padding: 3rem !important;
  }

  .sWSNaq_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sWSNaq_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sWSNaq_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sWSNaq_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sWSNaq_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sWSNaq_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sWSNaq_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sWSNaq_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sWSNaq_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sWSNaq_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sWSNaq_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sWSNaq_pt-xl-0 {
    padding-top: 0 !important;
  }

  .sWSNaq_pt-xl-1 {
    padding-top: .25rem !important;
  }

  .sWSNaq_pt-xl-2 {
    padding-top: .5rem !important;
  }

  .sWSNaq_pt-xl-3 {
    padding-top: 1rem !important;
  }

  .sWSNaq_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .sWSNaq_pt-xl-5 {
    padding-top: 3rem !important;
  }

  .sWSNaq_pe-xl-0 {
    padding-right: 0 !important;
  }

  .sWSNaq_pe-xl-1 {
    padding-right: .25rem !important;
  }

  .sWSNaq_pe-xl-2 {
    padding-right: .5rem !important;
  }

  .sWSNaq_pe-xl-3 {
    padding-right: 1rem !important;
  }

  .sWSNaq_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .sWSNaq_pe-xl-5 {
    padding-right: 3rem !important;
  }

  .sWSNaq_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .sWSNaq_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .sWSNaq_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .sWSNaq_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .sWSNaq_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .sWSNaq_ps-xl-0 {
    padding-left: 0 !important;
  }

  .sWSNaq_ps-xl-1 {
    padding-left: .25rem !important;
  }

  .sWSNaq_ps-xl-2 {
    padding-left: .5rem !important;
  }

  .sWSNaq_ps-xl-3 {
    padding-left: 1rem !important;
  }

  .sWSNaq_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .sWSNaq_ps-xl-5 {
    padding-left: 3rem !important;
  }

  .sWSNaq_gap-xl-0 {
    gap: 0 !important;
  }

  .sWSNaq_gap-xl-1 {
    gap: .25rem !important;
  }

  .sWSNaq_gap-xl-2 {
    gap: .5rem !important;
  }

  .sWSNaq_gap-xl-3 {
    gap: 1rem !important;
  }

  .sWSNaq_gap-xl-4 {
    gap: 1.5rem !important;
  }

  .sWSNaq_gap-xl-5 {
    gap: 3rem !important;
  }

  .sWSNaq_text-xl-start {
    text-align: left !important;
  }

  .sWSNaq_text-xl-end {
    text-align: right !important;
  }

  .sWSNaq_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .sWSNaq_float-xxl-start {
    float: left !important;
  }

  .sWSNaq_float-xxl-end {
    float: right !important;
  }

  .sWSNaq_float-xxl-none {
    float: none !important;
  }

  .sWSNaq_d-xxl-inline {
    display: inline !important;
  }

  .sWSNaq_d-xxl-inline-block {
    display: inline-block !important;
  }

  .sWSNaq_d-xxl-block {
    display: block !important;
  }

  .sWSNaq_d-xxl-grid {
    display: grid !important;
  }

  .sWSNaq_d-xxl-table {
    display: table !important;
  }

  .sWSNaq_d-xxl-table-row {
    display: table-row !important;
  }

  .sWSNaq_d-xxl-table-cell {
    display: table-cell !important;
  }

  .sWSNaq_d-xxl-flex {
    display: flex !important;
  }

  .sWSNaq_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .sWSNaq_d-xxl-none {
    display: none !important;
  }

  .sWSNaq_flex-xxl-fill {
    flex: auto !important;
  }

  .sWSNaq_flex-xxl-row {
    flex-direction: row !important;
  }

  .sWSNaq_flex-xxl-column {
    flex-direction: column !important;
  }

  .sWSNaq_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sWSNaq_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sWSNaq_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .sWSNaq_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .sWSNaq_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sWSNaq_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .sWSNaq_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .sWSNaq_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .sWSNaq_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sWSNaq_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .sWSNaq_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .sWSNaq_justify-content-xxl-center {
    justify-content: center !important;
  }

  .sWSNaq_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .sWSNaq_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .sWSNaq_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .sWSNaq_align-items-xxl-start {
    align-items: flex-start !important;
  }

  .sWSNaq_align-items-xxl-end {
    align-items: flex-end !important;
  }

  .sWSNaq_align-items-xxl-center {
    align-items: center !important;
  }

  .sWSNaq_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .sWSNaq_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .sWSNaq_align-content-xxl-start {
    align-content: flex-start !important;
  }

  .sWSNaq_align-content-xxl-end {
    align-content: flex-end !important;
  }

  .sWSNaq_align-content-xxl-center {
    align-content: center !important;
  }

  .sWSNaq_align-content-xxl-between {
    align-content: space-between !important;
  }

  .sWSNaq_align-content-xxl-around {
    align-content: space-around !important;
  }

  .sWSNaq_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .sWSNaq_align-self-xxl-auto {
    align-self: auto !important;
  }

  .sWSNaq_align-self-xxl-start {
    align-self: flex-start !important;
  }

  .sWSNaq_align-self-xxl-end {
    align-self: flex-end !important;
  }

  .sWSNaq_align-self-xxl-center {
    align-self: center !important;
  }

  .sWSNaq_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .sWSNaq_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .sWSNaq_order-xxl-first {
    order: -1 !important;
  }

  .sWSNaq_order-xxl-0 {
    order: 0 !important;
  }

  .sWSNaq_order-xxl-1 {
    order: 1 !important;
  }

  .sWSNaq_order-xxl-2 {
    order: 2 !important;
  }

  .sWSNaq_order-xxl-3 {
    order: 3 !important;
  }

  .sWSNaq_order-xxl-4 {
    order: 4 !important;
  }

  .sWSNaq_order-xxl-5 {
    order: 5 !important;
  }

  .sWSNaq_order-xxl-last {
    order: 6 !important;
  }

  .sWSNaq_m-xxl-0 {
    margin: 0 !important;
  }

  .sWSNaq_m-xxl-1 {
    margin: .25rem !important;
  }

  .sWSNaq_m-xxl-2 {
    margin: .5rem !important;
  }

  .sWSNaq_m-xxl-3 {
    margin: 1rem !important;
  }

  .sWSNaq_m-xxl-4 {
    margin: 1.5rem !important;
  }

  .sWSNaq_m-xxl-5 {
    margin: 3rem !important;
  }

  .sWSNaq_m-xxl-auto {
    margin: auto !important;
  }

  .sWSNaq_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sWSNaq_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sWSNaq_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sWSNaq_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sWSNaq_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sWSNaq_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sWSNaq_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sWSNaq_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sWSNaq_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sWSNaq_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sWSNaq_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sWSNaq_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sWSNaq_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sWSNaq_mt-xxl-0 {
    margin-top: 0 !important;
  }

  .sWSNaq_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .sWSNaq_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .sWSNaq_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .sWSNaq_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .sWSNaq_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .sWSNaq_mt-xxl-auto {
    margin-top: auto !important;
  }

  .sWSNaq_me-xxl-0 {
    margin-right: 0 !important;
  }

  .sWSNaq_me-xxl-1 {
    margin-right: .25rem !important;
  }

  .sWSNaq_me-xxl-2 {
    margin-right: .5rem !important;
  }

  .sWSNaq_me-xxl-3 {
    margin-right: 1rem !important;
  }

  .sWSNaq_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .sWSNaq_me-xxl-5 {
    margin-right: 3rem !important;
  }

  .sWSNaq_me-xxl-auto {
    margin-right: auto !important;
  }

  .sWSNaq_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .sWSNaq_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .sWSNaq_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .sWSNaq_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .sWSNaq_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .sWSNaq_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .sWSNaq_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .sWSNaq_ms-xxl-0 {
    margin-left: 0 !important;
  }

  .sWSNaq_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .sWSNaq_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .sWSNaq_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .sWSNaq_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .sWSNaq_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .sWSNaq_ms-xxl-auto {
    margin-left: auto !important;
  }

  .sWSNaq_p-xxl-0 {
    padding: 0 !important;
  }

  .sWSNaq_p-xxl-1 {
    padding: .25rem !important;
  }

  .sWSNaq_p-xxl-2 {
    padding: .5rem !important;
  }

  .sWSNaq_p-xxl-3 {
    padding: 1rem !important;
  }

  .sWSNaq_p-xxl-4 {
    padding: 1.5rem !important;
  }

  .sWSNaq_p-xxl-5 {
    padding: 3rem !important;
  }

  .sWSNaq_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sWSNaq_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sWSNaq_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sWSNaq_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sWSNaq_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sWSNaq_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sWSNaq_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sWSNaq_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sWSNaq_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sWSNaq_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sWSNaq_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sWSNaq_pt-xxl-0 {
    padding-top: 0 !important;
  }

  .sWSNaq_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .sWSNaq_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .sWSNaq_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .sWSNaq_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .sWSNaq_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .sWSNaq_pe-xxl-0 {
    padding-right: 0 !important;
  }

  .sWSNaq_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .sWSNaq_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .sWSNaq_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .sWSNaq_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .sWSNaq_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .sWSNaq_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .sWSNaq_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .sWSNaq_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .sWSNaq_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .sWSNaq_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .sWSNaq_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .sWSNaq_ps-xxl-0 {
    padding-left: 0 !important;
  }

  .sWSNaq_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .sWSNaq_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .sWSNaq_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .sWSNaq_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .sWSNaq_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .sWSNaq_gap-xxl-0 {
    gap: 0 !important;
  }

  .sWSNaq_gap-xxl-1 {
    gap: .25rem !important;
  }

  .sWSNaq_gap-xxl-2 {
    gap: .5rem !important;
  }

  .sWSNaq_gap-xxl-3 {
    gap: 1rem !important;
  }

  .sWSNaq_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .sWSNaq_gap-xxl-5 {
    gap: 3rem !important;
  }

  .sWSNaq_text-xxl-start {
    text-align: left !important;
  }

  .sWSNaq_text-xxl-end {
    text-align: right !important;
  }

  .sWSNaq_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .sWSNaq_fs-1 {
    font-size: 2.5rem !important;
  }

  .sWSNaq_fs-2 {
    font-size: 2rem !important;
  }

  .sWSNaq_fs-3 {
    font-size: 1.75rem !important;
  }

  .sWSNaq_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .sWSNaq_d-print-inline {
    display: inline !important;
  }

  .sWSNaq_d-print-inline-block {
    display: inline-block !important;
  }

  .sWSNaq_d-print-block {
    display: block !important;
  }

  .sWSNaq_d-print-grid {
    display: grid !important;
  }

  .sWSNaq_d-print-table {
    display: table !important;
  }

  .sWSNaq_d-print-table-row {
    display: table-row !important;
  }

  .sWSNaq_d-print-table-cell {
    display: table-cell !important;
  }

  .sWSNaq_d-print-flex {
    display: flex !important;
  }

  .sWSNaq_d-print-inline-flex {
    display: inline-flex !important;
  }

  .sWSNaq_d-print-none {
    display: none !important;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.sWSNaq_header {
  align-items: center;
  display: flex;
  position: relative;
  box-shadow: 0 1px 4px #00000040;
}

.sWSNaq_header.sWSNaq_transparent {
  width: 100%;
  color: #fff;
  box-shadow: none;
  z-index: 10;
  background-color: #0000;
  position: absolute;
  top: 0;
  left: 0;
}

.sWSNaq_header .sWSNaq_logo {
  height: 30px;
}

.sWSNaq_header .sWSNaq_menu {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sWSNaq_header .sWSNaq_menu a {
  all: unset;
  cursor: pointer;
  margin: 0 5px;
}

.sWSNaq_header .sWSNaq_menu a.sWSNaq_active {
  border-bottom: 2px solid #258e01;
}

.sWSNaq_header .sWSNaq_extra {
  margin: 0 30px;
  display: flex;
}

.sWSNaq_header .sWSNaq_extra div {
  margin: 0 10px;
}

.sWSNaq_header .sWSNaq_toggle {
  margin-left: auto;
}

.sWSNaq_header .sWSNaq_sidemenu {
  height: 100vh;
  color: #000;
  width: 70vw;
  z-index: 100;
  background-color: #ffffffe6;
  border-right: 1px solid gray;
  flex-direction: column;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.sWSNaq_header .sWSNaq_sidemenu a {
  all: unset;
  cursor: pointer;
  border-bottom: 1px solid gray;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.sWSNaq_header .sWSNaq_sidemenu.sWSNaq_show {
  transition: all .3s ease-in;
  left: 0;
}

.sWSNaq_header .sWSNaq_sidemenu.sWSNaq_hide {
  transition: all .3s ease-out;
  left: -70vw;
}

.C5izvq_mainButton {
  color: #fff;
  min-width: 120px;
  padding-left: 30px;
  padding-right: 30px;
}

.o55QHq_card {
  background: #f2f2f2;
  border-radius: 20px;
  margin: 0 20px;
  padding: 30px 50px;
}

.o55QHq_card h3 {
  letter-spacing: .25px;
  color: #25282b;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.o55QHq_card p {
  letter-spacing: .25px;
  color: #25282b;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.o55QHq_card strong {
  letter-spacing: .25px;
  color: #25282b;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.o55QHq_card hr {
  border: .625px solid #7c7c7c;
}

.o55QHq_box {
  all: unset;
  height: 20px;
  width: 20px;
  border: 1px solid #c4c4c4;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.o55QHq_cart {
  position: relative;
}

.szeylG_wrapper {
  height: 100vh;
  position: relative;
}

.szeylG_wrapper .szeylG_content {
  text-align: center;
  color: #fff;
  width: 100vw;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #fff3;
  padding: 81px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.szeylG_wrapper .szeylG_content p {
  max-width: 540px;
  margin: 30px auto;
}

.szeylG_wrapper .szeylG_controls {
  color: #fff;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.szeylG_wrapper .szeylG_controls div {
  padding: 10px;
}

.szeylG_wrapper .szeylG_controls .szeylG_left {
  background-color: #f0ca00;
}

.szeylG_wrapper .szeylG_controls .szeylG_right {
  background-color: #37c4cd;
}

.yTCGQW_card {
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 54px 30px;
}

.yTCGQW_category a {
  all: unset;
  cursor: pointer;
  margin: 0;
}

.kc_1sa_wrapper {
  text-align: center;
}

.kc_1sa_banner {
  height: 450px;
  aspect-ratio: .420977;
  color: #fff;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.kc_1sa_items {
  grid-gap: 20px;
  flex: 1;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 20px;
  display: grid;
}

.y-ZCra_wrapper {
  text-align: center;
  background-color: #fff;
  border: 1px solid #00000080;
  border-radius: 10px;
  box-shadow: 0 0 5px #000c;
}

.y-ZCra_image {
  aspect-ratio: 1 / 1;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  display: flex;
}

.y-ZCra_name {
  text-align: center;
  letter-spacing: .25px;
  text-transform: capitalize;
  color: #222;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.y-ZCra_price {
  text-align: center;
  color: #222;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.R0sThW_wrapper {
  height: 450px;
  color: #fff;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.R0sThW_overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000c;
  position: absolute;
  top: 0;
  left: 0;
}

.R0sThW_container {
  z-index: 2;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #258e01;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 37, 142, 1;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #258e01;
  --bs-link-hover-color: #1e7201;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, ._1kIl6q_h6, h5, ._1kIl6q_h5, h4, ._1kIl6q_h4, h3, ._1kIl6q_h3, h2, ._1kIl6q_h2, h1, ._1kIl6q_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, ._1kIl6q_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, ._1kIl6q_h1 {
    font-size: 2.5rem;
  }
}

h2, ._1kIl6q_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, ._1kIl6q_h2 {
    font-size: 2rem;
  }
}

h3, ._1kIl6q_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, ._1kIl6q_h3 {
    font-size: 1.75rem;
  }
}

h4, ._1kIl6q_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, ._1kIl6q_h4 {
    font-size: 1.5rem;
  }
}

h5, ._1kIl6q_h5 {
  font-size: 1.25rem;
}

h6, ._1kIl6q_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, ._1kIl6q_small {
  font-size: .875em;
}

mark, ._1kIl6q_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

._1kIl6q_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

._1kIl6q_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._1kIl6q_display-1 {
    font-size: 5rem;
  }
}

._1kIl6q_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._1kIl6q_display-2 {
    font-size: 4.5rem;
  }
}

._1kIl6q_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._1kIl6q_display-3 {
    font-size: 4rem;
  }
}

._1kIl6q_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._1kIl6q_display-4 {
    font-size: 3.5rem;
  }
}

._1kIl6q_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._1kIl6q_display-5 {
    font-size: 3rem;
  }
}

._1kIl6q_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._1kIl6q_display-6 {
    font-size: 2.5rem;
  }
}

._1kIl6q_list-unstyled, ._1kIl6q_list-inline {
  padding-left: 0;
  list-style: none;
}

._1kIl6q_list-inline-item {
  display: inline-block;
}

._1kIl6q_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

._1kIl6q_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

._1kIl6q_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

._1kIl6q_blockquote > :last-child {
  margin-bottom: 0;
}

._1kIl6q_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

._1kIl6q_blockquote-footer:before {
  content: "— ";
}

._1kIl6q_img-fluid {
  max-width: 100%;
  height: auto;
}

._1kIl6q_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

._1kIl6q_figure {
  display: inline-block;
}

._1kIl6q_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

._1kIl6q_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

._1kIl6q_container, ._1kIl6q_container-fluid, ._1kIl6q_container-xxl, ._1kIl6q_container-xl, ._1kIl6q_container-lg, ._1kIl6q_container-md, ._1kIl6q_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  ._1kIl6q_container-sm, ._1kIl6q_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_container-md, ._1kIl6q_container-sm, ._1kIl6q_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_container-lg, ._1kIl6q_container-md, ._1kIl6q_container-sm, ._1kIl6q_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_container-xl, ._1kIl6q_container-lg, ._1kIl6q_container-md, ._1kIl6q_container-sm, ._1kIl6q_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_container-xxl, ._1kIl6q_container-xl, ._1kIl6q_container-lg, ._1kIl6q_container-md, ._1kIl6q_container-sm, ._1kIl6q_container {
    max-width: 1320px;
  }
}

._1kIl6q_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

._1kIl6q_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

._1kIl6q_col {
  flex: 1 0;
}

._1kIl6q_row-cols-auto > * {
  width: auto;
  flex: none;
}

._1kIl6q_row-cols-1 > * {
  width: 100%;
  flex: none;
}

._1kIl6q_row-cols-2 > * {
  width: 50%;
  flex: none;
}

._1kIl6q_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

._1kIl6q_row-cols-4 > * {
  width: 25%;
  flex: none;
}

._1kIl6q_row-cols-5 > * {
  width: 20%;
  flex: none;
}

._1kIl6q_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

._1kIl6q_col-auto {
  width: auto;
  flex: none;
}

._1kIl6q_col-1 {
  width: 8.33333%;
  flex: none;
}

._1kIl6q_col-2 {
  width: 16.6667%;
  flex: none;
}

._1kIl6q_col-3 {
  width: 25%;
  flex: none;
}

._1kIl6q_col-4 {
  width: 33.3333%;
  flex: none;
}

._1kIl6q_col-5 {
  width: 41.6667%;
  flex: none;
}

._1kIl6q_col-6 {
  width: 50%;
  flex: none;
}

._1kIl6q_col-7 {
  width: 58.3333%;
  flex: none;
}

._1kIl6q_col-8 {
  width: 66.6667%;
  flex: none;
}

._1kIl6q_col-9 {
  width: 75%;
  flex: none;
}

._1kIl6q_col-10 {
  width: 83.3333%;
  flex: none;
}

._1kIl6q_col-11 {
  width: 91.6667%;
  flex: none;
}

._1kIl6q_col-12 {
  width: 100%;
  flex: none;
}

._1kIl6q_offset-1 {
  margin-left: 8.33333%;
}

._1kIl6q_offset-2 {
  margin-left: 16.6667%;
}

._1kIl6q_offset-3 {
  margin-left: 25%;
}

._1kIl6q_offset-4 {
  margin-left: 33.3333%;
}

._1kIl6q_offset-5 {
  margin-left: 41.6667%;
}

._1kIl6q_offset-6 {
  margin-left: 50%;
}

._1kIl6q_offset-7 {
  margin-left: 58.3333%;
}

._1kIl6q_offset-8 {
  margin-left: 66.6667%;
}

._1kIl6q_offset-9 {
  margin-left: 75%;
}

._1kIl6q_offset-10 {
  margin-left: 83.3333%;
}

._1kIl6q_offset-11 {
  margin-left: 91.6667%;
}

._1kIl6q_g-0, ._1kIl6q_gx-0 {
  --bs-gutter-x: 0;
}

._1kIl6q_g-0, ._1kIl6q_gy-0 {
  --bs-gutter-y: 0;
}

._1kIl6q_g-1, ._1kIl6q_gx-1 {
  --bs-gutter-x: .25rem;
}

._1kIl6q_g-1, ._1kIl6q_gy-1 {
  --bs-gutter-y: .25rem;
}

._1kIl6q_g-2, ._1kIl6q_gx-2 {
  --bs-gutter-x: .5rem;
}

._1kIl6q_g-2, ._1kIl6q_gy-2 {
  --bs-gutter-y: .5rem;
}

._1kIl6q_g-3, ._1kIl6q_gx-3 {
  --bs-gutter-x: 1rem;
}

._1kIl6q_g-3, ._1kIl6q_gy-3 {
  --bs-gutter-y: 1rem;
}

._1kIl6q_g-4, ._1kIl6q_gx-4 {
  --bs-gutter-x: 1.5rem;
}

._1kIl6q_g-4, ._1kIl6q_gy-4 {
  --bs-gutter-y: 1.5rem;
}

._1kIl6q_g-5, ._1kIl6q_gx-5 {
  --bs-gutter-x: 3rem;
}

._1kIl6q_g-5, ._1kIl6q_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  ._1kIl6q_col-sm {
    flex: 1 0;
  }

  ._1kIl6q_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  ._1kIl6q_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  ._1kIl6q_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-sm-auto {
    width: auto;
    flex: none;
  }

  ._1kIl6q_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  ._1kIl6q_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-sm-3 {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  ._1kIl6q_col-sm-6 {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  ._1kIl6q_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  ._1kIl6q_col-sm-9 {
    width: 75%;
    flex: none;
  }

  ._1kIl6q_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  ._1kIl6q_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  ._1kIl6q_col-sm-12 {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_offset-sm-0 {
    margin-left: 0;
  }

  ._1kIl6q_offset-sm-1 {
    margin-left: 8.33333%;
  }

  ._1kIl6q_offset-sm-2 {
    margin-left: 16.6667%;
  }

  ._1kIl6q_offset-sm-3 {
    margin-left: 25%;
  }

  ._1kIl6q_offset-sm-4 {
    margin-left: 33.3333%;
  }

  ._1kIl6q_offset-sm-5 {
    margin-left: 41.6667%;
  }

  ._1kIl6q_offset-sm-6 {
    margin-left: 50%;
  }

  ._1kIl6q_offset-sm-7 {
    margin-left: 58.3333%;
  }

  ._1kIl6q_offset-sm-8 {
    margin-left: 66.6667%;
  }

  ._1kIl6q_offset-sm-9 {
    margin-left: 75%;
  }

  ._1kIl6q_offset-sm-10 {
    margin-left: 83.3333%;
  }

  ._1kIl6q_offset-sm-11 {
    margin-left: 91.6667%;
  }

  ._1kIl6q_g-sm-0, ._1kIl6q_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  ._1kIl6q_g-sm-0, ._1kIl6q_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  ._1kIl6q_g-sm-1, ._1kIl6q_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  ._1kIl6q_g-sm-1, ._1kIl6q_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  ._1kIl6q_g-sm-2, ._1kIl6q_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  ._1kIl6q_g-sm-2, ._1kIl6q_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  ._1kIl6q_g-sm-3, ._1kIl6q_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  ._1kIl6q_g-sm-3, ._1kIl6q_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  ._1kIl6q_g-sm-4, ._1kIl6q_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._1kIl6q_g-sm-4, ._1kIl6q_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._1kIl6q_g-sm-5, ._1kIl6q_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  ._1kIl6q_g-sm-5, ._1kIl6q_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_col-md {
    flex: 1 0;
  }

  ._1kIl6q_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  ._1kIl6q_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  ._1kIl6q_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-md-auto {
    width: auto;
    flex: none;
  }

  ._1kIl6q_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  ._1kIl6q_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-md-3 {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  ._1kIl6q_col-md-6 {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  ._1kIl6q_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  ._1kIl6q_col-md-9 {
    width: 75%;
    flex: none;
  }

  ._1kIl6q_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  ._1kIl6q_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  ._1kIl6q_col-md-12 {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_offset-md-0 {
    margin-left: 0;
  }

  ._1kIl6q_offset-md-1 {
    margin-left: 8.33333%;
  }

  ._1kIl6q_offset-md-2 {
    margin-left: 16.6667%;
  }

  ._1kIl6q_offset-md-3 {
    margin-left: 25%;
  }

  ._1kIl6q_offset-md-4 {
    margin-left: 33.3333%;
  }

  ._1kIl6q_offset-md-5 {
    margin-left: 41.6667%;
  }

  ._1kIl6q_offset-md-6 {
    margin-left: 50%;
  }

  ._1kIl6q_offset-md-7 {
    margin-left: 58.3333%;
  }

  ._1kIl6q_offset-md-8 {
    margin-left: 66.6667%;
  }

  ._1kIl6q_offset-md-9 {
    margin-left: 75%;
  }

  ._1kIl6q_offset-md-10 {
    margin-left: 83.3333%;
  }

  ._1kIl6q_offset-md-11 {
    margin-left: 91.6667%;
  }

  ._1kIl6q_g-md-0, ._1kIl6q_gx-md-0 {
    --bs-gutter-x: 0;
  }

  ._1kIl6q_g-md-0, ._1kIl6q_gy-md-0 {
    --bs-gutter-y: 0;
  }

  ._1kIl6q_g-md-1, ._1kIl6q_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  ._1kIl6q_g-md-1, ._1kIl6q_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  ._1kIl6q_g-md-2, ._1kIl6q_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  ._1kIl6q_g-md-2, ._1kIl6q_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  ._1kIl6q_g-md-3, ._1kIl6q_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  ._1kIl6q_g-md-3, ._1kIl6q_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  ._1kIl6q_g-md-4, ._1kIl6q_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._1kIl6q_g-md-4, ._1kIl6q_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._1kIl6q_g-md-5, ._1kIl6q_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  ._1kIl6q_g-md-5, ._1kIl6q_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_col-lg {
    flex: 1 0;
  }

  ._1kIl6q_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  ._1kIl6q_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  ._1kIl6q_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-lg-auto {
    width: auto;
    flex: none;
  }

  ._1kIl6q_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  ._1kIl6q_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-lg-3 {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  ._1kIl6q_col-lg-6 {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  ._1kIl6q_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  ._1kIl6q_col-lg-9 {
    width: 75%;
    flex: none;
  }

  ._1kIl6q_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  ._1kIl6q_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  ._1kIl6q_col-lg-12 {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_offset-lg-0 {
    margin-left: 0;
  }

  ._1kIl6q_offset-lg-1 {
    margin-left: 8.33333%;
  }

  ._1kIl6q_offset-lg-2 {
    margin-left: 16.6667%;
  }

  ._1kIl6q_offset-lg-3 {
    margin-left: 25%;
  }

  ._1kIl6q_offset-lg-4 {
    margin-left: 33.3333%;
  }

  ._1kIl6q_offset-lg-5 {
    margin-left: 41.6667%;
  }

  ._1kIl6q_offset-lg-6 {
    margin-left: 50%;
  }

  ._1kIl6q_offset-lg-7 {
    margin-left: 58.3333%;
  }

  ._1kIl6q_offset-lg-8 {
    margin-left: 66.6667%;
  }

  ._1kIl6q_offset-lg-9 {
    margin-left: 75%;
  }

  ._1kIl6q_offset-lg-10 {
    margin-left: 83.3333%;
  }

  ._1kIl6q_offset-lg-11 {
    margin-left: 91.6667%;
  }

  ._1kIl6q_g-lg-0, ._1kIl6q_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  ._1kIl6q_g-lg-0, ._1kIl6q_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  ._1kIl6q_g-lg-1, ._1kIl6q_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  ._1kIl6q_g-lg-1, ._1kIl6q_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  ._1kIl6q_g-lg-2, ._1kIl6q_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  ._1kIl6q_g-lg-2, ._1kIl6q_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  ._1kIl6q_g-lg-3, ._1kIl6q_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  ._1kIl6q_g-lg-3, ._1kIl6q_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  ._1kIl6q_g-lg-4, ._1kIl6q_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._1kIl6q_g-lg-4, ._1kIl6q_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._1kIl6q_g-lg-5, ._1kIl6q_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  ._1kIl6q_g-lg-5, ._1kIl6q_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_col-xl {
    flex: 1 0;
  }

  ._1kIl6q_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  ._1kIl6q_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  ._1kIl6q_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-xl-auto {
    width: auto;
    flex: none;
  }

  ._1kIl6q_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._1kIl6q_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-xl-3 {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._1kIl6q_col-xl-6 {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._1kIl6q_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._1kIl6q_col-xl-9 {
    width: 75%;
    flex: none;
  }

  ._1kIl6q_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._1kIl6q_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._1kIl6q_col-xl-12 {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_offset-xl-0 {
    margin-left: 0;
  }

  ._1kIl6q_offset-xl-1 {
    margin-left: 8.33333%;
  }

  ._1kIl6q_offset-xl-2 {
    margin-left: 16.6667%;
  }

  ._1kIl6q_offset-xl-3 {
    margin-left: 25%;
  }

  ._1kIl6q_offset-xl-4 {
    margin-left: 33.3333%;
  }

  ._1kIl6q_offset-xl-5 {
    margin-left: 41.6667%;
  }

  ._1kIl6q_offset-xl-6 {
    margin-left: 50%;
  }

  ._1kIl6q_offset-xl-7 {
    margin-left: 58.3333%;
  }

  ._1kIl6q_offset-xl-8 {
    margin-left: 66.6667%;
  }

  ._1kIl6q_offset-xl-9 {
    margin-left: 75%;
  }

  ._1kIl6q_offset-xl-10 {
    margin-left: 83.3333%;
  }

  ._1kIl6q_offset-xl-11 {
    margin-left: 91.6667%;
  }

  ._1kIl6q_g-xl-0, ._1kIl6q_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  ._1kIl6q_g-xl-0, ._1kIl6q_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  ._1kIl6q_g-xl-1, ._1kIl6q_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  ._1kIl6q_g-xl-1, ._1kIl6q_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  ._1kIl6q_g-xl-2, ._1kIl6q_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  ._1kIl6q_g-xl-2, ._1kIl6q_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  ._1kIl6q_g-xl-3, ._1kIl6q_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  ._1kIl6q_g-xl-3, ._1kIl6q_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  ._1kIl6q_g-xl-4, ._1kIl6q_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._1kIl6q_g-xl-4, ._1kIl6q_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._1kIl6q_g-xl-5, ._1kIl6q_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  ._1kIl6q_g-xl-5, ._1kIl6q_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_col-xxl {
    flex: 1 0;
  }

  ._1kIl6q_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  ._1kIl6q_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  ._1kIl6q_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-xxl-auto {
    width: auto;
    flex: none;
  }

  ._1kIl6q_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._1kIl6q_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._1kIl6q_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  ._1kIl6q_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._1kIl6q_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._1kIl6q_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  ._1kIl6q_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._1kIl6q_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._1kIl6q_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  ._1kIl6q_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._1kIl6q_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._1kIl6q_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  ._1kIl6q_offset-xxl-0 {
    margin-left: 0;
  }

  ._1kIl6q_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  ._1kIl6q_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  ._1kIl6q_offset-xxl-3 {
    margin-left: 25%;
  }

  ._1kIl6q_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  ._1kIl6q_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  ._1kIl6q_offset-xxl-6 {
    margin-left: 50%;
  }

  ._1kIl6q_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  ._1kIl6q_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  ._1kIl6q_offset-xxl-9 {
    margin-left: 75%;
  }

  ._1kIl6q_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  ._1kIl6q_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  ._1kIl6q_g-xxl-0, ._1kIl6q_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  ._1kIl6q_g-xxl-0, ._1kIl6q_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  ._1kIl6q_g-xxl-1, ._1kIl6q_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  ._1kIl6q_g-xxl-1, ._1kIl6q_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  ._1kIl6q_g-xxl-2, ._1kIl6q_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  ._1kIl6q_g-xxl-2, ._1kIl6q_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  ._1kIl6q_g-xxl-3, ._1kIl6q_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  ._1kIl6q_g-xxl-3, ._1kIl6q_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  ._1kIl6q_g-xxl-4, ._1kIl6q_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._1kIl6q_g-xxl-4, ._1kIl6q_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._1kIl6q_g-xxl-5, ._1kIl6q_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  ._1kIl6q_g-xxl-5, ._1kIl6q_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

._1kIl6q_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

._1kIl6q_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

._1kIl6q_table > tbody {
  vertical-align: inherit;
}

._1kIl6q_table > thead {
  vertical-align: bottom;
}

._1kIl6q_table-group-divider {
  border-top: 2px solid;
}

._1kIl6q_caption-top {
  caption-side: top;
}

._1kIl6q_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

._1kIl6q_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

._1kIl6q_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

._1kIl6q_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

._1kIl6q_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

._1kIl6q_table-striped > tbody > tr:nth-of-type(2n+1) > *, ._1kIl6q_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

._1kIl6q_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

._1kIl6q_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

._1kIl6q_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d3e8cc;
  --bs-table-border-color: #bed1b8;
  --bs-table-striped-bg: #c8dcc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bed1b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3d7bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._1kIl6q_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  ._1kIl6q_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  ._1kIl6q_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  ._1kIl6q_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  ._1kIl6q_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  ._1kIl6q_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

._1kIl6q_form-label {
  margin-bottom: .5rem;
}

._1kIl6q_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

._1kIl6q_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

._1kIl6q_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

._1kIl6q_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

._1kIl6q_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-control {
    transition: none;
  }
}

._1kIl6q_form-control[type="file"] {
  overflow: hidden;
}

._1kIl6q_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._1kIl6q_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._1kIl6q_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

._1kIl6q_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

._1kIl6q_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

._1kIl6q_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-control::file-selector-button {
    transition: none;
  }
}

._1kIl6q_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

._1kIl6q_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

._1kIl6q_form-control-plaintext:focus {
  outline: 0;
}

._1kIl6q_form-control-plaintext._1kIl6q_form-control-sm, ._1kIl6q_form-control-plaintext._1kIl6q_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

._1kIl6q_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._1kIl6q_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

._1kIl6q_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._1kIl6q_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea._1kIl6q_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea._1kIl6q_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea._1kIl6q_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

._1kIl6q_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

._1kIl6q_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._1kIl6q_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

._1kIl6q_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

._1kIl6q_form-control-color._1kIl6q_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

._1kIl6q_form-control-color._1kIl6q_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

._1kIl6q_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-select {
    transition: none;
  }
}

._1kIl6q_form-select:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._1kIl6q_form-select[multiple], ._1kIl6q_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

._1kIl6q_form-select:disabled {
  background-color: #e9ecef;
}

._1kIl6q_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

._1kIl6q_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

._1kIl6q_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

._1kIl6q_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

._1kIl6q_form-check ._1kIl6q_form-check-input {
  float: left;
  margin-left: -1.5em;
}

._1kIl6q_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

._1kIl6q_form-check-reverse ._1kIl6q_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

._1kIl6q_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

._1kIl6q_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

._1kIl6q_form-check-input[type="radio"] {
  border-radius: 50%;
}

._1kIl6q_form-check-input:active {
  filter: brightness(90%);
}

._1kIl6q_form-check-input:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._1kIl6q_form-check-input:checked {
  background-color: #258e01;
  border-color: #258e01;
}

._1kIl6q_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

._1kIl6q_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

._1kIl6q_form-check-input[type="checkbox"]:indeterminate {
  background-color: #258e01;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #258e01;
}

._1kIl6q_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

._1kIl6q_form-check-input[disabled] ~ ._1kIl6q_form-check-label, ._1kIl6q_form-check-input:disabled ~ ._1kIl6q_form-check-label {
  cursor: default;
  opacity: .5;
}

._1kIl6q_form-switch {
  padding-left: 2.5em;
}

._1kIl6q_form-switch ._1kIl6q_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-switch ._1kIl6q_form-check-input {
    transition: none;
  }
}

._1kIl6q_form-switch ._1kIl6q_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2392c780'/%3e%3c/svg%3e");
}

._1kIl6q_form-switch ._1kIl6q_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

._1kIl6q_form-switch._1kIl6q_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

._1kIl6q_form-switch._1kIl6q_form-check-reverse ._1kIl6q_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

._1kIl6q_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

._1kIl6q_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

._1kIl6q_btn-check[disabled] + ._1kIl6q_btn, ._1kIl6q_btn-check:disabled + ._1kIl6q_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

._1kIl6q_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

._1kIl6q_form-range:focus {
  outline: 0;
}

._1kIl6q_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

._1kIl6q_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

._1kIl6q_form-range::-moz-focus-outer {
  border: 0;
}

._1kIl6q_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

._1kIl6q_form-range::-webkit-slider-thumb:active {
  background-color: #beddb3;
}

._1kIl6q_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._1kIl6q_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-range::-moz-range-thumb {
    transition: none;
  }
}

._1kIl6q_form-range::-moz-range-thumb:active {
  background-color: #beddb3;
}

._1kIl6q_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._1kIl6q_form-range:disabled {
  pointer-events: none;
}

._1kIl6q_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

._1kIl6q_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

._1kIl6q_form-floating {
  position: relative;
}

._1kIl6q_form-floating > ._1kIl6q_form-control, ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext, ._1kIl6q_form-floating > ._1kIl6q_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

._1kIl6q_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_form-floating > label {
    transition: none;
  }
}

._1kIl6q_form-floating > ._1kIl6q_form-control, ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext {
  padding: 1rem .75rem;
}

._1kIl6q_form-floating > ._1kIl6q_form-control::placeholder, ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext::placeholder {
  color: #0000;
}

._1kIl6q_form-floating > ._1kIl6q_form-control:focus, ._1kIl6q_form-floating > ._1kIl6q_form-control:not(:placeholder-shown), ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext:focus, ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._1kIl6q_form-floating > ._1kIl6q_form-control:-webkit-autofill, ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._1kIl6q_form-floating > ._1kIl6q_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._1kIl6q_form-floating > ._1kIl6q_form-control:focus ~ label, ._1kIl6q_form-floating > ._1kIl6q_form-control:not(:placeholder-shown) ~ label, ._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext ~ label, ._1kIl6q_form-floating > ._1kIl6q_form-select ~ label, ._1kIl6q_form-floating > ._1kIl6q_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

._1kIl6q_form-floating > ._1kIl6q_form-control-plaintext ~ label {
  border-width: 1px 0;
}

._1kIl6q_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

._1kIl6q_input-group > ._1kIl6q_form-control, ._1kIl6q_input-group > ._1kIl6q_form-select, ._1kIl6q_input-group > ._1kIl6q_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

._1kIl6q_input-group > ._1kIl6q_form-control:focus, ._1kIl6q_input-group > ._1kIl6q_form-select:focus, ._1kIl6q_input-group > ._1kIl6q_form-floating:focus-within {
  z-index: 5;
}

._1kIl6q_input-group ._1kIl6q_btn {
  z-index: 2;
  position: relative;
}

._1kIl6q_input-group ._1kIl6q_btn:focus {
  z-index: 5;
}

._1kIl6q_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

._1kIl6q_input-group-lg > ._1kIl6q_form-control, ._1kIl6q_input-group-lg > ._1kIl6q_form-select, ._1kIl6q_input-group-lg > ._1kIl6q_input-group-text, ._1kIl6q_input-group-lg > ._1kIl6q_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._1kIl6q_input-group-sm > ._1kIl6q_form-control, ._1kIl6q_input-group-sm > ._1kIl6q_form-select, ._1kIl6q_input-group-sm > ._1kIl6q_input-group-text, ._1kIl6q_input-group-sm > ._1kIl6q_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._1kIl6q_input-group-lg > ._1kIl6q_form-select, ._1kIl6q_input-group-sm > ._1kIl6q_form-select {
  padding-right: 3rem;
}

._1kIl6q_input-group:not(._1kIl6q_has-validation) > :not(:last-child):not(._1kIl6q_dropdown-toggle):not(._1kIl6q_dropdown-menu):not(._1kIl6q_form-floating), ._1kIl6q_input-group:not(._1kIl6q_has-validation) > ._1kIl6q_dropdown-toggle:nth-last-child(n+3), ._1kIl6q_input-group:not(._1kIl6q_has-validation) > ._1kIl6q_form-floating:not(:last-child) > ._1kIl6q_form-control, ._1kIl6q_input-group:not(._1kIl6q_has-validation) > ._1kIl6q_form-floating:not(:last-child) > ._1kIl6q_form-select, ._1kIl6q_input-group._1kIl6q_has-validation > :nth-last-child(n+3):not(._1kIl6q_dropdown-toggle):not(._1kIl6q_dropdown-menu):not(._1kIl6q_form-floating), ._1kIl6q_input-group._1kIl6q_has-validation > ._1kIl6q_dropdown-toggle:nth-last-child(n+4), ._1kIl6q_input-group._1kIl6q_has-validation > ._1kIl6q_form-floating:nth-last-child(n+3) > ._1kIl6q_form-control, ._1kIl6q_input-group._1kIl6q_has-validation > ._1kIl6q_form-floating:nth-last-child(n+3) > ._1kIl6q_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._1kIl6q_input-group > :not(:first-child):not(._1kIl6q_dropdown-menu):not(._1kIl6q_valid-tooltip):not(._1kIl6q_valid-feedback):not(._1kIl6q_invalid-tooltip):not(._1kIl6q_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

._1kIl6q_input-group > ._1kIl6q_form-floating:not(:first-child) > ._1kIl6q_form-control, ._1kIl6q_input-group > ._1kIl6q_form-floating:not(:first-child) > ._1kIl6q_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._1kIl6q_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._1kIl6q_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._1kIl6q_was-validated :valid ~ ._1kIl6q_valid-feedback, ._1kIl6q_was-validated :valid ~ ._1kIl6q_valid-tooltip, ._1kIl6q_is-valid ~ ._1kIl6q_valid-feedback, ._1kIl6q_is-valid ~ ._1kIl6q_valid-tooltip {
  display: block;
}

._1kIl6q_was-validated ._1kIl6q_form-control:valid, ._1kIl6q_form-control._1kIl6q_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

._1kIl6q_was-validated ._1kIl6q_form-control:valid:focus, ._1kIl6q_form-control._1kIl6q_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._1kIl6q_was-validated textarea._1kIl6q_form-control:valid, textarea._1kIl6q_form-control._1kIl6q_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._1kIl6q_was-validated ._1kIl6q_form-select:valid, ._1kIl6q_form-select._1kIl6q_is-valid {
  border-color: #198754;
}

._1kIl6q_was-validated ._1kIl6q_form-select:valid:not([multiple]):not([size]), ._1kIl6q_was-validated ._1kIl6q_form-select:valid:not([multiple])[size="1"], ._1kIl6q_form-select._1kIl6q_is-valid:not([multiple]):not([size]), ._1kIl6q_form-select._1kIl6q_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._1kIl6q_was-validated ._1kIl6q_form-select:valid:focus, ._1kIl6q_form-select._1kIl6q_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._1kIl6q_was-validated ._1kIl6q_form-control-color:valid, ._1kIl6q_form-control-color._1kIl6q_is-valid {
  width: calc(1.5em + 3.75rem);
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:valid, ._1kIl6q_form-check-input._1kIl6q_is-valid {
  border-color: #198754;
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:valid:checked, ._1kIl6q_form-check-input._1kIl6q_is-valid:checked {
  background-color: #198754;
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:valid:focus, ._1kIl6q_form-check-input._1kIl6q_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:valid ~ ._1kIl6q_form-check-label, ._1kIl6q_form-check-input._1kIl6q_is-valid ~ ._1kIl6q_form-check-label {
  color: #198754;
}

._1kIl6q_form-check-inline ._1kIl6q_form-check-input ~ ._1kIl6q_valid-feedback {
  margin-left: .5em;
}

._1kIl6q_was-validated ._1kIl6q_input-group > ._1kIl6q_form-control:not(:focus):valid, ._1kIl6q_input-group > ._1kIl6q_form-control:not(:focus)._1kIl6q_is-valid, ._1kIl6q_was-validated ._1kIl6q_input-group > ._1kIl6q_form-select:not(:focus):valid, ._1kIl6q_input-group > ._1kIl6q_form-select:not(:focus)._1kIl6q_is-valid, ._1kIl6q_was-validated ._1kIl6q_input-group > ._1kIl6q_form-floating:not(:focus-within):valid, ._1kIl6q_input-group > ._1kIl6q_form-floating:not(:focus-within)._1kIl6q_is-valid {
  z-index: 3;
}

._1kIl6q_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._1kIl6q_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._1kIl6q_was-validated :invalid ~ ._1kIl6q_invalid-feedback, ._1kIl6q_was-validated :invalid ~ ._1kIl6q_invalid-tooltip, ._1kIl6q_is-invalid ~ ._1kIl6q_invalid-feedback, ._1kIl6q_is-invalid ~ ._1kIl6q_invalid-tooltip {
  display: block;
}

._1kIl6q_was-validated ._1kIl6q_form-control:invalid, ._1kIl6q_form-control._1kIl6q_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

._1kIl6q_was-validated ._1kIl6q_form-control:invalid:focus, ._1kIl6q_form-control._1kIl6q_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._1kIl6q_was-validated textarea._1kIl6q_form-control:invalid, textarea._1kIl6q_form-control._1kIl6q_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._1kIl6q_was-validated ._1kIl6q_form-select:invalid, ._1kIl6q_form-select._1kIl6q_is-invalid {
  border-color: #dc3545;
}

._1kIl6q_was-validated ._1kIl6q_form-select:invalid:not([multiple]):not([size]), ._1kIl6q_was-validated ._1kIl6q_form-select:invalid:not([multiple])[size="1"], ._1kIl6q_form-select._1kIl6q_is-invalid:not([multiple]):not([size]), ._1kIl6q_form-select._1kIl6q_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._1kIl6q_was-validated ._1kIl6q_form-select:invalid:focus, ._1kIl6q_form-select._1kIl6q_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._1kIl6q_was-validated ._1kIl6q_form-control-color:invalid, ._1kIl6q_form-control-color._1kIl6q_is-invalid {
  width: calc(1.5em + 3.75rem);
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:invalid, ._1kIl6q_form-check-input._1kIl6q_is-invalid {
  border-color: #dc3545;
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:invalid:checked, ._1kIl6q_form-check-input._1kIl6q_is-invalid:checked {
  background-color: #dc3545;
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:invalid:focus, ._1kIl6q_form-check-input._1kIl6q_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

._1kIl6q_was-validated ._1kIl6q_form-check-input:invalid ~ ._1kIl6q_form-check-label, ._1kIl6q_form-check-input._1kIl6q_is-invalid ~ ._1kIl6q_form-check-label {
  color: #dc3545;
}

._1kIl6q_form-check-inline ._1kIl6q_form-check-input ~ ._1kIl6q_invalid-feedback {
  margin-left: .5em;
}

._1kIl6q_was-validated ._1kIl6q_input-group > ._1kIl6q_form-control:not(:focus):invalid, ._1kIl6q_input-group > ._1kIl6q_form-control:not(:focus)._1kIl6q_is-invalid, ._1kIl6q_was-validated ._1kIl6q_input-group > ._1kIl6q_form-select:not(:focus):invalid, ._1kIl6q_input-group > ._1kIl6q_form-select:not(:focus)._1kIl6q_is-invalid, ._1kIl6q_was-validated ._1kIl6q_input-group > ._1kIl6q_form-floating:not(:focus-within):invalid, ._1kIl6q_input-group > ._1kIl6q_form-floating:not(:focus-within)._1kIl6q_is-invalid {
  z-index: 4;
}

._1kIl6q_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_btn {
    transition: none;
  }
}

._1kIl6q_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

._1kIl6q_btn-check + ._1kIl6q_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

._1kIl6q_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._1kIl6q_btn-check:focus-visible + ._1kIl6q_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._1kIl6q_btn-check:checked + ._1kIl6q_btn, :not(._1kIl6q_btn-check) + ._1kIl6q_btn:active, ._1kIl6q_btn:first-child:active, ._1kIl6q_btn._1kIl6q_active, ._1kIl6q_btn._1kIl6q_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

._1kIl6q_btn-check:checked + ._1kIl6q_btn:focus-visible, :not(._1kIl6q_btn-check) + ._1kIl6q_btn:active:focus-visible, ._1kIl6q_btn:first-child:active:focus-visible, ._1kIl6q_btn._1kIl6q_active:focus-visible, ._1kIl6q_btn._1kIl6q_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

._1kIl6q_btn:disabled, ._1kIl6q_btn._1kIl6q_disabled, fieldset:disabled ._1kIl6q_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

._1kIl6q_btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #469f27;
  --bs-btn-hover-border-color: #3b991a;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #51a534;
  --bs-btn-active-border-color: #3b991a;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #258e01;
  --bs-btn-disabled-border-color: #258e01;
}

._1kIl6q_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

._1kIl6q_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

._1kIl6q_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

._1kIl6q_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

._1kIl6q_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

._1kIl6q_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

._1kIl6q_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

._1kIl6q_btn-outline-primary {
  --bs-btn-color: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #258e01;
  --bs-btn-hover-border-color: #258e01;
  --bs-btn-focus-shadow-rgb: 37, 142, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #258e01;
  --bs-btn-active-border-color: #258e01;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #258e01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #258e01;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

._1kIl6q_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

._1kIl6q_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  text-decoration: underline;
}

._1kIl6q_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

._1kIl6q_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

._1kIl6q_btn-lg, ._1kIl6q_btn-group-lg > ._1kIl6q_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

._1kIl6q_btn-sm, ._1kIl6q_btn-group-sm > ._1kIl6q_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

._1kIl6q_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_fade {
    transition: none;
  }
}

._1kIl6q_fade:not(._1kIl6q_show) {
  opacity: 0;
}

._1kIl6q_collapse:not(._1kIl6q_show) {
  display: none;
}

._1kIl6q_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_collapsing {
    transition: none;
  }
}

._1kIl6q_collapsing._1kIl6q_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_collapsing._1kIl6q_collapse-horizontal {
    transition: none;
  }
}

._1kIl6q_dropup, ._1kIl6q_dropend, ._1kIl6q_dropdown, ._1kIl6q_dropstart, ._1kIl6q_dropup-center, ._1kIl6q_dropdown-center {
  position: relative;
}

._1kIl6q_dropdown-toggle {
  white-space: nowrap;
}

._1kIl6q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

._1kIl6q_dropdown-toggle:empty:after {
  margin-left: 0;
}

._1kIl6q_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

._1kIl6q_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

._1kIl6q_dropdown-menu-start {
  --bs-position: start;
}

._1kIl6q_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

._1kIl6q_dropdown-menu-end {
  --bs-position: end;
}

._1kIl6q_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  ._1kIl6q_dropdown-menu-sm-start {
    --bs-position: start;
  }

  ._1kIl6q_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._1kIl6q_dropdown-menu-sm-end {
    --bs-position: end;
  }

  ._1kIl6q_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_dropdown-menu-md-start {
    --bs-position: start;
  }

  ._1kIl6q_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._1kIl6q_dropdown-menu-md-end {
    --bs-position: end;
  }

  ._1kIl6q_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_dropdown-menu-lg-start {
    --bs-position: start;
  }

  ._1kIl6q_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._1kIl6q_dropdown-menu-lg-end {
    --bs-position: end;
  }

  ._1kIl6q_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_dropdown-menu-xl-start {
    --bs-position: start;
  }

  ._1kIl6q_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._1kIl6q_dropdown-menu-xl-end {
    --bs-position: end;
  }

  ._1kIl6q_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  ._1kIl6q_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._1kIl6q_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  ._1kIl6q_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

._1kIl6q_dropup ._1kIl6q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

._1kIl6q_dropup ._1kIl6q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

._1kIl6q_dropup ._1kIl6q_dropdown-toggle:empty:after {
  margin-left: 0;
}

._1kIl6q_dropend ._1kIl6q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

._1kIl6q_dropend ._1kIl6q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

._1kIl6q_dropend ._1kIl6q_dropdown-toggle:empty:after {
  margin-left: 0;
}

._1kIl6q_dropend ._1kIl6q_dropdown-toggle:after {
  vertical-align: 0;
}

._1kIl6q_dropstart ._1kIl6q_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

._1kIl6q_dropstart ._1kIl6q_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

._1kIl6q_dropstart ._1kIl6q_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

._1kIl6q_dropstart ._1kIl6q_dropdown-toggle:empty:after {
  margin-left: 0;
}

._1kIl6q_dropstart ._1kIl6q_dropdown-toggle:before {
  vertical-align: 0;
}

._1kIl6q_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

._1kIl6q_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

._1kIl6q_dropdown-item:hover, ._1kIl6q_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

._1kIl6q_dropdown-item._1kIl6q_active, ._1kIl6q_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

._1kIl6q_dropdown-item._1kIl6q_disabled, ._1kIl6q_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

._1kIl6q_dropdown-menu._1kIl6q_show {
  display: block;
}

._1kIl6q_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

._1kIl6q_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

._1kIl6q_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

._1kIl6q_btn-group, ._1kIl6q_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

._1kIl6q_btn-group > ._1kIl6q_btn, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn {
  flex: auto;
  position: relative;
}

._1kIl6q_btn-group > ._1kIl6q_btn-check:checked + ._1kIl6q_btn, ._1kIl6q_btn-group > ._1kIl6q_btn-check:focus + ._1kIl6q_btn, ._1kIl6q_btn-group > ._1kIl6q_btn:hover, ._1kIl6q_btn-group > ._1kIl6q_btn:focus, ._1kIl6q_btn-group > ._1kIl6q_btn:active, ._1kIl6q_btn-group > ._1kIl6q_btn._1kIl6q_active, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn-check:checked + ._1kIl6q_btn, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn-check:focus + ._1kIl6q_btn, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn:hover, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn:focus, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn:active, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn._1kIl6q_active {
  z-index: 1;
}

._1kIl6q_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

._1kIl6q_btn-toolbar ._1kIl6q_input-group {
  width: auto;
}

._1kIl6q_btn-group {
  border-radius: .375rem;
}

._1kIl6q_btn-group > :not(._1kIl6q_btn-check:first-child) + ._1kIl6q_btn, ._1kIl6q_btn-group > ._1kIl6q_btn-group:not(:first-child) {
  margin-left: -1px;
}

._1kIl6q_btn-group > ._1kIl6q_btn:not(:last-child):not(._1kIl6q_dropdown-toggle), ._1kIl6q_btn-group > ._1kIl6q_btn._1kIl6q_dropdown-toggle-split:first-child, ._1kIl6q_btn-group > ._1kIl6q_btn-group:not(:last-child) > ._1kIl6q_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._1kIl6q_btn-group > ._1kIl6q_btn:nth-child(n+3), ._1kIl6q_btn-group > :not(._1kIl6q_btn-check) + ._1kIl6q_btn, ._1kIl6q_btn-group > ._1kIl6q_btn-group:not(:first-child) > ._1kIl6q_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._1kIl6q_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

._1kIl6q_dropdown-toggle-split:after, ._1kIl6q_dropup ._1kIl6q_dropdown-toggle-split:after, ._1kIl6q_dropend ._1kIl6q_dropdown-toggle-split:after {
  margin-left: 0;
}

._1kIl6q_dropstart ._1kIl6q_dropdown-toggle-split:before {
  margin-right: 0;
}

._1kIl6q_btn-sm + ._1kIl6q_dropdown-toggle-split, ._1kIl6q_btn-group-sm > ._1kIl6q_btn + ._1kIl6q_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

._1kIl6q_btn-lg + ._1kIl6q_dropdown-toggle-split, ._1kIl6q_btn-group-lg > ._1kIl6q_btn + ._1kIl6q_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

._1kIl6q_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

._1kIl6q_btn-group-vertical > ._1kIl6q_btn, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn-group {
  width: 100%;
}

._1kIl6q_btn-group-vertical > ._1kIl6q_btn:not(:first-child), ._1kIl6q_btn-group-vertical > ._1kIl6q_btn-group:not(:first-child) {
  margin-top: -1px;
}

._1kIl6q_btn-group-vertical > ._1kIl6q_btn:not(:last-child):not(._1kIl6q_dropdown-toggle), ._1kIl6q_btn-group-vertical > ._1kIl6q_btn-group:not(:last-child) > ._1kIl6q_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

._1kIl6q_btn-group-vertical > ._1kIl6q_btn ~ ._1kIl6q_btn, ._1kIl6q_btn-group-vertical > ._1kIl6q_btn-group:not(:first-child) > ._1kIl6q_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._1kIl6q_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._1kIl6q_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_nav-link {
    transition: none;
  }
}

._1kIl6q_nav-link:hover, ._1kIl6q_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

._1kIl6q_nav-link._1kIl6q_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

._1kIl6q_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

._1kIl6q_nav-tabs ._1kIl6q_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

._1kIl6q_nav-tabs ._1kIl6q_nav-link:hover, ._1kIl6q_nav-tabs ._1kIl6q_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

._1kIl6q_nav-tabs ._1kIl6q_nav-link._1kIl6q_disabled, ._1kIl6q_nav-tabs ._1kIl6q_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._1kIl6q_nav-tabs ._1kIl6q_nav-link._1kIl6q_active, ._1kIl6q_nav-tabs ._1kIl6q_nav-item._1kIl6q_show ._1kIl6q_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

._1kIl6q_nav-tabs ._1kIl6q_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._1kIl6q_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #258e01;
}

._1kIl6q_nav-pills ._1kIl6q_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

._1kIl6q_nav-pills ._1kIl6q_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._1kIl6q_nav-pills ._1kIl6q_nav-link._1kIl6q_active, ._1kIl6q_nav-pills ._1kIl6q_show > ._1kIl6q_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

._1kIl6q_nav-fill > ._1kIl6q_nav-link, ._1kIl6q_nav-fill ._1kIl6q_nav-item {
  text-align: center;
  flex: auto;
}

._1kIl6q_nav-justified > ._1kIl6q_nav-link, ._1kIl6q_nav-justified ._1kIl6q_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

._1kIl6q_nav-fill ._1kIl6q_nav-item ._1kIl6q_nav-link, ._1kIl6q_nav-justified ._1kIl6q_nav-item ._1kIl6q_nav-link {
  width: 100%;
}

._1kIl6q_tab-content > ._1kIl6q_tab-pane {
  display: none;
}

._1kIl6q_tab-content > ._1kIl6q_active {
  display: block;
}

._1kIl6q_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

._1kIl6q_navbar > ._1kIl6q_container, ._1kIl6q_navbar > ._1kIl6q_container-fluid, ._1kIl6q_navbar > ._1kIl6q_container-sm, ._1kIl6q_navbar > ._1kIl6q_container-md, ._1kIl6q_navbar > ._1kIl6q_container-lg, ._1kIl6q_navbar > ._1kIl6q_container-xl, ._1kIl6q_navbar > ._1kIl6q_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._1kIl6q_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

._1kIl6q_navbar-brand:hover, ._1kIl6q_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

._1kIl6q_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._1kIl6q_navbar-nav ._1kIl6q_show > ._1kIl6q_nav-link, ._1kIl6q_navbar-nav ._1kIl6q_nav-link._1kIl6q_active {
  color: var(--bs-navbar-active-color);
}

._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
  position: static;
}

._1kIl6q_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

._1kIl6q_navbar-text a, ._1kIl6q_navbar-text a:hover, ._1kIl6q_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

._1kIl6q_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

._1kIl6q_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_navbar-toggler {
    transition: none;
  }
}

._1kIl6q_navbar-toggler:hover {
  text-decoration: none;
}

._1kIl6q_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

._1kIl6q_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

._1kIl6q_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  ._1kIl6q_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_navbar-nav {
    flex-direction: row;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
    position: absolute;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_navbar-nav ._1kIl6q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_navbar-nav-scroll {
    overflow: visible;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_navbar-toggler {
    display: none;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_offcanvas ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_navbar-expand-sm ._1kIl6q_offcanvas ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_navbar-nav {
    flex-direction: row;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
    position: absolute;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_navbar-nav ._1kIl6q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_navbar-nav-scroll {
    overflow: visible;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_navbar-toggler {
    display: none;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_offcanvas ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_navbar-expand-md ._1kIl6q_offcanvas ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_navbar-nav {
    flex-direction: row;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
    position: absolute;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_navbar-nav ._1kIl6q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_navbar-nav-scroll {
    overflow: visible;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_navbar-toggler {
    display: none;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_offcanvas ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_navbar-expand-lg ._1kIl6q_offcanvas ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_navbar-nav {
    flex-direction: row;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
    position: absolute;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_navbar-nav ._1kIl6q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_navbar-nav-scroll {
    overflow: visible;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_navbar-toggler {
    display: none;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_offcanvas ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_navbar-expand-xl ._1kIl6q_offcanvas ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_navbar-nav {
    flex-direction: row;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
    position: absolute;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_navbar-nav ._1kIl6q_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_navbar-nav-scroll {
    overflow: visible;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_navbar-toggler {
    display: none;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_offcanvas ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_navbar-expand-xxl ._1kIl6q_offcanvas ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

._1kIl6q_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

._1kIl6q_navbar-expand ._1kIl6q_navbar-nav {
  flex-direction: row;
}

._1kIl6q_navbar-expand ._1kIl6q_navbar-nav ._1kIl6q_dropdown-menu {
  position: absolute;
}

._1kIl6q_navbar-expand ._1kIl6q_navbar-nav ._1kIl6q_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

._1kIl6q_navbar-expand ._1kIl6q_navbar-nav-scroll {
  overflow: visible;
}

._1kIl6q_navbar-expand ._1kIl6q_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

._1kIl6q_navbar-expand ._1kIl6q_navbar-toggler {
  display: none;
}

._1kIl6q_navbar-expand ._1kIl6q_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

._1kIl6q_navbar-expand ._1kIl6q_offcanvas ._1kIl6q_offcanvas-header {
  display: none;
}

._1kIl6q_navbar-expand ._1kIl6q_offcanvas ._1kIl6q_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

._1kIl6q_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

._1kIl6q_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

._1kIl6q_card > hr {
  margin-left: 0;
  margin-right: 0;
}

._1kIl6q_card > ._1kIl6q_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

._1kIl6q_card > ._1kIl6q_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

._1kIl6q_card > ._1kIl6q_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

._1kIl6q_card > ._1kIl6q_card-header + ._1kIl6q_list-group, ._1kIl6q_card > ._1kIl6q_list-group + ._1kIl6q_card-footer {
  border-top: 0;
}

._1kIl6q_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

._1kIl6q_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

._1kIl6q_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

._1kIl6q_card-text:last-child {
  margin-bottom: 0;
}

._1kIl6q_card-link + ._1kIl6q_card-link {
  margin-left: var(--bs-card-spacer-x);
}

._1kIl6q_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

._1kIl6q_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

._1kIl6q_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

._1kIl6q_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

._1kIl6q_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

._1kIl6q_card-header-tabs ._1kIl6q_nav-link._1kIl6q_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

._1kIl6q_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

._1kIl6q_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

._1kIl6q_card-img, ._1kIl6q_card-img-top, ._1kIl6q_card-img-bottom {
  width: 100%;
}

._1kIl6q_card-img, ._1kIl6q_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

._1kIl6q_card-img, ._1kIl6q_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

._1kIl6q_card-group > ._1kIl6q_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  ._1kIl6q_card-group {
    flex-flow: wrap;
    display: flex;
  }

  ._1kIl6q_card-group > ._1kIl6q_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card + ._1kIl6q_card {
    border-left: 0;
    margin-left: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card:not(:last-child) ._1kIl6q_card-img-top, ._1kIl6q_card-group > ._1kIl6q_card:not(:last-child) ._1kIl6q_card-header {
    border-top-right-radius: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card:not(:last-child) ._1kIl6q_card-img-bottom, ._1kIl6q_card-group > ._1kIl6q_card:not(:last-child) ._1kIl6q_card-footer {
    border-bottom-right-radius: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card:not(:first-child) ._1kIl6q_card-img-top, ._1kIl6q_card-group > ._1kIl6q_card:not(:first-child) ._1kIl6q_card-header {
    border-top-left-radius: 0;
  }

  ._1kIl6q_card-group > ._1kIl6q_card:not(:first-child) ._1kIl6q_card-img-bottom, ._1kIl6q_card-group > ._1kIl6q_card:not(:first-child) ._1kIl6q_card-footer {
    border-bottom-left-radius: 0;
  }
}

._1kIl6q_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23218001'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #92c780;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #218001;
  --bs-accordion-active-bg: #e9f4e6;
}

._1kIl6q_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_accordion-button {
    transition: none;
  }
}

._1kIl6q_accordion-button:not(._1kIl6q_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

._1kIl6q_accordion-button:not(._1kIl6q_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

._1kIl6q_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_accordion-button:after {
    transition: none;
  }
}

._1kIl6q_accordion-button:hover {
  z-index: 2;
}

._1kIl6q_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

._1kIl6q_accordion-header {
  margin-bottom: 0;
}

._1kIl6q_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

._1kIl6q_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

._1kIl6q_accordion-item:first-of-type ._1kIl6q_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

._1kIl6q_accordion-item:not(:first-of-type) {
  border-top: 0;
}

._1kIl6q_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._1kIl6q_accordion-item:last-of-type ._1kIl6q_accordion-button._1kIl6q_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

._1kIl6q_accordion-item:last-of-type ._1kIl6q_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._1kIl6q_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

._1kIl6q_accordion-flush ._1kIl6q_accordion-collapse {
  border-width: 0;
}

._1kIl6q_accordion-flush ._1kIl6q_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

._1kIl6q_accordion-flush ._1kIl6q_accordion-item:first-child {
  border-top: 0;
}

._1kIl6q_accordion-flush ._1kIl6q_accordion-item:last-child {
  border-bottom: 0;
}

._1kIl6q_accordion-flush ._1kIl6q_accordion-item ._1kIl6q_accordion-button, ._1kIl6q_accordion-flush ._1kIl6q_accordion-item ._1kIl6q_accordion-button._1kIl6q_collapsed {
  border-radius: 0;
}

._1kIl6q_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

._1kIl6q_breadcrumb-item + ._1kIl6q_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

._1kIl6q_breadcrumb-item + ._1kIl6q_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

._1kIl6q_breadcrumb-item._1kIl6q_active {
  color: var(--bs-breadcrumb-item-active-color);
}

._1kIl6q_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #258e01;
  --bs-pagination-active-border-color: #258e01;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._1kIl6q_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_page-link {
    transition: none;
  }
}

._1kIl6q_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

._1kIl6q_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

._1kIl6q_page-link._1kIl6q_active, ._1kIl6q_active > ._1kIl6q_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

._1kIl6q_page-link._1kIl6q_disabled, ._1kIl6q_disabled > ._1kIl6q_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

._1kIl6q_page-item:not(:first-child) ._1kIl6q_page-link {
  margin-left: -1px;
}

._1kIl6q_page-item:first-child ._1kIl6q_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

._1kIl6q_page-item:last-child ._1kIl6q_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

._1kIl6q_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

._1kIl6q_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

._1kIl6q_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

._1kIl6q_badge:empty {
  display: none;
}

._1kIl6q_btn ._1kIl6q_badge {
  position: relative;
  top: -1px;
}

._1kIl6q_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

._1kIl6q_alert-heading {
  color: inherit;
}

._1kIl6q_alert-link {
  font-weight: 700;
}

._1kIl6q_alert-dismissible {
  padding-right: 3rem;
}

._1kIl6q_alert-dismissible ._1kIl6q_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

._1kIl6q_alert-primary {
  --bs-alert-color: #165501;
  --bs-alert-bg: #d3e8cc;
  --bs-alert-border-color: #beddb3;
}

._1kIl6q_alert-primary ._1kIl6q_alert-link {
  color: #124401;
}

._1kIl6q_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

._1kIl6q_alert-secondary ._1kIl6q_alert-link {
  color: #34383c;
}

._1kIl6q_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

._1kIl6q_alert-success ._1kIl6q_alert-link {
  color: #0c4128;
}

._1kIl6q_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

._1kIl6q_alert-info ._1kIl6q_alert-link {
  color: #04414d;
}

._1kIl6q_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

._1kIl6q_alert-warning ._1kIl6q_alert-link {
  color: #523e02;
}

._1kIl6q_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

._1kIl6q_alert-danger ._1kIl6q_alert-link {
  color: #6a1a21;
}

._1kIl6q_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

._1kIl6q_alert-light ._1kIl6q_alert-link {
  color: #4f5050;
}

._1kIl6q_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

._1kIl6q_alert-dark ._1kIl6q_alert-link {
  color: #101214;
}

@keyframes _1kIl6q_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

._1kIl6q_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #258e01;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

._1kIl6q_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_progress-bar {
    transition: none;
  }
}

._1kIl6q_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

._1kIl6q_progress-bar-animated {
  animation: 1s linear infinite _1kIl6q_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_progress-bar-animated {
    animation: none;
  }
}

._1kIl6q_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #258e01;
  --bs-list-group-active-border-color: #258e01;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

._1kIl6q_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

._1kIl6q_list-group-numbered > ._1kIl6q_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

._1kIl6q_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

._1kIl6q_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

._1kIl6q_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

._1kIl6q_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

._1kIl6q_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

._1kIl6q_list-group-item._1kIl6q_disabled, ._1kIl6q_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

._1kIl6q_list-group-item._1kIl6q_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
  border-top-width: 0;
}

._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

._1kIl6q_list-group-horizontal {
  flex-direction: row;
}

._1kIl6q_list-group-horizontal > ._1kIl6q_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

._1kIl6q_list-group-horizontal > ._1kIl6q_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

._1kIl6q_list-group-horizontal > ._1kIl6q_list-group-item._1kIl6q_active {
  margin-top: 0;
}

._1kIl6q_list-group-horizontal > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

._1kIl6q_list-group-horizontal > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  ._1kIl6q_list-group-horizontal-sm {
    flex-direction: row;
  }

  ._1kIl6q_list-group-horizontal-sm > ._1kIl6q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-sm > ._1kIl6q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-sm > ._1kIl6q_list-group-item._1kIl6q_active {
    margin-top: 0;
  }

  ._1kIl6q_list-group-horizontal-sm > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._1kIl6q_list-group-horizontal-sm > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  ._1kIl6q_list-group-horizontal-md {
    flex-direction: row;
  }

  ._1kIl6q_list-group-horizontal-md > ._1kIl6q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-md > ._1kIl6q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-md > ._1kIl6q_list-group-item._1kIl6q_active {
    margin-top: 0;
  }

  ._1kIl6q_list-group-horizontal-md > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._1kIl6q_list-group-horizontal-md > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  ._1kIl6q_list-group-horizontal-lg {
    flex-direction: row;
  }

  ._1kIl6q_list-group-horizontal-lg > ._1kIl6q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-lg > ._1kIl6q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-lg > ._1kIl6q_list-group-item._1kIl6q_active {
    margin-top: 0;
  }

  ._1kIl6q_list-group-horizontal-lg > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._1kIl6q_list-group-horizontal-lg > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_list-group-horizontal-xl {
    flex-direction: row;
  }

  ._1kIl6q_list-group-horizontal-xl > ._1kIl6q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-xl > ._1kIl6q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-xl > ._1kIl6q_list-group-item._1kIl6q_active {
    margin-top: 0;
  }

  ._1kIl6q_list-group-horizontal-xl > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._1kIl6q_list-group-horizontal-xl > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_list-group-horizontal-xxl {
    flex-direction: row;
  }

  ._1kIl6q_list-group-horizontal-xxl > ._1kIl6q_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-xxl > ._1kIl6q_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._1kIl6q_list-group-horizontal-xxl > ._1kIl6q_list-group-item._1kIl6q_active {
    margin-top: 0;
  }

  ._1kIl6q_list-group-horizontal-xxl > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._1kIl6q_list-group-horizontal-xxl > ._1kIl6q_list-group-item + ._1kIl6q_list-group-item._1kIl6q_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

._1kIl6q_list-group-flush {
  border-radius: 0;
}

._1kIl6q_list-group-flush > ._1kIl6q_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

._1kIl6q_list-group-flush > ._1kIl6q_list-group-item:last-child {
  border-bottom-width: 0;
}

._1kIl6q_list-group-item-primary {
  color: #165501;
  background-color: #d3e8cc;
}

._1kIl6q_list-group-item-primary._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-primary._1kIl6q_list-group-item-action:focus {
  color: #165501;
  background-color: #bed1b8;
}

._1kIl6q_list-group-item-primary._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #165501;
  border-color: #165501;
}

._1kIl6q_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

._1kIl6q_list-group-item-secondary._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-secondary._1kIl6q_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

._1kIl6q_list-group-item-secondary._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

._1kIl6q_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

._1kIl6q_list-group-item-success._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-success._1kIl6q_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

._1kIl6q_list-group-item-success._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

._1kIl6q_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

._1kIl6q_list-group-item-info._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-info._1kIl6q_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

._1kIl6q_list-group-item-info._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

._1kIl6q_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

._1kIl6q_list-group-item-warning._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-warning._1kIl6q_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

._1kIl6q_list-group-item-warning._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

._1kIl6q_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

._1kIl6q_list-group-item-danger._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-danger._1kIl6q_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

._1kIl6q_list-group-item-danger._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

._1kIl6q_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

._1kIl6q_list-group-item-light._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-light._1kIl6q_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

._1kIl6q_list-group-item-light._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

._1kIl6q_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

._1kIl6q_list-group-item-dark._1kIl6q_list-group-item-action:hover, ._1kIl6q_list-group-item-dark._1kIl6q_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

._1kIl6q_list-group-item-dark._1kIl6q_list-group-item-action._1kIl6q_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

._1kIl6q_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

._1kIl6q_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

._1kIl6q_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._1kIl6q_btn-close:disabled, ._1kIl6q_btn-close._1kIl6q_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

._1kIl6q_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

._1kIl6q_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

._1kIl6q_toast._1kIl6q_showing {
  opacity: 0;
}

._1kIl6q_toast:not(._1kIl6q_show) {
  display: none;
}

._1kIl6q_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

._1kIl6q_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

._1kIl6q_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

._1kIl6q_toast-header ._1kIl6q_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

._1kIl6q_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

._1kIl6q_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

._1kIl6q_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

._1kIl6q_modal._1kIl6q_fade ._1kIl6q_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_modal._1kIl6q_fade ._1kIl6q_modal-dialog {
    transition: none;
  }
}

._1kIl6q_modal._1kIl6q_show ._1kIl6q_modal-dialog {
  transform: none;
}

._1kIl6q_modal._1kIl6q_modal-static ._1kIl6q_modal-dialog {
  transform: scale(1.02);
}

._1kIl6q_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

._1kIl6q_modal-dialog-scrollable ._1kIl6q_modal-content {
  max-height: 100%;
  overflow: hidden;
}

._1kIl6q_modal-dialog-scrollable ._1kIl6q_modal-body {
  overflow-y: auto;
}

._1kIl6q_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

._1kIl6q_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

._1kIl6q_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

._1kIl6q_modal-backdrop._1kIl6q_fade {
  opacity: 0;
}

._1kIl6q_modal-backdrop._1kIl6q_show {
  opacity: var(--bs-backdrop-opacity);
}

._1kIl6q_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._1kIl6q_modal-header ._1kIl6q_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

._1kIl6q_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

._1kIl6q_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

._1kIl6q_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._1kIl6q_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  ._1kIl6q_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  ._1kIl6q_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  ._1kIl6q_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_modal-lg, ._1kIl6q_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_modal-xl {
    --bs-modal-width: 1140px;
  }
}

._1kIl6q_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

._1kIl6q_modal-fullscreen ._1kIl6q_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

._1kIl6q_modal-fullscreen ._1kIl6q_modal-header, ._1kIl6q_modal-fullscreen ._1kIl6q_modal-footer {
  border-radius: 0;
}

._1kIl6q_modal-fullscreen ._1kIl6q_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  ._1kIl6q_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._1kIl6q_modal-fullscreen-sm-down ._1kIl6q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-sm-down ._1kIl6q_modal-header, ._1kIl6q_modal-fullscreen-sm-down ._1kIl6q_modal-footer {
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-sm-down ._1kIl6q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  ._1kIl6q_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._1kIl6q_modal-fullscreen-md-down ._1kIl6q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-md-down ._1kIl6q_modal-header, ._1kIl6q_modal-fullscreen-md-down ._1kIl6q_modal-footer {
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-md-down ._1kIl6q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  ._1kIl6q_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._1kIl6q_modal-fullscreen-lg-down ._1kIl6q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-lg-down ._1kIl6q_modal-header, ._1kIl6q_modal-fullscreen-lg-down ._1kIl6q_modal-footer {
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-lg-down ._1kIl6q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  ._1kIl6q_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._1kIl6q_modal-fullscreen-xl-down ._1kIl6q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-xl-down ._1kIl6q_modal-header, ._1kIl6q_modal-fullscreen-xl-down ._1kIl6q_modal-footer {
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-xl-down ._1kIl6q_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  ._1kIl6q_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._1kIl6q_modal-fullscreen-xxl-down ._1kIl6q_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-xxl-down ._1kIl6q_modal-header, ._1kIl6q_modal-fullscreen-xxl-down ._1kIl6q_modal-footer {
    border-radius: 0;
  }

  ._1kIl6q_modal-fullscreen-xxl-down ._1kIl6q_modal-body {
    overflow-y: auto;
  }
}

._1kIl6q_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

._1kIl6q_tooltip._1kIl6q_show {
  opacity: var(--bs-tooltip-opacity);
}

._1kIl6q_tooltip ._1kIl6q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

._1kIl6q_tooltip ._1kIl6q_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

._1kIl6q_bs-tooltip-top ._1kIl6q_tooltip-arrow, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="top"] ._1kIl6q_tooltip-arrow {
  bottom: 0;
}

._1kIl6q_bs-tooltip-top ._1kIl6q_tooltip-arrow:before, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="top"] ._1kIl6q_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

._1kIl6q_bs-tooltip-end ._1kIl6q_tooltip-arrow, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="right"] ._1kIl6q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

._1kIl6q_bs-tooltip-end ._1kIl6q_tooltip-arrow:before, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="right"] ._1kIl6q_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

._1kIl6q_bs-tooltip-bottom ._1kIl6q_tooltip-arrow, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="bottom"] ._1kIl6q_tooltip-arrow {
  top: 0;
}

._1kIl6q_bs-tooltip-bottom ._1kIl6q_tooltip-arrow:before, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="bottom"] ._1kIl6q_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

._1kIl6q_bs-tooltip-start ._1kIl6q_tooltip-arrow, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="left"] ._1kIl6q_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

._1kIl6q_bs-tooltip-start ._1kIl6q_tooltip-arrow:before, ._1kIl6q_bs-tooltip-auto[data-popper-placement^="left"] ._1kIl6q_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

._1kIl6q_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

._1kIl6q_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

._1kIl6q_popover ._1kIl6q_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

._1kIl6q_popover ._1kIl6q_popover-arrow:before, ._1kIl6q_popover ._1kIl6q_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

._1kIl6q_bs-popover-top > ._1kIl6q_popover-arrow, ._1kIl6q_bs-popover-auto[data-popper-placement^="top"] > ._1kIl6q_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._1kIl6q_bs-popover-top > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="top"] > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-top > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="top"] > ._1kIl6q_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._1kIl6q_bs-popover-top > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="top"] > ._1kIl6q_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

._1kIl6q_bs-popover-top > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="top"] > ._1kIl6q_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

._1kIl6q_bs-popover-end > ._1kIl6q_popover-arrow, ._1kIl6q_bs-popover-auto[data-popper-placement^="right"] > ._1kIl6q_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._1kIl6q_bs-popover-end > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="right"] > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-end > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="right"] > ._1kIl6q_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._1kIl6q_bs-popover-end > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="right"] > ._1kIl6q_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

._1kIl6q_bs-popover-end > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="right"] > ._1kIl6q_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

._1kIl6q_bs-popover-bottom > ._1kIl6q_popover-arrow, ._1kIl6q_bs-popover-auto[data-popper-placement^="bottom"] > ._1kIl6q_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._1kIl6q_bs-popover-bottom > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="bottom"] > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-bottom > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="bottom"] > ._1kIl6q_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._1kIl6q_bs-popover-bottom > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="bottom"] > ._1kIl6q_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

._1kIl6q_bs-popover-bottom > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="bottom"] > ._1kIl6q_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

._1kIl6q_bs-popover-bottom ._1kIl6q_popover-header:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="bottom"] ._1kIl6q_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

._1kIl6q_bs-popover-start > ._1kIl6q_popover-arrow, ._1kIl6q_bs-popover-auto[data-popper-placement^="left"] > ._1kIl6q_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._1kIl6q_bs-popover-start > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="left"] > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-start > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="left"] > ._1kIl6q_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._1kIl6q_bs-popover-start > ._1kIl6q_popover-arrow:before, ._1kIl6q_bs-popover-auto[data-popper-placement^="left"] > ._1kIl6q_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

._1kIl6q_bs-popover-start > ._1kIl6q_popover-arrow:after, ._1kIl6q_bs-popover-auto[data-popper-placement^="left"] > ._1kIl6q_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

._1kIl6q_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

._1kIl6q_popover-header:empty {
  display: none;
}

._1kIl6q_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

._1kIl6q_carousel {
  position: relative;
}

._1kIl6q_carousel._1kIl6q_pointer-event {
  touch-action: pan-y;
}

._1kIl6q_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

._1kIl6q_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

._1kIl6q_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_carousel-item {
    transition: none;
  }
}

._1kIl6q_carousel-item._1kIl6q_active, ._1kIl6q_carousel-item-next, ._1kIl6q_carousel-item-prev {
  display: block;
}

._1kIl6q_carousel-item-next:not(._1kIl6q_carousel-item-start), ._1kIl6q_active._1kIl6q_carousel-item-end {
  transform: translateX(100%);
}

._1kIl6q_carousel-item-prev:not(._1kIl6q_carousel-item-end), ._1kIl6q_active._1kIl6q_carousel-item-start {
  transform: translateX(-100%);
}

._1kIl6q_carousel-fade ._1kIl6q_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

._1kIl6q_carousel-fade ._1kIl6q_carousel-item._1kIl6q_active, ._1kIl6q_carousel-fade ._1kIl6q_carousel-item-next._1kIl6q_carousel-item-start, ._1kIl6q_carousel-fade ._1kIl6q_carousel-item-prev._1kIl6q_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

._1kIl6q_carousel-fade ._1kIl6q_active._1kIl6q_carousel-item-start, ._1kIl6q_carousel-fade ._1kIl6q_active._1kIl6q_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_carousel-fade ._1kIl6q_active._1kIl6q_carousel-item-start, ._1kIl6q_carousel-fade ._1kIl6q_active._1kIl6q_carousel-item-end {
    transition: none;
  }
}

._1kIl6q_carousel-control-prev, ._1kIl6q_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_carousel-control-prev, ._1kIl6q_carousel-control-next {
    transition: none;
  }
}

._1kIl6q_carousel-control-prev:hover, ._1kIl6q_carousel-control-prev:focus, ._1kIl6q_carousel-control-next:hover, ._1kIl6q_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

._1kIl6q_carousel-control-prev {
  left: 0;
}

._1kIl6q_carousel-control-next {
  right: 0;
}

._1kIl6q_carousel-control-prev-icon, ._1kIl6q_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

._1kIl6q_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

._1kIl6q_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

._1kIl6q_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

._1kIl6q_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

._1kIl6q_carousel-indicators ._1kIl6q_active {
  opacity: 1;
}

._1kIl6q_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

._1kIl6q_carousel-dark ._1kIl6q_carousel-control-prev-icon, ._1kIl6q_carousel-dark ._1kIl6q_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

._1kIl6q_carousel-dark ._1kIl6q_carousel-indicators [data-bs-target] {
  background-color: #000;
}

._1kIl6q_carousel-dark ._1kIl6q_carousel-caption {
  color: #000;
}

._1kIl6q_spinner-grow, ._1kIl6q_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes _1kIl6q_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

._1kIl6q_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

._1kIl6q_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes _1kIl6q_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

._1kIl6q_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

._1kIl6q_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_spinner-border, ._1kIl6q_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

._1kIl6q_offcanvas, ._1kIl6q_offcanvas-xxl, ._1kIl6q_offcanvas-xl, ._1kIl6q_offcanvas-lg, ._1kIl6q_offcanvas-md, ._1kIl6q_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  ._1kIl6q_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  ._1kIl6q_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  ._1kIl6q_offcanvas-sm._1kIl6q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._1kIl6q_offcanvas-sm._1kIl6q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._1kIl6q_offcanvas-sm._1kIl6q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._1kIl6q_offcanvas-sm._1kIl6q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._1kIl6q_offcanvas-sm._1kIl6q_showing, ._1kIl6q_offcanvas-sm._1kIl6q_show:not(._1kIl6q_hiding) {
    transform: none;
  }

  ._1kIl6q_offcanvas-sm._1kIl6q_showing, ._1kIl6q_offcanvas-sm._1kIl6q_hiding, ._1kIl6q_offcanvas-sm._1kIl6q_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  ._1kIl6q_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._1kIl6q_offcanvas-sm ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_offcanvas-sm ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  ._1kIl6q_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  ._1kIl6q_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  ._1kIl6q_offcanvas-md._1kIl6q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._1kIl6q_offcanvas-md._1kIl6q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._1kIl6q_offcanvas-md._1kIl6q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._1kIl6q_offcanvas-md._1kIl6q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._1kIl6q_offcanvas-md._1kIl6q_showing, ._1kIl6q_offcanvas-md._1kIl6q_show:not(._1kIl6q_hiding) {
    transform: none;
  }

  ._1kIl6q_offcanvas-md._1kIl6q_showing, ._1kIl6q_offcanvas-md._1kIl6q_hiding, ._1kIl6q_offcanvas-md._1kIl6q_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._1kIl6q_offcanvas-md ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_offcanvas-md ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  ._1kIl6q_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  ._1kIl6q_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  ._1kIl6q_offcanvas-lg._1kIl6q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._1kIl6q_offcanvas-lg._1kIl6q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._1kIl6q_offcanvas-lg._1kIl6q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._1kIl6q_offcanvas-lg._1kIl6q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._1kIl6q_offcanvas-lg._1kIl6q_showing, ._1kIl6q_offcanvas-lg._1kIl6q_show:not(._1kIl6q_hiding) {
    transform: none;
  }

  ._1kIl6q_offcanvas-lg._1kIl6q_showing, ._1kIl6q_offcanvas-lg._1kIl6q_hiding, ._1kIl6q_offcanvas-lg._1kIl6q_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._1kIl6q_offcanvas-lg ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_offcanvas-lg ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  ._1kIl6q_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  ._1kIl6q_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  ._1kIl6q_offcanvas-xl._1kIl6q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._1kIl6q_offcanvas-xl._1kIl6q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._1kIl6q_offcanvas-xl._1kIl6q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._1kIl6q_offcanvas-xl._1kIl6q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._1kIl6q_offcanvas-xl._1kIl6q_showing, ._1kIl6q_offcanvas-xl._1kIl6q_show:not(._1kIl6q_hiding) {
    transform: none;
  }

  ._1kIl6q_offcanvas-xl._1kIl6q_showing, ._1kIl6q_offcanvas-xl._1kIl6q_hiding, ._1kIl6q_offcanvas-xl._1kIl6q_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._1kIl6q_offcanvas-xl ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_offcanvas-xl ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  ._1kIl6q_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  ._1kIl6q_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  ._1kIl6q_offcanvas-xxl._1kIl6q_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._1kIl6q_offcanvas-xxl._1kIl6q_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._1kIl6q_offcanvas-xxl._1kIl6q_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._1kIl6q_offcanvas-xxl._1kIl6q_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._1kIl6q_offcanvas-xxl._1kIl6q_showing, ._1kIl6q_offcanvas-xxl._1kIl6q_show:not(._1kIl6q_hiding) {
    transform: none;
  }

  ._1kIl6q_offcanvas-xxl._1kIl6q_showing, ._1kIl6q_offcanvas-xxl._1kIl6q_hiding, ._1kIl6q_offcanvas-xxl._1kIl6q_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._1kIl6q_offcanvas-xxl ._1kIl6q_offcanvas-header {
    display: none;
  }

  ._1kIl6q_offcanvas-xxl ._1kIl6q_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

._1kIl6q_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._1kIl6q_offcanvas {
    transition: none;
  }
}

._1kIl6q_offcanvas._1kIl6q_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

._1kIl6q_offcanvas._1kIl6q_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

._1kIl6q_offcanvas._1kIl6q_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

._1kIl6q_offcanvas._1kIl6q_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

._1kIl6q_offcanvas._1kIl6q_showing, ._1kIl6q_offcanvas._1kIl6q_show:not(._1kIl6q_hiding) {
  transform: none;
}

._1kIl6q_offcanvas._1kIl6q_showing, ._1kIl6q_offcanvas._1kIl6q_hiding, ._1kIl6q_offcanvas._1kIl6q_show {
  visibility: visible;
}

._1kIl6q_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

._1kIl6q_offcanvas-backdrop._1kIl6q_fade {
  opacity: 0;
}

._1kIl6q_offcanvas-backdrop._1kIl6q_show {
  opacity: .5;
}

._1kIl6q_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._1kIl6q_offcanvas-header ._1kIl6q_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

._1kIl6q_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

._1kIl6q_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

._1kIl6q_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

._1kIl6q_placeholder._1kIl6q_btn:before {
  content: "";
  display: inline-block;
}

._1kIl6q_placeholder-xs {
  min-height: .6em;
}

._1kIl6q_placeholder-sm {
  min-height: .8em;
}

._1kIl6q_placeholder-lg {
  min-height: 1.2em;
}

._1kIl6q_placeholder-glow ._1kIl6q_placeholder {
  animation: 2s ease-in-out infinite _1kIl6q_placeholder-glow;
}

@keyframes _1kIl6q_placeholder-glow {
  50% {
    opacity: .2;
  }
}

._1kIl6q_placeholder-wave {
  animation: 2s linear infinite _1kIl6q_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes _1kIl6q_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

._1kIl6q_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

._1kIl6q_text-bg-primary {
  color: #000 !important;
  background-color: RGBA(37, 142, 1, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

._1kIl6q_link-primary {
  color: #258e01 !important;
}

._1kIl6q_link-primary:hover, ._1kIl6q_link-primary:focus {
  color: #51a534 !important;
}

._1kIl6q_link-secondary {
  color: #6c757d !important;
}

._1kIl6q_link-secondary:hover, ._1kIl6q_link-secondary:focus {
  color: #565e64 !important;
}

._1kIl6q_link-success {
  color: #198754 !important;
}

._1kIl6q_link-success:hover, ._1kIl6q_link-success:focus {
  color: #146c43 !important;
}

._1kIl6q_link-info {
  color: #0dcaf0 !important;
}

._1kIl6q_link-info:hover, ._1kIl6q_link-info:focus {
  color: #3dd5f3 !important;
}

._1kIl6q_link-warning {
  color: #ffc107 !important;
}

._1kIl6q_link-warning:hover, ._1kIl6q_link-warning:focus {
  color: #ffcd39 !important;
}

._1kIl6q_link-danger {
  color: #dc3545 !important;
}

._1kIl6q_link-danger:hover, ._1kIl6q_link-danger:focus {
  color: #b02a37 !important;
}

._1kIl6q_link-light {
  color: #f8f9fa !important;
}

._1kIl6q_link-light:hover, ._1kIl6q_link-light:focus {
  color: #f9fafb !important;
}

._1kIl6q_link-dark {
  color: #212529 !important;
}

._1kIl6q_link-dark:hover, ._1kIl6q_link-dark:focus {
  color: #1a1e21 !important;
}

._1kIl6q_ratio {
  width: 100%;
  position: relative;
}

._1kIl6q_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

._1kIl6q_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._1kIl6q_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

._1kIl6q_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

._1kIl6q_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

._1kIl6q_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

._1kIl6q_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._1kIl6q_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

._1kIl6q_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

._1kIl6q_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  ._1kIl6q_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._1kIl6q_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._1kIl6q_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._1kIl6q_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._1kIl6q_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._1kIl6q_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

._1kIl6q_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

._1kIl6q_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

._1kIl6q_visually-hidden, ._1kIl6q_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

._1kIl6q_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

._1kIl6q_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._1kIl6q_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

._1kIl6q_align-baseline {
  vertical-align: baseline !important;
}

._1kIl6q_align-top {
  vertical-align: top !important;
}

._1kIl6q_align-middle {
  vertical-align: middle !important;
}

._1kIl6q_align-bottom {
  vertical-align: bottom !important;
}

._1kIl6q_align-text-bottom {
  vertical-align: text-bottom !important;
}

._1kIl6q_align-text-top {
  vertical-align: text-top !important;
}

._1kIl6q_float-start {
  float: left !important;
}

._1kIl6q_float-end {
  float: right !important;
}

._1kIl6q_float-none {
  float: none !important;
}

._1kIl6q_opacity-0 {
  opacity: 0 !important;
}

._1kIl6q_opacity-25 {
  opacity: .25 !important;
}

._1kIl6q_opacity-50 {
  opacity: .5 !important;
}

._1kIl6q_opacity-75 {
  opacity: .75 !important;
}

._1kIl6q_opacity-100 {
  opacity: 1 !important;
}

._1kIl6q_overflow-auto {
  overflow: auto !important;
}

._1kIl6q_overflow-hidden {
  overflow: hidden !important;
}

._1kIl6q_overflow-visible {
  overflow: visible !important;
}

._1kIl6q_overflow-scroll {
  overflow: scroll !important;
}

._1kIl6q_d-inline {
  display: inline !important;
}

._1kIl6q_d-inline-block {
  display: inline-block !important;
}

._1kIl6q_d-block {
  display: block !important;
}

._1kIl6q_d-grid {
  display: grid !important;
}

._1kIl6q_d-table {
  display: table !important;
}

._1kIl6q_d-table-row {
  display: table-row !important;
}

._1kIl6q_d-table-cell {
  display: table-cell !important;
}

._1kIl6q_d-flex {
  display: flex !important;
}

._1kIl6q_d-inline-flex {
  display: inline-flex !important;
}

._1kIl6q_d-none {
  display: none !important;
}

._1kIl6q_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

._1kIl6q_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

._1kIl6q_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

._1kIl6q_shadow-none {
  box-shadow: none !important;
}

._1kIl6q_position-static {
  position: static !important;
}

._1kIl6q_position-relative {
  position: relative !important;
}

._1kIl6q_position-absolute {
  position: absolute !important;
}

._1kIl6q_position-fixed {
  position: fixed !important;
}

._1kIl6q_position-sticky {
  position: sticky !important;
}

._1kIl6q_top-0 {
  top: 0 !important;
}

._1kIl6q_top-50 {
  top: 50% !important;
}

._1kIl6q_top-100 {
  top: 100% !important;
}

._1kIl6q_bottom-0 {
  bottom: 0 !important;
}

._1kIl6q_bottom-50 {
  bottom: 50% !important;
}

._1kIl6q_bottom-100 {
  bottom: 100% !important;
}

._1kIl6q_start-0 {
  left: 0 !important;
}

._1kIl6q_start-50 {
  left: 50% !important;
}

._1kIl6q_start-100 {
  left: 100% !important;
}

._1kIl6q_end-0 {
  right: 0 !important;
}

._1kIl6q_end-50 {
  right: 50% !important;
}

._1kIl6q_end-100 {
  right: 100% !important;
}

._1kIl6q_translate-middle {
  transform: translate(-50%, -50%) !important;
}

._1kIl6q_translate-middle-x {
  transform: translateX(-50%) !important;
}

._1kIl6q_translate-middle-y {
  transform: translateY(-50%) !important;
}

._1kIl6q_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._1kIl6q_border-0 {
  border: 0 !important;
}

._1kIl6q_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._1kIl6q_border-top-0 {
  border-top: 0 !important;
}

._1kIl6q_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._1kIl6q_border-end-0 {
  border-right: 0 !important;
}

._1kIl6q_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._1kIl6q_border-bottom-0 {
  border-bottom: 0 !important;
}

._1kIl6q_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._1kIl6q_border-start-0 {
  border-left: 0 !important;
}

._1kIl6q_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

._1kIl6q_border-1 {
  --bs-border-width: 1px;
}

._1kIl6q_border-2 {
  --bs-border-width: 2px;
}

._1kIl6q_border-3 {
  --bs-border-width: 3px;
}

._1kIl6q_border-4 {
  --bs-border-width: 4px;
}

._1kIl6q_border-5 {
  --bs-border-width: 5px;
}

._1kIl6q_border-opacity-10 {
  --bs-border-opacity: .1;
}

._1kIl6q_border-opacity-25 {
  --bs-border-opacity: .25;
}

._1kIl6q_border-opacity-50 {
  --bs-border-opacity: .5;
}

._1kIl6q_border-opacity-75 {
  --bs-border-opacity: .75;
}

._1kIl6q_border-opacity-100 {
  --bs-border-opacity: 1;
}

._1kIl6q_w-25 {
  width: 25% !important;
}

._1kIl6q_w-50 {
  width: 50% !important;
}

._1kIl6q_w-75 {
  width: 75% !important;
}

._1kIl6q_w-100 {
  width: 100% !important;
}

._1kIl6q_w-auto {
  width: auto !important;
}

._1kIl6q_mw-100 {
  max-width: 100% !important;
}

._1kIl6q_vw-100 {
  width: 100vw !important;
}

._1kIl6q_min-vw-100 {
  min-width: 100vw !important;
}

._1kIl6q_h-25 {
  height: 25% !important;
}

._1kIl6q_h-50 {
  height: 50% !important;
}

._1kIl6q_h-75 {
  height: 75% !important;
}

._1kIl6q_h-100 {
  height: 100% !important;
}

._1kIl6q_h-auto {
  height: auto !important;
}

._1kIl6q_mh-100 {
  max-height: 100% !important;
}

._1kIl6q_vh-100 {
  height: 100vh !important;
}

._1kIl6q_min-vh-100 {
  min-height: 100vh !important;
}

._1kIl6q_flex-fill {
  flex: auto !important;
}

._1kIl6q_flex-row {
  flex-direction: row !important;
}

._1kIl6q_flex-column {
  flex-direction: column !important;
}

._1kIl6q_flex-row-reverse {
  flex-direction: row-reverse !important;
}

._1kIl6q_flex-column-reverse {
  flex-direction: column-reverse !important;
}

._1kIl6q_flex-grow-0 {
  flex-grow: 0 !important;
}

._1kIl6q_flex-grow-1 {
  flex-grow: 1 !important;
}

._1kIl6q_flex-shrink-0 {
  flex-shrink: 0 !important;
}

._1kIl6q_flex-shrink-1 {
  flex-shrink: 1 !important;
}

._1kIl6q_flex-wrap {
  flex-wrap: wrap !important;
}

._1kIl6q_flex-nowrap {
  flex-wrap: nowrap !important;
}

._1kIl6q_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

._1kIl6q_justify-content-start {
  justify-content: flex-start !important;
}

._1kIl6q_justify-content-end {
  justify-content: flex-end !important;
}

._1kIl6q_justify-content-center {
  justify-content: center !important;
}

._1kIl6q_justify-content-between {
  justify-content: space-between !important;
}

._1kIl6q_justify-content-around {
  justify-content: space-around !important;
}

._1kIl6q_justify-content-evenly {
  justify-content: space-evenly !important;
}

._1kIl6q_align-items-start {
  align-items: flex-start !important;
}

._1kIl6q_align-items-end {
  align-items: flex-end !important;
}

._1kIl6q_align-items-center {
  align-items: center !important;
}

._1kIl6q_align-items-baseline {
  align-items: baseline !important;
}

._1kIl6q_align-items-stretch {
  align-items: stretch !important;
}

._1kIl6q_align-content-start {
  align-content: flex-start !important;
}

._1kIl6q_align-content-end {
  align-content: flex-end !important;
}

._1kIl6q_align-content-center {
  align-content: center !important;
}

._1kIl6q_align-content-between {
  align-content: space-between !important;
}

._1kIl6q_align-content-around {
  align-content: space-around !important;
}

._1kIl6q_align-content-stretch {
  align-content: stretch !important;
}

._1kIl6q_align-self-auto {
  align-self: auto !important;
}

._1kIl6q_align-self-start {
  align-self: flex-start !important;
}

._1kIl6q_align-self-end {
  align-self: flex-end !important;
}

._1kIl6q_align-self-center {
  align-self: center !important;
}

._1kIl6q_align-self-baseline {
  align-self: baseline !important;
}

._1kIl6q_align-self-stretch {
  align-self: stretch !important;
}

._1kIl6q_order-first {
  order: -1 !important;
}

._1kIl6q_order-0 {
  order: 0 !important;
}

._1kIl6q_order-1 {
  order: 1 !important;
}

._1kIl6q_order-2 {
  order: 2 !important;
}

._1kIl6q_order-3 {
  order: 3 !important;
}

._1kIl6q_order-4 {
  order: 4 !important;
}

._1kIl6q_order-5 {
  order: 5 !important;
}

._1kIl6q_order-last {
  order: 6 !important;
}

._1kIl6q_m-0 {
  margin: 0 !important;
}

._1kIl6q_m-1 {
  margin: .25rem !important;
}

._1kIl6q_m-2 {
  margin: .5rem !important;
}

._1kIl6q_m-3 {
  margin: 1rem !important;
}

._1kIl6q_m-4 {
  margin: 1.5rem !important;
}

._1kIl6q_m-5 {
  margin: 3rem !important;
}

._1kIl6q_m-auto {
  margin: auto !important;
}

._1kIl6q_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

._1kIl6q_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

._1kIl6q_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

._1kIl6q_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

._1kIl6q_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

._1kIl6q_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

._1kIl6q_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

._1kIl6q_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

._1kIl6q_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

._1kIl6q_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

._1kIl6q_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

._1kIl6q_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

._1kIl6q_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

._1kIl6q_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

._1kIl6q_mt-0 {
  margin-top: 0 !important;
}

._1kIl6q_mt-1 {
  margin-top: .25rem !important;
}

._1kIl6q_mt-2 {
  margin-top: .5rem !important;
}

._1kIl6q_mt-3 {
  margin-top: 1rem !important;
}

._1kIl6q_mt-4 {
  margin-top: 1.5rem !important;
}

._1kIl6q_mt-5 {
  margin-top: 3rem !important;
}

._1kIl6q_mt-auto {
  margin-top: auto !important;
}

._1kIl6q_me-0 {
  margin-right: 0 !important;
}

._1kIl6q_me-1 {
  margin-right: .25rem !important;
}

._1kIl6q_me-2 {
  margin-right: .5rem !important;
}

._1kIl6q_me-3 {
  margin-right: 1rem !important;
}

._1kIl6q_me-4 {
  margin-right: 1.5rem !important;
}

._1kIl6q_me-5 {
  margin-right: 3rem !important;
}

._1kIl6q_me-auto {
  margin-right: auto !important;
}

._1kIl6q_mb-0 {
  margin-bottom: 0 !important;
}

._1kIl6q_mb-1 {
  margin-bottom: .25rem !important;
}

._1kIl6q_mb-2 {
  margin-bottom: .5rem !important;
}

._1kIl6q_mb-3 {
  margin-bottom: 1rem !important;
}

._1kIl6q_mb-4 {
  margin-bottom: 1.5rem !important;
}

._1kIl6q_mb-5 {
  margin-bottom: 3rem !important;
}

._1kIl6q_mb-auto {
  margin-bottom: auto !important;
}

._1kIl6q_ms-0 {
  margin-left: 0 !important;
}

._1kIl6q_ms-1 {
  margin-left: .25rem !important;
}

._1kIl6q_ms-2 {
  margin-left: .5rem !important;
}

._1kIl6q_ms-3 {
  margin-left: 1rem !important;
}

._1kIl6q_ms-4 {
  margin-left: 1.5rem !important;
}

._1kIl6q_ms-5 {
  margin-left: 3rem !important;
}

._1kIl6q_ms-auto {
  margin-left: auto !important;
}

._1kIl6q_p-0 {
  padding: 0 !important;
}

._1kIl6q_p-1 {
  padding: .25rem !important;
}

._1kIl6q_p-2 {
  padding: .5rem !important;
}

._1kIl6q_p-3 {
  padding: 1rem !important;
}

._1kIl6q_p-4 {
  padding: 1.5rem !important;
}

._1kIl6q_p-5 {
  padding: 3rem !important;
}

._1kIl6q_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

._1kIl6q_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

._1kIl6q_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

._1kIl6q_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

._1kIl6q_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

._1kIl6q_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

._1kIl6q_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

._1kIl6q_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

._1kIl6q_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

._1kIl6q_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

._1kIl6q_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

._1kIl6q_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

._1kIl6q_pt-0 {
  padding-top: 0 !important;
}

._1kIl6q_pt-1 {
  padding-top: .25rem !important;
}

._1kIl6q_pt-2 {
  padding-top: .5rem !important;
}

._1kIl6q_pt-3 {
  padding-top: 1rem !important;
}

._1kIl6q_pt-4 {
  padding-top: 1.5rem !important;
}

._1kIl6q_pt-5 {
  padding-top: 3rem !important;
}

._1kIl6q_pe-0 {
  padding-right: 0 !important;
}

._1kIl6q_pe-1 {
  padding-right: .25rem !important;
}

._1kIl6q_pe-2 {
  padding-right: .5rem !important;
}

._1kIl6q_pe-3 {
  padding-right: 1rem !important;
}

._1kIl6q_pe-4 {
  padding-right: 1.5rem !important;
}

._1kIl6q_pe-5 {
  padding-right: 3rem !important;
}

._1kIl6q_pb-0 {
  padding-bottom: 0 !important;
}

._1kIl6q_pb-1 {
  padding-bottom: .25rem !important;
}

._1kIl6q_pb-2 {
  padding-bottom: .5rem !important;
}

._1kIl6q_pb-3 {
  padding-bottom: 1rem !important;
}

._1kIl6q_pb-4 {
  padding-bottom: 1.5rem !important;
}

._1kIl6q_pb-5 {
  padding-bottom: 3rem !important;
}

._1kIl6q_ps-0 {
  padding-left: 0 !important;
}

._1kIl6q_ps-1 {
  padding-left: .25rem !important;
}

._1kIl6q_ps-2 {
  padding-left: .5rem !important;
}

._1kIl6q_ps-3 {
  padding-left: 1rem !important;
}

._1kIl6q_ps-4 {
  padding-left: 1.5rem !important;
}

._1kIl6q_ps-5 {
  padding-left: 3rem !important;
}

._1kIl6q_gap-0 {
  gap: 0 !important;
}

._1kIl6q_gap-1 {
  gap: .25rem !important;
}

._1kIl6q_gap-2 {
  gap: .5rem !important;
}

._1kIl6q_gap-3 {
  gap: 1rem !important;
}

._1kIl6q_gap-4 {
  gap: 1.5rem !important;
}

._1kIl6q_gap-5 {
  gap: 3rem !important;
}

._1kIl6q_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

._1kIl6q_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

._1kIl6q_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

._1kIl6q_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

._1kIl6q_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

._1kIl6q_fs-5 {
  font-size: 1.25rem !important;
}

._1kIl6q_fs-6 {
  font-size: 1rem !important;
}

._1kIl6q_fst-italic {
  font-style: italic !important;
}

._1kIl6q_fst-normal {
  font-style: normal !important;
}

._1kIl6q_fw-light {
  font-weight: 300 !important;
}

._1kIl6q_fw-lighter {
  font-weight: lighter !important;
}

._1kIl6q_fw-normal {
  font-weight: 400 !important;
}

._1kIl6q_fw-bold {
  font-weight: 700 !important;
}

._1kIl6q_fw-semibold {
  font-weight: 600 !important;
}

._1kIl6q_fw-bolder {
  font-weight: bolder !important;
}

._1kIl6q_lh-1 {
  line-height: 1 !important;
}

._1kIl6q_lh-sm {
  line-height: 1.25 !important;
}

._1kIl6q_lh-base {
  line-height: 1.5 !important;
}

._1kIl6q_lh-lg {
  line-height: 2 !important;
}

._1kIl6q_text-start {
  text-align: left !important;
}

._1kIl6q_text-end {
  text-align: right !important;
}

._1kIl6q_text-center {
  text-align: center !important;
}

._1kIl6q_text-decoration-none {
  text-decoration: none !important;
}

._1kIl6q_text-decoration-underline {
  text-decoration: underline !important;
}

._1kIl6q_text-decoration-line-through {
  text-decoration: line-through !important;
}

._1kIl6q_text-lowercase {
  text-transform: lowercase !important;
}

._1kIl6q_text-uppercase {
  text-transform: uppercase !important;
}

._1kIl6q_text-capitalize {
  text-transform: capitalize !important;
}

._1kIl6q_text-wrap {
  white-space: normal !important;
}

._1kIl6q_text-nowrap {
  white-space: nowrap !important;
}

._1kIl6q_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

._1kIl6q_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

._1kIl6q_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

._1kIl6q_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

._1kIl6q_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

._1kIl6q_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

._1kIl6q_text-opacity-25 {
  --bs-text-opacity: .25;
}

._1kIl6q_text-opacity-50 {
  --bs-text-opacity: .5;
}

._1kIl6q_text-opacity-75 {
  --bs-text-opacity: .75;
}

._1kIl6q_text-opacity-100 {
  --bs-text-opacity: 1;
}

._1kIl6q_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

._1kIl6q_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

._1kIl6q_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

._1kIl6q_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

._1kIl6q_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

._1kIl6q_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

._1kIl6q_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

._1kIl6q_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

._1kIl6q_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

._1kIl6q_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

._1kIl6q_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

._1kIl6q_pe-none {
  pointer-events: none !important;
}

._1kIl6q_pe-auto {
  pointer-events: auto !important;
}

._1kIl6q_rounded {
  border-radius: var(--bs-border-radius) !important;
}

._1kIl6q_rounded-0 {
  border-radius: 0 !important;
}

._1kIl6q_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

._1kIl6q_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

._1kIl6q_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

._1kIl6q_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

._1kIl6q_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

._1kIl6q_rounded-circle {
  border-radius: 50% !important;
}

._1kIl6q_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

._1kIl6q_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

._1kIl6q_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

._1kIl6q_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

._1kIl6q_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

._1kIl6q_visible {
  visibility: visible !important;
}

._1kIl6q_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  ._1kIl6q_float-sm-start {
    float: left !important;
  }

  ._1kIl6q_float-sm-end {
    float: right !important;
  }

  ._1kIl6q_float-sm-none {
    float: none !important;
  }

  ._1kIl6q_d-sm-inline {
    display: inline !important;
  }

  ._1kIl6q_d-sm-inline-block {
    display: inline-block !important;
  }

  ._1kIl6q_d-sm-block {
    display: block !important;
  }

  ._1kIl6q_d-sm-grid {
    display: grid !important;
  }

  ._1kIl6q_d-sm-table {
    display: table !important;
  }

  ._1kIl6q_d-sm-table-row {
    display: table-row !important;
  }

  ._1kIl6q_d-sm-table-cell {
    display: table-cell !important;
  }

  ._1kIl6q_d-sm-flex {
    display: flex !important;
  }

  ._1kIl6q_d-sm-inline-flex {
    display: inline-flex !important;
  }

  ._1kIl6q_d-sm-none {
    display: none !important;
  }

  ._1kIl6q_flex-sm-fill {
    flex: auto !important;
  }

  ._1kIl6q_flex-sm-row {
    flex-direction: row !important;
  }

  ._1kIl6q_flex-sm-column {
    flex-direction: column !important;
  }

  ._1kIl6q_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._1kIl6q_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._1kIl6q_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  ._1kIl6q_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  ._1kIl6q_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._1kIl6q_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._1kIl6q_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  ._1kIl6q_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  ._1kIl6q_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._1kIl6q_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  ._1kIl6q_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  ._1kIl6q_justify-content-sm-center {
    justify-content: center !important;
  }

  ._1kIl6q_justify-content-sm-between {
    justify-content: space-between !important;
  }

  ._1kIl6q_justify-content-sm-around {
    justify-content: space-around !important;
  }

  ._1kIl6q_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  ._1kIl6q_align-items-sm-start {
    align-items: flex-start !important;
  }

  ._1kIl6q_align-items-sm-end {
    align-items: flex-end !important;
  }

  ._1kIl6q_align-items-sm-center {
    align-items: center !important;
  }

  ._1kIl6q_align-items-sm-baseline {
    align-items: baseline !important;
  }

  ._1kIl6q_align-items-sm-stretch {
    align-items: stretch !important;
  }

  ._1kIl6q_align-content-sm-start {
    align-content: flex-start !important;
  }

  ._1kIl6q_align-content-sm-end {
    align-content: flex-end !important;
  }

  ._1kIl6q_align-content-sm-center {
    align-content: center !important;
  }

  ._1kIl6q_align-content-sm-between {
    align-content: space-between !important;
  }

  ._1kIl6q_align-content-sm-around {
    align-content: space-around !important;
  }

  ._1kIl6q_align-content-sm-stretch {
    align-content: stretch !important;
  }

  ._1kIl6q_align-self-sm-auto {
    align-self: auto !important;
  }

  ._1kIl6q_align-self-sm-start {
    align-self: flex-start !important;
  }

  ._1kIl6q_align-self-sm-end {
    align-self: flex-end !important;
  }

  ._1kIl6q_align-self-sm-center {
    align-self: center !important;
  }

  ._1kIl6q_align-self-sm-baseline {
    align-self: baseline !important;
  }

  ._1kIl6q_align-self-sm-stretch {
    align-self: stretch !important;
  }

  ._1kIl6q_order-sm-first {
    order: -1 !important;
  }

  ._1kIl6q_order-sm-0 {
    order: 0 !important;
  }

  ._1kIl6q_order-sm-1 {
    order: 1 !important;
  }

  ._1kIl6q_order-sm-2 {
    order: 2 !important;
  }

  ._1kIl6q_order-sm-3 {
    order: 3 !important;
  }

  ._1kIl6q_order-sm-4 {
    order: 4 !important;
  }

  ._1kIl6q_order-sm-5 {
    order: 5 !important;
  }

  ._1kIl6q_order-sm-last {
    order: 6 !important;
  }

  ._1kIl6q_m-sm-0 {
    margin: 0 !important;
  }

  ._1kIl6q_m-sm-1 {
    margin: .25rem !important;
  }

  ._1kIl6q_m-sm-2 {
    margin: .5rem !important;
  }

  ._1kIl6q_m-sm-3 {
    margin: 1rem !important;
  }

  ._1kIl6q_m-sm-4 {
    margin: 1.5rem !important;
  }

  ._1kIl6q_m-sm-5 {
    margin: 3rem !important;
  }

  ._1kIl6q_m-sm-auto {
    margin: auto !important;
  }

  ._1kIl6q_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._1kIl6q_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._1kIl6q_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._1kIl6q_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._1kIl6q_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._1kIl6q_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._1kIl6q_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._1kIl6q_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._1kIl6q_mt-sm-0 {
    margin-top: 0 !important;
  }

  ._1kIl6q_mt-sm-1 {
    margin-top: .25rem !important;
  }

  ._1kIl6q_mt-sm-2 {
    margin-top: .5rem !important;
  }

  ._1kIl6q_mt-sm-3 {
    margin-top: 1rem !important;
  }

  ._1kIl6q_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  ._1kIl6q_mt-sm-5 {
    margin-top: 3rem !important;
  }

  ._1kIl6q_mt-sm-auto {
    margin-top: auto !important;
  }

  ._1kIl6q_me-sm-0 {
    margin-right: 0 !important;
  }

  ._1kIl6q_me-sm-1 {
    margin-right: .25rem !important;
  }

  ._1kIl6q_me-sm-2 {
    margin-right: .5rem !important;
  }

  ._1kIl6q_me-sm-3 {
    margin-right: 1rem !important;
  }

  ._1kIl6q_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_me-sm-5 {
    margin-right: 3rem !important;
  }

  ._1kIl6q_me-sm-auto {
    margin-right: auto !important;
  }

  ._1kIl6q_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  ._1kIl6q_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_mb-sm-auto {
    margin-bottom: auto !important;
  }

  ._1kIl6q_ms-sm-0 {
    margin-left: 0 !important;
  }

  ._1kIl6q_ms-sm-1 {
    margin-left: .25rem !important;
  }

  ._1kIl6q_ms-sm-2 {
    margin-left: .5rem !important;
  }

  ._1kIl6q_ms-sm-3 {
    margin-left: 1rem !important;
  }

  ._1kIl6q_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  ._1kIl6q_ms-sm-5 {
    margin-left: 3rem !important;
  }

  ._1kIl6q_ms-sm-auto {
    margin-left: auto !important;
  }

  ._1kIl6q_p-sm-0 {
    padding: 0 !important;
  }

  ._1kIl6q_p-sm-1 {
    padding: .25rem !important;
  }

  ._1kIl6q_p-sm-2 {
    padding: .5rem !important;
  }

  ._1kIl6q_p-sm-3 {
    padding: 1rem !important;
  }

  ._1kIl6q_p-sm-4 {
    padding: 1.5rem !important;
  }

  ._1kIl6q_p-sm-5 {
    padding: 3rem !important;
  }

  ._1kIl6q_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._1kIl6q_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._1kIl6q_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._1kIl6q_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._1kIl6q_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._1kIl6q_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._1kIl6q_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_pt-sm-0 {
    padding-top: 0 !important;
  }

  ._1kIl6q_pt-sm-1 {
    padding-top: .25rem !important;
  }

  ._1kIl6q_pt-sm-2 {
    padding-top: .5rem !important;
  }

  ._1kIl6q_pt-sm-3 {
    padding-top: 1rem !important;
  }

  ._1kIl6q_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  ._1kIl6q_pt-sm-5 {
    padding-top: 3rem !important;
  }

  ._1kIl6q_pe-sm-0 {
    padding-right: 0 !important;
  }

  ._1kIl6q_pe-sm-1 {
    padding-right: .25rem !important;
  }

  ._1kIl6q_pe-sm-2 {
    padding-right: .5rem !important;
  }

  ._1kIl6q_pe-sm-3 {
    padding-right: 1rem !important;
  }

  ._1kIl6q_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_pe-sm-5 {
    padding-right: 3rem !important;
  }

  ._1kIl6q_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  ._1kIl6q_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_ps-sm-0 {
    padding-left: 0 !important;
  }

  ._1kIl6q_ps-sm-1 {
    padding-left: .25rem !important;
  }

  ._1kIl6q_ps-sm-2 {
    padding-left: .5rem !important;
  }

  ._1kIl6q_ps-sm-3 {
    padding-left: 1rem !important;
  }

  ._1kIl6q_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  ._1kIl6q_ps-sm-5 {
    padding-left: 3rem !important;
  }

  ._1kIl6q_gap-sm-0 {
    gap: 0 !important;
  }

  ._1kIl6q_gap-sm-1 {
    gap: .25rem !important;
  }

  ._1kIl6q_gap-sm-2 {
    gap: .5rem !important;
  }

  ._1kIl6q_gap-sm-3 {
    gap: 1rem !important;
  }

  ._1kIl6q_gap-sm-4 {
    gap: 1.5rem !important;
  }

  ._1kIl6q_gap-sm-5 {
    gap: 3rem !important;
  }

  ._1kIl6q_text-sm-start {
    text-align: left !important;
  }

  ._1kIl6q_text-sm-end {
    text-align: right !important;
  }

  ._1kIl6q_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  ._1kIl6q_float-md-start {
    float: left !important;
  }

  ._1kIl6q_float-md-end {
    float: right !important;
  }

  ._1kIl6q_float-md-none {
    float: none !important;
  }

  ._1kIl6q_d-md-inline {
    display: inline !important;
  }

  ._1kIl6q_d-md-inline-block {
    display: inline-block !important;
  }

  ._1kIl6q_d-md-block {
    display: block !important;
  }

  ._1kIl6q_d-md-grid {
    display: grid !important;
  }

  ._1kIl6q_d-md-table {
    display: table !important;
  }

  ._1kIl6q_d-md-table-row {
    display: table-row !important;
  }

  ._1kIl6q_d-md-table-cell {
    display: table-cell !important;
  }

  ._1kIl6q_d-md-flex {
    display: flex !important;
  }

  ._1kIl6q_d-md-inline-flex {
    display: inline-flex !important;
  }

  ._1kIl6q_d-md-none {
    display: none !important;
  }

  ._1kIl6q_flex-md-fill {
    flex: auto !important;
  }

  ._1kIl6q_flex-md-row {
    flex-direction: row !important;
  }

  ._1kIl6q_flex-md-column {
    flex-direction: column !important;
  }

  ._1kIl6q_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._1kIl6q_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._1kIl6q_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  ._1kIl6q_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  ._1kIl6q_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._1kIl6q_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._1kIl6q_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  ._1kIl6q_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  ._1kIl6q_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._1kIl6q_justify-content-md-start {
    justify-content: flex-start !important;
  }

  ._1kIl6q_justify-content-md-end {
    justify-content: flex-end !important;
  }

  ._1kIl6q_justify-content-md-center {
    justify-content: center !important;
  }

  ._1kIl6q_justify-content-md-between {
    justify-content: space-between !important;
  }

  ._1kIl6q_justify-content-md-around {
    justify-content: space-around !important;
  }

  ._1kIl6q_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  ._1kIl6q_align-items-md-start {
    align-items: flex-start !important;
  }

  ._1kIl6q_align-items-md-end {
    align-items: flex-end !important;
  }

  ._1kIl6q_align-items-md-center {
    align-items: center !important;
  }

  ._1kIl6q_align-items-md-baseline {
    align-items: baseline !important;
  }

  ._1kIl6q_align-items-md-stretch {
    align-items: stretch !important;
  }

  ._1kIl6q_align-content-md-start {
    align-content: flex-start !important;
  }

  ._1kIl6q_align-content-md-end {
    align-content: flex-end !important;
  }

  ._1kIl6q_align-content-md-center {
    align-content: center !important;
  }

  ._1kIl6q_align-content-md-between {
    align-content: space-between !important;
  }

  ._1kIl6q_align-content-md-around {
    align-content: space-around !important;
  }

  ._1kIl6q_align-content-md-stretch {
    align-content: stretch !important;
  }

  ._1kIl6q_align-self-md-auto {
    align-self: auto !important;
  }

  ._1kIl6q_align-self-md-start {
    align-self: flex-start !important;
  }

  ._1kIl6q_align-self-md-end {
    align-self: flex-end !important;
  }

  ._1kIl6q_align-self-md-center {
    align-self: center !important;
  }

  ._1kIl6q_align-self-md-baseline {
    align-self: baseline !important;
  }

  ._1kIl6q_align-self-md-stretch {
    align-self: stretch !important;
  }

  ._1kIl6q_order-md-first {
    order: -1 !important;
  }

  ._1kIl6q_order-md-0 {
    order: 0 !important;
  }

  ._1kIl6q_order-md-1 {
    order: 1 !important;
  }

  ._1kIl6q_order-md-2 {
    order: 2 !important;
  }

  ._1kIl6q_order-md-3 {
    order: 3 !important;
  }

  ._1kIl6q_order-md-4 {
    order: 4 !important;
  }

  ._1kIl6q_order-md-5 {
    order: 5 !important;
  }

  ._1kIl6q_order-md-last {
    order: 6 !important;
  }

  ._1kIl6q_m-md-0 {
    margin: 0 !important;
  }

  ._1kIl6q_m-md-1 {
    margin: .25rem !important;
  }

  ._1kIl6q_m-md-2 {
    margin: .5rem !important;
  }

  ._1kIl6q_m-md-3 {
    margin: 1rem !important;
  }

  ._1kIl6q_m-md-4 {
    margin: 1.5rem !important;
  }

  ._1kIl6q_m-md-5 {
    margin: 3rem !important;
  }

  ._1kIl6q_m-md-auto {
    margin: auto !important;
  }

  ._1kIl6q_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._1kIl6q_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._1kIl6q_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._1kIl6q_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._1kIl6q_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._1kIl6q_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._1kIl6q_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._1kIl6q_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._1kIl6q_mt-md-0 {
    margin-top: 0 !important;
  }

  ._1kIl6q_mt-md-1 {
    margin-top: .25rem !important;
  }

  ._1kIl6q_mt-md-2 {
    margin-top: .5rem !important;
  }

  ._1kIl6q_mt-md-3 {
    margin-top: 1rem !important;
  }

  ._1kIl6q_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  ._1kIl6q_mt-md-5 {
    margin-top: 3rem !important;
  }

  ._1kIl6q_mt-md-auto {
    margin-top: auto !important;
  }

  ._1kIl6q_me-md-0 {
    margin-right: 0 !important;
  }

  ._1kIl6q_me-md-1 {
    margin-right: .25rem !important;
  }

  ._1kIl6q_me-md-2 {
    margin-right: .5rem !important;
  }

  ._1kIl6q_me-md-3 {
    margin-right: 1rem !important;
  }

  ._1kIl6q_me-md-4 {
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_me-md-5 {
    margin-right: 3rem !important;
  }

  ._1kIl6q_me-md-auto {
    margin-right: auto !important;
  }

  ._1kIl6q_mb-md-0 {
    margin-bottom: 0 !important;
  }

  ._1kIl6q_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_mb-md-auto {
    margin-bottom: auto !important;
  }

  ._1kIl6q_ms-md-0 {
    margin-left: 0 !important;
  }

  ._1kIl6q_ms-md-1 {
    margin-left: .25rem !important;
  }

  ._1kIl6q_ms-md-2 {
    margin-left: .5rem !important;
  }

  ._1kIl6q_ms-md-3 {
    margin-left: 1rem !important;
  }

  ._1kIl6q_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  ._1kIl6q_ms-md-5 {
    margin-left: 3rem !important;
  }

  ._1kIl6q_ms-md-auto {
    margin-left: auto !important;
  }

  ._1kIl6q_p-md-0 {
    padding: 0 !important;
  }

  ._1kIl6q_p-md-1 {
    padding: .25rem !important;
  }

  ._1kIl6q_p-md-2 {
    padding: .5rem !important;
  }

  ._1kIl6q_p-md-3 {
    padding: 1rem !important;
  }

  ._1kIl6q_p-md-4 {
    padding: 1.5rem !important;
  }

  ._1kIl6q_p-md-5 {
    padding: 3rem !important;
  }

  ._1kIl6q_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._1kIl6q_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._1kIl6q_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._1kIl6q_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._1kIl6q_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._1kIl6q_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._1kIl6q_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_pt-md-0 {
    padding-top: 0 !important;
  }

  ._1kIl6q_pt-md-1 {
    padding-top: .25rem !important;
  }

  ._1kIl6q_pt-md-2 {
    padding-top: .5rem !important;
  }

  ._1kIl6q_pt-md-3 {
    padding-top: 1rem !important;
  }

  ._1kIl6q_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  ._1kIl6q_pt-md-5 {
    padding-top: 3rem !important;
  }

  ._1kIl6q_pe-md-0 {
    padding-right: 0 !important;
  }

  ._1kIl6q_pe-md-1 {
    padding-right: .25rem !important;
  }

  ._1kIl6q_pe-md-2 {
    padding-right: .5rem !important;
  }

  ._1kIl6q_pe-md-3 {
    padding-right: 1rem !important;
  }

  ._1kIl6q_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_pe-md-5 {
    padding-right: 3rem !important;
  }

  ._1kIl6q_pb-md-0 {
    padding-bottom: 0 !important;
  }

  ._1kIl6q_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_ps-md-0 {
    padding-left: 0 !important;
  }

  ._1kIl6q_ps-md-1 {
    padding-left: .25rem !important;
  }

  ._1kIl6q_ps-md-2 {
    padding-left: .5rem !important;
  }

  ._1kIl6q_ps-md-3 {
    padding-left: 1rem !important;
  }

  ._1kIl6q_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  ._1kIl6q_ps-md-5 {
    padding-left: 3rem !important;
  }

  ._1kIl6q_gap-md-0 {
    gap: 0 !important;
  }

  ._1kIl6q_gap-md-1 {
    gap: .25rem !important;
  }

  ._1kIl6q_gap-md-2 {
    gap: .5rem !important;
  }

  ._1kIl6q_gap-md-3 {
    gap: 1rem !important;
  }

  ._1kIl6q_gap-md-4 {
    gap: 1.5rem !important;
  }

  ._1kIl6q_gap-md-5 {
    gap: 3rem !important;
  }

  ._1kIl6q_text-md-start {
    text-align: left !important;
  }

  ._1kIl6q_text-md-end {
    text-align: right !important;
  }

  ._1kIl6q_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  ._1kIl6q_float-lg-start {
    float: left !important;
  }

  ._1kIl6q_float-lg-end {
    float: right !important;
  }

  ._1kIl6q_float-lg-none {
    float: none !important;
  }

  ._1kIl6q_d-lg-inline {
    display: inline !important;
  }

  ._1kIl6q_d-lg-inline-block {
    display: inline-block !important;
  }

  ._1kIl6q_d-lg-block {
    display: block !important;
  }

  ._1kIl6q_d-lg-grid {
    display: grid !important;
  }

  ._1kIl6q_d-lg-table {
    display: table !important;
  }

  ._1kIl6q_d-lg-table-row {
    display: table-row !important;
  }

  ._1kIl6q_d-lg-table-cell {
    display: table-cell !important;
  }

  ._1kIl6q_d-lg-flex {
    display: flex !important;
  }

  ._1kIl6q_d-lg-inline-flex {
    display: inline-flex !important;
  }

  ._1kIl6q_d-lg-none {
    display: none !important;
  }

  ._1kIl6q_flex-lg-fill {
    flex: auto !important;
  }

  ._1kIl6q_flex-lg-row {
    flex-direction: row !important;
  }

  ._1kIl6q_flex-lg-column {
    flex-direction: column !important;
  }

  ._1kIl6q_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._1kIl6q_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._1kIl6q_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  ._1kIl6q_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  ._1kIl6q_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._1kIl6q_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._1kIl6q_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  ._1kIl6q_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  ._1kIl6q_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._1kIl6q_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  ._1kIl6q_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  ._1kIl6q_justify-content-lg-center {
    justify-content: center !important;
  }

  ._1kIl6q_justify-content-lg-between {
    justify-content: space-between !important;
  }

  ._1kIl6q_justify-content-lg-around {
    justify-content: space-around !important;
  }

  ._1kIl6q_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  ._1kIl6q_align-items-lg-start {
    align-items: flex-start !important;
  }

  ._1kIl6q_align-items-lg-end {
    align-items: flex-end !important;
  }

  ._1kIl6q_align-items-lg-center {
    align-items: center !important;
  }

  ._1kIl6q_align-items-lg-baseline {
    align-items: baseline !important;
  }

  ._1kIl6q_align-items-lg-stretch {
    align-items: stretch !important;
  }

  ._1kIl6q_align-content-lg-start {
    align-content: flex-start !important;
  }

  ._1kIl6q_align-content-lg-end {
    align-content: flex-end !important;
  }

  ._1kIl6q_align-content-lg-center {
    align-content: center !important;
  }

  ._1kIl6q_align-content-lg-between {
    align-content: space-between !important;
  }

  ._1kIl6q_align-content-lg-around {
    align-content: space-around !important;
  }

  ._1kIl6q_align-content-lg-stretch {
    align-content: stretch !important;
  }

  ._1kIl6q_align-self-lg-auto {
    align-self: auto !important;
  }

  ._1kIl6q_align-self-lg-start {
    align-self: flex-start !important;
  }

  ._1kIl6q_align-self-lg-end {
    align-self: flex-end !important;
  }

  ._1kIl6q_align-self-lg-center {
    align-self: center !important;
  }

  ._1kIl6q_align-self-lg-baseline {
    align-self: baseline !important;
  }

  ._1kIl6q_align-self-lg-stretch {
    align-self: stretch !important;
  }

  ._1kIl6q_order-lg-first {
    order: -1 !important;
  }

  ._1kIl6q_order-lg-0 {
    order: 0 !important;
  }

  ._1kIl6q_order-lg-1 {
    order: 1 !important;
  }

  ._1kIl6q_order-lg-2 {
    order: 2 !important;
  }

  ._1kIl6q_order-lg-3 {
    order: 3 !important;
  }

  ._1kIl6q_order-lg-4 {
    order: 4 !important;
  }

  ._1kIl6q_order-lg-5 {
    order: 5 !important;
  }

  ._1kIl6q_order-lg-last {
    order: 6 !important;
  }

  ._1kIl6q_m-lg-0 {
    margin: 0 !important;
  }

  ._1kIl6q_m-lg-1 {
    margin: .25rem !important;
  }

  ._1kIl6q_m-lg-2 {
    margin: .5rem !important;
  }

  ._1kIl6q_m-lg-3 {
    margin: 1rem !important;
  }

  ._1kIl6q_m-lg-4 {
    margin: 1.5rem !important;
  }

  ._1kIl6q_m-lg-5 {
    margin: 3rem !important;
  }

  ._1kIl6q_m-lg-auto {
    margin: auto !important;
  }

  ._1kIl6q_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._1kIl6q_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._1kIl6q_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._1kIl6q_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._1kIl6q_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._1kIl6q_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._1kIl6q_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._1kIl6q_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._1kIl6q_mt-lg-0 {
    margin-top: 0 !important;
  }

  ._1kIl6q_mt-lg-1 {
    margin-top: .25rem !important;
  }

  ._1kIl6q_mt-lg-2 {
    margin-top: .5rem !important;
  }

  ._1kIl6q_mt-lg-3 {
    margin-top: 1rem !important;
  }

  ._1kIl6q_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  ._1kIl6q_mt-lg-5 {
    margin-top: 3rem !important;
  }

  ._1kIl6q_mt-lg-auto {
    margin-top: auto !important;
  }

  ._1kIl6q_me-lg-0 {
    margin-right: 0 !important;
  }

  ._1kIl6q_me-lg-1 {
    margin-right: .25rem !important;
  }

  ._1kIl6q_me-lg-2 {
    margin-right: .5rem !important;
  }

  ._1kIl6q_me-lg-3 {
    margin-right: 1rem !important;
  }

  ._1kIl6q_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_me-lg-5 {
    margin-right: 3rem !important;
  }

  ._1kIl6q_me-lg-auto {
    margin-right: auto !important;
  }

  ._1kIl6q_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  ._1kIl6q_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_mb-lg-auto {
    margin-bottom: auto !important;
  }

  ._1kIl6q_ms-lg-0 {
    margin-left: 0 !important;
  }

  ._1kIl6q_ms-lg-1 {
    margin-left: .25rem !important;
  }

  ._1kIl6q_ms-lg-2 {
    margin-left: .5rem !important;
  }

  ._1kIl6q_ms-lg-3 {
    margin-left: 1rem !important;
  }

  ._1kIl6q_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  ._1kIl6q_ms-lg-5 {
    margin-left: 3rem !important;
  }

  ._1kIl6q_ms-lg-auto {
    margin-left: auto !important;
  }

  ._1kIl6q_p-lg-0 {
    padding: 0 !important;
  }

  ._1kIl6q_p-lg-1 {
    padding: .25rem !important;
  }

  ._1kIl6q_p-lg-2 {
    padding: .5rem !important;
  }

  ._1kIl6q_p-lg-3 {
    padding: 1rem !important;
  }

  ._1kIl6q_p-lg-4 {
    padding: 1.5rem !important;
  }

  ._1kIl6q_p-lg-5 {
    padding: 3rem !important;
  }

  ._1kIl6q_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._1kIl6q_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._1kIl6q_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._1kIl6q_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._1kIl6q_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._1kIl6q_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._1kIl6q_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_pt-lg-0 {
    padding-top: 0 !important;
  }

  ._1kIl6q_pt-lg-1 {
    padding-top: .25rem !important;
  }

  ._1kIl6q_pt-lg-2 {
    padding-top: .5rem !important;
  }

  ._1kIl6q_pt-lg-3 {
    padding-top: 1rem !important;
  }

  ._1kIl6q_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  ._1kIl6q_pt-lg-5 {
    padding-top: 3rem !important;
  }

  ._1kIl6q_pe-lg-0 {
    padding-right: 0 !important;
  }

  ._1kIl6q_pe-lg-1 {
    padding-right: .25rem !important;
  }

  ._1kIl6q_pe-lg-2 {
    padding-right: .5rem !important;
  }

  ._1kIl6q_pe-lg-3 {
    padding-right: 1rem !important;
  }

  ._1kIl6q_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_pe-lg-5 {
    padding-right: 3rem !important;
  }

  ._1kIl6q_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  ._1kIl6q_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_ps-lg-0 {
    padding-left: 0 !important;
  }

  ._1kIl6q_ps-lg-1 {
    padding-left: .25rem !important;
  }

  ._1kIl6q_ps-lg-2 {
    padding-left: .5rem !important;
  }

  ._1kIl6q_ps-lg-3 {
    padding-left: 1rem !important;
  }

  ._1kIl6q_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  ._1kIl6q_ps-lg-5 {
    padding-left: 3rem !important;
  }

  ._1kIl6q_gap-lg-0 {
    gap: 0 !important;
  }

  ._1kIl6q_gap-lg-1 {
    gap: .25rem !important;
  }

  ._1kIl6q_gap-lg-2 {
    gap: .5rem !important;
  }

  ._1kIl6q_gap-lg-3 {
    gap: 1rem !important;
  }

  ._1kIl6q_gap-lg-4 {
    gap: 1.5rem !important;
  }

  ._1kIl6q_gap-lg-5 {
    gap: 3rem !important;
  }

  ._1kIl6q_text-lg-start {
    text-align: left !important;
  }

  ._1kIl6q_text-lg-end {
    text-align: right !important;
  }

  ._1kIl6q_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_float-xl-start {
    float: left !important;
  }

  ._1kIl6q_float-xl-end {
    float: right !important;
  }

  ._1kIl6q_float-xl-none {
    float: none !important;
  }

  ._1kIl6q_d-xl-inline {
    display: inline !important;
  }

  ._1kIl6q_d-xl-inline-block {
    display: inline-block !important;
  }

  ._1kIl6q_d-xl-block {
    display: block !important;
  }

  ._1kIl6q_d-xl-grid {
    display: grid !important;
  }

  ._1kIl6q_d-xl-table {
    display: table !important;
  }

  ._1kIl6q_d-xl-table-row {
    display: table-row !important;
  }

  ._1kIl6q_d-xl-table-cell {
    display: table-cell !important;
  }

  ._1kIl6q_d-xl-flex {
    display: flex !important;
  }

  ._1kIl6q_d-xl-inline-flex {
    display: inline-flex !important;
  }

  ._1kIl6q_d-xl-none {
    display: none !important;
  }

  ._1kIl6q_flex-xl-fill {
    flex: auto !important;
  }

  ._1kIl6q_flex-xl-row {
    flex-direction: row !important;
  }

  ._1kIl6q_flex-xl-column {
    flex-direction: column !important;
  }

  ._1kIl6q_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._1kIl6q_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._1kIl6q_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  ._1kIl6q_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  ._1kIl6q_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._1kIl6q_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._1kIl6q_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  ._1kIl6q_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._1kIl6q_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._1kIl6q_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  ._1kIl6q_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  ._1kIl6q_justify-content-xl-center {
    justify-content: center !important;
  }

  ._1kIl6q_justify-content-xl-between {
    justify-content: space-between !important;
  }

  ._1kIl6q_justify-content-xl-around {
    justify-content: space-around !important;
  }

  ._1kIl6q_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  ._1kIl6q_align-items-xl-start {
    align-items: flex-start !important;
  }

  ._1kIl6q_align-items-xl-end {
    align-items: flex-end !important;
  }

  ._1kIl6q_align-items-xl-center {
    align-items: center !important;
  }

  ._1kIl6q_align-items-xl-baseline {
    align-items: baseline !important;
  }

  ._1kIl6q_align-items-xl-stretch {
    align-items: stretch !important;
  }

  ._1kIl6q_align-content-xl-start {
    align-content: flex-start !important;
  }

  ._1kIl6q_align-content-xl-end {
    align-content: flex-end !important;
  }

  ._1kIl6q_align-content-xl-center {
    align-content: center !important;
  }

  ._1kIl6q_align-content-xl-between {
    align-content: space-between !important;
  }

  ._1kIl6q_align-content-xl-around {
    align-content: space-around !important;
  }

  ._1kIl6q_align-content-xl-stretch {
    align-content: stretch !important;
  }

  ._1kIl6q_align-self-xl-auto {
    align-self: auto !important;
  }

  ._1kIl6q_align-self-xl-start {
    align-self: flex-start !important;
  }

  ._1kIl6q_align-self-xl-end {
    align-self: flex-end !important;
  }

  ._1kIl6q_align-self-xl-center {
    align-self: center !important;
  }

  ._1kIl6q_align-self-xl-baseline {
    align-self: baseline !important;
  }

  ._1kIl6q_align-self-xl-stretch {
    align-self: stretch !important;
  }

  ._1kIl6q_order-xl-first {
    order: -1 !important;
  }

  ._1kIl6q_order-xl-0 {
    order: 0 !important;
  }

  ._1kIl6q_order-xl-1 {
    order: 1 !important;
  }

  ._1kIl6q_order-xl-2 {
    order: 2 !important;
  }

  ._1kIl6q_order-xl-3 {
    order: 3 !important;
  }

  ._1kIl6q_order-xl-4 {
    order: 4 !important;
  }

  ._1kIl6q_order-xl-5 {
    order: 5 !important;
  }

  ._1kIl6q_order-xl-last {
    order: 6 !important;
  }

  ._1kIl6q_m-xl-0 {
    margin: 0 !important;
  }

  ._1kIl6q_m-xl-1 {
    margin: .25rem !important;
  }

  ._1kIl6q_m-xl-2 {
    margin: .5rem !important;
  }

  ._1kIl6q_m-xl-3 {
    margin: 1rem !important;
  }

  ._1kIl6q_m-xl-4 {
    margin: 1.5rem !important;
  }

  ._1kIl6q_m-xl-5 {
    margin: 3rem !important;
  }

  ._1kIl6q_m-xl-auto {
    margin: auto !important;
  }

  ._1kIl6q_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._1kIl6q_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._1kIl6q_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._1kIl6q_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._1kIl6q_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._1kIl6q_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._1kIl6q_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._1kIl6q_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._1kIl6q_mt-xl-0 {
    margin-top: 0 !important;
  }

  ._1kIl6q_mt-xl-1 {
    margin-top: .25rem !important;
  }

  ._1kIl6q_mt-xl-2 {
    margin-top: .5rem !important;
  }

  ._1kIl6q_mt-xl-3 {
    margin-top: 1rem !important;
  }

  ._1kIl6q_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  ._1kIl6q_mt-xl-5 {
    margin-top: 3rem !important;
  }

  ._1kIl6q_mt-xl-auto {
    margin-top: auto !important;
  }

  ._1kIl6q_me-xl-0 {
    margin-right: 0 !important;
  }

  ._1kIl6q_me-xl-1 {
    margin-right: .25rem !important;
  }

  ._1kIl6q_me-xl-2 {
    margin-right: .5rem !important;
  }

  ._1kIl6q_me-xl-3 {
    margin-right: 1rem !important;
  }

  ._1kIl6q_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_me-xl-5 {
    margin-right: 3rem !important;
  }

  ._1kIl6q_me-xl-auto {
    margin-right: auto !important;
  }

  ._1kIl6q_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  ._1kIl6q_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_mb-xl-auto {
    margin-bottom: auto !important;
  }

  ._1kIl6q_ms-xl-0 {
    margin-left: 0 !important;
  }

  ._1kIl6q_ms-xl-1 {
    margin-left: .25rem !important;
  }

  ._1kIl6q_ms-xl-2 {
    margin-left: .5rem !important;
  }

  ._1kIl6q_ms-xl-3 {
    margin-left: 1rem !important;
  }

  ._1kIl6q_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  ._1kIl6q_ms-xl-5 {
    margin-left: 3rem !important;
  }

  ._1kIl6q_ms-xl-auto {
    margin-left: auto !important;
  }

  ._1kIl6q_p-xl-0 {
    padding: 0 !important;
  }

  ._1kIl6q_p-xl-1 {
    padding: .25rem !important;
  }

  ._1kIl6q_p-xl-2 {
    padding: .5rem !important;
  }

  ._1kIl6q_p-xl-3 {
    padding: 1rem !important;
  }

  ._1kIl6q_p-xl-4 {
    padding: 1.5rem !important;
  }

  ._1kIl6q_p-xl-5 {
    padding: 3rem !important;
  }

  ._1kIl6q_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._1kIl6q_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._1kIl6q_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._1kIl6q_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._1kIl6q_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._1kIl6q_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._1kIl6q_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_pt-xl-0 {
    padding-top: 0 !important;
  }

  ._1kIl6q_pt-xl-1 {
    padding-top: .25rem !important;
  }

  ._1kIl6q_pt-xl-2 {
    padding-top: .5rem !important;
  }

  ._1kIl6q_pt-xl-3 {
    padding-top: 1rem !important;
  }

  ._1kIl6q_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  ._1kIl6q_pt-xl-5 {
    padding-top: 3rem !important;
  }

  ._1kIl6q_pe-xl-0 {
    padding-right: 0 !important;
  }

  ._1kIl6q_pe-xl-1 {
    padding-right: .25rem !important;
  }

  ._1kIl6q_pe-xl-2 {
    padding-right: .5rem !important;
  }

  ._1kIl6q_pe-xl-3 {
    padding-right: 1rem !important;
  }

  ._1kIl6q_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_pe-xl-5 {
    padding-right: 3rem !important;
  }

  ._1kIl6q_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  ._1kIl6q_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_ps-xl-0 {
    padding-left: 0 !important;
  }

  ._1kIl6q_ps-xl-1 {
    padding-left: .25rem !important;
  }

  ._1kIl6q_ps-xl-2 {
    padding-left: .5rem !important;
  }

  ._1kIl6q_ps-xl-3 {
    padding-left: 1rem !important;
  }

  ._1kIl6q_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  ._1kIl6q_ps-xl-5 {
    padding-left: 3rem !important;
  }

  ._1kIl6q_gap-xl-0 {
    gap: 0 !important;
  }

  ._1kIl6q_gap-xl-1 {
    gap: .25rem !important;
  }

  ._1kIl6q_gap-xl-2 {
    gap: .5rem !important;
  }

  ._1kIl6q_gap-xl-3 {
    gap: 1rem !important;
  }

  ._1kIl6q_gap-xl-4 {
    gap: 1.5rem !important;
  }

  ._1kIl6q_gap-xl-5 {
    gap: 3rem !important;
  }

  ._1kIl6q_text-xl-start {
    text-align: left !important;
  }

  ._1kIl6q_text-xl-end {
    text-align: right !important;
  }

  ._1kIl6q_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  ._1kIl6q_float-xxl-start {
    float: left !important;
  }

  ._1kIl6q_float-xxl-end {
    float: right !important;
  }

  ._1kIl6q_float-xxl-none {
    float: none !important;
  }

  ._1kIl6q_d-xxl-inline {
    display: inline !important;
  }

  ._1kIl6q_d-xxl-inline-block {
    display: inline-block !important;
  }

  ._1kIl6q_d-xxl-block {
    display: block !important;
  }

  ._1kIl6q_d-xxl-grid {
    display: grid !important;
  }

  ._1kIl6q_d-xxl-table {
    display: table !important;
  }

  ._1kIl6q_d-xxl-table-row {
    display: table-row !important;
  }

  ._1kIl6q_d-xxl-table-cell {
    display: table-cell !important;
  }

  ._1kIl6q_d-xxl-flex {
    display: flex !important;
  }

  ._1kIl6q_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  ._1kIl6q_d-xxl-none {
    display: none !important;
  }

  ._1kIl6q_flex-xxl-fill {
    flex: auto !important;
  }

  ._1kIl6q_flex-xxl-row {
    flex-direction: row !important;
  }

  ._1kIl6q_flex-xxl-column {
    flex-direction: column !important;
  }

  ._1kIl6q_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._1kIl6q_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._1kIl6q_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  ._1kIl6q_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  ._1kIl6q_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._1kIl6q_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._1kIl6q_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  ._1kIl6q_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._1kIl6q_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._1kIl6q_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  ._1kIl6q_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  ._1kIl6q_justify-content-xxl-center {
    justify-content: center !important;
  }

  ._1kIl6q_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  ._1kIl6q_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  ._1kIl6q_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  ._1kIl6q_align-items-xxl-start {
    align-items: flex-start !important;
  }

  ._1kIl6q_align-items-xxl-end {
    align-items: flex-end !important;
  }

  ._1kIl6q_align-items-xxl-center {
    align-items: center !important;
  }

  ._1kIl6q_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  ._1kIl6q_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  ._1kIl6q_align-content-xxl-start {
    align-content: flex-start !important;
  }

  ._1kIl6q_align-content-xxl-end {
    align-content: flex-end !important;
  }

  ._1kIl6q_align-content-xxl-center {
    align-content: center !important;
  }

  ._1kIl6q_align-content-xxl-between {
    align-content: space-between !important;
  }

  ._1kIl6q_align-content-xxl-around {
    align-content: space-around !important;
  }

  ._1kIl6q_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  ._1kIl6q_align-self-xxl-auto {
    align-self: auto !important;
  }

  ._1kIl6q_align-self-xxl-start {
    align-self: flex-start !important;
  }

  ._1kIl6q_align-self-xxl-end {
    align-self: flex-end !important;
  }

  ._1kIl6q_align-self-xxl-center {
    align-self: center !important;
  }

  ._1kIl6q_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  ._1kIl6q_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  ._1kIl6q_order-xxl-first {
    order: -1 !important;
  }

  ._1kIl6q_order-xxl-0 {
    order: 0 !important;
  }

  ._1kIl6q_order-xxl-1 {
    order: 1 !important;
  }

  ._1kIl6q_order-xxl-2 {
    order: 2 !important;
  }

  ._1kIl6q_order-xxl-3 {
    order: 3 !important;
  }

  ._1kIl6q_order-xxl-4 {
    order: 4 !important;
  }

  ._1kIl6q_order-xxl-5 {
    order: 5 !important;
  }

  ._1kIl6q_order-xxl-last {
    order: 6 !important;
  }

  ._1kIl6q_m-xxl-0 {
    margin: 0 !important;
  }

  ._1kIl6q_m-xxl-1 {
    margin: .25rem !important;
  }

  ._1kIl6q_m-xxl-2 {
    margin: .5rem !important;
  }

  ._1kIl6q_m-xxl-3 {
    margin: 1rem !important;
  }

  ._1kIl6q_m-xxl-4 {
    margin: 1.5rem !important;
  }

  ._1kIl6q_m-xxl-5 {
    margin: 3rem !important;
  }

  ._1kIl6q_m-xxl-auto {
    margin: auto !important;
  }

  ._1kIl6q_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._1kIl6q_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._1kIl6q_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._1kIl6q_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._1kIl6q_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._1kIl6q_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._1kIl6q_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._1kIl6q_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._1kIl6q_mt-xxl-0 {
    margin-top: 0 !important;
  }

  ._1kIl6q_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  ._1kIl6q_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  ._1kIl6q_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  ._1kIl6q_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  ._1kIl6q_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  ._1kIl6q_mt-xxl-auto {
    margin-top: auto !important;
  }

  ._1kIl6q_me-xxl-0 {
    margin-right: 0 !important;
  }

  ._1kIl6q_me-xxl-1 {
    margin-right: .25rem !important;
  }

  ._1kIl6q_me-xxl-2 {
    margin-right: .5rem !important;
  }

  ._1kIl6q_me-xxl-3 {
    margin-right: 1rem !important;
  }

  ._1kIl6q_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  ._1kIl6q_me-xxl-5 {
    margin-right: 3rem !important;
  }

  ._1kIl6q_me-xxl-auto {
    margin-right: auto !important;
  }

  ._1kIl6q_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  ._1kIl6q_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  ._1kIl6q_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  ._1kIl6q_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  ._1kIl6q_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._1kIl6q_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  ._1kIl6q_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  ._1kIl6q_ms-xxl-0 {
    margin-left: 0 !important;
  }

  ._1kIl6q_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  ._1kIl6q_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  ._1kIl6q_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  ._1kIl6q_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  ._1kIl6q_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  ._1kIl6q_ms-xxl-auto {
    margin-left: auto !important;
  }

  ._1kIl6q_p-xxl-0 {
    padding: 0 !important;
  }

  ._1kIl6q_p-xxl-1 {
    padding: .25rem !important;
  }

  ._1kIl6q_p-xxl-2 {
    padding: .5rem !important;
  }

  ._1kIl6q_p-xxl-3 {
    padding: 1rem !important;
  }

  ._1kIl6q_p-xxl-4 {
    padding: 1.5rem !important;
  }

  ._1kIl6q_p-xxl-5 {
    padding: 3rem !important;
  }

  ._1kIl6q_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._1kIl6q_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._1kIl6q_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._1kIl6q_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._1kIl6q_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._1kIl6q_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._1kIl6q_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_pt-xxl-0 {
    padding-top: 0 !important;
  }

  ._1kIl6q_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  ._1kIl6q_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  ._1kIl6q_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  ._1kIl6q_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  ._1kIl6q_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  ._1kIl6q_pe-xxl-0 {
    padding-right: 0 !important;
  }

  ._1kIl6q_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  ._1kIl6q_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  ._1kIl6q_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  ._1kIl6q_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  ._1kIl6q_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  ._1kIl6q_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  ._1kIl6q_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  ._1kIl6q_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  ._1kIl6q_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  ._1kIl6q_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._1kIl6q_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  ._1kIl6q_ps-xxl-0 {
    padding-left: 0 !important;
  }

  ._1kIl6q_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  ._1kIl6q_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  ._1kIl6q_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  ._1kIl6q_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  ._1kIl6q_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  ._1kIl6q_gap-xxl-0 {
    gap: 0 !important;
  }

  ._1kIl6q_gap-xxl-1 {
    gap: .25rem !important;
  }

  ._1kIl6q_gap-xxl-2 {
    gap: .5rem !important;
  }

  ._1kIl6q_gap-xxl-3 {
    gap: 1rem !important;
  }

  ._1kIl6q_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  ._1kIl6q_gap-xxl-5 {
    gap: 3rem !important;
  }

  ._1kIl6q_text-xxl-start {
    text-align: left !important;
  }

  ._1kIl6q_text-xxl-end {
    text-align: right !important;
  }

  ._1kIl6q_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._1kIl6q_fs-1 {
    font-size: 2.5rem !important;
  }

  ._1kIl6q_fs-2 {
    font-size: 2rem !important;
  }

  ._1kIl6q_fs-3 {
    font-size: 1.75rem !important;
  }

  ._1kIl6q_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  ._1kIl6q_d-print-inline {
    display: inline !important;
  }

  ._1kIl6q_d-print-inline-block {
    display: inline-block !important;
  }

  ._1kIl6q_d-print-block {
    display: block !important;
  }

  ._1kIl6q_d-print-grid {
    display: grid !important;
  }

  ._1kIl6q_d-print-table {
    display: table !important;
  }

  ._1kIl6q_d-print-table-row {
    display: table-row !important;
  }

  ._1kIl6q_d-print-table-cell {
    display: table-cell !important;
  }

  ._1kIl6q_d-print-flex {
    display: flex !important;
  }

  ._1kIl6q_d-print-inline-flex {
    display: inline-flex !important;
  }

  ._1kIl6q_d-print-none {
    display: none !important;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

._1kIl6q_tabs {
  justify-content: center;
  gap: 20px;
  display: flex;
}

._1kIl6q_tabs ._1kIl6q_tab {
  padding: 0 10px;
}

._1kIl6q_tabs ._1kIl6q_tab._1kIl6q_active {
  border-bottom: 2px solid #258e01;
}

._1kIl6q_items {
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px;
  display: grid;
}

.GEoi2a_wrapper {
  height: 450px;
  color: #fff;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.GEoi2a_overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000000e6;
  position: absolute;
  top: 0;
  left: 0;
}

.GEoi2a_container {
  z-index: 2;
}

.GEoi2a_box {
  z-index: 1;
  border: 2px solid #ffffffe6;
  margin: 10px;
  padding: 20px;
  position: relative;
}

.GEoi2a_box:before {
  content: "";
  height: 5px;
  width: 60%;
  background: #000000e6;
  margin-top: 17px;
  display: block;
  position: absolute;
  top: -20px;
  left: 20%;
}

.GEoi2a_box:after {
  content: "";
  height: 10px;
  width: 80%;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 10%;
}

.jTSoSa_image {
  width: 100%;
  height: 270px;
  background-position: center;
  background-size: cover;
}

.jTSoSa_section {
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.jTSoSa_overlay {
  width: 100%;
  height: 270px;
  background: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.xSc9rq_name {
  letter-spacing: .25px;
  color: #25282b;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.xSc9rq_imageSize {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding-right: 2px;
}

.xSc9rq_extra {
  margin: 0 30px;
  display: flex;
}

.xSc9rq_extra div {
  margin: 0 10px;
}

.lsZwYa_page {
  text-align: center;
  margin: 50px;
}

.lsZwYa_page .lsZwYa_image {
  margin: 0 auto 30px;
  display: inline-block;
  position: relative;
}

.lsZwYa_page .lsZwYa_image h1 {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
}

.lsZwYa_page .lsZwYa_image img {
  height: 360px;
}

.lsZwYa_page h1, .lsZwYa_page p {
  text-align: center;
}



.ZiZX4a_imageCart {
  width: 50px;
  height: 50px;
}

.ZiZX4a_operator {
  text-align: center;
  margin-right: 10px;
}

.ZiZX4a_operator span {
  display: block;
}

.ZiZX4a_operator img {
  height: 90px;
  width: 90px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.ZiZX4a_popup {
  height: 100%;
  width: 100%;
  background-color: #0000009e;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.ZiZX4a_popup .ZiZX4a_dialog {
  width: 60vw;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  display: flex;
}



.uQi_ya_wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;
  display: flex;
}

.uQi_ya_wrapper .uQi_ya_sidebar {
  height: 100vh;
  width: 270px;
  background-color: #fff;
  border-right: 1px solid #d9d9d9;
}

.uQi_ya_wrapper .uQi_ya_bodyWrapper {
  flex: 1;
}

.uQi_ya_wrapper .uQi_ya_bodyWrapper .uQi_ya_header {
  height: 65px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
}

.uQi_ya_wrapper .uQi_ya_bodyWrapper .uQi_ya_body {
  height: calc(100vh - 65px);
  width: 100%;
  background-color: #f8f8f8;
  padding: 31px;
  overflow: scroll;
}

.tndVNa_wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 31px;
  display: flex;
}

.tndVNa_wrapper .tndVNa_divider {
  width: 1px;
  height: 40px;
  background-color: #d9d9d9;
  margin: 0 31px;
}

.tndVNa_wrapper .tndVNa_user {
  align-items: center;
  display: flex;
}

.tndVNa_wrapper .tndVNa_user .tndVNa_avatar {
  width: 40px;
  height: 40px;
  background-color: #ffd240;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-right: 12px;
}

.tndVNa_wrapper .tndVNa_user .tndVNa_name {
  font-size: 15px;
  font-weight: normal;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #258e01;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 37, 142, 1;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #258e01;
  --bs-link-hover-color: #1e7201;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, ._9qOvuW_h6, h5, ._9qOvuW_h5, h4, ._9qOvuW_h4, h3, ._9qOvuW_h3, h2, ._9qOvuW_h2, h1, ._9qOvuW_h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, ._9qOvuW_h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, ._9qOvuW_h1 {
    font-size: 2.5rem;
  }
}

h2, ._9qOvuW_h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, ._9qOvuW_h2 {
    font-size: 2rem;
  }
}

h3, ._9qOvuW_h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, ._9qOvuW_h3 {
    font-size: 1.75rem;
  }
}

h4, ._9qOvuW_h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, ._9qOvuW_h4 {
    font-size: 1.5rem;
  }
}

h5, ._9qOvuW_h5 {
  font-size: 1.25rem;
}

h6, ._9qOvuW_h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, ._9qOvuW_small {
  font-size: .875em;
}

mark, ._9qOvuW_mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

._9qOvuW_lead {
  font-size: 1.25rem;
  font-weight: 300;
}

._9qOvuW_display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._9qOvuW_display-1 {
    font-size: 5rem;
  }
}

._9qOvuW_display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._9qOvuW_display-2 {
    font-size: 4.5rem;
  }
}

._9qOvuW_display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._9qOvuW_display-3 {
    font-size: 4rem;
  }
}

._9qOvuW_display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._9qOvuW_display-4 {
    font-size: 3.5rem;
  }
}

._9qOvuW_display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._9qOvuW_display-5 {
    font-size: 3rem;
  }
}

._9qOvuW_display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  ._9qOvuW_display-6 {
    font-size: 2.5rem;
  }
}

._9qOvuW_list-unstyled, ._9qOvuW_list-inline {
  padding-left: 0;
  list-style: none;
}

._9qOvuW_list-inline-item {
  display: inline-block;
}

._9qOvuW_list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

._9qOvuW_initialism {
  text-transform: uppercase;
  font-size: .875em;
}

._9qOvuW_blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

._9qOvuW_blockquote > :last-child {
  margin-bottom: 0;
}

._9qOvuW_blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

._9qOvuW_blockquote-footer:before {
  content: "— ";
}

._9qOvuW_img-fluid {
  max-width: 100%;
  height: auto;
}

._9qOvuW_img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

._9qOvuW_figure {
  display: inline-block;
}

._9qOvuW_figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

._9qOvuW_figure-caption {
  color: #6c757d;
  font-size: .875em;
}

._9qOvuW_container, ._9qOvuW_container-fluid, ._9qOvuW_container-xxl, ._9qOvuW_container-xl, ._9qOvuW_container-lg, ._9qOvuW_container-md, ._9qOvuW_container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  ._9qOvuW_container-sm, ._9qOvuW_container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_container-md, ._9qOvuW_container-sm, ._9qOvuW_container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_container-lg, ._9qOvuW_container-md, ._9qOvuW_container-sm, ._9qOvuW_container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_container-xl, ._9qOvuW_container-lg, ._9qOvuW_container-md, ._9qOvuW_container-sm, ._9qOvuW_container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_container-xxl, ._9qOvuW_container-xl, ._9qOvuW_container-lg, ._9qOvuW_container-md, ._9qOvuW_container-sm, ._9qOvuW_container {
    max-width: 1320px;
  }
}

._9qOvuW_row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

._9qOvuW_row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

._9qOvuW_col {
  flex: 1 0;
}

._9qOvuW_row-cols-auto > * {
  width: auto;
  flex: none;
}

._9qOvuW_row-cols-1 > * {
  width: 100%;
  flex: none;
}

._9qOvuW_row-cols-2 > * {
  width: 50%;
  flex: none;
}

._9qOvuW_row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

._9qOvuW_row-cols-4 > * {
  width: 25%;
  flex: none;
}

._9qOvuW_row-cols-5 > * {
  width: 20%;
  flex: none;
}

._9qOvuW_row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

._9qOvuW_col-auto {
  width: auto;
  flex: none;
}

._9qOvuW_col-1 {
  width: 8.33333%;
  flex: none;
}

._9qOvuW_col-2 {
  width: 16.6667%;
  flex: none;
}

._9qOvuW_col-3 {
  width: 25%;
  flex: none;
}

._9qOvuW_col-4 {
  width: 33.3333%;
  flex: none;
}

._9qOvuW_col-5 {
  width: 41.6667%;
  flex: none;
}

._9qOvuW_col-6 {
  width: 50%;
  flex: none;
}

._9qOvuW_col-7 {
  width: 58.3333%;
  flex: none;
}

._9qOvuW_col-8 {
  width: 66.6667%;
  flex: none;
}

._9qOvuW_col-9 {
  width: 75%;
  flex: none;
}

._9qOvuW_col-10 {
  width: 83.3333%;
  flex: none;
}

._9qOvuW_col-11 {
  width: 91.6667%;
  flex: none;
}

._9qOvuW_col-12 {
  width: 100%;
  flex: none;
}

._9qOvuW_offset-1 {
  margin-left: 8.33333%;
}

._9qOvuW_offset-2 {
  margin-left: 16.6667%;
}

._9qOvuW_offset-3 {
  margin-left: 25%;
}

._9qOvuW_offset-4 {
  margin-left: 33.3333%;
}

._9qOvuW_offset-5 {
  margin-left: 41.6667%;
}

._9qOvuW_offset-6 {
  margin-left: 50%;
}

._9qOvuW_offset-7 {
  margin-left: 58.3333%;
}

._9qOvuW_offset-8 {
  margin-left: 66.6667%;
}

._9qOvuW_offset-9 {
  margin-left: 75%;
}

._9qOvuW_offset-10 {
  margin-left: 83.3333%;
}

._9qOvuW_offset-11 {
  margin-left: 91.6667%;
}

._9qOvuW_g-0, ._9qOvuW_gx-0 {
  --bs-gutter-x: 0;
}

._9qOvuW_g-0, ._9qOvuW_gy-0 {
  --bs-gutter-y: 0;
}

._9qOvuW_g-1, ._9qOvuW_gx-1 {
  --bs-gutter-x: .25rem;
}

._9qOvuW_g-1, ._9qOvuW_gy-1 {
  --bs-gutter-y: .25rem;
}

._9qOvuW_g-2, ._9qOvuW_gx-2 {
  --bs-gutter-x: .5rem;
}

._9qOvuW_g-2, ._9qOvuW_gy-2 {
  --bs-gutter-y: .5rem;
}

._9qOvuW_g-3, ._9qOvuW_gx-3 {
  --bs-gutter-x: 1rem;
}

._9qOvuW_g-3, ._9qOvuW_gy-3 {
  --bs-gutter-y: 1rem;
}

._9qOvuW_g-4, ._9qOvuW_gx-4 {
  --bs-gutter-x: 1.5rem;
}

._9qOvuW_g-4, ._9qOvuW_gy-4 {
  --bs-gutter-y: 1.5rem;
}

._9qOvuW_g-5, ._9qOvuW_gx-5 {
  --bs-gutter-x: 3rem;
}

._9qOvuW_g-5, ._9qOvuW_gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  ._9qOvuW_col-sm {
    flex: 1 0;
  }

  ._9qOvuW_row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  ._9qOvuW_row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  ._9qOvuW_row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-sm-auto {
    width: auto;
    flex: none;
  }

  ._9qOvuW_col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  ._9qOvuW_col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-sm-3 {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  ._9qOvuW_col-sm-6 {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  ._9qOvuW_col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  ._9qOvuW_col-sm-9 {
    width: 75%;
    flex: none;
  }

  ._9qOvuW_col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  ._9qOvuW_col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  ._9qOvuW_col-sm-12 {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_offset-sm-0 {
    margin-left: 0;
  }

  ._9qOvuW_offset-sm-1 {
    margin-left: 8.33333%;
  }

  ._9qOvuW_offset-sm-2 {
    margin-left: 16.6667%;
  }

  ._9qOvuW_offset-sm-3 {
    margin-left: 25%;
  }

  ._9qOvuW_offset-sm-4 {
    margin-left: 33.3333%;
  }

  ._9qOvuW_offset-sm-5 {
    margin-left: 41.6667%;
  }

  ._9qOvuW_offset-sm-6 {
    margin-left: 50%;
  }

  ._9qOvuW_offset-sm-7 {
    margin-left: 58.3333%;
  }

  ._9qOvuW_offset-sm-8 {
    margin-left: 66.6667%;
  }

  ._9qOvuW_offset-sm-9 {
    margin-left: 75%;
  }

  ._9qOvuW_offset-sm-10 {
    margin-left: 83.3333%;
  }

  ._9qOvuW_offset-sm-11 {
    margin-left: 91.6667%;
  }

  ._9qOvuW_g-sm-0, ._9qOvuW_gx-sm-0 {
    --bs-gutter-x: 0;
  }

  ._9qOvuW_g-sm-0, ._9qOvuW_gy-sm-0 {
    --bs-gutter-y: 0;
  }

  ._9qOvuW_g-sm-1, ._9qOvuW_gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  ._9qOvuW_g-sm-1, ._9qOvuW_gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  ._9qOvuW_g-sm-2, ._9qOvuW_gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  ._9qOvuW_g-sm-2, ._9qOvuW_gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  ._9qOvuW_g-sm-3, ._9qOvuW_gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  ._9qOvuW_g-sm-3, ._9qOvuW_gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  ._9qOvuW_g-sm-4, ._9qOvuW_gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._9qOvuW_g-sm-4, ._9qOvuW_gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._9qOvuW_g-sm-5, ._9qOvuW_gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  ._9qOvuW_g-sm-5, ._9qOvuW_gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_col-md {
    flex: 1 0;
  }

  ._9qOvuW_row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  ._9qOvuW_row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  ._9qOvuW_row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-md-auto {
    width: auto;
    flex: none;
  }

  ._9qOvuW_col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  ._9qOvuW_col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-md-3 {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  ._9qOvuW_col-md-6 {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  ._9qOvuW_col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  ._9qOvuW_col-md-9 {
    width: 75%;
    flex: none;
  }

  ._9qOvuW_col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  ._9qOvuW_col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  ._9qOvuW_col-md-12 {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_offset-md-0 {
    margin-left: 0;
  }

  ._9qOvuW_offset-md-1 {
    margin-left: 8.33333%;
  }

  ._9qOvuW_offset-md-2 {
    margin-left: 16.6667%;
  }

  ._9qOvuW_offset-md-3 {
    margin-left: 25%;
  }

  ._9qOvuW_offset-md-4 {
    margin-left: 33.3333%;
  }

  ._9qOvuW_offset-md-5 {
    margin-left: 41.6667%;
  }

  ._9qOvuW_offset-md-6 {
    margin-left: 50%;
  }

  ._9qOvuW_offset-md-7 {
    margin-left: 58.3333%;
  }

  ._9qOvuW_offset-md-8 {
    margin-left: 66.6667%;
  }

  ._9qOvuW_offset-md-9 {
    margin-left: 75%;
  }

  ._9qOvuW_offset-md-10 {
    margin-left: 83.3333%;
  }

  ._9qOvuW_offset-md-11 {
    margin-left: 91.6667%;
  }

  ._9qOvuW_g-md-0, ._9qOvuW_gx-md-0 {
    --bs-gutter-x: 0;
  }

  ._9qOvuW_g-md-0, ._9qOvuW_gy-md-0 {
    --bs-gutter-y: 0;
  }

  ._9qOvuW_g-md-1, ._9qOvuW_gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  ._9qOvuW_g-md-1, ._9qOvuW_gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  ._9qOvuW_g-md-2, ._9qOvuW_gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  ._9qOvuW_g-md-2, ._9qOvuW_gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  ._9qOvuW_g-md-3, ._9qOvuW_gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  ._9qOvuW_g-md-3, ._9qOvuW_gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  ._9qOvuW_g-md-4, ._9qOvuW_gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._9qOvuW_g-md-4, ._9qOvuW_gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._9qOvuW_g-md-5, ._9qOvuW_gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  ._9qOvuW_g-md-5, ._9qOvuW_gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_col-lg {
    flex: 1 0;
  }

  ._9qOvuW_row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  ._9qOvuW_row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  ._9qOvuW_row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-lg-auto {
    width: auto;
    flex: none;
  }

  ._9qOvuW_col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  ._9qOvuW_col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-lg-3 {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  ._9qOvuW_col-lg-6 {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  ._9qOvuW_col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  ._9qOvuW_col-lg-9 {
    width: 75%;
    flex: none;
  }

  ._9qOvuW_col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  ._9qOvuW_col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  ._9qOvuW_col-lg-12 {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_offset-lg-0 {
    margin-left: 0;
  }

  ._9qOvuW_offset-lg-1 {
    margin-left: 8.33333%;
  }

  ._9qOvuW_offset-lg-2 {
    margin-left: 16.6667%;
  }

  ._9qOvuW_offset-lg-3 {
    margin-left: 25%;
  }

  ._9qOvuW_offset-lg-4 {
    margin-left: 33.3333%;
  }

  ._9qOvuW_offset-lg-5 {
    margin-left: 41.6667%;
  }

  ._9qOvuW_offset-lg-6 {
    margin-left: 50%;
  }

  ._9qOvuW_offset-lg-7 {
    margin-left: 58.3333%;
  }

  ._9qOvuW_offset-lg-8 {
    margin-left: 66.6667%;
  }

  ._9qOvuW_offset-lg-9 {
    margin-left: 75%;
  }

  ._9qOvuW_offset-lg-10 {
    margin-left: 83.3333%;
  }

  ._9qOvuW_offset-lg-11 {
    margin-left: 91.6667%;
  }

  ._9qOvuW_g-lg-0, ._9qOvuW_gx-lg-0 {
    --bs-gutter-x: 0;
  }

  ._9qOvuW_g-lg-0, ._9qOvuW_gy-lg-0 {
    --bs-gutter-y: 0;
  }

  ._9qOvuW_g-lg-1, ._9qOvuW_gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  ._9qOvuW_g-lg-1, ._9qOvuW_gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  ._9qOvuW_g-lg-2, ._9qOvuW_gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  ._9qOvuW_g-lg-2, ._9qOvuW_gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  ._9qOvuW_g-lg-3, ._9qOvuW_gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  ._9qOvuW_g-lg-3, ._9qOvuW_gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  ._9qOvuW_g-lg-4, ._9qOvuW_gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._9qOvuW_g-lg-4, ._9qOvuW_gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._9qOvuW_g-lg-5, ._9qOvuW_gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  ._9qOvuW_g-lg-5, ._9qOvuW_gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_col-xl {
    flex: 1 0;
  }

  ._9qOvuW_row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  ._9qOvuW_row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  ._9qOvuW_row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-xl-auto {
    width: auto;
    flex: none;
  }

  ._9qOvuW_col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._9qOvuW_col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-xl-3 {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._9qOvuW_col-xl-6 {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._9qOvuW_col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._9qOvuW_col-xl-9 {
    width: 75%;
    flex: none;
  }

  ._9qOvuW_col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._9qOvuW_col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._9qOvuW_col-xl-12 {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_offset-xl-0 {
    margin-left: 0;
  }

  ._9qOvuW_offset-xl-1 {
    margin-left: 8.33333%;
  }

  ._9qOvuW_offset-xl-2 {
    margin-left: 16.6667%;
  }

  ._9qOvuW_offset-xl-3 {
    margin-left: 25%;
  }

  ._9qOvuW_offset-xl-4 {
    margin-left: 33.3333%;
  }

  ._9qOvuW_offset-xl-5 {
    margin-left: 41.6667%;
  }

  ._9qOvuW_offset-xl-6 {
    margin-left: 50%;
  }

  ._9qOvuW_offset-xl-7 {
    margin-left: 58.3333%;
  }

  ._9qOvuW_offset-xl-8 {
    margin-left: 66.6667%;
  }

  ._9qOvuW_offset-xl-9 {
    margin-left: 75%;
  }

  ._9qOvuW_offset-xl-10 {
    margin-left: 83.3333%;
  }

  ._9qOvuW_offset-xl-11 {
    margin-left: 91.6667%;
  }

  ._9qOvuW_g-xl-0, ._9qOvuW_gx-xl-0 {
    --bs-gutter-x: 0;
  }

  ._9qOvuW_g-xl-0, ._9qOvuW_gy-xl-0 {
    --bs-gutter-y: 0;
  }

  ._9qOvuW_g-xl-1, ._9qOvuW_gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  ._9qOvuW_g-xl-1, ._9qOvuW_gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  ._9qOvuW_g-xl-2, ._9qOvuW_gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  ._9qOvuW_g-xl-2, ._9qOvuW_gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  ._9qOvuW_g-xl-3, ._9qOvuW_gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  ._9qOvuW_g-xl-3, ._9qOvuW_gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  ._9qOvuW_g-xl-4, ._9qOvuW_gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._9qOvuW_g-xl-4, ._9qOvuW_gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._9qOvuW_g-xl-5, ._9qOvuW_gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  ._9qOvuW_g-xl-5, ._9qOvuW_gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_col-xxl {
    flex: 1 0;
  }

  ._9qOvuW_row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  ._9qOvuW_row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  ._9qOvuW_row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-xxl-auto {
    width: auto;
    flex: none;
  }

  ._9qOvuW_col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  ._9qOvuW_col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  ._9qOvuW_col-xxl-3 {
    width: 25%;
    flex: none;
  }

  ._9qOvuW_col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  ._9qOvuW_col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  ._9qOvuW_col-xxl-6 {
    width: 50%;
    flex: none;
  }

  ._9qOvuW_col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  ._9qOvuW_col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  ._9qOvuW_col-xxl-9 {
    width: 75%;
    flex: none;
  }

  ._9qOvuW_col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  ._9qOvuW_col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  ._9qOvuW_col-xxl-12 {
    width: 100%;
    flex: none;
  }

  ._9qOvuW_offset-xxl-0 {
    margin-left: 0;
  }

  ._9qOvuW_offset-xxl-1 {
    margin-left: 8.33333%;
  }

  ._9qOvuW_offset-xxl-2 {
    margin-left: 16.6667%;
  }

  ._9qOvuW_offset-xxl-3 {
    margin-left: 25%;
  }

  ._9qOvuW_offset-xxl-4 {
    margin-left: 33.3333%;
  }

  ._9qOvuW_offset-xxl-5 {
    margin-left: 41.6667%;
  }

  ._9qOvuW_offset-xxl-6 {
    margin-left: 50%;
  }

  ._9qOvuW_offset-xxl-7 {
    margin-left: 58.3333%;
  }

  ._9qOvuW_offset-xxl-8 {
    margin-left: 66.6667%;
  }

  ._9qOvuW_offset-xxl-9 {
    margin-left: 75%;
  }

  ._9qOvuW_offset-xxl-10 {
    margin-left: 83.3333%;
  }

  ._9qOvuW_offset-xxl-11 {
    margin-left: 91.6667%;
  }

  ._9qOvuW_g-xxl-0, ._9qOvuW_gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  ._9qOvuW_g-xxl-0, ._9qOvuW_gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  ._9qOvuW_g-xxl-1, ._9qOvuW_gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  ._9qOvuW_g-xxl-1, ._9qOvuW_gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  ._9qOvuW_g-xxl-2, ._9qOvuW_gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  ._9qOvuW_g-xxl-2, ._9qOvuW_gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  ._9qOvuW_g-xxl-3, ._9qOvuW_gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  ._9qOvuW_g-xxl-3, ._9qOvuW_gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  ._9qOvuW_g-xxl-4, ._9qOvuW_gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  ._9qOvuW_g-xxl-4, ._9qOvuW_gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  ._9qOvuW_g-xxl-5, ._9qOvuW_gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  ._9qOvuW_g-xxl-5, ._9qOvuW_gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

._9qOvuW_table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

._9qOvuW_table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

._9qOvuW_table > tbody {
  vertical-align: inherit;
}

._9qOvuW_table > thead {
  vertical-align: bottom;
}

._9qOvuW_table-group-divider {
  border-top: 2px solid;
}

._9qOvuW_caption-top {
  caption-side: top;
}

._9qOvuW_table-sm > :not(caption) > * > * {
  padding: .25rem;
}

._9qOvuW_table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

._9qOvuW_table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

._9qOvuW_table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

._9qOvuW_table-borderless > :not(:first-child) {
  border-top-width: 0;
}

._9qOvuW_table-striped > tbody > tr:nth-of-type(2n+1) > *, ._9qOvuW_table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

._9qOvuW_table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

._9qOvuW_table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

._9qOvuW_table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d3e8cc;
  --bs-table-border-color: #bed1b8;
  --bs-table-striped-bg: #c8dcc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bed1b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3d7bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

._9qOvuW_table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  ._9qOvuW_table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  ._9qOvuW_table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  ._9qOvuW_table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  ._9qOvuW_table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  ._9qOvuW_table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

._9qOvuW_form-label {
  margin-bottom: .5rem;
}

._9qOvuW_col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

._9qOvuW_col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

._9qOvuW_col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

._9qOvuW_form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

._9qOvuW_form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-control {
    transition: none;
  }
}

._9qOvuW_form-control[type="file"] {
  overflow: hidden;
}

._9qOvuW_form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._9qOvuW_form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._9qOvuW_form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

._9qOvuW_form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

._9qOvuW_form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

._9qOvuW_form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-control::file-selector-button {
    transition: none;
  }
}

._9qOvuW_form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

._9qOvuW_form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

._9qOvuW_form-control-plaintext:focus {
  outline: 0;
}

._9qOvuW_form-control-plaintext._9qOvuW_form-control-sm, ._9qOvuW_form-control-plaintext._9qOvuW_form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

._9qOvuW_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._9qOvuW_form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

._9qOvuW_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._9qOvuW_form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea._9qOvuW_form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea._9qOvuW_form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea._9qOvuW_form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

._9qOvuW_form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

._9qOvuW_form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

._9qOvuW_form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

._9qOvuW_form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

._9qOvuW_form-control-color._9qOvuW_form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

._9qOvuW_form-control-color._9qOvuW_form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

._9qOvuW_form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-select {
    transition: none;
  }
}

._9qOvuW_form-select:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._9qOvuW_form-select[multiple], ._9qOvuW_form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

._9qOvuW_form-select:disabled {
  background-color: #e9ecef;
}

._9qOvuW_form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

._9qOvuW_form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

._9qOvuW_form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

._9qOvuW_form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

._9qOvuW_form-check ._9qOvuW_form-check-input {
  float: left;
  margin-left: -1.5em;
}

._9qOvuW_form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

._9qOvuW_form-check-reverse ._9qOvuW_form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

._9qOvuW_form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

._9qOvuW_form-check-input[type="checkbox"] {
  border-radius: .25em;
}

._9qOvuW_form-check-input[type="radio"] {
  border-radius: 50%;
}

._9qOvuW_form-check-input:active {
  filter: brightness(90%);
}

._9qOvuW_form-check-input:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._9qOvuW_form-check-input:checked {
  background-color: #258e01;
  border-color: #258e01;
}

._9qOvuW_form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

._9qOvuW_form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

._9qOvuW_form-check-input[type="checkbox"]:indeterminate {
  background-color: #258e01;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #258e01;
}

._9qOvuW_form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

._9qOvuW_form-check-input[disabled] ~ ._9qOvuW_form-check-label, ._9qOvuW_form-check-input:disabled ~ ._9qOvuW_form-check-label {
  cursor: default;
  opacity: .5;
}

._9qOvuW_form-switch {
  padding-left: 2.5em;
}

._9qOvuW_form-switch ._9qOvuW_form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-switch ._9qOvuW_form-check-input {
    transition: none;
  }
}

._9qOvuW_form-switch ._9qOvuW_form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2392c780'/%3e%3c/svg%3e");
}

._9qOvuW_form-switch ._9qOvuW_form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

._9qOvuW_form-switch._9qOvuW_form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

._9qOvuW_form-switch._9qOvuW_form-check-reverse ._9qOvuW_form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

._9qOvuW_form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

._9qOvuW_btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

._9qOvuW_btn-check[disabled] + ._9qOvuW_btn, ._9qOvuW_btn-check:disabled + ._9qOvuW_btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

._9qOvuW_form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

._9qOvuW_form-range:focus {
  outline: 0;
}

._9qOvuW_form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

._9qOvuW_form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

._9qOvuW_form-range::-moz-focus-outer {
  border: 0;
}

._9qOvuW_form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-range::-webkit-slider-thumb {
    transition: none;
  }
}

._9qOvuW_form-range::-webkit-slider-thumb:active {
  background-color: #beddb3;
}

._9qOvuW_form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._9qOvuW_form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-range::-moz-range-thumb {
    transition: none;
  }
}

._9qOvuW_form-range::-moz-range-thumb:active {
  background-color: #beddb3;
}

._9qOvuW_form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

._9qOvuW_form-range:disabled {
  pointer-events: none;
}

._9qOvuW_form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

._9qOvuW_form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

._9qOvuW_form-floating {
  position: relative;
}

._9qOvuW_form-floating > ._9qOvuW_form-control, ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext, ._9qOvuW_form-floating > ._9qOvuW_form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

._9qOvuW_form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_form-floating > label {
    transition: none;
  }
}

._9qOvuW_form-floating > ._9qOvuW_form-control, ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext {
  padding: 1rem .75rem;
}

._9qOvuW_form-floating > ._9qOvuW_form-control::placeholder, ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext::placeholder {
  color: #0000;
}

._9qOvuW_form-floating > ._9qOvuW_form-control:focus, ._9qOvuW_form-floating > ._9qOvuW_form-control:not(:placeholder-shown), ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext:focus, ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._9qOvuW_form-floating > ._9qOvuW_form-control:-webkit-autofill, ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._9qOvuW_form-floating > ._9qOvuW_form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

._9qOvuW_form-floating > ._9qOvuW_form-control:focus ~ label, ._9qOvuW_form-floating > ._9qOvuW_form-control:not(:placeholder-shown) ~ label, ._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext ~ label, ._9qOvuW_form-floating > ._9qOvuW_form-select ~ label, ._9qOvuW_form-floating > ._9qOvuW_form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

._9qOvuW_form-floating > ._9qOvuW_form-control-plaintext ~ label {
  border-width: 1px 0;
}

._9qOvuW_input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

._9qOvuW_input-group > ._9qOvuW_form-control, ._9qOvuW_input-group > ._9qOvuW_form-select, ._9qOvuW_input-group > ._9qOvuW_form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

._9qOvuW_input-group > ._9qOvuW_form-control:focus, ._9qOvuW_input-group > ._9qOvuW_form-select:focus, ._9qOvuW_input-group > ._9qOvuW_form-floating:focus-within {
  z-index: 5;
}

._9qOvuW_input-group ._9qOvuW_btn {
  z-index: 2;
  position: relative;
}

._9qOvuW_input-group ._9qOvuW_btn:focus {
  z-index: 5;
}

._9qOvuW_input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

._9qOvuW_input-group-lg > ._9qOvuW_form-control, ._9qOvuW_input-group-lg > ._9qOvuW_form-select, ._9qOvuW_input-group-lg > ._9qOvuW_input-group-text, ._9qOvuW_input-group-lg > ._9qOvuW_btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

._9qOvuW_input-group-sm > ._9qOvuW_form-control, ._9qOvuW_input-group-sm > ._9qOvuW_form-select, ._9qOvuW_input-group-sm > ._9qOvuW_input-group-text, ._9qOvuW_input-group-sm > ._9qOvuW_btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

._9qOvuW_input-group-lg > ._9qOvuW_form-select, ._9qOvuW_input-group-sm > ._9qOvuW_form-select {
  padding-right: 3rem;
}

._9qOvuW_input-group:not(._9qOvuW_has-validation) > :not(:last-child):not(._9qOvuW_dropdown-toggle):not(._9qOvuW_dropdown-menu):not(._9qOvuW_form-floating), ._9qOvuW_input-group:not(._9qOvuW_has-validation) > ._9qOvuW_dropdown-toggle:nth-last-child(n+3), ._9qOvuW_input-group:not(._9qOvuW_has-validation) > ._9qOvuW_form-floating:not(:last-child) > ._9qOvuW_form-control, ._9qOvuW_input-group:not(._9qOvuW_has-validation) > ._9qOvuW_form-floating:not(:last-child) > ._9qOvuW_form-select, ._9qOvuW_input-group._9qOvuW_has-validation > :nth-last-child(n+3):not(._9qOvuW_dropdown-toggle):not(._9qOvuW_dropdown-menu):not(._9qOvuW_form-floating), ._9qOvuW_input-group._9qOvuW_has-validation > ._9qOvuW_dropdown-toggle:nth-last-child(n+4), ._9qOvuW_input-group._9qOvuW_has-validation > ._9qOvuW_form-floating:nth-last-child(n+3) > ._9qOvuW_form-control, ._9qOvuW_input-group._9qOvuW_has-validation > ._9qOvuW_form-floating:nth-last-child(n+3) > ._9qOvuW_form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._9qOvuW_input-group > :not(:first-child):not(._9qOvuW_dropdown-menu):not(._9qOvuW_valid-tooltip):not(._9qOvuW_valid-feedback):not(._9qOvuW_invalid-tooltip):not(._9qOvuW_invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

._9qOvuW_input-group > ._9qOvuW_form-floating:not(:first-child) > ._9qOvuW_form-control, ._9qOvuW_input-group > ._9qOvuW_form-floating:not(:first-child) > ._9qOvuW_form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._9qOvuW_valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._9qOvuW_valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._9qOvuW_was-validated :valid ~ ._9qOvuW_valid-feedback, ._9qOvuW_was-validated :valid ~ ._9qOvuW_valid-tooltip, ._9qOvuW_is-valid ~ ._9qOvuW_valid-feedback, ._9qOvuW_is-valid ~ ._9qOvuW_valid-tooltip {
  display: block;
}

._9qOvuW_was-validated ._9qOvuW_form-control:valid, ._9qOvuW_form-control._9qOvuW_is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

._9qOvuW_was-validated ._9qOvuW_form-control:valid:focus, ._9qOvuW_form-control._9qOvuW_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._9qOvuW_was-validated textarea._9qOvuW_form-control:valid, textarea._9qOvuW_form-control._9qOvuW_is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._9qOvuW_was-validated ._9qOvuW_form-select:valid, ._9qOvuW_form-select._9qOvuW_is-valid {
  border-color: #198754;
}

._9qOvuW_was-validated ._9qOvuW_form-select:valid:not([multiple]):not([size]), ._9qOvuW_was-validated ._9qOvuW_form-select:valid:not([multiple])[size="1"], ._9qOvuW_form-select._9qOvuW_is-valid:not([multiple]):not([size]), ._9qOvuW_form-select._9qOvuW_is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._9qOvuW_was-validated ._9qOvuW_form-select:valid:focus, ._9qOvuW_form-select._9qOvuW_is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

._9qOvuW_was-validated ._9qOvuW_form-control-color:valid, ._9qOvuW_form-control-color._9qOvuW_is-valid {
  width: calc(1.5em + 3.75rem);
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:valid, ._9qOvuW_form-check-input._9qOvuW_is-valid {
  border-color: #198754;
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:valid:checked, ._9qOvuW_form-check-input._9qOvuW_is-valid:checked {
  background-color: #198754;
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:valid:focus, ._9qOvuW_form-check-input._9qOvuW_is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:valid ~ ._9qOvuW_form-check-label, ._9qOvuW_form-check-input._9qOvuW_is-valid ~ ._9qOvuW_form-check-label {
  color: #198754;
}

._9qOvuW_form-check-inline ._9qOvuW_form-check-input ~ ._9qOvuW_valid-feedback {
  margin-left: .5em;
}

._9qOvuW_was-validated ._9qOvuW_input-group > ._9qOvuW_form-control:not(:focus):valid, ._9qOvuW_input-group > ._9qOvuW_form-control:not(:focus)._9qOvuW_is-valid, ._9qOvuW_was-validated ._9qOvuW_input-group > ._9qOvuW_form-select:not(:focus):valid, ._9qOvuW_input-group > ._9qOvuW_form-select:not(:focus)._9qOvuW_is-valid, ._9qOvuW_was-validated ._9qOvuW_input-group > ._9qOvuW_form-floating:not(:focus-within):valid, ._9qOvuW_input-group > ._9qOvuW_form-floating:not(:focus-within)._9qOvuW_is-valid {
  z-index: 3;
}

._9qOvuW_invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

._9qOvuW_invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

._9qOvuW_was-validated :invalid ~ ._9qOvuW_invalid-feedback, ._9qOvuW_was-validated :invalid ~ ._9qOvuW_invalid-tooltip, ._9qOvuW_is-invalid ~ ._9qOvuW_invalid-feedback, ._9qOvuW_is-invalid ~ ._9qOvuW_invalid-tooltip {
  display: block;
}

._9qOvuW_was-validated ._9qOvuW_form-control:invalid, ._9qOvuW_form-control._9qOvuW_is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

._9qOvuW_was-validated ._9qOvuW_form-control:invalid:focus, ._9qOvuW_form-control._9qOvuW_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._9qOvuW_was-validated textarea._9qOvuW_form-control:invalid, textarea._9qOvuW_form-control._9qOvuW_is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

._9qOvuW_was-validated ._9qOvuW_form-select:invalid, ._9qOvuW_form-select._9qOvuW_is-invalid {
  border-color: #dc3545;
}

._9qOvuW_was-validated ._9qOvuW_form-select:invalid:not([multiple]):not([size]), ._9qOvuW_was-validated ._9qOvuW_form-select:invalid:not([multiple])[size="1"], ._9qOvuW_form-select._9qOvuW_is-invalid:not([multiple]):not([size]), ._9qOvuW_form-select._9qOvuW_is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

._9qOvuW_was-validated ._9qOvuW_form-select:invalid:focus, ._9qOvuW_form-select._9qOvuW_is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

._9qOvuW_was-validated ._9qOvuW_form-control-color:invalid, ._9qOvuW_form-control-color._9qOvuW_is-invalid {
  width: calc(1.5em + 3.75rem);
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:invalid, ._9qOvuW_form-check-input._9qOvuW_is-invalid {
  border-color: #dc3545;
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:invalid:checked, ._9qOvuW_form-check-input._9qOvuW_is-invalid:checked {
  background-color: #dc3545;
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:invalid:focus, ._9qOvuW_form-check-input._9qOvuW_is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

._9qOvuW_was-validated ._9qOvuW_form-check-input:invalid ~ ._9qOvuW_form-check-label, ._9qOvuW_form-check-input._9qOvuW_is-invalid ~ ._9qOvuW_form-check-label {
  color: #dc3545;
}

._9qOvuW_form-check-inline ._9qOvuW_form-check-input ~ ._9qOvuW_invalid-feedback {
  margin-left: .5em;
}

._9qOvuW_was-validated ._9qOvuW_input-group > ._9qOvuW_form-control:not(:focus):invalid, ._9qOvuW_input-group > ._9qOvuW_form-control:not(:focus)._9qOvuW_is-invalid, ._9qOvuW_was-validated ._9qOvuW_input-group > ._9qOvuW_form-select:not(:focus):invalid, ._9qOvuW_input-group > ._9qOvuW_form-select:not(:focus)._9qOvuW_is-invalid, ._9qOvuW_was-validated ._9qOvuW_input-group > ._9qOvuW_form-floating:not(:focus-within):invalid, ._9qOvuW_input-group > ._9qOvuW_form-floating:not(:focus-within)._9qOvuW_is-invalid {
  z-index: 4;
}

._9qOvuW_btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_btn {
    transition: none;
  }
}

._9qOvuW_btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

._9qOvuW_btn-check + ._9qOvuW_btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

._9qOvuW_btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._9qOvuW_btn-check:focus-visible + ._9qOvuW_btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

._9qOvuW_btn-check:checked + ._9qOvuW_btn, :not(._9qOvuW_btn-check) + ._9qOvuW_btn:active, ._9qOvuW_btn:first-child:active, ._9qOvuW_btn._9qOvuW_active, ._9qOvuW_btn._9qOvuW_show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

._9qOvuW_btn-check:checked + ._9qOvuW_btn:focus-visible, :not(._9qOvuW_btn-check) + ._9qOvuW_btn:active:focus-visible, ._9qOvuW_btn:first-child:active:focus-visible, ._9qOvuW_btn._9qOvuW_active:focus-visible, ._9qOvuW_btn._9qOvuW_show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

._9qOvuW_btn:disabled, ._9qOvuW_btn._9qOvuW_disabled, fieldset:disabled ._9qOvuW_btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

._9qOvuW_btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #469f27;
  --bs-btn-hover-border-color: #3b991a;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #51a534;
  --bs-btn-active-border-color: #3b991a;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #258e01;
  --bs-btn-disabled-border-color: #258e01;
}

._9qOvuW_btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

._9qOvuW_btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

._9qOvuW_btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

._9qOvuW_btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

._9qOvuW_btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

._9qOvuW_btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

._9qOvuW_btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

._9qOvuW_btn-outline-primary {
  --bs-btn-color: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #258e01;
  --bs-btn-hover-border-color: #258e01;
  --bs-btn-focus-shadow-rgb: 37, 142, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #258e01;
  --bs-btn-active-border-color: #258e01;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #258e01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #258e01;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

._9qOvuW_btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

._9qOvuW_btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  text-decoration: underline;
}

._9qOvuW_btn-link:focus-visible {
  color: var(--bs-btn-color);
}

._9qOvuW_btn-link:hover {
  color: var(--bs-btn-hover-color);
}

._9qOvuW_btn-lg, ._9qOvuW_btn-group-lg > ._9qOvuW_btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

._9qOvuW_btn-sm, ._9qOvuW_btn-group-sm > ._9qOvuW_btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

._9qOvuW_fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_fade {
    transition: none;
  }
}

._9qOvuW_fade:not(._9qOvuW_show) {
  opacity: 0;
}

._9qOvuW_collapse:not(._9qOvuW_show) {
  display: none;
}

._9qOvuW_collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_collapsing {
    transition: none;
  }
}

._9qOvuW_collapsing._9qOvuW_collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_collapsing._9qOvuW_collapse-horizontal {
    transition: none;
  }
}

._9qOvuW_dropup, ._9qOvuW_dropend, ._9qOvuW_dropdown, ._9qOvuW_dropstart, ._9qOvuW_dropup-center, ._9qOvuW_dropdown-center {
  position: relative;
}

._9qOvuW_dropdown-toggle {
  white-space: nowrap;
}

._9qOvuW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

._9qOvuW_dropdown-toggle:empty:after {
  margin-left: 0;
}

._9qOvuW_dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

._9qOvuW_dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

._9qOvuW_dropdown-menu-start {
  --bs-position: start;
}

._9qOvuW_dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

._9qOvuW_dropdown-menu-end {
  --bs-position: end;
}

._9qOvuW_dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  ._9qOvuW_dropdown-menu-sm-start {
    --bs-position: start;
  }

  ._9qOvuW_dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._9qOvuW_dropdown-menu-sm-end {
    --bs-position: end;
  }

  ._9qOvuW_dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_dropdown-menu-md-start {
    --bs-position: start;
  }

  ._9qOvuW_dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._9qOvuW_dropdown-menu-md-end {
    --bs-position: end;
  }

  ._9qOvuW_dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_dropdown-menu-lg-start {
    --bs-position: start;
  }

  ._9qOvuW_dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._9qOvuW_dropdown-menu-lg-end {
    --bs-position: end;
  }

  ._9qOvuW_dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_dropdown-menu-xl-start {
    --bs-position: start;
  }

  ._9qOvuW_dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._9qOvuW_dropdown-menu-xl-end {
    --bs-position: end;
  }

  ._9qOvuW_dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_dropdown-menu-xxl-start {
    --bs-position: start;
  }

  ._9qOvuW_dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  ._9qOvuW_dropdown-menu-xxl-end {
    --bs-position: end;
  }

  ._9qOvuW_dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

._9qOvuW_dropup ._9qOvuW_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

._9qOvuW_dropup ._9qOvuW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

._9qOvuW_dropup ._9qOvuW_dropdown-toggle:empty:after {
  margin-left: 0;
}

._9qOvuW_dropend ._9qOvuW_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

._9qOvuW_dropend ._9qOvuW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

._9qOvuW_dropend ._9qOvuW_dropdown-toggle:empty:after {
  margin-left: 0;
}

._9qOvuW_dropend ._9qOvuW_dropdown-toggle:after {
  vertical-align: 0;
}

._9qOvuW_dropstart ._9qOvuW_dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

._9qOvuW_dropstart ._9qOvuW_dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

._9qOvuW_dropstart ._9qOvuW_dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

._9qOvuW_dropstart ._9qOvuW_dropdown-toggle:empty:after {
  margin-left: 0;
}

._9qOvuW_dropstart ._9qOvuW_dropdown-toggle:before {
  vertical-align: 0;
}

._9qOvuW_dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

._9qOvuW_dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

._9qOvuW_dropdown-item:hover, ._9qOvuW_dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

._9qOvuW_dropdown-item._9qOvuW_active, ._9qOvuW_dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

._9qOvuW_dropdown-item._9qOvuW_disabled, ._9qOvuW_dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

._9qOvuW_dropdown-menu._9qOvuW_show {
  display: block;
}

._9qOvuW_dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

._9qOvuW_dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

._9qOvuW_dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

._9qOvuW_btn-group, ._9qOvuW_btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

._9qOvuW_btn-group > ._9qOvuW_btn, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn {
  flex: auto;
  position: relative;
}

._9qOvuW_btn-group > ._9qOvuW_btn-check:checked + ._9qOvuW_btn, ._9qOvuW_btn-group > ._9qOvuW_btn-check:focus + ._9qOvuW_btn, ._9qOvuW_btn-group > ._9qOvuW_btn:hover, ._9qOvuW_btn-group > ._9qOvuW_btn:focus, ._9qOvuW_btn-group > ._9qOvuW_btn:active, ._9qOvuW_btn-group > ._9qOvuW_btn._9qOvuW_active, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn-check:checked + ._9qOvuW_btn, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn-check:focus + ._9qOvuW_btn, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn:hover, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn:focus, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn:active, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn._9qOvuW_active {
  z-index: 1;
}

._9qOvuW_btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

._9qOvuW_btn-toolbar ._9qOvuW_input-group {
  width: auto;
}

._9qOvuW_btn-group {
  border-radius: .375rem;
}

._9qOvuW_btn-group > :not(._9qOvuW_btn-check:first-child) + ._9qOvuW_btn, ._9qOvuW_btn-group > ._9qOvuW_btn-group:not(:first-child) {
  margin-left: -1px;
}

._9qOvuW_btn-group > ._9qOvuW_btn:not(:last-child):not(._9qOvuW_dropdown-toggle), ._9qOvuW_btn-group > ._9qOvuW_btn._9qOvuW_dropdown-toggle-split:first-child, ._9qOvuW_btn-group > ._9qOvuW_btn-group:not(:last-child) > ._9qOvuW_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._9qOvuW_btn-group > ._9qOvuW_btn:nth-child(n+3), ._9qOvuW_btn-group > :not(._9qOvuW_btn-check) + ._9qOvuW_btn, ._9qOvuW_btn-group > ._9qOvuW_btn-group:not(:first-child) > ._9qOvuW_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._9qOvuW_dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

._9qOvuW_dropdown-toggle-split:after, ._9qOvuW_dropup ._9qOvuW_dropdown-toggle-split:after, ._9qOvuW_dropend ._9qOvuW_dropdown-toggle-split:after {
  margin-left: 0;
}

._9qOvuW_dropstart ._9qOvuW_dropdown-toggle-split:before {
  margin-right: 0;
}

._9qOvuW_btn-sm + ._9qOvuW_dropdown-toggle-split, ._9qOvuW_btn-group-sm > ._9qOvuW_btn + ._9qOvuW_dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

._9qOvuW_btn-lg + ._9qOvuW_dropdown-toggle-split, ._9qOvuW_btn-group-lg > ._9qOvuW_btn + ._9qOvuW_dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

._9qOvuW_btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

._9qOvuW_btn-group-vertical > ._9qOvuW_btn, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn-group {
  width: 100%;
}

._9qOvuW_btn-group-vertical > ._9qOvuW_btn:not(:first-child), ._9qOvuW_btn-group-vertical > ._9qOvuW_btn-group:not(:first-child) {
  margin-top: -1px;
}

._9qOvuW_btn-group-vertical > ._9qOvuW_btn:not(:last-child):not(._9qOvuW_dropdown-toggle), ._9qOvuW_btn-group-vertical > ._9qOvuW_btn-group:not(:last-child) > ._9qOvuW_btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

._9qOvuW_btn-group-vertical > ._9qOvuW_btn ~ ._9qOvuW_btn, ._9qOvuW_btn-group-vertical > ._9qOvuW_btn-group:not(:first-child) > ._9qOvuW_btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._9qOvuW_nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._9qOvuW_nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_nav-link {
    transition: none;
  }
}

._9qOvuW_nav-link:hover, ._9qOvuW_nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

._9qOvuW_nav-link._9qOvuW_disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

._9qOvuW_nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

._9qOvuW_nav-tabs ._9qOvuW_nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

._9qOvuW_nav-tabs ._9qOvuW_nav-link:hover, ._9qOvuW_nav-tabs ._9qOvuW_nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

._9qOvuW_nav-tabs ._9qOvuW_nav-link._9qOvuW_disabled, ._9qOvuW_nav-tabs ._9qOvuW_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._9qOvuW_nav-tabs ._9qOvuW_nav-link._9qOvuW_active, ._9qOvuW_nav-tabs ._9qOvuW_nav-item._9qOvuW_show ._9qOvuW_nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

._9qOvuW_nav-tabs ._9qOvuW_dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._9qOvuW_nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #258e01;
}

._9qOvuW_nav-pills ._9qOvuW_nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

._9qOvuW_nav-pills ._9qOvuW_nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

._9qOvuW_nav-pills ._9qOvuW_nav-link._9qOvuW_active, ._9qOvuW_nav-pills ._9qOvuW_show > ._9qOvuW_nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

._9qOvuW_nav-fill > ._9qOvuW_nav-link, ._9qOvuW_nav-fill ._9qOvuW_nav-item {
  text-align: center;
  flex: auto;
}

._9qOvuW_nav-justified > ._9qOvuW_nav-link, ._9qOvuW_nav-justified ._9qOvuW_nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

._9qOvuW_nav-fill ._9qOvuW_nav-item ._9qOvuW_nav-link, ._9qOvuW_nav-justified ._9qOvuW_nav-item ._9qOvuW_nav-link {
  width: 100%;
}

._9qOvuW_tab-content > ._9qOvuW_tab-pane {
  display: none;
}

._9qOvuW_tab-content > ._9qOvuW_active {
  display: block;
}

._9qOvuW_navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

._9qOvuW_navbar > ._9qOvuW_container, ._9qOvuW_navbar > ._9qOvuW_container-fluid, ._9qOvuW_navbar > ._9qOvuW_container-sm, ._9qOvuW_navbar > ._9qOvuW_container-md, ._9qOvuW_navbar > ._9qOvuW_container-lg, ._9qOvuW_navbar > ._9qOvuW_container-xl, ._9qOvuW_navbar > ._9qOvuW_container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._9qOvuW_navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

._9qOvuW_navbar-brand:hover, ._9qOvuW_navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

._9qOvuW_navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._9qOvuW_navbar-nav ._9qOvuW_show > ._9qOvuW_nav-link, ._9qOvuW_navbar-nav ._9qOvuW_nav-link._9qOvuW_active {
  color: var(--bs-navbar-active-color);
}

._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
  position: static;
}

._9qOvuW_navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

._9qOvuW_navbar-text a, ._9qOvuW_navbar-text a:hover, ._9qOvuW_navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

._9qOvuW_navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

._9qOvuW_navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_navbar-toggler {
    transition: none;
  }
}

._9qOvuW_navbar-toggler:hover {
  text-decoration: none;
}

._9qOvuW_navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

._9qOvuW_navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

._9qOvuW_navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  ._9qOvuW_navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_navbar-nav {
    flex-direction: row;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
    position: absolute;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_navbar-nav ._9qOvuW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_navbar-nav-scroll {
    overflow: visible;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_navbar-toggler {
    display: none;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_offcanvas ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_navbar-expand-sm ._9qOvuW_offcanvas ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_navbar-nav {
    flex-direction: row;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
    position: absolute;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_navbar-nav ._9qOvuW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_navbar-nav-scroll {
    overflow: visible;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_navbar-toggler {
    display: none;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_offcanvas ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_navbar-expand-md ._9qOvuW_offcanvas ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_navbar-nav {
    flex-direction: row;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
    position: absolute;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_navbar-nav ._9qOvuW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_navbar-nav-scroll {
    overflow: visible;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_navbar-toggler {
    display: none;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_offcanvas ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_navbar-expand-lg ._9qOvuW_offcanvas ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_navbar-nav {
    flex-direction: row;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
    position: absolute;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_navbar-nav ._9qOvuW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_navbar-nav-scroll {
    overflow: visible;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_navbar-toggler {
    display: none;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_offcanvas ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_navbar-expand-xl ._9qOvuW_offcanvas ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_navbar-nav {
    flex-direction: row;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
    position: absolute;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_navbar-nav ._9qOvuW_nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_navbar-nav-scroll {
    overflow: visible;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_navbar-toggler {
    display: none;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_offcanvas ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_navbar-expand-xxl ._9qOvuW_offcanvas ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

._9qOvuW_navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

._9qOvuW_navbar-expand ._9qOvuW_navbar-nav {
  flex-direction: row;
}

._9qOvuW_navbar-expand ._9qOvuW_navbar-nav ._9qOvuW_dropdown-menu {
  position: absolute;
}

._9qOvuW_navbar-expand ._9qOvuW_navbar-nav ._9qOvuW_nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

._9qOvuW_navbar-expand ._9qOvuW_navbar-nav-scroll {
  overflow: visible;
}

._9qOvuW_navbar-expand ._9qOvuW_navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

._9qOvuW_navbar-expand ._9qOvuW_navbar-toggler {
  display: none;
}

._9qOvuW_navbar-expand ._9qOvuW_offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

._9qOvuW_navbar-expand ._9qOvuW_offcanvas ._9qOvuW_offcanvas-header {
  display: none;
}

._9qOvuW_navbar-expand ._9qOvuW_offcanvas ._9qOvuW_offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

._9qOvuW_navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

._9qOvuW_card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

._9qOvuW_card > hr {
  margin-left: 0;
  margin-right: 0;
}

._9qOvuW_card > ._9qOvuW_list-group {
  border-top: inherit;
  border-bottom: inherit;
}

._9qOvuW_card > ._9qOvuW_list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

._9qOvuW_card > ._9qOvuW_list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

._9qOvuW_card > ._9qOvuW_card-header + ._9qOvuW_list-group, ._9qOvuW_card > ._9qOvuW_list-group + ._9qOvuW_card-footer {
  border-top: 0;
}

._9qOvuW_card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

._9qOvuW_card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

._9qOvuW_card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

._9qOvuW_card-text:last-child {
  margin-bottom: 0;
}

._9qOvuW_card-link + ._9qOvuW_card-link {
  margin-left: var(--bs-card-spacer-x);
}

._9qOvuW_card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

._9qOvuW_card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

._9qOvuW_card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

._9qOvuW_card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

._9qOvuW_card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

._9qOvuW_card-header-tabs ._9qOvuW_nav-link._9qOvuW_active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

._9qOvuW_card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

._9qOvuW_card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

._9qOvuW_card-img, ._9qOvuW_card-img-top, ._9qOvuW_card-img-bottom {
  width: 100%;
}

._9qOvuW_card-img, ._9qOvuW_card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

._9qOvuW_card-img, ._9qOvuW_card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

._9qOvuW_card-group > ._9qOvuW_card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  ._9qOvuW_card-group {
    flex-flow: wrap;
    display: flex;
  }

  ._9qOvuW_card-group > ._9qOvuW_card {
    flex: 1 0;
    margin-bottom: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card + ._9qOvuW_card {
    border-left: 0;
    margin-left: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card:not(:last-child) ._9qOvuW_card-img-top, ._9qOvuW_card-group > ._9qOvuW_card:not(:last-child) ._9qOvuW_card-header {
    border-top-right-radius: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card:not(:last-child) ._9qOvuW_card-img-bottom, ._9qOvuW_card-group > ._9qOvuW_card:not(:last-child) ._9qOvuW_card-footer {
    border-bottom-right-radius: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card:not(:first-child) ._9qOvuW_card-img-top, ._9qOvuW_card-group > ._9qOvuW_card:not(:first-child) ._9qOvuW_card-header {
    border-top-left-radius: 0;
  }

  ._9qOvuW_card-group > ._9qOvuW_card:not(:first-child) ._9qOvuW_card-img-bottom, ._9qOvuW_card-group > ._9qOvuW_card:not(:first-child) ._9qOvuW_card-footer {
    border-bottom-left-radius: 0;
  }
}

._9qOvuW_accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23218001'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #92c780;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #218001;
  --bs-accordion-active-bg: #e9f4e6;
}

._9qOvuW_accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_accordion-button {
    transition: none;
  }
}

._9qOvuW_accordion-button:not(._9qOvuW_collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

._9qOvuW_accordion-button:not(._9qOvuW_collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

._9qOvuW_accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_accordion-button:after {
    transition: none;
  }
}

._9qOvuW_accordion-button:hover {
  z-index: 2;
}

._9qOvuW_accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

._9qOvuW_accordion-header {
  margin-bottom: 0;
}

._9qOvuW_accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

._9qOvuW_accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

._9qOvuW_accordion-item:first-of-type ._9qOvuW_accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

._9qOvuW_accordion-item:not(:first-of-type) {
  border-top: 0;
}

._9qOvuW_accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._9qOvuW_accordion-item:last-of-type ._9qOvuW_accordion-button._9qOvuW_collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

._9qOvuW_accordion-item:last-of-type ._9qOvuW_accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

._9qOvuW_accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

._9qOvuW_accordion-flush ._9qOvuW_accordion-collapse {
  border-width: 0;
}

._9qOvuW_accordion-flush ._9qOvuW_accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

._9qOvuW_accordion-flush ._9qOvuW_accordion-item:first-child {
  border-top: 0;
}

._9qOvuW_accordion-flush ._9qOvuW_accordion-item:last-child {
  border-bottom: 0;
}

._9qOvuW_accordion-flush ._9qOvuW_accordion-item ._9qOvuW_accordion-button, ._9qOvuW_accordion-flush ._9qOvuW_accordion-item ._9qOvuW_accordion-button._9qOvuW_collapsed {
  border-radius: 0;
}

._9qOvuW_breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

._9qOvuW_breadcrumb-item + ._9qOvuW_breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

._9qOvuW_breadcrumb-item + ._9qOvuW_breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

._9qOvuW_breadcrumb-item._9qOvuW_active {
  color: var(--bs-breadcrumb-item-active-color);
}

._9qOvuW_pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #258e01;
  --bs-pagination-active-border-color: #258e01;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

._9qOvuW_page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_page-link {
    transition: none;
  }
}

._9qOvuW_page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

._9qOvuW_page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

._9qOvuW_page-link._9qOvuW_active, ._9qOvuW_active > ._9qOvuW_page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

._9qOvuW_page-link._9qOvuW_disabled, ._9qOvuW_disabled > ._9qOvuW_page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

._9qOvuW_page-item:not(:first-child) ._9qOvuW_page-link {
  margin-left: -1px;
}

._9qOvuW_page-item:first-child ._9qOvuW_page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

._9qOvuW_page-item:last-child ._9qOvuW_page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

._9qOvuW_pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

._9qOvuW_pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

._9qOvuW_badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

._9qOvuW_badge:empty {
  display: none;
}

._9qOvuW_btn ._9qOvuW_badge {
  position: relative;
  top: -1px;
}

._9qOvuW_alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

._9qOvuW_alert-heading {
  color: inherit;
}

._9qOvuW_alert-link {
  font-weight: 700;
}

._9qOvuW_alert-dismissible {
  padding-right: 3rem;
}

._9qOvuW_alert-dismissible ._9qOvuW_btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

._9qOvuW_alert-primary {
  --bs-alert-color: #165501;
  --bs-alert-bg: #d3e8cc;
  --bs-alert-border-color: #beddb3;
}

._9qOvuW_alert-primary ._9qOvuW_alert-link {
  color: #124401;
}

._9qOvuW_alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

._9qOvuW_alert-secondary ._9qOvuW_alert-link {
  color: #34383c;
}

._9qOvuW_alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

._9qOvuW_alert-success ._9qOvuW_alert-link {
  color: #0c4128;
}

._9qOvuW_alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

._9qOvuW_alert-info ._9qOvuW_alert-link {
  color: #04414d;
}

._9qOvuW_alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

._9qOvuW_alert-warning ._9qOvuW_alert-link {
  color: #523e02;
}

._9qOvuW_alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

._9qOvuW_alert-danger ._9qOvuW_alert-link {
  color: #6a1a21;
}

._9qOvuW_alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

._9qOvuW_alert-light ._9qOvuW_alert-link {
  color: #4f5050;
}

._9qOvuW_alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

._9qOvuW_alert-dark ._9qOvuW_alert-link {
  color: #101214;
}

@keyframes _9qOvuW_progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

._9qOvuW_progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #258e01;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

._9qOvuW_progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_progress-bar {
    transition: none;
  }
}

._9qOvuW_progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

._9qOvuW_progress-bar-animated {
  animation: 1s linear infinite _9qOvuW_progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_progress-bar-animated {
    animation: none;
  }
}

._9qOvuW_list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #258e01;
  --bs-list-group-active-border-color: #258e01;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

._9qOvuW_list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

._9qOvuW_list-group-numbered > ._9qOvuW_list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

._9qOvuW_list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

._9qOvuW_list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

._9qOvuW_list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

._9qOvuW_list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

._9qOvuW_list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

._9qOvuW_list-group-item._9qOvuW_disabled, ._9qOvuW_list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

._9qOvuW_list-group-item._9qOvuW_active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
  border-top-width: 0;
}

._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

._9qOvuW_list-group-horizontal {
  flex-direction: row;
}

._9qOvuW_list-group-horizontal > ._9qOvuW_list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

._9qOvuW_list-group-horizontal > ._9qOvuW_list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

._9qOvuW_list-group-horizontal > ._9qOvuW_list-group-item._9qOvuW_active {
  margin-top: 0;
}

._9qOvuW_list-group-horizontal > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

._9qOvuW_list-group-horizontal > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  ._9qOvuW_list-group-horizontal-sm {
    flex-direction: row;
  }

  ._9qOvuW_list-group-horizontal-sm > ._9qOvuW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-sm > ._9qOvuW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-sm > ._9qOvuW_list-group-item._9qOvuW_active {
    margin-top: 0;
  }

  ._9qOvuW_list-group-horizontal-sm > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._9qOvuW_list-group-horizontal-sm > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  ._9qOvuW_list-group-horizontal-md {
    flex-direction: row;
  }

  ._9qOvuW_list-group-horizontal-md > ._9qOvuW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-md > ._9qOvuW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-md > ._9qOvuW_list-group-item._9qOvuW_active {
    margin-top: 0;
  }

  ._9qOvuW_list-group-horizontal-md > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._9qOvuW_list-group-horizontal-md > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  ._9qOvuW_list-group-horizontal-lg {
    flex-direction: row;
  }

  ._9qOvuW_list-group-horizontal-lg > ._9qOvuW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-lg > ._9qOvuW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-lg > ._9qOvuW_list-group-item._9qOvuW_active {
    margin-top: 0;
  }

  ._9qOvuW_list-group-horizontal-lg > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._9qOvuW_list-group-horizontal-lg > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_list-group-horizontal-xl {
    flex-direction: row;
  }

  ._9qOvuW_list-group-horizontal-xl > ._9qOvuW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-xl > ._9qOvuW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-xl > ._9qOvuW_list-group-item._9qOvuW_active {
    margin-top: 0;
  }

  ._9qOvuW_list-group-horizontal-xl > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._9qOvuW_list-group-horizontal-xl > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_list-group-horizontal-xxl {
    flex-direction: row;
  }

  ._9qOvuW_list-group-horizontal-xxl > ._9qOvuW_list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-xxl > ._9qOvuW_list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  ._9qOvuW_list-group-horizontal-xxl > ._9qOvuW_list-group-item._9qOvuW_active {
    margin-top: 0;
  }

  ._9qOvuW_list-group-horizontal-xxl > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  ._9qOvuW_list-group-horizontal-xxl > ._9qOvuW_list-group-item + ._9qOvuW_list-group-item._9qOvuW_active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

._9qOvuW_list-group-flush {
  border-radius: 0;
}

._9qOvuW_list-group-flush > ._9qOvuW_list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

._9qOvuW_list-group-flush > ._9qOvuW_list-group-item:last-child {
  border-bottom-width: 0;
}

._9qOvuW_list-group-item-primary {
  color: #165501;
  background-color: #d3e8cc;
}

._9qOvuW_list-group-item-primary._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-primary._9qOvuW_list-group-item-action:focus {
  color: #165501;
  background-color: #bed1b8;
}

._9qOvuW_list-group-item-primary._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #165501;
  border-color: #165501;
}

._9qOvuW_list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

._9qOvuW_list-group-item-secondary._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-secondary._9qOvuW_list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

._9qOvuW_list-group-item-secondary._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

._9qOvuW_list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

._9qOvuW_list-group-item-success._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-success._9qOvuW_list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

._9qOvuW_list-group-item-success._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

._9qOvuW_list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

._9qOvuW_list-group-item-info._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-info._9qOvuW_list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

._9qOvuW_list-group-item-info._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

._9qOvuW_list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

._9qOvuW_list-group-item-warning._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-warning._9qOvuW_list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

._9qOvuW_list-group-item-warning._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

._9qOvuW_list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

._9qOvuW_list-group-item-danger._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-danger._9qOvuW_list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

._9qOvuW_list-group-item-danger._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

._9qOvuW_list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

._9qOvuW_list-group-item-light._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-light._9qOvuW_list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

._9qOvuW_list-group-item-light._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

._9qOvuW_list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

._9qOvuW_list-group-item-dark._9qOvuW_list-group-item-action:hover, ._9qOvuW_list-group-item-dark._9qOvuW_list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

._9qOvuW_list-group-item-dark._9qOvuW_list-group-item-action._9qOvuW_active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

._9qOvuW_btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

._9qOvuW_btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

._9qOvuW_btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

._9qOvuW_btn-close:disabled, ._9qOvuW_btn-close._9qOvuW_disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

._9qOvuW_btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

._9qOvuW_toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

._9qOvuW_toast._9qOvuW_showing {
  opacity: 0;
}

._9qOvuW_toast:not(._9qOvuW_show) {
  display: none;
}

._9qOvuW_toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

._9qOvuW_toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

._9qOvuW_toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

._9qOvuW_toast-header ._9qOvuW_btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

._9qOvuW_toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

._9qOvuW_modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

._9qOvuW_modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

._9qOvuW_modal._9qOvuW_fade ._9qOvuW_modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_modal._9qOvuW_fade ._9qOvuW_modal-dialog {
    transition: none;
  }
}

._9qOvuW_modal._9qOvuW_show ._9qOvuW_modal-dialog {
  transform: none;
}

._9qOvuW_modal._9qOvuW_modal-static ._9qOvuW_modal-dialog {
  transform: scale(1.02);
}

._9qOvuW_modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

._9qOvuW_modal-dialog-scrollable ._9qOvuW_modal-content {
  max-height: 100%;
  overflow: hidden;
}

._9qOvuW_modal-dialog-scrollable ._9qOvuW_modal-body {
  overflow-y: auto;
}

._9qOvuW_modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

._9qOvuW_modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

._9qOvuW_modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

._9qOvuW_modal-backdrop._9qOvuW_fade {
  opacity: 0;
}

._9qOvuW_modal-backdrop._9qOvuW_show {
  opacity: var(--bs-backdrop-opacity);
}

._9qOvuW_modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._9qOvuW_modal-header ._9qOvuW_btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

._9qOvuW_modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

._9qOvuW_modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

._9qOvuW_modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._9qOvuW_modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  ._9qOvuW_modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  ._9qOvuW_modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  ._9qOvuW_modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_modal-lg, ._9qOvuW_modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_modal-xl {
    --bs-modal-width: 1140px;
  }
}

._9qOvuW_modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

._9qOvuW_modal-fullscreen ._9qOvuW_modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

._9qOvuW_modal-fullscreen ._9qOvuW_modal-header, ._9qOvuW_modal-fullscreen ._9qOvuW_modal-footer {
  border-radius: 0;
}

._9qOvuW_modal-fullscreen ._9qOvuW_modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  ._9qOvuW_modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._9qOvuW_modal-fullscreen-sm-down ._9qOvuW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-sm-down ._9qOvuW_modal-header, ._9qOvuW_modal-fullscreen-sm-down ._9qOvuW_modal-footer {
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-sm-down ._9qOvuW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  ._9qOvuW_modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._9qOvuW_modal-fullscreen-md-down ._9qOvuW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-md-down ._9qOvuW_modal-header, ._9qOvuW_modal-fullscreen-md-down ._9qOvuW_modal-footer {
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-md-down ._9qOvuW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  ._9qOvuW_modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._9qOvuW_modal-fullscreen-lg-down ._9qOvuW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-lg-down ._9qOvuW_modal-header, ._9qOvuW_modal-fullscreen-lg-down ._9qOvuW_modal-footer {
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-lg-down ._9qOvuW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  ._9qOvuW_modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._9qOvuW_modal-fullscreen-xl-down ._9qOvuW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-xl-down ._9qOvuW_modal-header, ._9qOvuW_modal-fullscreen-xl-down ._9qOvuW_modal-footer {
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-xl-down ._9qOvuW_modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  ._9qOvuW_modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  ._9qOvuW_modal-fullscreen-xxl-down ._9qOvuW_modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-xxl-down ._9qOvuW_modal-header, ._9qOvuW_modal-fullscreen-xxl-down ._9qOvuW_modal-footer {
    border-radius: 0;
  }

  ._9qOvuW_modal-fullscreen-xxl-down ._9qOvuW_modal-body {
    overflow-y: auto;
  }
}

._9qOvuW_tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

._9qOvuW_tooltip._9qOvuW_show {
  opacity: var(--bs-tooltip-opacity);
}

._9qOvuW_tooltip ._9qOvuW_tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

._9qOvuW_tooltip ._9qOvuW_tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

._9qOvuW_bs-tooltip-top ._9qOvuW_tooltip-arrow, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="top"] ._9qOvuW_tooltip-arrow {
  bottom: 0;
}

._9qOvuW_bs-tooltip-top ._9qOvuW_tooltip-arrow:before, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="top"] ._9qOvuW_tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

._9qOvuW_bs-tooltip-end ._9qOvuW_tooltip-arrow, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="right"] ._9qOvuW_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

._9qOvuW_bs-tooltip-end ._9qOvuW_tooltip-arrow:before, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="right"] ._9qOvuW_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

._9qOvuW_bs-tooltip-bottom ._9qOvuW_tooltip-arrow, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="bottom"] ._9qOvuW_tooltip-arrow {
  top: 0;
}

._9qOvuW_bs-tooltip-bottom ._9qOvuW_tooltip-arrow:before, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="bottom"] ._9qOvuW_tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

._9qOvuW_bs-tooltip-start ._9qOvuW_tooltip-arrow, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="left"] ._9qOvuW_tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

._9qOvuW_bs-tooltip-start ._9qOvuW_tooltip-arrow:before, ._9qOvuW_bs-tooltip-auto[data-popper-placement^="left"] ._9qOvuW_tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

._9qOvuW_tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

._9qOvuW_popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

._9qOvuW_popover ._9qOvuW_popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

._9qOvuW_popover ._9qOvuW_popover-arrow:before, ._9qOvuW_popover ._9qOvuW_popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

._9qOvuW_bs-popover-top > ._9qOvuW_popover-arrow, ._9qOvuW_bs-popover-auto[data-popper-placement^="top"] > ._9qOvuW_popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._9qOvuW_bs-popover-top > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="top"] > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-top > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="top"] > ._9qOvuW_popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._9qOvuW_bs-popover-top > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="top"] > ._9qOvuW_popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

._9qOvuW_bs-popover-top > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="top"] > ._9qOvuW_popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

._9qOvuW_bs-popover-end > ._9qOvuW_popover-arrow, ._9qOvuW_bs-popover-auto[data-popper-placement^="right"] > ._9qOvuW_popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._9qOvuW_bs-popover-end > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="right"] > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-end > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="right"] > ._9qOvuW_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

._9qOvuW_bs-popover-end > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="right"] > ._9qOvuW_popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

._9qOvuW_bs-popover-end > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="right"] > ._9qOvuW_popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

._9qOvuW_bs-popover-bottom > ._9qOvuW_popover-arrow, ._9qOvuW_bs-popover-auto[data-popper-placement^="bottom"] > ._9qOvuW_popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

._9qOvuW_bs-popover-bottom > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="bottom"] > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-bottom > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="bottom"] > ._9qOvuW_popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._9qOvuW_bs-popover-bottom > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="bottom"] > ._9qOvuW_popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

._9qOvuW_bs-popover-bottom > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="bottom"] > ._9qOvuW_popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

._9qOvuW_bs-popover-bottom ._9qOvuW_popover-header:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="bottom"] ._9qOvuW_popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

._9qOvuW_bs-popover-start > ._9qOvuW_popover-arrow, ._9qOvuW_bs-popover-auto[data-popper-placement^="left"] > ._9qOvuW_popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

._9qOvuW_bs-popover-start > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="left"] > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-start > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="left"] > ._9qOvuW_popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

._9qOvuW_bs-popover-start > ._9qOvuW_popover-arrow:before, ._9qOvuW_bs-popover-auto[data-popper-placement^="left"] > ._9qOvuW_popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

._9qOvuW_bs-popover-start > ._9qOvuW_popover-arrow:after, ._9qOvuW_bs-popover-auto[data-popper-placement^="left"] > ._9qOvuW_popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

._9qOvuW_popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

._9qOvuW_popover-header:empty {
  display: none;
}

._9qOvuW_popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

._9qOvuW_carousel {
  position: relative;
}

._9qOvuW_carousel._9qOvuW_pointer-event {
  touch-action: pan-y;
}

._9qOvuW_carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

._9qOvuW_carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

._9qOvuW_carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_carousel-item {
    transition: none;
  }
}

._9qOvuW_carousel-item._9qOvuW_active, ._9qOvuW_carousel-item-next, ._9qOvuW_carousel-item-prev {
  display: block;
}

._9qOvuW_carousel-item-next:not(._9qOvuW_carousel-item-start), ._9qOvuW_active._9qOvuW_carousel-item-end {
  transform: translateX(100%);
}

._9qOvuW_carousel-item-prev:not(._9qOvuW_carousel-item-end), ._9qOvuW_active._9qOvuW_carousel-item-start {
  transform: translateX(-100%);
}

._9qOvuW_carousel-fade ._9qOvuW_carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

._9qOvuW_carousel-fade ._9qOvuW_carousel-item._9qOvuW_active, ._9qOvuW_carousel-fade ._9qOvuW_carousel-item-next._9qOvuW_carousel-item-start, ._9qOvuW_carousel-fade ._9qOvuW_carousel-item-prev._9qOvuW_carousel-item-end {
  z-index: 1;
  opacity: 1;
}

._9qOvuW_carousel-fade ._9qOvuW_active._9qOvuW_carousel-item-start, ._9qOvuW_carousel-fade ._9qOvuW_active._9qOvuW_carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_carousel-fade ._9qOvuW_active._9qOvuW_carousel-item-start, ._9qOvuW_carousel-fade ._9qOvuW_active._9qOvuW_carousel-item-end {
    transition: none;
  }
}

._9qOvuW_carousel-control-prev, ._9qOvuW_carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_carousel-control-prev, ._9qOvuW_carousel-control-next {
    transition: none;
  }
}

._9qOvuW_carousel-control-prev:hover, ._9qOvuW_carousel-control-prev:focus, ._9qOvuW_carousel-control-next:hover, ._9qOvuW_carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

._9qOvuW_carousel-control-prev {
  left: 0;
}

._9qOvuW_carousel-control-next {
  right: 0;
}

._9qOvuW_carousel-control-prev-icon, ._9qOvuW_carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

._9qOvuW_carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

._9qOvuW_carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

._9qOvuW_carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

._9qOvuW_carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_carousel-indicators [data-bs-target] {
    transition: none;
  }
}

._9qOvuW_carousel-indicators ._9qOvuW_active {
  opacity: 1;
}

._9qOvuW_carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

._9qOvuW_carousel-dark ._9qOvuW_carousel-control-prev-icon, ._9qOvuW_carousel-dark ._9qOvuW_carousel-control-next-icon {
  filter: invert() grayscale(100);
}

._9qOvuW_carousel-dark ._9qOvuW_carousel-indicators [data-bs-target] {
  background-color: #000;
}

._9qOvuW_carousel-dark ._9qOvuW_carousel-caption {
  color: #000;
}

._9qOvuW_spinner-grow, ._9qOvuW_spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes _9qOvuW_spinner-border {
  to {
    transform: rotate(360deg);
  }
}

._9qOvuW_spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

._9qOvuW_spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes _9qOvuW_spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

._9qOvuW_spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

._9qOvuW_spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_spinner-border, ._9qOvuW_spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

._9qOvuW_offcanvas, ._9qOvuW_offcanvas-xxl, ._9qOvuW_offcanvas-xl, ._9qOvuW_offcanvas-lg, ._9qOvuW_offcanvas-md, ._9qOvuW_offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  ._9qOvuW_offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  ._9qOvuW_offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  ._9qOvuW_offcanvas-sm._9qOvuW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._9qOvuW_offcanvas-sm._9qOvuW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._9qOvuW_offcanvas-sm._9qOvuW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._9qOvuW_offcanvas-sm._9qOvuW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._9qOvuW_offcanvas-sm._9qOvuW_showing, ._9qOvuW_offcanvas-sm._9qOvuW_show:not(._9qOvuW_hiding) {
    transform: none;
  }

  ._9qOvuW_offcanvas-sm._9qOvuW_showing, ._9qOvuW_offcanvas-sm._9qOvuW_hiding, ._9qOvuW_offcanvas-sm._9qOvuW_show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  ._9qOvuW_offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._9qOvuW_offcanvas-sm ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_offcanvas-sm ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  ._9qOvuW_offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  ._9qOvuW_offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  ._9qOvuW_offcanvas-md._9qOvuW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._9qOvuW_offcanvas-md._9qOvuW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._9qOvuW_offcanvas-md._9qOvuW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._9qOvuW_offcanvas-md._9qOvuW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._9qOvuW_offcanvas-md._9qOvuW_showing, ._9qOvuW_offcanvas-md._9qOvuW_show:not(._9qOvuW_hiding) {
    transform: none;
  }

  ._9qOvuW_offcanvas-md._9qOvuW_showing, ._9qOvuW_offcanvas-md._9qOvuW_hiding, ._9qOvuW_offcanvas-md._9qOvuW_show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._9qOvuW_offcanvas-md ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_offcanvas-md ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  ._9qOvuW_offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  ._9qOvuW_offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  ._9qOvuW_offcanvas-lg._9qOvuW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._9qOvuW_offcanvas-lg._9qOvuW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._9qOvuW_offcanvas-lg._9qOvuW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._9qOvuW_offcanvas-lg._9qOvuW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._9qOvuW_offcanvas-lg._9qOvuW_showing, ._9qOvuW_offcanvas-lg._9qOvuW_show:not(._9qOvuW_hiding) {
    transform: none;
  }

  ._9qOvuW_offcanvas-lg._9qOvuW_showing, ._9qOvuW_offcanvas-lg._9qOvuW_hiding, ._9qOvuW_offcanvas-lg._9qOvuW_show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._9qOvuW_offcanvas-lg ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_offcanvas-lg ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  ._9qOvuW_offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  ._9qOvuW_offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  ._9qOvuW_offcanvas-xl._9qOvuW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._9qOvuW_offcanvas-xl._9qOvuW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._9qOvuW_offcanvas-xl._9qOvuW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._9qOvuW_offcanvas-xl._9qOvuW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._9qOvuW_offcanvas-xl._9qOvuW_showing, ._9qOvuW_offcanvas-xl._9qOvuW_show:not(._9qOvuW_hiding) {
    transform: none;
  }

  ._9qOvuW_offcanvas-xl._9qOvuW_showing, ._9qOvuW_offcanvas-xl._9qOvuW_hiding, ._9qOvuW_offcanvas-xl._9qOvuW_show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._9qOvuW_offcanvas-xl ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_offcanvas-xl ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  ._9qOvuW_offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  ._9qOvuW_offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  ._9qOvuW_offcanvas-xxl._9qOvuW_offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  ._9qOvuW_offcanvas-xxl._9qOvuW_offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  ._9qOvuW_offcanvas-xxl._9qOvuW_offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  ._9qOvuW_offcanvas-xxl._9qOvuW_offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  ._9qOvuW_offcanvas-xxl._9qOvuW_showing, ._9qOvuW_offcanvas-xxl._9qOvuW_show:not(._9qOvuW_hiding) {
    transform: none;
  }

  ._9qOvuW_offcanvas-xxl._9qOvuW_showing, ._9qOvuW_offcanvas-xxl._9qOvuW_hiding, ._9qOvuW_offcanvas-xxl._9qOvuW_show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  ._9qOvuW_offcanvas-xxl ._9qOvuW_offcanvas-header {
    display: none;
  }

  ._9qOvuW_offcanvas-xxl ._9qOvuW_offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

._9qOvuW_offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  ._9qOvuW_offcanvas {
    transition: none;
  }
}

._9qOvuW_offcanvas._9qOvuW_offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

._9qOvuW_offcanvas._9qOvuW_offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

._9qOvuW_offcanvas._9qOvuW_offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

._9qOvuW_offcanvas._9qOvuW_offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

._9qOvuW_offcanvas._9qOvuW_showing, ._9qOvuW_offcanvas._9qOvuW_show:not(._9qOvuW_hiding) {
  transform: none;
}

._9qOvuW_offcanvas._9qOvuW_showing, ._9qOvuW_offcanvas._9qOvuW_hiding, ._9qOvuW_offcanvas._9qOvuW_show {
  visibility: visible;
}

._9qOvuW_offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

._9qOvuW_offcanvas-backdrop._9qOvuW_fade {
  opacity: 0;
}

._9qOvuW_offcanvas-backdrop._9qOvuW_show {
  opacity: .5;
}

._9qOvuW_offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._9qOvuW_offcanvas-header ._9qOvuW_btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

._9qOvuW_offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

._9qOvuW_offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

._9qOvuW_placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

._9qOvuW_placeholder._9qOvuW_btn:before {
  content: "";
  display: inline-block;
}

._9qOvuW_placeholder-xs {
  min-height: .6em;
}

._9qOvuW_placeholder-sm {
  min-height: .8em;
}

._9qOvuW_placeholder-lg {
  min-height: 1.2em;
}

._9qOvuW_placeholder-glow ._9qOvuW_placeholder {
  animation: 2s ease-in-out infinite _9qOvuW_placeholder-glow;
}

@keyframes _9qOvuW_placeholder-glow {
  50% {
    opacity: .2;
  }
}

._9qOvuW_placeholder-wave {
  animation: 2s linear infinite _9qOvuW_placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes _9qOvuW_placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

._9qOvuW_clearfix:after {
  clear: both;
  content: "";
  display: block;
}

._9qOvuW_text-bg-primary {
  color: #000 !important;
  background-color: RGBA(37, 142, 1, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

._9qOvuW_link-primary {
  color: #258e01 !important;
}

._9qOvuW_link-primary:hover, ._9qOvuW_link-primary:focus {
  color: #51a534 !important;
}

._9qOvuW_link-secondary {
  color: #6c757d !important;
}

._9qOvuW_link-secondary:hover, ._9qOvuW_link-secondary:focus {
  color: #565e64 !important;
}

._9qOvuW_link-success {
  color: #198754 !important;
}

._9qOvuW_link-success:hover, ._9qOvuW_link-success:focus {
  color: #146c43 !important;
}

._9qOvuW_link-info {
  color: #0dcaf0 !important;
}

._9qOvuW_link-info:hover, ._9qOvuW_link-info:focus {
  color: #3dd5f3 !important;
}

._9qOvuW_link-warning {
  color: #ffc107 !important;
}

._9qOvuW_link-warning:hover, ._9qOvuW_link-warning:focus {
  color: #ffcd39 !important;
}

._9qOvuW_link-danger {
  color: #dc3545 !important;
}

._9qOvuW_link-danger:hover, ._9qOvuW_link-danger:focus {
  color: #b02a37 !important;
}

._9qOvuW_link-light {
  color: #f8f9fa !important;
}

._9qOvuW_link-light:hover, ._9qOvuW_link-light:focus {
  color: #f9fafb !important;
}

._9qOvuW_link-dark {
  color: #212529 !important;
}

._9qOvuW_link-dark:hover, ._9qOvuW_link-dark:focus {
  color: #1a1e21 !important;
}

._9qOvuW_ratio {
  width: 100%;
  position: relative;
}

._9qOvuW_ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

._9qOvuW_ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._9qOvuW_ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

._9qOvuW_ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

._9qOvuW_ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

._9qOvuW_ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

._9qOvuW_fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._9qOvuW_fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

._9qOvuW_sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

._9qOvuW_sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  ._9qOvuW_sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._9qOvuW_sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._9qOvuW_sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._9qOvuW_sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._9qOvuW_sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  ._9qOvuW_sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

._9qOvuW_hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

._9qOvuW_vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

._9qOvuW_visually-hidden, ._9qOvuW_visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

._9qOvuW_stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

._9qOvuW_text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._9qOvuW_vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

._9qOvuW_align-baseline {
  vertical-align: baseline !important;
}

._9qOvuW_align-top {
  vertical-align: top !important;
}

._9qOvuW_align-middle {
  vertical-align: middle !important;
}

._9qOvuW_align-bottom {
  vertical-align: bottom !important;
}

._9qOvuW_align-text-bottom {
  vertical-align: text-bottom !important;
}

._9qOvuW_align-text-top {
  vertical-align: text-top !important;
}

._9qOvuW_float-start {
  float: left !important;
}

._9qOvuW_float-end {
  float: right !important;
}

._9qOvuW_float-none {
  float: none !important;
}

._9qOvuW_opacity-0 {
  opacity: 0 !important;
}

._9qOvuW_opacity-25 {
  opacity: .25 !important;
}

._9qOvuW_opacity-50 {
  opacity: .5 !important;
}

._9qOvuW_opacity-75 {
  opacity: .75 !important;
}

._9qOvuW_opacity-100 {
  opacity: 1 !important;
}

._9qOvuW_overflow-auto {
  overflow: auto !important;
}

._9qOvuW_overflow-hidden {
  overflow: hidden !important;
}

._9qOvuW_overflow-visible {
  overflow: visible !important;
}

._9qOvuW_overflow-scroll {
  overflow: scroll !important;
}

._9qOvuW_d-inline {
  display: inline !important;
}

._9qOvuW_d-inline-block {
  display: inline-block !important;
}

._9qOvuW_d-block {
  display: block !important;
}

._9qOvuW_d-grid {
  display: grid !important;
}

._9qOvuW_d-table {
  display: table !important;
}

._9qOvuW_d-table-row {
  display: table-row !important;
}

._9qOvuW_d-table-cell {
  display: table-cell !important;
}

._9qOvuW_d-flex {
  display: flex !important;
}

._9qOvuW_d-inline-flex {
  display: inline-flex !important;
}

._9qOvuW_d-none {
  display: none !important;
}

._9qOvuW_shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

._9qOvuW_shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

._9qOvuW_shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

._9qOvuW_shadow-none {
  box-shadow: none !important;
}

._9qOvuW_position-static {
  position: static !important;
}

._9qOvuW_position-relative {
  position: relative !important;
}

._9qOvuW_position-absolute {
  position: absolute !important;
}

._9qOvuW_position-fixed {
  position: fixed !important;
}

._9qOvuW_position-sticky {
  position: sticky !important;
}

._9qOvuW_top-0 {
  top: 0 !important;
}

._9qOvuW_top-50 {
  top: 50% !important;
}

._9qOvuW_top-100 {
  top: 100% !important;
}

._9qOvuW_bottom-0 {
  bottom: 0 !important;
}

._9qOvuW_bottom-50 {
  bottom: 50% !important;
}

._9qOvuW_bottom-100 {
  bottom: 100% !important;
}

._9qOvuW_start-0 {
  left: 0 !important;
}

._9qOvuW_start-50 {
  left: 50% !important;
}

._9qOvuW_start-100 {
  left: 100% !important;
}

._9qOvuW_end-0 {
  right: 0 !important;
}

._9qOvuW_end-50 {
  right: 50% !important;
}

._9qOvuW_end-100 {
  right: 100% !important;
}

._9qOvuW_translate-middle {
  transform: translate(-50%, -50%) !important;
}

._9qOvuW_translate-middle-x {
  transform: translateX(-50%) !important;
}

._9qOvuW_translate-middle-y {
  transform: translateY(-50%) !important;
}

._9qOvuW_border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._9qOvuW_border-0 {
  border: 0 !important;
}

._9qOvuW_border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._9qOvuW_border-top-0 {
  border-top: 0 !important;
}

._9qOvuW_border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._9qOvuW_border-end-0 {
  border-right: 0 !important;
}

._9qOvuW_border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._9qOvuW_border-bottom-0 {
  border-bottom: 0 !important;
}

._9qOvuW_border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

._9qOvuW_border-start-0 {
  border-left: 0 !important;
}

._9qOvuW_border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

._9qOvuW_border-1 {
  --bs-border-width: 1px;
}

._9qOvuW_border-2 {
  --bs-border-width: 2px;
}

._9qOvuW_border-3 {
  --bs-border-width: 3px;
}

._9qOvuW_border-4 {
  --bs-border-width: 4px;
}

._9qOvuW_border-5 {
  --bs-border-width: 5px;
}

._9qOvuW_border-opacity-10 {
  --bs-border-opacity: .1;
}

._9qOvuW_border-opacity-25 {
  --bs-border-opacity: .25;
}

._9qOvuW_border-opacity-50 {
  --bs-border-opacity: .5;
}

._9qOvuW_border-opacity-75 {
  --bs-border-opacity: .75;
}

._9qOvuW_border-opacity-100 {
  --bs-border-opacity: 1;
}

._9qOvuW_w-25 {
  width: 25% !important;
}

._9qOvuW_w-50 {
  width: 50% !important;
}

._9qOvuW_w-75 {
  width: 75% !important;
}

._9qOvuW_w-100 {
  width: 100% !important;
}

._9qOvuW_w-auto {
  width: auto !important;
}

._9qOvuW_mw-100 {
  max-width: 100% !important;
}

._9qOvuW_vw-100 {
  width: 100vw !important;
}

._9qOvuW_min-vw-100 {
  min-width: 100vw !important;
}

._9qOvuW_h-25 {
  height: 25% !important;
}

._9qOvuW_h-50 {
  height: 50% !important;
}

._9qOvuW_h-75 {
  height: 75% !important;
}

._9qOvuW_h-100 {
  height: 100% !important;
}

._9qOvuW_h-auto {
  height: auto !important;
}

._9qOvuW_mh-100 {
  max-height: 100% !important;
}

._9qOvuW_vh-100 {
  height: 100vh !important;
}

._9qOvuW_min-vh-100 {
  min-height: 100vh !important;
}

._9qOvuW_flex-fill {
  flex: auto !important;
}

._9qOvuW_flex-row {
  flex-direction: row !important;
}

._9qOvuW_flex-column {
  flex-direction: column !important;
}

._9qOvuW_flex-row-reverse {
  flex-direction: row-reverse !important;
}

._9qOvuW_flex-column-reverse {
  flex-direction: column-reverse !important;
}

._9qOvuW_flex-grow-0 {
  flex-grow: 0 !important;
}

._9qOvuW_flex-grow-1 {
  flex-grow: 1 !important;
}

._9qOvuW_flex-shrink-0 {
  flex-shrink: 0 !important;
}

._9qOvuW_flex-shrink-1 {
  flex-shrink: 1 !important;
}

._9qOvuW_flex-wrap {
  flex-wrap: wrap !important;
}

._9qOvuW_flex-nowrap {
  flex-wrap: nowrap !important;
}

._9qOvuW_flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

._9qOvuW_justify-content-start {
  justify-content: flex-start !important;
}

._9qOvuW_justify-content-end {
  justify-content: flex-end !important;
}

._9qOvuW_justify-content-center {
  justify-content: center !important;
}

._9qOvuW_justify-content-between {
  justify-content: space-between !important;
}

._9qOvuW_justify-content-around {
  justify-content: space-around !important;
}

._9qOvuW_justify-content-evenly {
  justify-content: space-evenly !important;
}

._9qOvuW_align-items-start {
  align-items: flex-start !important;
}

._9qOvuW_align-items-end {
  align-items: flex-end !important;
}

._9qOvuW_align-items-center {
  align-items: center !important;
}

._9qOvuW_align-items-baseline {
  align-items: baseline !important;
}

._9qOvuW_align-items-stretch {
  align-items: stretch !important;
}

._9qOvuW_align-content-start {
  align-content: flex-start !important;
}

._9qOvuW_align-content-end {
  align-content: flex-end !important;
}

._9qOvuW_align-content-center {
  align-content: center !important;
}

._9qOvuW_align-content-between {
  align-content: space-between !important;
}

._9qOvuW_align-content-around {
  align-content: space-around !important;
}

._9qOvuW_align-content-stretch {
  align-content: stretch !important;
}

._9qOvuW_align-self-auto {
  align-self: auto !important;
}

._9qOvuW_align-self-start {
  align-self: flex-start !important;
}

._9qOvuW_align-self-end {
  align-self: flex-end !important;
}

._9qOvuW_align-self-center {
  align-self: center !important;
}

._9qOvuW_align-self-baseline {
  align-self: baseline !important;
}

._9qOvuW_align-self-stretch {
  align-self: stretch !important;
}

._9qOvuW_order-first {
  order: -1 !important;
}

._9qOvuW_order-0 {
  order: 0 !important;
}

._9qOvuW_order-1 {
  order: 1 !important;
}

._9qOvuW_order-2 {
  order: 2 !important;
}

._9qOvuW_order-3 {
  order: 3 !important;
}

._9qOvuW_order-4 {
  order: 4 !important;
}

._9qOvuW_order-5 {
  order: 5 !important;
}

._9qOvuW_order-last {
  order: 6 !important;
}

._9qOvuW_m-0 {
  margin: 0 !important;
}

._9qOvuW_m-1 {
  margin: .25rem !important;
}

._9qOvuW_m-2 {
  margin: .5rem !important;
}

._9qOvuW_m-3 {
  margin: 1rem !important;
}

._9qOvuW_m-4 {
  margin: 1.5rem !important;
}

._9qOvuW_m-5 {
  margin: 3rem !important;
}

._9qOvuW_m-auto {
  margin: auto !important;
}

._9qOvuW_mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

._9qOvuW_mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

._9qOvuW_mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

._9qOvuW_mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

._9qOvuW_mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

._9qOvuW_mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

._9qOvuW_mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

._9qOvuW_my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

._9qOvuW_my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

._9qOvuW_my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

._9qOvuW_my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

._9qOvuW_my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

._9qOvuW_my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

._9qOvuW_my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

._9qOvuW_mt-0 {
  margin-top: 0 !important;
}

._9qOvuW_mt-1 {
  margin-top: .25rem !important;
}

._9qOvuW_mt-2 {
  margin-top: .5rem !important;
}

._9qOvuW_mt-3 {
  margin-top: 1rem !important;
}

._9qOvuW_mt-4 {
  margin-top: 1.5rem !important;
}

._9qOvuW_mt-5 {
  margin-top: 3rem !important;
}

._9qOvuW_mt-auto {
  margin-top: auto !important;
}

._9qOvuW_me-0 {
  margin-right: 0 !important;
}

._9qOvuW_me-1 {
  margin-right: .25rem !important;
}

._9qOvuW_me-2 {
  margin-right: .5rem !important;
}

._9qOvuW_me-3 {
  margin-right: 1rem !important;
}

._9qOvuW_me-4 {
  margin-right: 1.5rem !important;
}

._9qOvuW_me-5 {
  margin-right: 3rem !important;
}

._9qOvuW_me-auto {
  margin-right: auto !important;
}

._9qOvuW_mb-0 {
  margin-bottom: 0 !important;
}

._9qOvuW_mb-1 {
  margin-bottom: .25rem !important;
}

._9qOvuW_mb-2 {
  margin-bottom: .5rem !important;
}

._9qOvuW_mb-3 {
  margin-bottom: 1rem !important;
}

._9qOvuW_mb-4 {
  margin-bottom: 1.5rem !important;
}

._9qOvuW_mb-5 {
  margin-bottom: 3rem !important;
}

._9qOvuW_mb-auto {
  margin-bottom: auto !important;
}

._9qOvuW_ms-0 {
  margin-left: 0 !important;
}

._9qOvuW_ms-1 {
  margin-left: .25rem !important;
}

._9qOvuW_ms-2 {
  margin-left: .5rem !important;
}

._9qOvuW_ms-3 {
  margin-left: 1rem !important;
}

._9qOvuW_ms-4 {
  margin-left: 1.5rem !important;
}

._9qOvuW_ms-5 {
  margin-left: 3rem !important;
}

._9qOvuW_ms-auto {
  margin-left: auto !important;
}

._9qOvuW_p-0 {
  padding: 0 !important;
}

._9qOvuW_p-1 {
  padding: .25rem !important;
}

._9qOvuW_p-2 {
  padding: .5rem !important;
}

._9qOvuW_p-3 {
  padding: 1rem !important;
}

._9qOvuW_p-4 {
  padding: 1.5rem !important;
}

._9qOvuW_p-5 {
  padding: 3rem !important;
}

._9qOvuW_px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

._9qOvuW_px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

._9qOvuW_px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

._9qOvuW_px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

._9qOvuW_px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

._9qOvuW_px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

._9qOvuW_py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

._9qOvuW_py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

._9qOvuW_py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

._9qOvuW_py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

._9qOvuW_py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

._9qOvuW_py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

._9qOvuW_pt-0 {
  padding-top: 0 !important;
}

._9qOvuW_pt-1 {
  padding-top: .25rem !important;
}

._9qOvuW_pt-2 {
  padding-top: .5rem !important;
}

._9qOvuW_pt-3 {
  padding-top: 1rem !important;
}

._9qOvuW_pt-4 {
  padding-top: 1.5rem !important;
}

._9qOvuW_pt-5 {
  padding-top: 3rem !important;
}

._9qOvuW_pe-0 {
  padding-right: 0 !important;
}

._9qOvuW_pe-1 {
  padding-right: .25rem !important;
}

._9qOvuW_pe-2 {
  padding-right: .5rem !important;
}

._9qOvuW_pe-3 {
  padding-right: 1rem !important;
}

._9qOvuW_pe-4 {
  padding-right: 1.5rem !important;
}

._9qOvuW_pe-5 {
  padding-right: 3rem !important;
}

._9qOvuW_pb-0 {
  padding-bottom: 0 !important;
}

._9qOvuW_pb-1 {
  padding-bottom: .25rem !important;
}

._9qOvuW_pb-2 {
  padding-bottom: .5rem !important;
}

._9qOvuW_pb-3 {
  padding-bottom: 1rem !important;
}

._9qOvuW_pb-4 {
  padding-bottom: 1.5rem !important;
}

._9qOvuW_pb-5 {
  padding-bottom: 3rem !important;
}

._9qOvuW_ps-0 {
  padding-left: 0 !important;
}

._9qOvuW_ps-1 {
  padding-left: .25rem !important;
}

._9qOvuW_ps-2 {
  padding-left: .5rem !important;
}

._9qOvuW_ps-3 {
  padding-left: 1rem !important;
}

._9qOvuW_ps-4 {
  padding-left: 1.5rem !important;
}

._9qOvuW_ps-5 {
  padding-left: 3rem !important;
}

._9qOvuW_gap-0 {
  gap: 0 !important;
}

._9qOvuW_gap-1 {
  gap: .25rem !important;
}

._9qOvuW_gap-2 {
  gap: .5rem !important;
}

._9qOvuW_gap-3 {
  gap: 1rem !important;
}

._9qOvuW_gap-4 {
  gap: 1.5rem !important;
}

._9qOvuW_gap-5 {
  gap: 3rem !important;
}

._9qOvuW_font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

._9qOvuW_fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

._9qOvuW_fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

._9qOvuW_fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

._9qOvuW_fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

._9qOvuW_fs-5 {
  font-size: 1.25rem !important;
}

._9qOvuW_fs-6 {
  font-size: 1rem !important;
}

._9qOvuW_fst-italic {
  font-style: italic !important;
}

._9qOvuW_fst-normal {
  font-style: normal !important;
}

._9qOvuW_fw-light {
  font-weight: 300 !important;
}

._9qOvuW_fw-lighter {
  font-weight: lighter !important;
}

._9qOvuW_fw-normal {
  font-weight: 400 !important;
}

._9qOvuW_fw-bold {
  font-weight: 700 !important;
}

._9qOvuW_fw-semibold {
  font-weight: 600 !important;
}

._9qOvuW_fw-bolder {
  font-weight: bolder !important;
}

._9qOvuW_lh-1 {
  line-height: 1 !important;
}

._9qOvuW_lh-sm {
  line-height: 1.25 !important;
}

._9qOvuW_lh-base {
  line-height: 1.5 !important;
}

._9qOvuW_lh-lg {
  line-height: 2 !important;
}

._9qOvuW_text-start {
  text-align: left !important;
}

._9qOvuW_text-end {
  text-align: right !important;
}

._9qOvuW_text-center {
  text-align: center !important;
}

._9qOvuW_text-decoration-none {
  text-decoration: none !important;
}

._9qOvuW_text-decoration-underline {
  text-decoration: underline !important;
}

._9qOvuW_text-decoration-line-through {
  text-decoration: line-through !important;
}

._9qOvuW_text-lowercase {
  text-transform: lowercase !important;
}

._9qOvuW_text-uppercase {
  text-transform: uppercase !important;
}

._9qOvuW_text-capitalize {
  text-transform: capitalize !important;
}

._9qOvuW_text-wrap {
  white-space: normal !important;
}

._9qOvuW_text-nowrap {
  white-space: nowrap !important;
}

._9qOvuW_text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

._9qOvuW_text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

._9qOvuW_text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

._9qOvuW_text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

._9qOvuW_text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

._9qOvuW_text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

._9qOvuW_text-opacity-25 {
  --bs-text-opacity: .25;
}

._9qOvuW_text-opacity-50 {
  --bs-text-opacity: .5;
}

._9qOvuW_text-opacity-75 {
  --bs-text-opacity: .75;
}

._9qOvuW_text-opacity-100 {
  --bs-text-opacity: 1;
}

._9qOvuW_bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

._9qOvuW_bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

._9qOvuW_bg-opacity-10 {
  --bs-bg-opacity: .1;
}

._9qOvuW_bg-opacity-25 {
  --bs-bg-opacity: .25;
}

._9qOvuW_bg-opacity-50 {
  --bs-bg-opacity: .5;
}

._9qOvuW_bg-opacity-75 {
  --bs-bg-opacity: .75;
}

._9qOvuW_bg-opacity-100 {
  --bs-bg-opacity: 1;
}

._9qOvuW_bg-gradient {
  background-image: var(--bs-gradient) !important;
}

._9qOvuW_user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

._9qOvuW_user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

._9qOvuW_user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

._9qOvuW_pe-none {
  pointer-events: none !important;
}

._9qOvuW_pe-auto {
  pointer-events: auto !important;
}

._9qOvuW_rounded {
  border-radius: var(--bs-border-radius) !important;
}

._9qOvuW_rounded-0 {
  border-radius: 0 !important;
}

._9qOvuW_rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

._9qOvuW_rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

._9qOvuW_rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

._9qOvuW_rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

._9qOvuW_rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

._9qOvuW_rounded-circle {
  border-radius: 50% !important;
}

._9qOvuW_rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

._9qOvuW_rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

._9qOvuW_rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

._9qOvuW_rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

._9qOvuW_rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

._9qOvuW_visible {
  visibility: visible !important;
}

._9qOvuW_invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  ._9qOvuW_float-sm-start {
    float: left !important;
  }

  ._9qOvuW_float-sm-end {
    float: right !important;
  }

  ._9qOvuW_float-sm-none {
    float: none !important;
  }

  ._9qOvuW_d-sm-inline {
    display: inline !important;
  }

  ._9qOvuW_d-sm-inline-block {
    display: inline-block !important;
  }

  ._9qOvuW_d-sm-block {
    display: block !important;
  }

  ._9qOvuW_d-sm-grid {
    display: grid !important;
  }

  ._9qOvuW_d-sm-table {
    display: table !important;
  }

  ._9qOvuW_d-sm-table-row {
    display: table-row !important;
  }

  ._9qOvuW_d-sm-table-cell {
    display: table-cell !important;
  }

  ._9qOvuW_d-sm-flex {
    display: flex !important;
  }

  ._9qOvuW_d-sm-inline-flex {
    display: inline-flex !important;
  }

  ._9qOvuW_d-sm-none {
    display: none !important;
  }

  ._9qOvuW_flex-sm-fill {
    flex: auto !important;
  }

  ._9qOvuW_flex-sm-row {
    flex-direction: row !important;
  }

  ._9qOvuW_flex-sm-column {
    flex-direction: column !important;
  }

  ._9qOvuW_flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._9qOvuW_flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._9qOvuW_flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  ._9qOvuW_flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  ._9qOvuW_flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._9qOvuW_flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._9qOvuW_flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  ._9qOvuW_flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  ._9qOvuW_flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._9qOvuW_justify-content-sm-start {
    justify-content: flex-start !important;
  }

  ._9qOvuW_justify-content-sm-end {
    justify-content: flex-end !important;
  }

  ._9qOvuW_justify-content-sm-center {
    justify-content: center !important;
  }

  ._9qOvuW_justify-content-sm-between {
    justify-content: space-between !important;
  }

  ._9qOvuW_justify-content-sm-around {
    justify-content: space-around !important;
  }

  ._9qOvuW_justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  ._9qOvuW_align-items-sm-start {
    align-items: flex-start !important;
  }

  ._9qOvuW_align-items-sm-end {
    align-items: flex-end !important;
  }

  ._9qOvuW_align-items-sm-center {
    align-items: center !important;
  }

  ._9qOvuW_align-items-sm-baseline {
    align-items: baseline !important;
  }

  ._9qOvuW_align-items-sm-stretch {
    align-items: stretch !important;
  }

  ._9qOvuW_align-content-sm-start {
    align-content: flex-start !important;
  }

  ._9qOvuW_align-content-sm-end {
    align-content: flex-end !important;
  }

  ._9qOvuW_align-content-sm-center {
    align-content: center !important;
  }

  ._9qOvuW_align-content-sm-between {
    align-content: space-between !important;
  }

  ._9qOvuW_align-content-sm-around {
    align-content: space-around !important;
  }

  ._9qOvuW_align-content-sm-stretch {
    align-content: stretch !important;
  }

  ._9qOvuW_align-self-sm-auto {
    align-self: auto !important;
  }

  ._9qOvuW_align-self-sm-start {
    align-self: flex-start !important;
  }

  ._9qOvuW_align-self-sm-end {
    align-self: flex-end !important;
  }

  ._9qOvuW_align-self-sm-center {
    align-self: center !important;
  }

  ._9qOvuW_align-self-sm-baseline {
    align-self: baseline !important;
  }

  ._9qOvuW_align-self-sm-stretch {
    align-self: stretch !important;
  }

  ._9qOvuW_order-sm-first {
    order: -1 !important;
  }

  ._9qOvuW_order-sm-0 {
    order: 0 !important;
  }

  ._9qOvuW_order-sm-1 {
    order: 1 !important;
  }

  ._9qOvuW_order-sm-2 {
    order: 2 !important;
  }

  ._9qOvuW_order-sm-3 {
    order: 3 !important;
  }

  ._9qOvuW_order-sm-4 {
    order: 4 !important;
  }

  ._9qOvuW_order-sm-5 {
    order: 5 !important;
  }

  ._9qOvuW_order-sm-last {
    order: 6 !important;
  }

  ._9qOvuW_m-sm-0 {
    margin: 0 !important;
  }

  ._9qOvuW_m-sm-1 {
    margin: .25rem !important;
  }

  ._9qOvuW_m-sm-2 {
    margin: .5rem !important;
  }

  ._9qOvuW_m-sm-3 {
    margin: 1rem !important;
  }

  ._9qOvuW_m-sm-4 {
    margin: 1.5rem !important;
  }

  ._9qOvuW_m-sm-5 {
    margin: 3rem !important;
  }

  ._9qOvuW_m-sm-auto {
    margin: auto !important;
  }

  ._9qOvuW_mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._9qOvuW_mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._9qOvuW_mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._9qOvuW_mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._9qOvuW_mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._9qOvuW_mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._9qOvuW_my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._9qOvuW_my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._9qOvuW_mt-sm-0 {
    margin-top: 0 !important;
  }

  ._9qOvuW_mt-sm-1 {
    margin-top: .25rem !important;
  }

  ._9qOvuW_mt-sm-2 {
    margin-top: .5rem !important;
  }

  ._9qOvuW_mt-sm-3 {
    margin-top: 1rem !important;
  }

  ._9qOvuW_mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  ._9qOvuW_mt-sm-5 {
    margin-top: 3rem !important;
  }

  ._9qOvuW_mt-sm-auto {
    margin-top: auto !important;
  }

  ._9qOvuW_me-sm-0 {
    margin-right: 0 !important;
  }

  ._9qOvuW_me-sm-1 {
    margin-right: .25rem !important;
  }

  ._9qOvuW_me-sm-2 {
    margin-right: .5rem !important;
  }

  ._9qOvuW_me-sm-3 {
    margin-right: 1rem !important;
  }

  ._9qOvuW_me-sm-4 {
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_me-sm-5 {
    margin-right: 3rem !important;
  }

  ._9qOvuW_me-sm-auto {
    margin-right: auto !important;
  }

  ._9qOvuW_mb-sm-0 {
    margin-bottom: 0 !important;
  }

  ._9qOvuW_mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_mb-sm-auto {
    margin-bottom: auto !important;
  }

  ._9qOvuW_ms-sm-0 {
    margin-left: 0 !important;
  }

  ._9qOvuW_ms-sm-1 {
    margin-left: .25rem !important;
  }

  ._9qOvuW_ms-sm-2 {
    margin-left: .5rem !important;
  }

  ._9qOvuW_ms-sm-3 {
    margin-left: 1rem !important;
  }

  ._9qOvuW_ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  ._9qOvuW_ms-sm-5 {
    margin-left: 3rem !important;
  }

  ._9qOvuW_ms-sm-auto {
    margin-left: auto !important;
  }

  ._9qOvuW_p-sm-0 {
    padding: 0 !important;
  }

  ._9qOvuW_p-sm-1 {
    padding: .25rem !important;
  }

  ._9qOvuW_p-sm-2 {
    padding: .5rem !important;
  }

  ._9qOvuW_p-sm-3 {
    padding: 1rem !important;
  }

  ._9qOvuW_p-sm-4 {
    padding: 1.5rem !important;
  }

  ._9qOvuW_p-sm-5 {
    padding: 3rem !important;
  }

  ._9qOvuW_px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._9qOvuW_px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._9qOvuW_px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._9qOvuW_px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._9qOvuW_px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._9qOvuW_py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._9qOvuW_py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_pt-sm-0 {
    padding-top: 0 !important;
  }

  ._9qOvuW_pt-sm-1 {
    padding-top: .25rem !important;
  }

  ._9qOvuW_pt-sm-2 {
    padding-top: .5rem !important;
  }

  ._9qOvuW_pt-sm-3 {
    padding-top: 1rem !important;
  }

  ._9qOvuW_pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  ._9qOvuW_pt-sm-5 {
    padding-top: 3rem !important;
  }

  ._9qOvuW_pe-sm-0 {
    padding-right: 0 !important;
  }

  ._9qOvuW_pe-sm-1 {
    padding-right: .25rem !important;
  }

  ._9qOvuW_pe-sm-2 {
    padding-right: .5rem !important;
  }

  ._9qOvuW_pe-sm-3 {
    padding-right: 1rem !important;
  }

  ._9qOvuW_pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_pe-sm-5 {
    padding-right: 3rem !important;
  }

  ._9qOvuW_pb-sm-0 {
    padding-bottom: 0 !important;
  }

  ._9qOvuW_pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_ps-sm-0 {
    padding-left: 0 !important;
  }

  ._9qOvuW_ps-sm-1 {
    padding-left: .25rem !important;
  }

  ._9qOvuW_ps-sm-2 {
    padding-left: .5rem !important;
  }

  ._9qOvuW_ps-sm-3 {
    padding-left: 1rem !important;
  }

  ._9qOvuW_ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  ._9qOvuW_ps-sm-5 {
    padding-left: 3rem !important;
  }

  ._9qOvuW_gap-sm-0 {
    gap: 0 !important;
  }

  ._9qOvuW_gap-sm-1 {
    gap: .25rem !important;
  }

  ._9qOvuW_gap-sm-2 {
    gap: .5rem !important;
  }

  ._9qOvuW_gap-sm-3 {
    gap: 1rem !important;
  }

  ._9qOvuW_gap-sm-4 {
    gap: 1.5rem !important;
  }

  ._9qOvuW_gap-sm-5 {
    gap: 3rem !important;
  }

  ._9qOvuW_text-sm-start {
    text-align: left !important;
  }

  ._9qOvuW_text-sm-end {
    text-align: right !important;
  }

  ._9qOvuW_text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  ._9qOvuW_float-md-start {
    float: left !important;
  }

  ._9qOvuW_float-md-end {
    float: right !important;
  }

  ._9qOvuW_float-md-none {
    float: none !important;
  }

  ._9qOvuW_d-md-inline {
    display: inline !important;
  }

  ._9qOvuW_d-md-inline-block {
    display: inline-block !important;
  }

  ._9qOvuW_d-md-block {
    display: block !important;
  }

  ._9qOvuW_d-md-grid {
    display: grid !important;
  }

  ._9qOvuW_d-md-table {
    display: table !important;
  }

  ._9qOvuW_d-md-table-row {
    display: table-row !important;
  }

  ._9qOvuW_d-md-table-cell {
    display: table-cell !important;
  }

  ._9qOvuW_d-md-flex {
    display: flex !important;
  }

  ._9qOvuW_d-md-inline-flex {
    display: inline-flex !important;
  }

  ._9qOvuW_d-md-none {
    display: none !important;
  }

  ._9qOvuW_flex-md-fill {
    flex: auto !important;
  }

  ._9qOvuW_flex-md-row {
    flex-direction: row !important;
  }

  ._9qOvuW_flex-md-column {
    flex-direction: column !important;
  }

  ._9qOvuW_flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._9qOvuW_flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._9qOvuW_flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  ._9qOvuW_flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  ._9qOvuW_flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._9qOvuW_flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._9qOvuW_flex-md-wrap {
    flex-wrap: wrap !important;
  }

  ._9qOvuW_flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  ._9qOvuW_flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._9qOvuW_justify-content-md-start {
    justify-content: flex-start !important;
  }

  ._9qOvuW_justify-content-md-end {
    justify-content: flex-end !important;
  }

  ._9qOvuW_justify-content-md-center {
    justify-content: center !important;
  }

  ._9qOvuW_justify-content-md-between {
    justify-content: space-between !important;
  }

  ._9qOvuW_justify-content-md-around {
    justify-content: space-around !important;
  }

  ._9qOvuW_justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  ._9qOvuW_align-items-md-start {
    align-items: flex-start !important;
  }

  ._9qOvuW_align-items-md-end {
    align-items: flex-end !important;
  }

  ._9qOvuW_align-items-md-center {
    align-items: center !important;
  }

  ._9qOvuW_align-items-md-baseline {
    align-items: baseline !important;
  }

  ._9qOvuW_align-items-md-stretch {
    align-items: stretch !important;
  }

  ._9qOvuW_align-content-md-start {
    align-content: flex-start !important;
  }

  ._9qOvuW_align-content-md-end {
    align-content: flex-end !important;
  }

  ._9qOvuW_align-content-md-center {
    align-content: center !important;
  }

  ._9qOvuW_align-content-md-between {
    align-content: space-between !important;
  }

  ._9qOvuW_align-content-md-around {
    align-content: space-around !important;
  }

  ._9qOvuW_align-content-md-stretch {
    align-content: stretch !important;
  }

  ._9qOvuW_align-self-md-auto {
    align-self: auto !important;
  }

  ._9qOvuW_align-self-md-start {
    align-self: flex-start !important;
  }

  ._9qOvuW_align-self-md-end {
    align-self: flex-end !important;
  }

  ._9qOvuW_align-self-md-center {
    align-self: center !important;
  }

  ._9qOvuW_align-self-md-baseline {
    align-self: baseline !important;
  }

  ._9qOvuW_align-self-md-stretch {
    align-self: stretch !important;
  }

  ._9qOvuW_order-md-first {
    order: -1 !important;
  }

  ._9qOvuW_order-md-0 {
    order: 0 !important;
  }

  ._9qOvuW_order-md-1 {
    order: 1 !important;
  }

  ._9qOvuW_order-md-2 {
    order: 2 !important;
  }

  ._9qOvuW_order-md-3 {
    order: 3 !important;
  }

  ._9qOvuW_order-md-4 {
    order: 4 !important;
  }

  ._9qOvuW_order-md-5 {
    order: 5 !important;
  }

  ._9qOvuW_order-md-last {
    order: 6 !important;
  }

  ._9qOvuW_m-md-0 {
    margin: 0 !important;
  }

  ._9qOvuW_m-md-1 {
    margin: .25rem !important;
  }

  ._9qOvuW_m-md-2 {
    margin: .5rem !important;
  }

  ._9qOvuW_m-md-3 {
    margin: 1rem !important;
  }

  ._9qOvuW_m-md-4 {
    margin: 1.5rem !important;
  }

  ._9qOvuW_m-md-5 {
    margin: 3rem !important;
  }

  ._9qOvuW_m-md-auto {
    margin: auto !important;
  }

  ._9qOvuW_mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._9qOvuW_mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._9qOvuW_mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._9qOvuW_mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._9qOvuW_mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._9qOvuW_mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._9qOvuW_my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._9qOvuW_my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._9qOvuW_mt-md-0 {
    margin-top: 0 !important;
  }

  ._9qOvuW_mt-md-1 {
    margin-top: .25rem !important;
  }

  ._9qOvuW_mt-md-2 {
    margin-top: .5rem !important;
  }

  ._9qOvuW_mt-md-3 {
    margin-top: 1rem !important;
  }

  ._9qOvuW_mt-md-4 {
    margin-top: 1.5rem !important;
  }

  ._9qOvuW_mt-md-5 {
    margin-top: 3rem !important;
  }

  ._9qOvuW_mt-md-auto {
    margin-top: auto !important;
  }

  ._9qOvuW_me-md-0 {
    margin-right: 0 !important;
  }

  ._9qOvuW_me-md-1 {
    margin-right: .25rem !important;
  }

  ._9qOvuW_me-md-2 {
    margin-right: .5rem !important;
  }

  ._9qOvuW_me-md-3 {
    margin-right: 1rem !important;
  }

  ._9qOvuW_me-md-4 {
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_me-md-5 {
    margin-right: 3rem !important;
  }

  ._9qOvuW_me-md-auto {
    margin-right: auto !important;
  }

  ._9qOvuW_mb-md-0 {
    margin-bottom: 0 !important;
  }

  ._9qOvuW_mb-md-1 {
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_mb-md-2 {
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_mb-md-3 {
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_mb-md-5 {
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_mb-md-auto {
    margin-bottom: auto !important;
  }

  ._9qOvuW_ms-md-0 {
    margin-left: 0 !important;
  }

  ._9qOvuW_ms-md-1 {
    margin-left: .25rem !important;
  }

  ._9qOvuW_ms-md-2 {
    margin-left: .5rem !important;
  }

  ._9qOvuW_ms-md-3 {
    margin-left: 1rem !important;
  }

  ._9qOvuW_ms-md-4 {
    margin-left: 1.5rem !important;
  }

  ._9qOvuW_ms-md-5 {
    margin-left: 3rem !important;
  }

  ._9qOvuW_ms-md-auto {
    margin-left: auto !important;
  }

  ._9qOvuW_p-md-0 {
    padding: 0 !important;
  }

  ._9qOvuW_p-md-1 {
    padding: .25rem !important;
  }

  ._9qOvuW_p-md-2 {
    padding: .5rem !important;
  }

  ._9qOvuW_p-md-3 {
    padding: 1rem !important;
  }

  ._9qOvuW_p-md-4 {
    padding: 1.5rem !important;
  }

  ._9qOvuW_p-md-5 {
    padding: 3rem !important;
  }

  ._9qOvuW_px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._9qOvuW_px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._9qOvuW_px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._9qOvuW_px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._9qOvuW_px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._9qOvuW_py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._9qOvuW_py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_pt-md-0 {
    padding-top: 0 !important;
  }

  ._9qOvuW_pt-md-1 {
    padding-top: .25rem !important;
  }

  ._9qOvuW_pt-md-2 {
    padding-top: .5rem !important;
  }

  ._9qOvuW_pt-md-3 {
    padding-top: 1rem !important;
  }

  ._9qOvuW_pt-md-4 {
    padding-top: 1.5rem !important;
  }

  ._9qOvuW_pt-md-5 {
    padding-top: 3rem !important;
  }

  ._9qOvuW_pe-md-0 {
    padding-right: 0 !important;
  }

  ._9qOvuW_pe-md-1 {
    padding-right: .25rem !important;
  }

  ._9qOvuW_pe-md-2 {
    padding-right: .5rem !important;
  }

  ._9qOvuW_pe-md-3 {
    padding-right: 1rem !important;
  }

  ._9qOvuW_pe-md-4 {
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_pe-md-5 {
    padding-right: 3rem !important;
  }

  ._9qOvuW_pb-md-0 {
    padding-bottom: 0 !important;
  }

  ._9qOvuW_pb-md-1 {
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_pb-md-2 {
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_pb-md-3 {
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_pb-md-5 {
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_ps-md-0 {
    padding-left: 0 !important;
  }

  ._9qOvuW_ps-md-1 {
    padding-left: .25rem !important;
  }

  ._9qOvuW_ps-md-2 {
    padding-left: .5rem !important;
  }

  ._9qOvuW_ps-md-3 {
    padding-left: 1rem !important;
  }

  ._9qOvuW_ps-md-4 {
    padding-left: 1.5rem !important;
  }

  ._9qOvuW_ps-md-5 {
    padding-left: 3rem !important;
  }

  ._9qOvuW_gap-md-0 {
    gap: 0 !important;
  }

  ._9qOvuW_gap-md-1 {
    gap: .25rem !important;
  }

  ._9qOvuW_gap-md-2 {
    gap: .5rem !important;
  }

  ._9qOvuW_gap-md-3 {
    gap: 1rem !important;
  }

  ._9qOvuW_gap-md-4 {
    gap: 1.5rem !important;
  }

  ._9qOvuW_gap-md-5 {
    gap: 3rem !important;
  }

  ._9qOvuW_text-md-start {
    text-align: left !important;
  }

  ._9qOvuW_text-md-end {
    text-align: right !important;
  }

  ._9qOvuW_text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  ._9qOvuW_float-lg-start {
    float: left !important;
  }

  ._9qOvuW_float-lg-end {
    float: right !important;
  }

  ._9qOvuW_float-lg-none {
    float: none !important;
  }

  ._9qOvuW_d-lg-inline {
    display: inline !important;
  }

  ._9qOvuW_d-lg-inline-block {
    display: inline-block !important;
  }

  ._9qOvuW_d-lg-block {
    display: block !important;
  }

  ._9qOvuW_d-lg-grid {
    display: grid !important;
  }

  ._9qOvuW_d-lg-table {
    display: table !important;
  }

  ._9qOvuW_d-lg-table-row {
    display: table-row !important;
  }

  ._9qOvuW_d-lg-table-cell {
    display: table-cell !important;
  }

  ._9qOvuW_d-lg-flex {
    display: flex !important;
  }

  ._9qOvuW_d-lg-inline-flex {
    display: inline-flex !important;
  }

  ._9qOvuW_d-lg-none {
    display: none !important;
  }

  ._9qOvuW_flex-lg-fill {
    flex: auto !important;
  }

  ._9qOvuW_flex-lg-row {
    flex-direction: row !important;
  }

  ._9qOvuW_flex-lg-column {
    flex-direction: column !important;
  }

  ._9qOvuW_flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._9qOvuW_flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._9qOvuW_flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  ._9qOvuW_flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  ._9qOvuW_flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._9qOvuW_flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._9qOvuW_flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  ._9qOvuW_flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  ._9qOvuW_flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._9qOvuW_justify-content-lg-start {
    justify-content: flex-start !important;
  }

  ._9qOvuW_justify-content-lg-end {
    justify-content: flex-end !important;
  }

  ._9qOvuW_justify-content-lg-center {
    justify-content: center !important;
  }

  ._9qOvuW_justify-content-lg-between {
    justify-content: space-between !important;
  }

  ._9qOvuW_justify-content-lg-around {
    justify-content: space-around !important;
  }

  ._9qOvuW_justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  ._9qOvuW_align-items-lg-start {
    align-items: flex-start !important;
  }

  ._9qOvuW_align-items-lg-end {
    align-items: flex-end !important;
  }

  ._9qOvuW_align-items-lg-center {
    align-items: center !important;
  }

  ._9qOvuW_align-items-lg-baseline {
    align-items: baseline !important;
  }

  ._9qOvuW_align-items-lg-stretch {
    align-items: stretch !important;
  }

  ._9qOvuW_align-content-lg-start {
    align-content: flex-start !important;
  }

  ._9qOvuW_align-content-lg-end {
    align-content: flex-end !important;
  }

  ._9qOvuW_align-content-lg-center {
    align-content: center !important;
  }

  ._9qOvuW_align-content-lg-between {
    align-content: space-between !important;
  }

  ._9qOvuW_align-content-lg-around {
    align-content: space-around !important;
  }

  ._9qOvuW_align-content-lg-stretch {
    align-content: stretch !important;
  }

  ._9qOvuW_align-self-lg-auto {
    align-self: auto !important;
  }

  ._9qOvuW_align-self-lg-start {
    align-self: flex-start !important;
  }

  ._9qOvuW_align-self-lg-end {
    align-self: flex-end !important;
  }

  ._9qOvuW_align-self-lg-center {
    align-self: center !important;
  }

  ._9qOvuW_align-self-lg-baseline {
    align-self: baseline !important;
  }

  ._9qOvuW_align-self-lg-stretch {
    align-self: stretch !important;
  }

  ._9qOvuW_order-lg-first {
    order: -1 !important;
  }

  ._9qOvuW_order-lg-0 {
    order: 0 !important;
  }

  ._9qOvuW_order-lg-1 {
    order: 1 !important;
  }

  ._9qOvuW_order-lg-2 {
    order: 2 !important;
  }

  ._9qOvuW_order-lg-3 {
    order: 3 !important;
  }

  ._9qOvuW_order-lg-4 {
    order: 4 !important;
  }

  ._9qOvuW_order-lg-5 {
    order: 5 !important;
  }

  ._9qOvuW_order-lg-last {
    order: 6 !important;
  }

  ._9qOvuW_m-lg-0 {
    margin: 0 !important;
  }

  ._9qOvuW_m-lg-1 {
    margin: .25rem !important;
  }

  ._9qOvuW_m-lg-2 {
    margin: .5rem !important;
  }

  ._9qOvuW_m-lg-3 {
    margin: 1rem !important;
  }

  ._9qOvuW_m-lg-4 {
    margin: 1.5rem !important;
  }

  ._9qOvuW_m-lg-5 {
    margin: 3rem !important;
  }

  ._9qOvuW_m-lg-auto {
    margin: auto !important;
  }

  ._9qOvuW_mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._9qOvuW_mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._9qOvuW_mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._9qOvuW_mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._9qOvuW_mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._9qOvuW_mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._9qOvuW_my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._9qOvuW_my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._9qOvuW_mt-lg-0 {
    margin-top: 0 !important;
  }

  ._9qOvuW_mt-lg-1 {
    margin-top: .25rem !important;
  }

  ._9qOvuW_mt-lg-2 {
    margin-top: .5rem !important;
  }

  ._9qOvuW_mt-lg-3 {
    margin-top: 1rem !important;
  }

  ._9qOvuW_mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  ._9qOvuW_mt-lg-5 {
    margin-top: 3rem !important;
  }

  ._9qOvuW_mt-lg-auto {
    margin-top: auto !important;
  }

  ._9qOvuW_me-lg-0 {
    margin-right: 0 !important;
  }

  ._9qOvuW_me-lg-1 {
    margin-right: .25rem !important;
  }

  ._9qOvuW_me-lg-2 {
    margin-right: .5rem !important;
  }

  ._9qOvuW_me-lg-3 {
    margin-right: 1rem !important;
  }

  ._9qOvuW_me-lg-4 {
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_me-lg-5 {
    margin-right: 3rem !important;
  }

  ._9qOvuW_me-lg-auto {
    margin-right: auto !important;
  }

  ._9qOvuW_mb-lg-0 {
    margin-bottom: 0 !important;
  }

  ._9qOvuW_mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_mb-lg-auto {
    margin-bottom: auto !important;
  }

  ._9qOvuW_ms-lg-0 {
    margin-left: 0 !important;
  }

  ._9qOvuW_ms-lg-1 {
    margin-left: .25rem !important;
  }

  ._9qOvuW_ms-lg-2 {
    margin-left: .5rem !important;
  }

  ._9qOvuW_ms-lg-3 {
    margin-left: 1rem !important;
  }

  ._9qOvuW_ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  ._9qOvuW_ms-lg-5 {
    margin-left: 3rem !important;
  }

  ._9qOvuW_ms-lg-auto {
    margin-left: auto !important;
  }

  ._9qOvuW_p-lg-0 {
    padding: 0 !important;
  }

  ._9qOvuW_p-lg-1 {
    padding: .25rem !important;
  }

  ._9qOvuW_p-lg-2 {
    padding: .5rem !important;
  }

  ._9qOvuW_p-lg-3 {
    padding: 1rem !important;
  }

  ._9qOvuW_p-lg-4 {
    padding: 1.5rem !important;
  }

  ._9qOvuW_p-lg-5 {
    padding: 3rem !important;
  }

  ._9qOvuW_px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._9qOvuW_px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._9qOvuW_px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._9qOvuW_px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._9qOvuW_px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._9qOvuW_py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._9qOvuW_py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_pt-lg-0 {
    padding-top: 0 !important;
  }

  ._9qOvuW_pt-lg-1 {
    padding-top: .25rem !important;
  }

  ._9qOvuW_pt-lg-2 {
    padding-top: .5rem !important;
  }

  ._9qOvuW_pt-lg-3 {
    padding-top: 1rem !important;
  }

  ._9qOvuW_pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  ._9qOvuW_pt-lg-5 {
    padding-top: 3rem !important;
  }

  ._9qOvuW_pe-lg-0 {
    padding-right: 0 !important;
  }

  ._9qOvuW_pe-lg-1 {
    padding-right: .25rem !important;
  }

  ._9qOvuW_pe-lg-2 {
    padding-right: .5rem !important;
  }

  ._9qOvuW_pe-lg-3 {
    padding-right: 1rem !important;
  }

  ._9qOvuW_pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_pe-lg-5 {
    padding-right: 3rem !important;
  }

  ._9qOvuW_pb-lg-0 {
    padding-bottom: 0 !important;
  }

  ._9qOvuW_pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_ps-lg-0 {
    padding-left: 0 !important;
  }

  ._9qOvuW_ps-lg-1 {
    padding-left: .25rem !important;
  }

  ._9qOvuW_ps-lg-2 {
    padding-left: .5rem !important;
  }

  ._9qOvuW_ps-lg-3 {
    padding-left: 1rem !important;
  }

  ._9qOvuW_ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  ._9qOvuW_ps-lg-5 {
    padding-left: 3rem !important;
  }

  ._9qOvuW_gap-lg-0 {
    gap: 0 !important;
  }

  ._9qOvuW_gap-lg-1 {
    gap: .25rem !important;
  }

  ._9qOvuW_gap-lg-2 {
    gap: .5rem !important;
  }

  ._9qOvuW_gap-lg-3 {
    gap: 1rem !important;
  }

  ._9qOvuW_gap-lg-4 {
    gap: 1.5rem !important;
  }

  ._9qOvuW_gap-lg-5 {
    gap: 3rem !important;
  }

  ._9qOvuW_text-lg-start {
    text-align: left !important;
  }

  ._9qOvuW_text-lg-end {
    text-align: right !important;
  }

  ._9qOvuW_text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_float-xl-start {
    float: left !important;
  }

  ._9qOvuW_float-xl-end {
    float: right !important;
  }

  ._9qOvuW_float-xl-none {
    float: none !important;
  }

  ._9qOvuW_d-xl-inline {
    display: inline !important;
  }

  ._9qOvuW_d-xl-inline-block {
    display: inline-block !important;
  }

  ._9qOvuW_d-xl-block {
    display: block !important;
  }

  ._9qOvuW_d-xl-grid {
    display: grid !important;
  }

  ._9qOvuW_d-xl-table {
    display: table !important;
  }

  ._9qOvuW_d-xl-table-row {
    display: table-row !important;
  }

  ._9qOvuW_d-xl-table-cell {
    display: table-cell !important;
  }

  ._9qOvuW_d-xl-flex {
    display: flex !important;
  }

  ._9qOvuW_d-xl-inline-flex {
    display: inline-flex !important;
  }

  ._9qOvuW_d-xl-none {
    display: none !important;
  }

  ._9qOvuW_flex-xl-fill {
    flex: auto !important;
  }

  ._9qOvuW_flex-xl-row {
    flex-direction: row !important;
  }

  ._9qOvuW_flex-xl-column {
    flex-direction: column !important;
  }

  ._9qOvuW_flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._9qOvuW_flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._9qOvuW_flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  ._9qOvuW_flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  ._9qOvuW_flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._9qOvuW_flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._9qOvuW_flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  ._9qOvuW_flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._9qOvuW_flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._9qOvuW_justify-content-xl-start {
    justify-content: flex-start !important;
  }

  ._9qOvuW_justify-content-xl-end {
    justify-content: flex-end !important;
  }

  ._9qOvuW_justify-content-xl-center {
    justify-content: center !important;
  }

  ._9qOvuW_justify-content-xl-between {
    justify-content: space-between !important;
  }

  ._9qOvuW_justify-content-xl-around {
    justify-content: space-around !important;
  }

  ._9qOvuW_justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  ._9qOvuW_align-items-xl-start {
    align-items: flex-start !important;
  }

  ._9qOvuW_align-items-xl-end {
    align-items: flex-end !important;
  }

  ._9qOvuW_align-items-xl-center {
    align-items: center !important;
  }

  ._9qOvuW_align-items-xl-baseline {
    align-items: baseline !important;
  }

  ._9qOvuW_align-items-xl-stretch {
    align-items: stretch !important;
  }

  ._9qOvuW_align-content-xl-start {
    align-content: flex-start !important;
  }

  ._9qOvuW_align-content-xl-end {
    align-content: flex-end !important;
  }

  ._9qOvuW_align-content-xl-center {
    align-content: center !important;
  }

  ._9qOvuW_align-content-xl-between {
    align-content: space-between !important;
  }

  ._9qOvuW_align-content-xl-around {
    align-content: space-around !important;
  }

  ._9qOvuW_align-content-xl-stretch {
    align-content: stretch !important;
  }

  ._9qOvuW_align-self-xl-auto {
    align-self: auto !important;
  }

  ._9qOvuW_align-self-xl-start {
    align-self: flex-start !important;
  }

  ._9qOvuW_align-self-xl-end {
    align-self: flex-end !important;
  }

  ._9qOvuW_align-self-xl-center {
    align-self: center !important;
  }

  ._9qOvuW_align-self-xl-baseline {
    align-self: baseline !important;
  }

  ._9qOvuW_align-self-xl-stretch {
    align-self: stretch !important;
  }

  ._9qOvuW_order-xl-first {
    order: -1 !important;
  }

  ._9qOvuW_order-xl-0 {
    order: 0 !important;
  }

  ._9qOvuW_order-xl-1 {
    order: 1 !important;
  }

  ._9qOvuW_order-xl-2 {
    order: 2 !important;
  }

  ._9qOvuW_order-xl-3 {
    order: 3 !important;
  }

  ._9qOvuW_order-xl-4 {
    order: 4 !important;
  }

  ._9qOvuW_order-xl-5 {
    order: 5 !important;
  }

  ._9qOvuW_order-xl-last {
    order: 6 !important;
  }

  ._9qOvuW_m-xl-0 {
    margin: 0 !important;
  }

  ._9qOvuW_m-xl-1 {
    margin: .25rem !important;
  }

  ._9qOvuW_m-xl-2 {
    margin: .5rem !important;
  }

  ._9qOvuW_m-xl-3 {
    margin: 1rem !important;
  }

  ._9qOvuW_m-xl-4 {
    margin: 1.5rem !important;
  }

  ._9qOvuW_m-xl-5 {
    margin: 3rem !important;
  }

  ._9qOvuW_m-xl-auto {
    margin: auto !important;
  }

  ._9qOvuW_mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._9qOvuW_mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._9qOvuW_mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._9qOvuW_mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._9qOvuW_mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._9qOvuW_mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._9qOvuW_my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._9qOvuW_my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._9qOvuW_mt-xl-0 {
    margin-top: 0 !important;
  }

  ._9qOvuW_mt-xl-1 {
    margin-top: .25rem !important;
  }

  ._9qOvuW_mt-xl-2 {
    margin-top: .5rem !important;
  }

  ._9qOvuW_mt-xl-3 {
    margin-top: 1rem !important;
  }

  ._9qOvuW_mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  ._9qOvuW_mt-xl-5 {
    margin-top: 3rem !important;
  }

  ._9qOvuW_mt-xl-auto {
    margin-top: auto !important;
  }

  ._9qOvuW_me-xl-0 {
    margin-right: 0 !important;
  }

  ._9qOvuW_me-xl-1 {
    margin-right: .25rem !important;
  }

  ._9qOvuW_me-xl-2 {
    margin-right: .5rem !important;
  }

  ._9qOvuW_me-xl-3 {
    margin-right: 1rem !important;
  }

  ._9qOvuW_me-xl-4 {
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_me-xl-5 {
    margin-right: 3rem !important;
  }

  ._9qOvuW_me-xl-auto {
    margin-right: auto !important;
  }

  ._9qOvuW_mb-xl-0 {
    margin-bottom: 0 !important;
  }

  ._9qOvuW_mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_mb-xl-auto {
    margin-bottom: auto !important;
  }

  ._9qOvuW_ms-xl-0 {
    margin-left: 0 !important;
  }

  ._9qOvuW_ms-xl-1 {
    margin-left: .25rem !important;
  }

  ._9qOvuW_ms-xl-2 {
    margin-left: .5rem !important;
  }

  ._9qOvuW_ms-xl-3 {
    margin-left: 1rem !important;
  }

  ._9qOvuW_ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  ._9qOvuW_ms-xl-5 {
    margin-left: 3rem !important;
  }

  ._9qOvuW_ms-xl-auto {
    margin-left: auto !important;
  }

  ._9qOvuW_p-xl-0 {
    padding: 0 !important;
  }

  ._9qOvuW_p-xl-1 {
    padding: .25rem !important;
  }

  ._9qOvuW_p-xl-2 {
    padding: .5rem !important;
  }

  ._9qOvuW_p-xl-3 {
    padding: 1rem !important;
  }

  ._9qOvuW_p-xl-4 {
    padding: 1.5rem !important;
  }

  ._9qOvuW_p-xl-5 {
    padding: 3rem !important;
  }

  ._9qOvuW_px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._9qOvuW_px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._9qOvuW_px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._9qOvuW_px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._9qOvuW_px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._9qOvuW_py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._9qOvuW_py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_pt-xl-0 {
    padding-top: 0 !important;
  }

  ._9qOvuW_pt-xl-1 {
    padding-top: .25rem !important;
  }

  ._9qOvuW_pt-xl-2 {
    padding-top: .5rem !important;
  }

  ._9qOvuW_pt-xl-3 {
    padding-top: 1rem !important;
  }

  ._9qOvuW_pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  ._9qOvuW_pt-xl-5 {
    padding-top: 3rem !important;
  }

  ._9qOvuW_pe-xl-0 {
    padding-right: 0 !important;
  }

  ._9qOvuW_pe-xl-1 {
    padding-right: .25rem !important;
  }

  ._9qOvuW_pe-xl-2 {
    padding-right: .5rem !important;
  }

  ._9qOvuW_pe-xl-3 {
    padding-right: 1rem !important;
  }

  ._9qOvuW_pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_pe-xl-5 {
    padding-right: 3rem !important;
  }

  ._9qOvuW_pb-xl-0 {
    padding-bottom: 0 !important;
  }

  ._9qOvuW_pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_ps-xl-0 {
    padding-left: 0 !important;
  }

  ._9qOvuW_ps-xl-1 {
    padding-left: .25rem !important;
  }

  ._9qOvuW_ps-xl-2 {
    padding-left: .5rem !important;
  }

  ._9qOvuW_ps-xl-3 {
    padding-left: 1rem !important;
  }

  ._9qOvuW_ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  ._9qOvuW_ps-xl-5 {
    padding-left: 3rem !important;
  }

  ._9qOvuW_gap-xl-0 {
    gap: 0 !important;
  }

  ._9qOvuW_gap-xl-1 {
    gap: .25rem !important;
  }

  ._9qOvuW_gap-xl-2 {
    gap: .5rem !important;
  }

  ._9qOvuW_gap-xl-3 {
    gap: 1rem !important;
  }

  ._9qOvuW_gap-xl-4 {
    gap: 1.5rem !important;
  }

  ._9qOvuW_gap-xl-5 {
    gap: 3rem !important;
  }

  ._9qOvuW_text-xl-start {
    text-align: left !important;
  }

  ._9qOvuW_text-xl-end {
    text-align: right !important;
  }

  ._9qOvuW_text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  ._9qOvuW_float-xxl-start {
    float: left !important;
  }

  ._9qOvuW_float-xxl-end {
    float: right !important;
  }

  ._9qOvuW_float-xxl-none {
    float: none !important;
  }

  ._9qOvuW_d-xxl-inline {
    display: inline !important;
  }

  ._9qOvuW_d-xxl-inline-block {
    display: inline-block !important;
  }

  ._9qOvuW_d-xxl-block {
    display: block !important;
  }

  ._9qOvuW_d-xxl-grid {
    display: grid !important;
  }

  ._9qOvuW_d-xxl-table {
    display: table !important;
  }

  ._9qOvuW_d-xxl-table-row {
    display: table-row !important;
  }

  ._9qOvuW_d-xxl-table-cell {
    display: table-cell !important;
  }

  ._9qOvuW_d-xxl-flex {
    display: flex !important;
  }

  ._9qOvuW_d-xxl-inline-flex {
    display: inline-flex !important;
  }

  ._9qOvuW_d-xxl-none {
    display: none !important;
  }

  ._9qOvuW_flex-xxl-fill {
    flex: auto !important;
  }

  ._9qOvuW_flex-xxl-row {
    flex-direction: row !important;
  }

  ._9qOvuW_flex-xxl-column {
    flex-direction: column !important;
  }

  ._9qOvuW_flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  ._9qOvuW_flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  ._9qOvuW_flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  ._9qOvuW_flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  ._9qOvuW_flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  ._9qOvuW_flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  ._9qOvuW_flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  ._9qOvuW_flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  ._9qOvuW_flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  ._9qOvuW_justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  ._9qOvuW_justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  ._9qOvuW_justify-content-xxl-center {
    justify-content: center !important;
  }

  ._9qOvuW_justify-content-xxl-between {
    justify-content: space-between !important;
  }

  ._9qOvuW_justify-content-xxl-around {
    justify-content: space-around !important;
  }

  ._9qOvuW_justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  ._9qOvuW_align-items-xxl-start {
    align-items: flex-start !important;
  }

  ._9qOvuW_align-items-xxl-end {
    align-items: flex-end !important;
  }

  ._9qOvuW_align-items-xxl-center {
    align-items: center !important;
  }

  ._9qOvuW_align-items-xxl-baseline {
    align-items: baseline !important;
  }

  ._9qOvuW_align-items-xxl-stretch {
    align-items: stretch !important;
  }

  ._9qOvuW_align-content-xxl-start {
    align-content: flex-start !important;
  }

  ._9qOvuW_align-content-xxl-end {
    align-content: flex-end !important;
  }

  ._9qOvuW_align-content-xxl-center {
    align-content: center !important;
  }

  ._9qOvuW_align-content-xxl-between {
    align-content: space-between !important;
  }

  ._9qOvuW_align-content-xxl-around {
    align-content: space-around !important;
  }

  ._9qOvuW_align-content-xxl-stretch {
    align-content: stretch !important;
  }

  ._9qOvuW_align-self-xxl-auto {
    align-self: auto !important;
  }

  ._9qOvuW_align-self-xxl-start {
    align-self: flex-start !important;
  }

  ._9qOvuW_align-self-xxl-end {
    align-self: flex-end !important;
  }

  ._9qOvuW_align-self-xxl-center {
    align-self: center !important;
  }

  ._9qOvuW_align-self-xxl-baseline {
    align-self: baseline !important;
  }

  ._9qOvuW_align-self-xxl-stretch {
    align-self: stretch !important;
  }

  ._9qOvuW_order-xxl-first {
    order: -1 !important;
  }

  ._9qOvuW_order-xxl-0 {
    order: 0 !important;
  }

  ._9qOvuW_order-xxl-1 {
    order: 1 !important;
  }

  ._9qOvuW_order-xxl-2 {
    order: 2 !important;
  }

  ._9qOvuW_order-xxl-3 {
    order: 3 !important;
  }

  ._9qOvuW_order-xxl-4 {
    order: 4 !important;
  }

  ._9qOvuW_order-xxl-5 {
    order: 5 !important;
  }

  ._9qOvuW_order-xxl-last {
    order: 6 !important;
  }

  ._9qOvuW_m-xxl-0 {
    margin: 0 !important;
  }

  ._9qOvuW_m-xxl-1 {
    margin: .25rem !important;
  }

  ._9qOvuW_m-xxl-2 {
    margin: .5rem !important;
  }

  ._9qOvuW_m-xxl-3 {
    margin: 1rem !important;
  }

  ._9qOvuW_m-xxl-4 {
    margin: 1.5rem !important;
  }

  ._9qOvuW_m-xxl-5 {
    margin: 3rem !important;
  }

  ._9qOvuW_m-xxl-auto {
    margin: auto !important;
  }

  ._9qOvuW_mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  ._9qOvuW_mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  ._9qOvuW_mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  ._9qOvuW_mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  ._9qOvuW_mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  ._9qOvuW_mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  ._9qOvuW_my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ._9qOvuW_my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  ._9qOvuW_mt-xxl-0 {
    margin-top: 0 !important;
  }

  ._9qOvuW_mt-xxl-1 {
    margin-top: .25rem !important;
  }

  ._9qOvuW_mt-xxl-2 {
    margin-top: .5rem !important;
  }

  ._9qOvuW_mt-xxl-3 {
    margin-top: 1rem !important;
  }

  ._9qOvuW_mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  ._9qOvuW_mt-xxl-5 {
    margin-top: 3rem !important;
  }

  ._9qOvuW_mt-xxl-auto {
    margin-top: auto !important;
  }

  ._9qOvuW_me-xxl-0 {
    margin-right: 0 !important;
  }

  ._9qOvuW_me-xxl-1 {
    margin-right: .25rem !important;
  }

  ._9qOvuW_me-xxl-2 {
    margin-right: .5rem !important;
  }

  ._9qOvuW_me-xxl-3 {
    margin-right: 1rem !important;
  }

  ._9qOvuW_me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  ._9qOvuW_me-xxl-5 {
    margin-right: 3rem !important;
  }

  ._9qOvuW_me-xxl-auto {
    margin-right: auto !important;
  }

  ._9qOvuW_mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  ._9qOvuW_mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  ._9qOvuW_mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  ._9qOvuW_mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  ._9qOvuW_mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  ._9qOvuW_mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  ._9qOvuW_mb-xxl-auto {
    margin-bottom: auto !important;
  }

  ._9qOvuW_ms-xxl-0 {
    margin-left: 0 !important;
  }

  ._9qOvuW_ms-xxl-1 {
    margin-left: .25rem !important;
  }

  ._9qOvuW_ms-xxl-2 {
    margin-left: .5rem !important;
  }

  ._9qOvuW_ms-xxl-3 {
    margin-left: 1rem !important;
  }

  ._9qOvuW_ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  ._9qOvuW_ms-xxl-5 {
    margin-left: 3rem !important;
  }

  ._9qOvuW_ms-xxl-auto {
    margin-left: auto !important;
  }

  ._9qOvuW_p-xxl-0 {
    padding: 0 !important;
  }

  ._9qOvuW_p-xxl-1 {
    padding: .25rem !important;
  }

  ._9qOvuW_p-xxl-2 {
    padding: .5rem !important;
  }

  ._9qOvuW_p-xxl-3 {
    padding: 1rem !important;
  }

  ._9qOvuW_p-xxl-4 {
    padding: 1.5rem !important;
  }

  ._9qOvuW_p-xxl-5 {
    padding: 3rem !important;
  }

  ._9qOvuW_px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ._9qOvuW_px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  ._9qOvuW_px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  ._9qOvuW_px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  ._9qOvuW_px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  ._9qOvuW_py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._9qOvuW_py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_pt-xxl-0 {
    padding-top: 0 !important;
  }

  ._9qOvuW_pt-xxl-1 {
    padding-top: .25rem !important;
  }

  ._9qOvuW_pt-xxl-2 {
    padding-top: .5rem !important;
  }

  ._9qOvuW_pt-xxl-3 {
    padding-top: 1rem !important;
  }

  ._9qOvuW_pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  ._9qOvuW_pt-xxl-5 {
    padding-top: 3rem !important;
  }

  ._9qOvuW_pe-xxl-0 {
    padding-right: 0 !important;
  }

  ._9qOvuW_pe-xxl-1 {
    padding-right: .25rem !important;
  }

  ._9qOvuW_pe-xxl-2 {
    padding-right: .5rem !important;
  }

  ._9qOvuW_pe-xxl-3 {
    padding-right: 1rem !important;
  }

  ._9qOvuW_pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  ._9qOvuW_pe-xxl-5 {
    padding-right: 3rem !important;
  }

  ._9qOvuW_pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  ._9qOvuW_pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  ._9qOvuW_pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  ._9qOvuW_pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  ._9qOvuW_pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  ._9qOvuW_pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  ._9qOvuW_ps-xxl-0 {
    padding-left: 0 !important;
  }

  ._9qOvuW_ps-xxl-1 {
    padding-left: .25rem !important;
  }

  ._9qOvuW_ps-xxl-2 {
    padding-left: .5rem !important;
  }

  ._9qOvuW_ps-xxl-3 {
    padding-left: 1rem !important;
  }

  ._9qOvuW_ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  ._9qOvuW_ps-xxl-5 {
    padding-left: 3rem !important;
  }

  ._9qOvuW_gap-xxl-0 {
    gap: 0 !important;
  }

  ._9qOvuW_gap-xxl-1 {
    gap: .25rem !important;
  }

  ._9qOvuW_gap-xxl-2 {
    gap: .5rem !important;
  }

  ._9qOvuW_gap-xxl-3 {
    gap: 1rem !important;
  }

  ._9qOvuW_gap-xxl-4 {
    gap: 1.5rem !important;
  }

  ._9qOvuW_gap-xxl-5 {
    gap: 3rem !important;
  }

  ._9qOvuW_text-xxl-start {
    text-align: left !important;
  }

  ._9qOvuW_text-xxl-end {
    text-align: right !important;
  }

  ._9qOvuW_text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  ._9qOvuW_fs-1 {
    font-size: 2.5rem !important;
  }

  ._9qOvuW_fs-2 {
    font-size: 2rem !important;
  }

  ._9qOvuW_fs-3 {
    font-size: 1.75rem !important;
  }

  ._9qOvuW_fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  ._9qOvuW_d-print-inline {
    display: inline !important;
  }

  ._9qOvuW_d-print-inline-block {
    display: inline-block !important;
  }

  ._9qOvuW_d-print-block {
    display: block !important;
  }

  ._9qOvuW_d-print-grid {
    display: grid !important;
  }

  ._9qOvuW_d-print-table {
    display: table !important;
  }

  ._9qOvuW_d-print-table-row {
    display: table-row !important;
  }

  ._9qOvuW_d-print-table-cell {
    display: table-cell !important;
  }

  ._9qOvuW_d-print-flex {
    display: flex !important;
  }

  ._9qOvuW_d-print-inline-flex {
    display: inline-flex !important;
  }

  ._9qOvuW_d-print-none {
    display: none !important;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

._9qOvuW_wrapper {
  height: 100%;
  width: 100%;
}

._9qOvuW_wrapper ._9qOvuW_logo {
  height: 65px;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

._9qOvuW_wrapper ._9qOvuW_logo h1, ._9qOvuW_wrapper ._9qOvuW_logo ._9qOvuW_h1 {
  all: unset;
  letter-spacing: .524245px;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

._9qOvuW_wrapper ._9qOvuW_menu {
  height: calc(100% - 65px);
  width: 100%;
  padding-top: 31px;
  overflow-y: scroll;
}

._9qOvuW_wrapper ._9qOvuW_menu ._9qOvuW_menuItem {
  all: unset;
  width: 100%;
  cursor: pointer;
  align-items: center;
  padding: 18px 28px;
  display: flex;
}

._9qOvuW_wrapper ._9qOvuW_menu ._9qOvuW_menuItem._9qOvuW_active {
  color: #fff;
  background-color: #258e01;
}

._9qOvuW_wrapper ._9qOvuW_menu ._9qOvuW_menuItem ._9qOvuW_menuItemText {
  all: unset;
  letter-spacing: .25px;
  text-align: left;
  margin-left: 13px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.OoTH8q_container {
  height: 505px;
  width: 552px;
  border-radius: 20px;
  top: 37px;
  left: 579px;
}

.OoTH8q_container .OoTH8q_header {
  width: 187px;
  height: 42px;
  color: #3f434a;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  position: absolute;
  top: 45px;
  left: 57px;
}

.OoTH8q_container .OoTH8q_body {
  height: 94.5px;
  width: 94.5px;
  margin-top: 20px;
  top: 0;
  left: 0;
}

.i-KNTa_module {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding-top: 20px;
}

._5bptxG_module {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding-top: 20px;
}

.kytRNq_wrapper {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.kytRNq_wrapper .kytRNq_nav {
  float: left;
  width: 420px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.kytRNq_wrapper .kytRNq_nav .kytRNq_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.kytRNq_wrapper .kytRNq_nav .kytRNq_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.kytRNq_wrapper .kytRNq_alginLeft {
  height: 100%;
}

.j9og9q_wrapper {
  height: 65px;
  width: 100%;
  padding: 0;
}

.j9og9q_wrapper .j9og9q_title {
  float: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  display: flex;
}

.j9og9q_wrapper .j9og9q_icon {
  float: right;
  padding: 10px;
  display: flex;
}

.aZP6_q_wrapper {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.aZP6_q_wrapper .aZP6_q_search {
  width: 100%;
  height: 75px;
  padding: 20px;
  display: flex;
}

.aZP6_q_wrapper .aZP6_q_search .aZP6_q_inputIcons {
  height: 100%;
  border-radius: 20px;
  display: flex;
}

.aZP6_q_wrapper .aZP6_q_search .aZP6_q_inputIcons .aZP6_q_inputField {
  height: 100%;
  border-radius: 20px;
  padding: 10px;
  display: flex;
}

.SBZH1W_navAl {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.SBZH1W_navAl .SBZH1W_nav {
  float: left;
  width: 420px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.SBZH1W_navAl .SBZH1W_nav .SBZH1W_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.SBZH1W_navAl .SBZH1W_nav .SBZH1W_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.SBZH1W_mainBody {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.SBZH1W_mainBody .SBZH1W_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.SBZH1W_mainBody .SBZH1W_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.Uvr-HG_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.Uvr-HG_container {
  height: 45px;
  width: 100%;
  padding: 31px;
}

.Uvr-HG_container .Uvr-HG_text {
  height: 100%;
  width: 100%;
  display: flex;
}

.Uvr-HG_container .Uvr-HG_text .Uvr-HG_button {
  color: #000;
  background-color: #cabcbc;
  border-radius: 20px;
}

.Uvr-HG_mindash {
  width: 100%;
  height: 120px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 30px;
  display: flex;
}

.Uvr-HG_mindash .Uvr-HG_divider {
  width: .12%;
  height: 100px;
  background-color: #d9d9d9;
  margin: 10px;
  display: flex;
}

.eTr8tW_wrapper {
  height: 65px;
  width: 100%;
}

.eTr8tW_wrapper .eTr8tW_title {
  float: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  display: flex;
}

.eTr8tW_wrapper .eTr8tW_icon {
  float: right;
  padding: 10px;
  display: flex;
}

.eTr8tW_navAl {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.eTr8tW_navAl .eTr8tW_nav {
  float: left;
  width: 420px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.eTr8tW_navAl .eTr8tW_nav .eTr8tW_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.eTr8tW_navAl .eTr8tW_nav .eTr8tW_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.eTr8tW_mainBody {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.eTr8tW_mainBody .eTr8tW_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.eTr8tW_mainBody .eTr8tW_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.VBukJG_wrapper {
  height: 65px;
  width: 100%;
  padding: 0;
}

.VBukJG_wrapper .VBukJG_title {
  float: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  display: flex;
}

.VBukJG_wrapper .VBukJG_icon {
  float: right;
  padding: 10px;
  display: flex;
}

.VBukJG_navAl {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.VBukJG_navAl .VBukJG_nav {
  float: left;
  width: 420px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.VBukJG_navAl .VBukJG_nav .VBukJG_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.VBukJG_navAl .VBukJG_nav .VBukJG_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.VBukJG_mainBody {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.VBukJG_mainBody .VBukJG_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.VBukJG_mainBody .VBukJG_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.ZCRwpW_wrapper {
  height: 65px;
  width: 100%;
  padding: 0;
}

.ZCRwpW_wrapper .ZCRwpW_title {
  float: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  display: flex;
}

.ZCRwpW_wrapper .ZCRwpW_icon {
  float: right;
  padding: 4px;
  display: flex;
}

.ZCRwpW_navAl {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.ZCRwpW_navAl .ZCRwpW_nav {
  float: left;
  width: 560px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.ZCRwpW_navAl .ZCRwpW_nav .ZCRwpW_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.ZCRwpW_navAl .ZCRwpW_nav .ZCRwpW_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.ZCRwpW_mainBody {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.ZCRwpW_mainBody .ZCRwpW_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.ZCRwpW_mainBody .ZCRwpW_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.ZBdoRG_wrapper {
  height: 65px;
  width: 100%;
}

.ZBdoRG_wrapper .ZBdoRG_title {
  float: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  display: flex;
}

.ZBdoRG_wrapper .ZBdoRG_icon {
  float: right;
  padding: 10px;
  display: flex;
}

.ZBdoRG_navAl {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.ZBdoRG_navAl .ZBdoRG_nav {
  float: left;
  width: 420px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.ZBdoRG_navAl .ZBdoRG_nav .ZBdoRG_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.ZBdoRG_navAl .ZBdoRG_nav .ZBdoRG_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.ZBdoRG_mainBody {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.ZBdoRG_mainBody .ZBdoRG_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.ZBdoRG_mainBody .ZBdoRG_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.yDxuba_wrapper {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.yDxuba_wrapper .yDxuba_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.yDxuba_wrapper .yDxuba_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.Y1S-Kq_wrapper {
  height: 65px;
  width: 100%;
  padding: 0;
}

.Y1S-Kq_wrapper .Y1S-Kq_title {
  float: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  display: flex;
}

.Y1S-Kq_wrapper .Y1S-Kq_icon {
  float: right;
  padding: 10px;
  display: flex;
}

.Y1S-Kq_navAl {
  height: 38px;
  width: 100%;
  padding: 0;
  display: flex;
}

.Y1S-Kq_navAl .Y1S-Kq_nav {
  float: left;
  width: 420px;
  height: 38px;
  background-color: #ddd;
  border-radius: 20px;
  display: flex;
}

.Y1S-Kq_navAl .Y1S-Kq_nav .Y1S-Kq_active {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px;
}

.Y1S-Kq_navAl .Y1S-Kq_nav .Y1S-Kq_link {
  width: 140px;
  height: 38px;
  text-align: center;
  color: #52575c;
  padding: 7px;
}

.Y1S-Kq_mainBody {
  width: 100%;
  height: 750px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.Y1S-Kq_mainBody .Y1S-Kq_inputSerach {
  width: 100%;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 6px;
}

.Y1S-Kq_mainBody .Y1S-Kq_datatable {
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #258e01;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 37, 142, 1;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #258e01;
  --bs-link-hover-color: #1e7201;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d3e8cc;
  --bs-table-border-color: #bed1b8;
  --bs-table-striped-bg: #c8dcc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bed1b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3d7bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #92c780;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.form-check-input:checked {
  background-color: #258e01;
  border-color: #258e01;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #258e01;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #258e01;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2392c780'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #258e0140;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #beddb3;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #258e01;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #beddb3;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #469f27;
  --bs-btn-hover-border-color: #3b991a;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #51a534;
  --bs-btn-active-border-color: #3b991a;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #258e01;
  --bs-btn-disabled-border-color: #258e01;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #258e01;
  --bs-btn-border-color: #258e01;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #258e01;
  --bs-btn-hover-border-color: #258e01;
  --bs-btn-focus-shadow-rgb: 37, 142, 1;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #258e01;
  --bs-btn-active-border-color: #258e01;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #258e01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #258e01;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 31, 121, 1;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #258e01;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #258e01;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23218001'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #92c780;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #218001;
  --bs-accordion-active-bg: #e9f4e6;
}

.accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #258e0140;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #258e01;
  --bs-pagination-active-border-color: #258e01;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #165501;
  --bs-alert-bg: #d3e8cc;
  --bs-alert-border-color: #beddb3;
}

.alert-primary .alert-link {
  color: #124401;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #258e01;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #258e01;
  --bs-list-group-active-border-color: #258e01;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #165501;
  background-color: #d3e8cc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #165501;
  background-color: #bed1b8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #165501;
  border-color: #165501;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #258e0140;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(37, 142, 1, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #258e01 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #51a534 !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.8029aa9a.css.map */
