.container {
    height: 505px;
    width: 552px;
    left: 579px;
    top: 37px;
    border-radius: 20px;


    .header {
        position: absolute;
        width: 187px;
        height: 42px;
        left: 57px;
        top: 45px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        color: #3F434A;
    }

    .body {
        margin-top: 20px;
        height: 94.5px;
        width: 94.5px;
        left: 0px;
        top: 0px;



    }


}